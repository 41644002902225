.cta-image-container {
	position: relative;

	.arrow-shape {
		position: absolute;
		top: -3rem;
		left: 65%;
		transform: translateX(-50%);
		width: 33px;
		height: 90px;
	}
}

.cta-img {
	max-height: 450px;
	overflow: hidden;
	background: linear-gradient(white, white) padding-box, linear-gradient(to bottom, $primary, transparent) border-box;
	border: 1px solid transparent;
	border-bottom: 0;
}

.cta-section {
	overflow: hidden;
	position: relative;
	z-index: 1;

	.bg-shape {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		height: auto;
		opacity: 0.5;
		z-index: -1;

		@include media-breakpoint-up(lg) {
			width: 85%;
			opacity: 1;
		}
	}

	&.style-2 {
		.cta-image-container {
			.arrow-shape {
				position: absolute;
				top: -3rem;
				left: auto;
				right: 2rem;
				width: 50px;
				transform: none;

				@include media-breakpoint-up(lg) {
					top: -3rem;
					left: -3rem;
					width: 82px;
					height: 40px;
				}

				@include media-breakpoint-up(xl) {
					top: 1rem;
					left: -6rem;
				}
			}
		}

		.cta-img {
			background: none;
			border: 1px solid $border-color;
		}
	}

	.gradient-bg {
		position: relative;
		overflow: hidden;

		&::before {
			content: "";
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			background: url(../images/bg/1.jpg) no-repeat center center / cover;
			z-index: -1;
			border-radius: inherit;
		}
	}
}
