.blog-card {
	.post-title {
		a {
			color: $dark;
			text-decoration: none;

			&:hover {
				color: $primary;
			}
		}
	}
}

.meta-list {
	li + li {
		padding-left: 10px;
		&::before {
			content: "-";
			float: left;
			padding-right: 10px;
		}
	}
}

.post-author {
	a {
		color: inherit;
		text-decoration: none;

		&:hover {
			color: $primary;
		}
	}
}

article {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: 1.5rem;
	}

	p,
	ul,
	ol {
		margin-bottom: 1.5rem;
		line-height: 1.625;
	}

	img {
		margin-bottom: 2rem;
	}
}

// Pagination

.pagination {
	.page-link {
		width: 40px;
		height: 40px;
		border-radius: 0.5rem;
		display: flex;
		align-items: center;
		justify-content: center;

		&.disabled {
			opacity: 0.8;
		}
	}
}
