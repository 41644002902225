.features-section {

    .blurry-shape {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 1000px;
        z-index: -1;
    }
}

.list-check {
    li {
        display: flex;
        gap: 1rem;

        .icon {
            width: 1.25rem;
            height: 1.25rem;
            margin-top: 2px;
        }

        &+li {
            margin-top: .5rem;
        }
    }
}

.hover-border {
    border: 1px solid transparent;
    transition: $transition-base;

    &:hover {
        border-color: $primary;
    }
}