.footer {
	position: relative;
	z-index: 1;

	&.bg-color-blur {
		&::before {
			content: "";
			position: absolute;
			inset: 0;
			display: block;
			width: 100%;
			height: 100%;
			z-index: -1;
			filter: blur(100px);
			background: url(../images/shapes/stripe-colored.svg) no-repeat center center / cover;
		}
	}
}
