.navbar-brand {
	img {
		max-width: 165px;
	}
}

.navbar-toggler {
	padding: 5px;
	border: 0;

	&:focus {
		box-shadow: 0 0 0 0.25rem rgba($primary, 0.2);
	}

	.navbar-toggler-icon {
		position: relative;
		width: 2rem;
		height: 1.5rem;
		background: none;

		span {
			position: absolute;
			display: block;
			width: 100%;
			height: 2px;
			border-radius: 100px;
			background: $primary;
			transition: $transition-base;
			transform: rotate(0);
			transform-origin: center;
			will-change: transform;

			&:first-child {
				top: 0;
			}

			&:nth-child(2) {
				top: 50%;
				transform: translateY(-50%);
			}

			&:last-child {
				top: calc(100% - 3px);
			}
		}
	}

	&[aria-expanded="true"] {
		.navbar-toggler-icon {
			span {
				&:first-child {
					top: 50%;
					transform: rotate(-45deg);
				}

				&:nth-child(2) {
					opacity: 0;
				}

				&:last-child {
					top: 50%;
					transform: rotate(45deg);
				}
			}
		}
	}
}

.dropdown-toggle::after {
	border-style: solid;
	border-width: 0.1em 0.1em 0 0;
	border-color: inherit;

	content: "";
	display: inline-block;
	height: 0.45em;
	transform: rotate(135deg);
	vertical-align: 0.15em;
	width: 0.45em;
}

.dropdown-item {
	position: relative;
	&:hover,
	&:focus {
		transform: translateX(5px);
	}
}

.navbar-nav {
	.dropdown-menu {
		@include media-breakpoint-up(lg) {
			display: block;
			opacity: 0;
			visibility: hidden;
			transform: translateY(10px);
			transition: $transition-base;

			&.show {
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.dropdown {
			&:hover {
				.dropdown-menu {
					opacity: 1;
					visibility: visible;
					transform: translateY(0);
				}
			}
		}
	}

	.megamenu {
		&-cols-2 {
			@include media-breakpoint-up(lg) {
				columns: 2;
			}
		}
		&-cols-3 {
			@include media-breakpoint-up(lg) {
				columns: 3;
			}
		}
	}
}

@include media-breakpoint-down(lg) {
	.navbar-content-inner {
		max-height: 70vh;
		overflow-y: auto;
	}
}

.headroom {
	will-change: transform;
	transition: all 0.2s ease-in-out;
}

.headroom--pinned {
	transform: translateY(0%);
	opacity: 1;
	box-shadow: 0 5px 15px rgba($primary, 0.2);
}

.headroom--unpinned {
	transform: translateY(-100%);
	opacity: 0;
}

.headroom--top {
	box-shadow: none;
}

.navbar {
	&.bg-hover-scroll {
		&:hover,
		&.headroom--not-top {
			background: $white;
		}
	}
}
