@charset "UTF-8";
/* ====================================
Template Name: GenAI
Description: AI Content Writing & Copywriting HTML5 Landing Page Template
Template URI: https://themeforest.net/item/genai-ai-based-copywriting-and-content-writing-landing-page-template/45150495
Author: Marvel Theme
Author URI: https://themeforest.net/user/marvel_theme
Version: 1.1
Published: 1 May 2023
Last Update: 9 May 2023
==================================== */
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
/* 
============================================
Colors
============================================
*/
:root,
[data-bs-theme=light] {
  --gen-blue: #0d6efd;
  --gen-indigo: #6610f2;
  --gen-purple: #6f42c1;
  --gen-pink: #d63384;
  --gen-red: #dc3545;
  --gen-orange: #fd7e14;
  --gen-yellow: #ffc107;
  --gen-green: #198754;
  --gen-teal: #20c997;
  --gen-cyan: #0dcaf0;
  --gen-black: #000;
  --gen-white: #fff;
  --gen-gray: #6c757d;
  --gen-gray-dark: #343a40;
  --gen-gray-100: #f8f9fa;
  --gen-gray-200: #e9ecef;
  --gen-gray-300: #dee2e6;
  --gen-gray-400: #ced4da;
  --gen-gray-500: #adb5bd;
  --gen-gray-600: #6c757d;
  --gen-gray-700: #495057;
  --gen-gray-800: #343a40;
  --gen-gray-900: #212529;
  --gen-primary: #6a4dff;
  --gen-secondary: #6c757d;
  --gen-success: #198754;
  --gen-info: #0dcaf0;
  --gen-warning: #ffc107;
  --gen-danger: #dc3545;
  --gen-light: #bdbfd4;
  --gen-dark: #11121c;
  --gen-white: #fff;
  --gen-black: #000;
  --gen-yellow: #e6f85e;
  --gen-blue: #6a4dff;
  --gen-light-blue: #f3f8ff;
  --gen-light-blue-2: #eae6ff;
  --gen-light-blue-3: #bdbfd4;
  --gen-light-blue-4: #c3d1e3;
  --gen-light-blue-5: #b7a9ff;
  --gen-light-blue-6: #747694;
  --gen-dark-blue: #2e2f45;
  --gen-dark-blue-2: #262838;
  --gen-dark-blue-3: #191a28;
  --gen-dark-blue-4: #171825;
  --gen-dark-blue-5: #11121c;
  --gen-primary-dark: #e6f85e;
  --gen-primary-rgb: 106, 77, 255;
  --gen-secondary-rgb: 108, 117, 125;
  --gen-success-rgb: 25, 135, 84;
  --gen-info-rgb: 13, 202, 240;
  --gen-warning-rgb: 255, 193, 7;
  --gen-danger-rgb: 220, 53, 69;
  --gen-light-rgb: 189, 191, 212;
  --gen-dark-rgb: 17, 18, 28;
  --gen-white-rgb: 255, 255, 255;
  --gen-black-rgb: 0, 0, 0;
  --gen-yellow-rgb: 230, 248, 94;
  --gen-blue-rgb: 106, 77, 255;
  --gen-light-blue-rgb: 243, 248, 255;
  --gen-light-blue-2-rgb: 234, 230, 255;
  --gen-light-blue-3-rgb: 189, 191, 212;
  --gen-light-blue-4-rgb: 195, 209, 227;
  --gen-light-blue-5-rgb: 183, 169, 255;
  --gen-light-blue-6-rgb: 116, 118, 148;
  --gen-dark-blue-rgb: 46, 47, 69;
  --gen-dark-blue-2-rgb: 38, 40, 56;
  --gen-dark-blue-3-rgb: 25, 26, 40;
  --gen-dark-blue-4-rgb: 23, 24, 37;
  --gen-dark-blue-5-rgb: 17, 18, 28;
  --gen-primary-dark-rgb: 230, 248, 94;
  --gen-primary-text-emphasis: #2a1f66;
  --gen-secondary-text-emphasis: #2b2f32;
  --gen-success-text-emphasis: #0a3622;
  --gen-info-text-emphasis: #055160;
  --gen-warning-text-emphasis: #664d03;
  --gen-danger-text-emphasis: #58151c;
  --gen-light-text-emphasis: #495057;
  --gen-dark-text-emphasis: #495057;
  --gen-primary-bg-subtle: #e1dbff;
  --gen-secondary-bg-subtle: #e2e3e5;
  --gen-success-bg-subtle: #d1e7dd;
  --gen-info-bg-subtle: #cff4fc;
  --gen-warning-bg-subtle: #fff3cd;
  --gen-danger-bg-subtle: #f8d7da;
  --gen-light-bg-subtle: #fcfcfd;
  --gen-dark-bg-subtle: #ced4da;
  --gen-primary-border-subtle: #c3b8ff;
  --gen-secondary-border-subtle: #c4c8cb;
  --gen-success-border-subtle: #a3cfbb;
  --gen-info-border-subtle: #9eeaf9;
  --gen-warning-border-subtle: #ffe69c;
  --gen-danger-border-subtle: #f1aeb5;
  --gen-light-border-subtle: #e9ecef;
  --gen-dark-border-subtle: #adb5bd;
  --gen-white-rgb: 255, 255, 255;
  --gen-black-rgb: 0, 0, 0;
  --gen-font-sans-serif: "Space Grotesk", system-ui, -apple-system, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --gen-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --gen-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --gen-body-font-family: var(--gen-font-sans-serif);
  --gen-body-font-size: 1rem;
  --gen-body-font-weight: 400;
  --gen-body-line-height: 1.5;
  --gen-body-color: #11121c;
  --gen-body-color-rgb: 17, 18, 28;
  --gen-body-bg: #fff;
  --gen-body-bg-rgb: 255, 255, 255;
  --gen-emphasis-color: #000;
  --gen-emphasis-color-rgb: 0, 0, 0;
  --gen-secondary-color: rgba(17, 18, 28, 0.75);
  --gen-secondary-color-rgb: 17, 18, 28;
  --gen-secondary-bg: #e9ecef;
  --gen-secondary-bg-rgb: 233, 236, 239;
  --gen-tertiary-color: rgba(17, 18, 28, 0.5);
  --gen-tertiary-color-rgb: 17, 18, 28;
  --gen-tertiary-bg: #f8f9fa;
  --gen-tertiary-bg-rgb: 248, 249, 250;
  --gen-link-color: #6a4dff;
  --gen-link-color-rgb: 106, 77, 255;
  --gen-link-decoration: underline;
  --gen-link-hover-color: #553ecc;
  --gen-link-hover-color-rgb: 85, 62, 204;
  --gen-code-color: #d63384;
  --gen-highlight-bg: #fff3cd;
  --gen-border-width: 1px;
  --gen-border-style: solid;
  --gen-border-color: #bdbfd4;
  --gen-border-color-translucent: rgba(0, 0, 0, 0.175);
  --gen-border-radius: 0.5rem;
  --gen-border-radius-sm: 0.25rem;
  --gen-border-radius-lg: 0.75rem;
  --gen-border-radius-xl: 1rem;
  --gen-border-radius-xxl: 1.25rem;
  --gen-border-radius-2xl: var(--gen-border-radius-xxl);
  --gen-border-radius-pill: 50rem;
  --gen-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --gen-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --gen-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --gen-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --gen-focus-ring-width: 0.25rem;
  --gen-focus-ring-opacity: 0.25;
  --gen-focus-ring-color: rgba(106, 77, 255, 0.25);
  --gen-form-valid-color: #198754;
  --gen-form-valid-border-color: #198754;
  --gen-form-invalid-color: #dc3545;
  --gen-form-invalid-border-color: #dc3545;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --gen-body-color: #bdbfd4;
  --gen-body-color-rgb: 189, 191, 212;
  --gen-body-bg: #11121c;
  --gen-body-bg-rgb: 17, 18, 28;
  --gen-emphasis-color: #fff;
  --gen-emphasis-color-rgb: 255, 255, 255;
  --gen-secondary-color: rgba(189, 191, 212, 0.75);
  --gen-secondary-color-rgb: 189, 191, 212;
  --gen-secondary-bg: #343a40;
  --gen-secondary-bg-rgb: 52, 58, 64;
  --gen-tertiary-color: rgba(189, 191, 212, 0.5);
  --gen-tertiary-color-rgb: 189, 191, 212;
  --gen-tertiary-bg: #2b3035;
  --gen-tertiary-bg-rgb: 43, 48, 53;
  --gen-primary-text-emphasis: #a694ff;
  --gen-secondary-text-emphasis: #a7acb1;
  --gen-success-text-emphasis: #75b798;
  --gen-info-text-emphasis: #6edff6;
  --gen-warning-text-emphasis: #ffda6a;
  --gen-danger-text-emphasis: #ea868f;
  --gen-light-text-emphasis: #f8f9fa;
  --gen-dark-text-emphasis: #dee2e6;
  --gen-primary-bg-subtle: #150f33;
  --gen-secondary-bg-subtle: #161719;
  --gen-success-bg-subtle: #051b11;
  --gen-info-bg-subtle: #032830;
  --gen-warning-bg-subtle: #332701;
  --gen-danger-bg-subtle: #2c0b0e;
  --gen-light-bg-subtle: #343a40;
  --gen-dark-bg-subtle: #1a1d20;
  --gen-primary-border-subtle: #402e99;
  --gen-secondary-border-subtle: #41464b;
  --gen-success-border-subtle: #0f5132;
  --gen-info-border-subtle: #087990;
  --gen-warning-border-subtle: #997404;
  --gen-danger-border-subtle: #842029;
  --gen-light-border-subtle: #495057;
  --gen-dark-border-subtle: #343a40;
  --gen-link-color: #e6f85e;
  --gen-link-hover-color: #ebf97e;
  --gen-link-color-rgb: 230, 248, 94;
  --gen-link-hover-color-rgb: 235, 249, 126;
  --gen-code-color: #e685b5;
  --gen-border-color: #2e2f45;
  --gen-border-color-translucent: rgba(255, 255, 255, 0.15);
  --gen-form-valid-color: #75b798;
  --gen-form-valid-border-color: #75b798;
  --gen-form-invalid-color: #ea868f;
  --gen-form-invalid-border-color: #ea868f;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--gen-body-font-family);
  font-size: var(--gen-body-font-size);
  font-weight: var(--gen-body-font-weight);
  line-height: var(--gen-body-line-height);
  color: var(--gen-body-color);
  text-align: var(--gen-body-text-align);
  background-color: var(--gen-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--gen-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.25;
  color: var(--gen-heading-color, inherit);
}

h1, .h1 {
  font-size: clamp(2.25rem, 1.7143rem + 1.7143vw, 3rem);
}

h2, .h2 {
  font-size: clamp(2rem, 1.6429rem + 1.1429vw, 2.5rem);
}

h3, .h3 {
  font-size: clamp(1.75rem, 1.5714rem + 0.5714vw, 2rem);
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1.125rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--gen-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--gen-link-color-rgb), var(--gen-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --gen-link-color-rgb: var(--gen-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--gen-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--gen-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--gen-body-bg);
  background-color: var(--gen-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--gen-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--gen-body-bg);
  border: var(--gen-border-width) solid var(--gen-border-color);
  border-radius: var(--gen-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--gen-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --gen-gutter-x: 3rem;
  --gen-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--gen-gutter-x) * 0.5);
  padding-left: calc(var(--gen-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1344px;
  }
}
:root {
  --gen-breakpoint-xs: 0;
  --gen-breakpoint-sm: 576px;
  --gen-breakpoint-md: 768px;
  --gen-breakpoint-lg: 992px;
  --gen-breakpoint-xl: 1200px;
  --gen-breakpoint-xxl: 1400px;
}

.row {
  --gen-gutter-x: 2rem;
  --gen-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--gen-gutter-y));
  margin-right: calc(-0.5 * var(--gen-gutter-x));
  margin-left: calc(-0.5 * var(--gen-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--gen-gutter-x) * 0.5);
  padding-left: calc(var(--gen-gutter-x) * 0.5);
  margin-top: var(--gen-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --gen-gutter-x: 0;
}

.g-0,
.gy-0 {
  --gen-gutter-y: 0;
}

.g-1,
.gx-1 {
  --gen-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --gen-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --gen-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --gen-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --gen-gutter-x: 0.75rem;
}

.g-3,
.gy-3 {
  --gen-gutter-y: 0.75rem;
}

.g-4,
.gx-4 {
  --gen-gutter-x: 1rem;
}

.g-4,
.gy-4 {
  --gen-gutter-y: 1rem;
}

.g-5,
.gx-5 {
  --gen-gutter-x: 1.25rem;
}

.g-5,
.gy-5 {
  --gen-gutter-y: 1.25rem;
}

.g-6,
.gx-6 {
  --gen-gutter-x: 1.5rem;
}

.g-6,
.gy-6 {
  --gen-gutter-y: 1.5rem;
}

.g-7,
.gx-7 {
  --gen-gutter-x: 1.75rem;
}

.g-7,
.gy-7 {
  --gen-gutter-y: 1.75rem;
}

.g-8,
.gx-8 {
  --gen-gutter-x: 2rem;
}

.g-8,
.gy-8 {
  --gen-gutter-y: 2rem;
}

.g-9,
.gx-9 {
  --gen-gutter-x: 2.25rem;
}

.g-9,
.gy-9 {
  --gen-gutter-y: 2.25rem;
}

.g-10,
.gx-10 {
  --gen-gutter-x: 2.5rem;
}

.g-10,
.gy-10 {
  --gen-gutter-y: 2.5rem;
}

.g-11,
.gx-11 {
  --gen-gutter-x: 2.75rem;
}

.g-11,
.gy-11 {
  --gen-gutter-y: 2.75rem;
}

.g-12,
.gx-12 {
  --gen-gutter-x: 3rem;
}

.g-12,
.gy-12 {
  --gen-gutter-y: 3rem;
}

.g-13,
.gx-13 {
  --gen-gutter-x: 3.25rem;
}

.g-13,
.gy-13 {
  --gen-gutter-y: 3.25rem;
}

.g-14,
.gx-14 {
  --gen-gutter-x: 3.5rem;
}

.g-14,
.gy-14 {
  --gen-gutter-y: 3.5rem;
}

.g-15,
.gx-15 {
  --gen-gutter-x: 3.75rem;
}

.g-15,
.gy-15 {
  --gen-gutter-y: 3.75rem;
}

.g-16,
.gx-16 {
  --gen-gutter-x: 4rem;
}

.g-16,
.gy-16 {
  --gen-gutter-y: 4rem;
}

.g-17,
.gx-17 {
  --gen-gutter-x: 4.25rem;
}

.g-17,
.gy-17 {
  --gen-gutter-y: 4.25rem;
}

.g-18,
.gx-18 {
  --gen-gutter-x: 4.5rem;
}

.g-18,
.gy-18 {
  --gen-gutter-y: 4.5rem;
}

.g-19,
.gx-19 {
  --gen-gutter-x: 4.75rem;
}

.g-19,
.gy-19 {
  --gen-gutter-y: 4.75rem;
}

.g-20,
.gx-20 {
  --gen-gutter-x: 5rem;
}

.g-20,
.gy-20 {
  --gen-gutter-y: 5rem;
}

.g-21,
.gx-21 {
  --gen-gutter-x: 5.25rem;
}

.g-21,
.gy-21 {
  --gen-gutter-y: 5.25rem;
}

.g-22,
.gx-22 {
  --gen-gutter-x: 5.5rem;
}

.g-22,
.gy-22 {
  --gen-gutter-y: 5.5rem;
}

.g-23,
.gx-23 {
  --gen-gutter-x: 5.75rem;
}

.g-23,
.gy-23 {
  --gen-gutter-y: 5.75rem;
}

.g-24,
.gx-24 {
  --gen-gutter-x: 6rem;
}

.g-24,
.gy-24 {
  --gen-gutter-y: 6rem;
}

.g-25,
.gx-25 {
  --gen-gutter-x: 6.25rem;
}

.g-25,
.gy-25 {
  --gen-gutter-y: 6.25rem;
}

.g-26,
.gx-26 {
  --gen-gutter-x: 6.5rem;
}

.g-26,
.gy-26 {
  --gen-gutter-y: 6.5rem;
}

.g-27,
.gx-27 {
  --gen-gutter-x: 6.75rem;
}

.g-27,
.gy-27 {
  --gen-gutter-y: 6.75rem;
}

.g-28,
.gx-28 {
  --gen-gutter-x: 7rem;
}

.g-28,
.gy-28 {
  --gen-gutter-y: 7rem;
}

.g-29,
.gx-29 {
  --gen-gutter-x: 7.25rem;
}

.g-29,
.gy-29 {
  --gen-gutter-y: 7.25rem;
}

.g-30,
.gx-30 {
  --gen-gutter-x: 7.5rem;
}

.g-30,
.gy-30 {
  --gen-gutter-y: 7.5rem;
}

.g-31,
.gx-31 {
  --gen-gutter-x: 7.75rem;
}

.g-31,
.gy-31 {
  --gen-gutter-y: 7.75rem;
}

.g-32,
.gx-32 {
  --gen-gutter-x: 8.25rem;
}

.g-32,
.gy-32 {
  --gen-gutter-y: 8.25rem;
}

.g-33,
.gx-33 {
  --gen-gutter-x: 8.5rem;
}

.g-33,
.gy-33 {
  --gen-gutter-y: 8.5rem;
}

.g-34,
.gx-34 {
  --gen-gutter-x: 8.75rem;
}

.g-34,
.gy-34 {
  --gen-gutter-y: 8.75rem;
}

.g-35,
.gx-35 {
  --gen-gutter-x: 9rem;
}

.g-35,
.gy-35 {
  --gen-gutter-y: 9rem;
}

.g-36,
.gx-36 {
  --gen-gutter-x: 9.25rem;
}

.g-36,
.gy-36 {
  --gen-gutter-y: 9.25rem;
}

.g-37,
.gx-37 {
  --gen-gutter-x: 9.5rem;
}

.g-37,
.gy-37 {
  --gen-gutter-y: 9.5rem;
}

.g-38,
.gx-38 {
  --gen-gutter-x: 9.75rem;
}

.g-38,
.gy-38 {
  --gen-gutter-y: 9.75rem;
}

.g-39,
.gx-39 {
  --gen-gutter-x: 10rem;
}

.g-39,
.gy-39 {
  --gen-gutter-y: 10rem;
}

.g-40,
.gx-40 {
  --gen-gutter-x: 10.25rem;
}

.g-40,
.gy-40 {
  --gen-gutter-y: 10.25rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --gen-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --gen-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --gen-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --gen-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --gen-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --gen-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --gen-gutter-x: 0.75rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --gen-gutter-y: 0.75rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --gen-gutter-x: 1rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --gen-gutter-y: 1rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --gen-gutter-x: 1.25rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --gen-gutter-y: 1.25rem;
  }
  .g-sm-6,
  .gx-sm-6 {
    --gen-gutter-x: 1.5rem;
  }
  .g-sm-6,
  .gy-sm-6 {
    --gen-gutter-y: 1.5rem;
  }
  .g-sm-7,
  .gx-sm-7 {
    --gen-gutter-x: 1.75rem;
  }
  .g-sm-7,
  .gy-sm-7 {
    --gen-gutter-y: 1.75rem;
  }
  .g-sm-8,
  .gx-sm-8 {
    --gen-gutter-x: 2rem;
  }
  .g-sm-8,
  .gy-sm-8 {
    --gen-gutter-y: 2rem;
  }
  .g-sm-9,
  .gx-sm-9 {
    --gen-gutter-x: 2.25rem;
  }
  .g-sm-9,
  .gy-sm-9 {
    --gen-gutter-y: 2.25rem;
  }
  .g-sm-10,
  .gx-sm-10 {
    --gen-gutter-x: 2.5rem;
  }
  .g-sm-10,
  .gy-sm-10 {
    --gen-gutter-y: 2.5rem;
  }
  .g-sm-11,
  .gx-sm-11 {
    --gen-gutter-x: 2.75rem;
  }
  .g-sm-11,
  .gy-sm-11 {
    --gen-gutter-y: 2.75rem;
  }
  .g-sm-12,
  .gx-sm-12 {
    --gen-gutter-x: 3rem;
  }
  .g-sm-12,
  .gy-sm-12 {
    --gen-gutter-y: 3rem;
  }
  .g-sm-13,
  .gx-sm-13 {
    --gen-gutter-x: 3.25rem;
  }
  .g-sm-13,
  .gy-sm-13 {
    --gen-gutter-y: 3.25rem;
  }
  .g-sm-14,
  .gx-sm-14 {
    --gen-gutter-x: 3.5rem;
  }
  .g-sm-14,
  .gy-sm-14 {
    --gen-gutter-y: 3.5rem;
  }
  .g-sm-15,
  .gx-sm-15 {
    --gen-gutter-x: 3.75rem;
  }
  .g-sm-15,
  .gy-sm-15 {
    --gen-gutter-y: 3.75rem;
  }
  .g-sm-16,
  .gx-sm-16 {
    --gen-gutter-x: 4rem;
  }
  .g-sm-16,
  .gy-sm-16 {
    --gen-gutter-y: 4rem;
  }
  .g-sm-17,
  .gx-sm-17 {
    --gen-gutter-x: 4.25rem;
  }
  .g-sm-17,
  .gy-sm-17 {
    --gen-gutter-y: 4.25rem;
  }
  .g-sm-18,
  .gx-sm-18 {
    --gen-gutter-x: 4.5rem;
  }
  .g-sm-18,
  .gy-sm-18 {
    --gen-gutter-y: 4.5rem;
  }
  .g-sm-19,
  .gx-sm-19 {
    --gen-gutter-x: 4.75rem;
  }
  .g-sm-19,
  .gy-sm-19 {
    --gen-gutter-y: 4.75rem;
  }
  .g-sm-20,
  .gx-sm-20 {
    --gen-gutter-x: 5rem;
  }
  .g-sm-20,
  .gy-sm-20 {
    --gen-gutter-y: 5rem;
  }
  .g-sm-21,
  .gx-sm-21 {
    --gen-gutter-x: 5.25rem;
  }
  .g-sm-21,
  .gy-sm-21 {
    --gen-gutter-y: 5.25rem;
  }
  .g-sm-22,
  .gx-sm-22 {
    --gen-gutter-x: 5.5rem;
  }
  .g-sm-22,
  .gy-sm-22 {
    --gen-gutter-y: 5.5rem;
  }
  .g-sm-23,
  .gx-sm-23 {
    --gen-gutter-x: 5.75rem;
  }
  .g-sm-23,
  .gy-sm-23 {
    --gen-gutter-y: 5.75rem;
  }
  .g-sm-24,
  .gx-sm-24 {
    --gen-gutter-x: 6rem;
  }
  .g-sm-24,
  .gy-sm-24 {
    --gen-gutter-y: 6rem;
  }
  .g-sm-25,
  .gx-sm-25 {
    --gen-gutter-x: 6.25rem;
  }
  .g-sm-25,
  .gy-sm-25 {
    --gen-gutter-y: 6.25rem;
  }
  .g-sm-26,
  .gx-sm-26 {
    --gen-gutter-x: 6.5rem;
  }
  .g-sm-26,
  .gy-sm-26 {
    --gen-gutter-y: 6.5rem;
  }
  .g-sm-27,
  .gx-sm-27 {
    --gen-gutter-x: 6.75rem;
  }
  .g-sm-27,
  .gy-sm-27 {
    --gen-gutter-y: 6.75rem;
  }
  .g-sm-28,
  .gx-sm-28 {
    --gen-gutter-x: 7rem;
  }
  .g-sm-28,
  .gy-sm-28 {
    --gen-gutter-y: 7rem;
  }
  .g-sm-29,
  .gx-sm-29 {
    --gen-gutter-x: 7.25rem;
  }
  .g-sm-29,
  .gy-sm-29 {
    --gen-gutter-y: 7.25rem;
  }
  .g-sm-30,
  .gx-sm-30 {
    --gen-gutter-x: 7.5rem;
  }
  .g-sm-30,
  .gy-sm-30 {
    --gen-gutter-y: 7.5rem;
  }
  .g-sm-31,
  .gx-sm-31 {
    --gen-gutter-x: 7.75rem;
  }
  .g-sm-31,
  .gy-sm-31 {
    --gen-gutter-y: 7.75rem;
  }
  .g-sm-32,
  .gx-sm-32 {
    --gen-gutter-x: 8.25rem;
  }
  .g-sm-32,
  .gy-sm-32 {
    --gen-gutter-y: 8.25rem;
  }
  .g-sm-33,
  .gx-sm-33 {
    --gen-gutter-x: 8.5rem;
  }
  .g-sm-33,
  .gy-sm-33 {
    --gen-gutter-y: 8.5rem;
  }
  .g-sm-34,
  .gx-sm-34 {
    --gen-gutter-x: 8.75rem;
  }
  .g-sm-34,
  .gy-sm-34 {
    --gen-gutter-y: 8.75rem;
  }
  .g-sm-35,
  .gx-sm-35 {
    --gen-gutter-x: 9rem;
  }
  .g-sm-35,
  .gy-sm-35 {
    --gen-gutter-y: 9rem;
  }
  .g-sm-36,
  .gx-sm-36 {
    --gen-gutter-x: 9.25rem;
  }
  .g-sm-36,
  .gy-sm-36 {
    --gen-gutter-y: 9.25rem;
  }
  .g-sm-37,
  .gx-sm-37 {
    --gen-gutter-x: 9.5rem;
  }
  .g-sm-37,
  .gy-sm-37 {
    --gen-gutter-y: 9.5rem;
  }
  .g-sm-38,
  .gx-sm-38 {
    --gen-gutter-x: 9.75rem;
  }
  .g-sm-38,
  .gy-sm-38 {
    --gen-gutter-y: 9.75rem;
  }
  .g-sm-39,
  .gx-sm-39 {
    --gen-gutter-x: 10rem;
  }
  .g-sm-39,
  .gy-sm-39 {
    --gen-gutter-y: 10rem;
  }
  .g-sm-40,
  .gx-sm-40 {
    --gen-gutter-x: 10.25rem;
  }
  .g-sm-40,
  .gy-sm-40 {
    --gen-gutter-y: 10.25rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --gen-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --gen-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --gen-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --gen-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --gen-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --gen-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --gen-gutter-x: 0.75rem;
  }
  .g-md-3,
  .gy-md-3 {
    --gen-gutter-y: 0.75rem;
  }
  .g-md-4,
  .gx-md-4 {
    --gen-gutter-x: 1rem;
  }
  .g-md-4,
  .gy-md-4 {
    --gen-gutter-y: 1rem;
  }
  .g-md-5,
  .gx-md-5 {
    --gen-gutter-x: 1.25rem;
  }
  .g-md-5,
  .gy-md-5 {
    --gen-gutter-y: 1.25rem;
  }
  .g-md-6,
  .gx-md-6 {
    --gen-gutter-x: 1.5rem;
  }
  .g-md-6,
  .gy-md-6 {
    --gen-gutter-y: 1.5rem;
  }
  .g-md-7,
  .gx-md-7 {
    --gen-gutter-x: 1.75rem;
  }
  .g-md-7,
  .gy-md-7 {
    --gen-gutter-y: 1.75rem;
  }
  .g-md-8,
  .gx-md-8 {
    --gen-gutter-x: 2rem;
  }
  .g-md-8,
  .gy-md-8 {
    --gen-gutter-y: 2rem;
  }
  .g-md-9,
  .gx-md-9 {
    --gen-gutter-x: 2.25rem;
  }
  .g-md-9,
  .gy-md-9 {
    --gen-gutter-y: 2.25rem;
  }
  .g-md-10,
  .gx-md-10 {
    --gen-gutter-x: 2.5rem;
  }
  .g-md-10,
  .gy-md-10 {
    --gen-gutter-y: 2.5rem;
  }
  .g-md-11,
  .gx-md-11 {
    --gen-gutter-x: 2.75rem;
  }
  .g-md-11,
  .gy-md-11 {
    --gen-gutter-y: 2.75rem;
  }
  .g-md-12,
  .gx-md-12 {
    --gen-gutter-x: 3rem;
  }
  .g-md-12,
  .gy-md-12 {
    --gen-gutter-y: 3rem;
  }
  .g-md-13,
  .gx-md-13 {
    --gen-gutter-x: 3.25rem;
  }
  .g-md-13,
  .gy-md-13 {
    --gen-gutter-y: 3.25rem;
  }
  .g-md-14,
  .gx-md-14 {
    --gen-gutter-x: 3.5rem;
  }
  .g-md-14,
  .gy-md-14 {
    --gen-gutter-y: 3.5rem;
  }
  .g-md-15,
  .gx-md-15 {
    --gen-gutter-x: 3.75rem;
  }
  .g-md-15,
  .gy-md-15 {
    --gen-gutter-y: 3.75rem;
  }
  .g-md-16,
  .gx-md-16 {
    --gen-gutter-x: 4rem;
  }
  .g-md-16,
  .gy-md-16 {
    --gen-gutter-y: 4rem;
  }
  .g-md-17,
  .gx-md-17 {
    --gen-gutter-x: 4.25rem;
  }
  .g-md-17,
  .gy-md-17 {
    --gen-gutter-y: 4.25rem;
  }
  .g-md-18,
  .gx-md-18 {
    --gen-gutter-x: 4.5rem;
  }
  .g-md-18,
  .gy-md-18 {
    --gen-gutter-y: 4.5rem;
  }
  .g-md-19,
  .gx-md-19 {
    --gen-gutter-x: 4.75rem;
  }
  .g-md-19,
  .gy-md-19 {
    --gen-gutter-y: 4.75rem;
  }
  .g-md-20,
  .gx-md-20 {
    --gen-gutter-x: 5rem;
  }
  .g-md-20,
  .gy-md-20 {
    --gen-gutter-y: 5rem;
  }
  .g-md-21,
  .gx-md-21 {
    --gen-gutter-x: 5.25rem;
  }
  .g-md-21,
  .gy-md-21 {
    --gen-gutter-y: 5.25rem;
  }
  .g-md-22,
  .gx-md-22 {
    --gen-gutter-x: 5.5rem;
  }
  .g-md-22,
  .gy-md-22 {
    --gen-gutter-y: 5.5rem;
  }
  .g-md-23,
  .gx-md-23 {
    --gen-gutter-x: 5.75rem;
  }
  .g-md-23,
  .gy-md-23 {
    --gen-gutter-y: 5.75rem;
  }
  .g-md-24,
  .gx-md-24 {
    --gen-gutter-x: 6rem;
  }
  .g-md-24,
  .gy-md-24 {
    --gen-gutter-y: 6rem;
  }
  .g-md-25,
  .gx-md-25 {
    --gen-gutter-x: 6.25rem;
  }
  .g-md-25,
  .gy-md-25 {
    --gen-gutter-y: 6.25rem;
  }
  .g-md-26,
  .gx-md-26 {
    --gen-gutter-x: 6.5rem;
  }
  .g-md-26,
  .gy-md-26 {
    --gen-gutter-y: 6.5rem;
  }
  .g-md-27,
  .gx-md-27 {
    --gen-gutter-x: 6.75rem;
  }
  .g-md-27,
  .gy-md-27 {
    --gen-gutter-y: 6.75rem;
  }
  .g-md-28,
  .gx-md-28 {
    --gen-gutter-x: 7rem;
  }
  .g-md-28,
  .gy-md-28 {
    --gen-gutter-y: 7rem;
  }
  .g-md-29,
  .gx-md-29 {
    --gen-gutter-x: 7.25rem;
  }
  .g-md-29,
  .gy-md-29 {
    --gen-gutter-y: 7.25rem;
  }
  .g-md-30,
  .gx-md-30 {
    --gen-gutter-x: 7.5rem;
  }
  .g-md-30,
  .gy-md-30 {
    --gen-gutter-y: 7.5rem;
  }
  .g-md-31,
  .gx-md-31 {
    --gen-gutter-x: 7.75rem;
  }
  .g-md-31,
  .gy-md-31 {
    --gen-gutter-y: 7.75rem;
  }
  .g-md-32,
  .gx-md-32 {
    --gen-gutter-x: 8.25rem;
  }
  .g-md-32,
  .gy-md-32 {
    --gen-gutter-y: 8.25rem;
  }
  .g-md-33,
  .gx-md-33 {
    --gen-gutter-x: 8.5rem;
  }
  .g-md-33,
  .gy-md-33 {
    --gen-gutter-y: 8.5rem;
  }
  .g-md-34,
  .gx-md-34 {
    --gen-gutter-x: 8.75rem;
  }
  .g-md-34,
  .gy-md-34 {
    --gen-gutter-y: 8.75rem;
  }
  .g-md-35,
  .gx-md-35 {
    --gen-gutter-x: 9rem;
  }
  .g-md-35,
  .gy-md-35 {
    --gen-gutter-y: 9rem;
  }
  .g-md-36,
  .gx-md-36 {
    --gen-gutter-x: 9.25rem;
  }
  .g-md-36,
  .gy-md-36 {
    --gen-gutter-y: 9.25rem;
  }
  .g-md-37,
  .gx-md-37 {
    --gen-gutter-x: 9.5rem;
  }
  .g-md-37,
  .gy-md-37 {
    --gen-gutter-y: 9.5rem;
  }
  .g-md-38,
  .gx-md-38 {
    --gen-gutter-x: 9.75rem;
  }
  .g-md-38,
  .gy-md-38 {
    --gen-gutter-y: 9.75rem;
  }
  .g-md-39,
  .gx-md-39 {
    --gen-gutter-x: 10rem;
  }
  .g-md-39,
  .gy-md-39 {
    --gen-gutter-y: 10rem;
  }
  .g-md-40,
  .gx-md-40 {
    --gen-gutter-x: 10.25rem;
  }
  .g-md-40,
  .gy-md-40 {
    --gen-gutter-y: 10.25rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --gen-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --gen-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --gen-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --gen-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --gen-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --gen-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --gen-gutter-x: 0.75rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --gen-gutter-y: 0.75rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --gen-gutter-x: 1rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --gen-gutter-y: 1rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --gen-gutter-x: 1.25rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --gen-gutter-y: 1.25rem;
  }
  .g-lg-6,
  .gx-lg-6 {
    --gen-gutter-x: 1.5rem;
  }
  .g-lg-6,
  .gy-lg-6 {
    --gen-gutter-y: 1.5rem;
  }
  .g-lg-7,
  .gx-lg-7 {
    --gen-gutter-x: 1.75rem;
  }
  .g-lg-7,
  .gy-lg-7 {
    --gen-gutter-y: 1.75rem;
  }
  .g-lg-8,
  .gx-lg-8 {
    --gen-gutter-x: 2rem;
  }
  .g-lg-8,
  .gy-lg-8 {
    --gen-gutter-y: 2rem;
  }
  .g-lg-9,
  .gx-lg-9 {
    --gen-gutter-x: 2.25rem;
  }
  .g-lg-9,
  .gy-lg-9 {
    --gen-gutter-y: 2.25rem;
  }
  .g-lg-10,
  .gx-lg-10 {
    --gen-gutter-x: 2.5rem;
  }
  .g-lg-10,
  .gy-lg-10 {
    --gen-gutter-y: 2.5rem;
  }
  .g-lg-11,
  .gx-lg-11 {
    --gen-gutter-x: 2.75rem;
  }
  .g-lg-11,
  .gy-lg-11 {
    --gen-gutter-y: 2.75rem;
  }
  .g-lg-12,
  .gx-lg-12 {
    --gen-gutter-x: 3rem;
  }
  .g-lg-12,
  .gy-lg-12 {
    --gen-gutter-y: 3rem;
  }
  .g-lg-13,
  .gx-lg-13 {
    --gen-gutter-x: 3.25rem;
  }
  .g-lg-13,
  .gy-lg-13 {
    --gen-gutter-y: 3.25rem;
  }
  .g-lg-14,
  .gx-lg-14 {
    --gen-gutter-x: 3.5rem;
  }
  .g-lg-14,
  .gy-lg-14 {
    --gen-gutter-y: 3.5rem;
  }
  .g-lg-15,
  .gx-lg-15 {
    --gen-gutter-x: 3.75rem;
  }
  .g-lg-15,
  .gy-lg-15 {
    --gen-gutter-y: 3.75rem;
  }
  .g-lg-16,
  .gx-lg-16 {
    --gen-gutter-x: 4rem;
  }
  .g-lg-16,
  .gy-lg-16 {
    --gen-gutter-y: 4rem;
  }
  .g-lg-17,
  .gx-lg-17 {
    --gen-gutter-x: 4.25rem;
  }
  .g-lg-17,
  .gy-lg-17 {
    --gen-gutter-y: 4.25rem;
  }
  .g-lg-18,
  .gx-lg-18 {
    --gen-gutter-x: 4.5rem;
  }
  .g-lg-18,
  .gy-lg-18 {
    --gen-gutter-y: 4.5rem;
  }
  .g-lg-19,
  .gx-lg-19 {
    --gen-gutter-x: 4.75rem;
  }
  .g-lg-19,
  .gy-lg-19 {
    --gen-gutter-y: 4.75rem;
  }
  .g-lg-20,
  .gx-lg-20 {
    --gen-gutter-x: 5rem;
  }
  .g-lg-20,
  .gy-lg-20 {
    --gen-gutter-y: 5rem;
  }
  .g-lg-21,
  .gx-lg-21 {
    --gen-gutter-x: 5.25rem;
  }
  .g-lg-21,
  .gy-lg-21 {
    --gen-gutter-y: 5.25rem;
  }
  .g-lg-22,
  .gx-lg-22 {
    --gen-gutter-x: 5.5rem;
  }
  .g-lg-22,
  .gy-lg-22 {
    --gen-gutter-y: 5.5rem;
  }
  .g-lg-23,
  .gx-lg-23 {
    --gen-gutter-x: 5.75rem;
  }
  .g-lg-23,
  .gy-lg-23 {
    --gen-gutter-y: 5.75rem;
  }
  .g-lg-24,
  .gx-lg-24 {
    --gen-gutter-x: 6rem;
  }
  .g-lg-24,
  .gy-lg-24 {
    --gen-gutter-y: 6rem;
  }
  .g-lg-25,
  .gx-lg-25 {
    --gen-gutter-x: 6.25rem;
  }
  .g-lg-25,
  .gy-lg-25 {
    --gen-gutter-y: 6.25rem;
  }
  .g-lg-26,
  .gx-lg-26 {
    --gen-gutter-x: 6.5rem;
  }
  .g-lg-26,
  .gy-lg-26 {
    --gen-gutter-y: 6.5rem;
  }
  .g-lg-27,
  .gx-lg-27 {
    --gen-gutter-x: 6.75rem;
  }
  .g-lg-27,
  .gy-lg-27 {
    --gen-gutter-y: 6.75rem;
  }
  .g-lg-28,
  .gx-lg-28 {
    --gen-gutter-x: 7rem;
  }
  .g-lg-28,
  .gy-lg-28 {
    --gen-gutter-y: 7rem;
  }
  .g-lg-29,
  .gx-lg-29 {
    --gen-gutter-x: 7.25rem;
  }
  .g-lg-29,
  .gy-lg-29 {
    --gen-gutter-y: 7.25rem;
  }
  .g-lg-30,
  .gx-lg-30 {
    --gen-gutter-x: 7.5rem;
  }
  .g-lg-30,
  .gy-lg-30 {
    --gen-gutter-y: 7.5rem;
  }
  .g-lg-31,
  .gx-lg-31 {
    --gen-gutter-x: 7.75rem;
  }
  .g-lg-31,
  .gy-lg-31 {
    --gen-gutter-y: 7.75rem;
  }
  .g-lg-32,
  .gx-lg-32 {
    --gen-gutter-x: 8.25rem;
  }
  .g-lg-32,
  .gy-lg-32 {
    --gen-gutter-y: 8.25rem;
  }
  .g-lg-33,
  .gx-lg-33 {
    --gen-gutter-x: 8.5rem;
  }
  .g-lg-33,
  .gy-lg-33 {
    --gen-gutter-y: 8.5rem;
  }
  .g-lg-34,
  .gx-lg-34 {
    --gen-gutter-x: 8.75rem;
  }
  .g-lg-34,
  .gy-lg-34 {
    --gen-gutter-y: 8.75rem;
  }
  .g-lg-35,
  .gx-lg-35 {
    --gen-gutter-x: 9rem;
  }
  .g-lg-35,
  .gy-lg-35 {
    --gen-gutter-y: 9rem;
  }
  .g-lg-36,
  .gx-lg-36 {
    --gen-gutter-x: 9.25rem;
  }
  .g-lg-36,
  .gy-lg-36 {
    --gen-gutter-y: 9.25rem;
  }
  .g-lg-37,
  .gx-lg-37 {
    --gen-gutter-x: 9.5rem;
  }
  .g-lg-37,
  .gy-lg-37 {
    --gen-gutter-y: 9.5rem;
  }
  .g-lg-38,
  .gx-lg-38 {
    --gen-gutter-x: 9.75rem;
  }
  .g-lg-38,
  .gy-lg-38 {
    --gen-gutter-y: 9.75rem;
  }
  .g-lg-39,
  .gx-lg-39 {
    --gen-gutter-x: 10rem;
  }
  .g-lg-39,
  .gy-lg-39 {
    --gen-gutter-y: 10rem;
  }
  .g-lg-40,
  .gx-lg-40 {
    --gen-gutter-x: 10.25rem;
  }
  .g-lg-40,
  .gy-lg-40 {
    --gen-gutter-y: 10.25rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --gen-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --gen-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --gen-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --gen-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --gen-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --gen-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --gen-gutter-x: 0.75rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --gen-gutter-y: 0.75rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --gen-gutter-x: 1rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --gen-gutter-y: 1rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --gen-gutter-x: 1.25rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --gen-gutter-y: 1.25rem;
  }
  .g-xl-6,
  .gx-xl-6 {
    --gen-gutter-x: 1.5rem;
  }
  .g-xl-6,
  .gy-xl-6 {
    --gen-gutter-y: 1.5rem;
  }
  .g-xl-7,
  .gx-xl-7 {
    --gen-gutter-x: 1.75rem;
  }
  .g-xl-7,
  .gy-xl-7 {
    --gen-gutter-y: 1.75rem;
  }
  .g-xl-8,
  .gx-xl-8 {
    --gen-gutter-x: 2rem;
  }
  .g-xl-8,
  .gy-xl-8 {
    --gen-gutter-y: 2rem;
  }
  .g-xl-9,
  .gx-xl-9 {
    --gen-gutter-x: 2.25rem;
  }
  .g-xl-9,
  .gy-xl-9 {
    --gen-gutter-y: 2.25rem;
  }
  .g-xl-10,
  .gx-xl-10 {
    --gen-gutter-x: 2.5rem;
  }
  .g-xl-10,
  .gy-xl-10 {
    --gen-gutter-y: 2.5rem;
  }
  .g-xl-11,
  .gx-xl-11 {
    --gen-gutter-x: 2.75rem;
  }
  .g-xl-11,
  .gy-xl-11 {
    --gen-gutter-y: 2.75rem;
  }
  .g-xl-12,
  .gx-xl-12 {
    --gen-gutter-x: 3rem;
  }
  .g-xl-12,
  .gy-xl-12 {
    --gen-gutter-y: 3rem;
  }
  .g-xl-13,
  .gx-xl-13 {
    --gen-gutter-x: 3.25rem;
  }
  .g-xl-13,
  .gy-xl-13 {
    --gen-gutter-y: 3.25rem;
  }
  .g-xl-14,
  .gx-xl-14 {
    --gen-gutter-x: 3.5rem;
  }
  .g-xl-14,
  .gy-xl-14 {
    --gen-gutter-y: 3.5rem;
  }
  .g-xl-15,
  .gx-xl-15 {
    --gen-gutter-x: 3.75rem;
  }
  .g-xl-15,
  .gy-xl-15 {
    --gen-gutter-y: 3.75rem;
  }
  .g-xl-16,
  .gx-xl-16 {
    --gen-gutter-x: 4rem;
  }
  .g-xl-16,
  .gy-xl-16 {
    --gen-gutter-y: 4rem;
  }
  .g-xl-17,
  .gx-xl-17 {
    --gen-gutter-x: 4.25rem;
  }
  .g-xl-17,
  .gy-xl-17 {
    --gen-gutter-y: 4.25rem;
  }
  .g-xl-18,
  .gx-xl-18 {
    --gen-gutter-x: 4.5rem;
  }
  .g-xl-18,
  .gy-xl-18 {
    --gen-gutter-y: 4.5rem;
  }
  .g-xl-19,
  .gx-xl-19 {
    --gen-gutter-x: 4.75rem;
  }
  .g-xl-19,
  .gy-xl-19 {
    --gen-gutter-y: 4.75rem;
  }
  .g-xl-20,
  .gx-xl-20 {
    --gen-gutter-x: 5rem;
  }
  .g-xl-20,
  .gy-xl-20 {
    --gen-gutter-y: 5rem;
  }
  .g-xl-21,
  .gx-xl-21 {
    --gen-gutter-x: 5.25rem;
  }
  .g-xl-21,
  .gy-xl-21 {
    --gen-gutter-y: 5.25rem;
  }
  .g-xl-22,
  .gx-xl-22 {
    --gen-gutter-x: 5.5rem;
  }
  .g-xl-22,
  .gy-xl-22 {
    --gen-gutter-y: 5.5rem;
  }
  .g-xl-23,
  .gx-xl-23 {
    --gen-gutter-x: 5.75rem;
  }
  .g-xl-23,
  .gy-xl-23 {
    --gen-gutter-y: 5.75rem;
  }
  .g-xl-24,
  .gx-xl-24 {
    --gen-gutter-x: 6rem;
  }
  .g-xl-24,
  .gy-xl-24 {
    --gen-gutter-y: 6rem;
  }
  .g-xl-25,
  .gx-xl-25 {
    --gen-gutter-x: 6.25rem;
  }
  .g-xl-25,
  .gy-xl-25 {
    --gen-gutter-y: 6.25rem;
  }
  .g-xl-26,
  .gx-xl-26 {
    --gen-gutter-x: 6.5rem;
  }
  .g-xl-26,
  .gy-xl-26 {
    --gen-gutter-y: 6.5rem;
  }
  .g-xl-27,
  .gx-xl-27 {
    --gen-gutter-x: 6.75rem;
  }
  .g-xl-27,
  .gy-xl-27 {
    --gen-gutter-y: 6.75rem;
  }
  .g-xl-28,
  .gx-xl-28 {
    --gen-gutter-x: 7rem;
  }
  .g-xl-28,
  .gy-xl-28 {
    --gen-gutter-y: 7rem;
  }
  .g-xl-29,
  .gx-xl-29 {
    --gen-gutter-x: 7.25rem;
  }
  .g-xl-29,
  .gy-xl-29 {
    --gen-gutter-y: 7.25rem;
  }
  .g-xl-30,
  .gx-xl-30 {
    --gen-gutter-x: 7.5rem;
  }
  .g-xl-30,
  .gy-xl-30 {
    --gen-gutter-y: 7.5rem;
  }
  .g-xl-31,
  .gx-xl-31 {
    --gen-gutter-x: 7.75rem;
  }
  .g-xl-31,
  .gy-xl-31 {
    --gen-gutter-y: 7.75rem;
  }
  .g-xl-32,
  .gx-xl-32 {
    --gen-gutter-x: 8.25rem;
  }
  .g-xl-32,
  .gy-xl-32 {
    --gen-gutter-y: 8.25rem;
  }
  .g-xl-33,
  .gx-xl-33 {
    --gen-gutter-x: 8.5rem;
  }
  .g-xl-33,
  .gy-xl-33 {
    --gen-gutter-y: 8.5rem;
  }
  .g-xl-34,
  .gx-xl-34 {
    --gen-gutter-x: 8.75rem;
  }
  .g-xl-34,
  .gy-xl-34 {
    --gen-gutter-y: 8.75rem;
  }
  .g-xl-35,
  .gx-xl-35 {
    --gen-gutter-x: 9rem;
  }
  .g-xl-35,
  .gy-xl-35 {
    --gen-gutter-y: 9rem;
  }
  .g-xl-36,
  .gx-xl-36 {
    --gen-gutter-x: 9.25rem;
  }
  .g-xl-36,
  .gy-xl-36 {
    --gen-gutter-y: 9.25rem;
  }
  .g-xl-37,
  .gx-xl-37 {
    --gen-gutter-x: 9.5rem;
  }
  .g-xl-37,
  .gy-xl-37 {
    --gen-gutter-y: 9.5rem;
  }
  .g-xl-38,
  .gx-xl-38 {
    --gen-gutter-x: 9.75rem;
  }
  .g-xl-38,
  .gy-xl-38 {
    --gen-gutter-y: 9.75rem;
  }
  .g-xl-39,
  .gx-xl-39 {
    --gen-gutter-x: 10rem;
  }
  .g-xl-39,
  .gy-xl-39 {
    --gen-gutter-y: 10rem;
  }
  .g-xl-40,
  .gx-xl-40 {
    --gen-gutter-x: 10.25rem;
  }
  .g-xl-40,
  .gy-xl-40 {
    --gen-gutter-y: 10.25rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --gen-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --gen-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --gen-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --gen-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --gen-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --gen-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --gen-gutter-x: 0.75rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --gen-gutter-y: 0.75rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --gen-gutter-x: 1rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --gen-gutter-y: 1rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --gen-gutter-x: 1.25rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --gen-gutter-y: 1.25rem;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --gen-gutter-x: 1.5rem;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --gen-gutter-y: 1.5rem;
  }
  .g-xxl-7,
  .gx-xxl-7 {
    --gen-gutter-x: 1.75rem;
  }
  .g-xxl-7,
  .gy-xxl-7 {
    --gen-gutter-y: 1.75rem;
  }
  .g-xxl-8,
  .gx-xxl-8 {
    --gen-gutter-x: 2rem;
  }
  .g-xxl-8,
  .gy-xxl-8 {
    --gen-gutter-y: 2rem;
  }
  .g-xxl-9,
  .gx-xxl-9 {
    --gen-gutter-x: 2.25rem;
  }
  .g-xxl-9,
  .gy-xxl-9 {
    --gen-gutter-y: 2.25rem;
  }
  .g-xxl-10,
  .gx-xxl-10 {
    --gen-gutter-x: 2.5rem;
  }
  .g-xxl-10,
  .gy-xxl-10 {
    --gen-gutter-y: 2.5rem;
  }
  .g-xxl-11,
  .gx-xxl-11 {
    --gen-gutter-x: 2.75rem;
  }
  .g-xxl-11,
  .gy-xxl-11 {
    --gen-gutter-y: 2.75rem;
  }
  .g-xxl-12,
  .gx-xxl-12 {
    --gen-gutter-x: 3rem;
  }
  .g-xxl-12,
  .gy-xxl-12 {
    --gen-gutter-y: 3rem;
  }
  .g-xxl-13,
  .gx-xxl-13 {
    --gen-gutter-x: 3.25rem;
  }
  .g-xxl-13,
  .gy-xxl-13 {
    --gen-gutter-y: 3.25rem;
  }
  .g-xxl-14,
  .gx-xxl-14 {
    --gen-gutter-x: 3.5rem;
  }
  .g-xxl-14,
  .gy-xxl-14 {
    --gen-gutter-y: 3.5rem;
  }
  .g-xxl-15,
  .gx-xxl-15 {
    --gen-gutter-x: 3.75rem;
  }
  .g-xxl-15,
  .gy-xxl-15 {
    --gen-gutter-y: 3.75rem;
  }
  .g-xxl-16,
  .gx-xxl-16 {
    --gen-gutter-x: 4rem;
  }
  .g-xxl-16,
  .gy-xxl-16 {
    --gen-gutter-y: 4rem;
  }
  .g-xxl-17,
  .gx-xxl-17 {
    --gen-gutter-x: 4.25rem;
  }
  .g-xxl-17,
  .gy-xxl-17 {
    --gen-gutter-y: 4.25rem;
  }
  .g-xxl-18,
  .gx-xxl-18 {
    --gen-gutter-x: 4.5rem;
  }
  .g-xxl-18,
  .gy-xxl-18 {
    --gen-gutter-y: 4.5rem;
  }
  .g-xxl-19,
  .gx-xxl-19 {
    --gen-gutter-x: 4.75rem;
  }
  .g-xxl-19,
  .gy-xxl-19 {
    --gen-gutter-y: 4.75rem;
  }
  .g-xxl-20,
  .gx-xxl-20 {
    --gen-gutter-x: 5rem;
  }
  .g-xxl-20,
  .gy-xxl-20 {
    --gen-gutter-y: 5rem;
  }
  .g-xxl-21,
  .gx-xxl-21 {
    --gen-gutter-x: 5.25rem;
  }
  .g-xxl-21,
  .gy-xxl-21 {
    --gen-gutter-y: 5.25rem;
  }
  .g-xxl-22,
  .gx-xxl-22 {
    --gen-gutter-x: 5.5rem;
  }
  .g-xxl-22,
  .gy-xxl-22 {
    --gen-gutter-y: 5.5rem;
  }
  .g-xxl-23,
  .gx-xxl-23 {
    --gen-gutter-x: 5.75rem;
  }
  .g-xxl-23,
  .gy-xxl-23 {
    --gen-gutter-y: 5.75rem;
  }
  .g-xxl-24,
  .gx-xxl-24 {
    --gen-gutter-x: 6rem;
  }
  .g-xxl-24,
  .gy-xxl-24 {
    --gen-gutter-y: 6rem;
  }
  .g-xxl-25,
  .gx-xxl-25 {
    --gen-gutter-x: 6.25rem;
  }
  .g-xxl-25,
  .gy-xxl-25 {
    --gen-gutter-y: 6.25rem;
  }
  .g-xxl-26,
  .gx-xxl-26 {
    --gen-gutter-x: 6.5rem;
  }
  .g-xxl-26,
  .gy-xxl-26 {
    --gen-gutter-y: 6.5rem;
  }
  .g-xxl-27,
  .gx-xxl-27 {
    --gen-gutter-x: 6.75rem;
  }
  .g-xxl-27,
  .gy-xxl-27 {
    --gen-gutter-y: 6.75rem;
  }
  .g-xxl-28,
  .gx-xxl-28 {
    --gen-gutter-x: 7rem;
  }
  .g-xxl-28,
  .gy-xxl-28 {
    --gen-gutter-y: 7rem;
  }
  .g-xxl-29,
  .gx-xxl-29 {
    --gen-gutter-x: 7.25rem;
  }
  .g-xxl-29,
  .gy-xxl-29 {
    --gen-gutter-y: 7.25rem;
  }
  .g-xxl-30,
  .gx-xxl-30 {
    --gen-gutter-x: 7.5rem;
  }
  .g-xxl-30,
  .gy-xxl-30 {
    --gen-gutter-y: 7.5rem;
  }
  .g-xxl-31,
  .gx-xxl-31 {
    --gen-gutter-x: 7.75rem;
  }
  .g-xxl-31,
  .gy-xxl-31 {
    --gen-gutter-y: 7.75rem;
  }
  .g-xxl-32,
  .gx-xxl-32 {
    --gen-gutter-x: 8.25rem;
  }
  .g-xxl-32,
  .gy-xxl-32 {
    --gen-gutter-y: 8.25rem;
  }
  .g-xxl-33,
  .gx-xxl-33 {
    --gen-gutter-x: 8.5rem;
  }
  .g-xxl-33,
  .gy-xxl-33 {
    --gen-gutter-y: 8.5rem;
  }
  .g-xxl-34,
  .gx-xxl-34 {
    --gen-gutter-x: 8.75rem;
  }
  .g-xxl-34,
  .gy-xxl-34 {
    --gen-gutter-y: 8.75rem;
  }
  .g-xxl-35,
  .gx-xxl-35 {
    --gen-gutter-x: 9rem;
  }
  .g-xxl-35,
  .gy-xxl-35 {
    --gen-gutter-y: 9rem;
  }
  .g-xxl-36,
  .gx-xxl-36 {
    --gen-gutter-x: 9.25rem;
  }
  .g-xxl-36,
  .gy-xxl-36 {
    --gen-gutter-y: 9.25rem;
  }
  .g-xxl-37,
  .gx-xxl-37 {
    --gen-gutter-x: 9.5rem;
  }
  .g-xxl-37,
  .gy-xxl-37 {
    --gen-gutter-y: 9.5rem;
  }
  .g-xxl-38,
  .gx-xxl-38 {
    --gen-gutter-x: 9.75rem;
  }
  .g-xxl-38,
  .gy-xxl-38 {
    --gen-gutter-y: 9.75rem;
  }
  .g-xxl-39,
  .gx-xxl-39 {
    --gen-gutter-x: 10rem;
  }
  .g-xxl-39,
  .gy-xxl-39 {
    --gen-gutter-y: 10rem;
  }
  .g-xxl-40,
  .gx-xxl-40 {
    --gen-gutter-x: 10.25rem;
  }
  .g-xxl-40,
  .gy-xxl-40 {
    --gen-gutter-y: 10.25rem;
  }
}
.table {
  --gen-table-color: var(--gen-body-color);
  --gen-table-bg: transparent;
  --gen-table-border-color: var(--gen-border-color);
  --gen-table-accent-bg: transparent;
  --gen-table-striped-color: var(--gen-body-color);
  --gen-table-striped-bg: rgba(0, 0, 0, 0.05);
  --gen-table-active-color: var(--gen-body-color);
  --gen-table-active-bg: rgba(0, 0, 0, 0.1);
  --gen-table-hover-color: var(--gen-body-color);
  --gen-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--gen-table-color);
  vertical-align: top;
  border-color: var(--gen-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--gen-table-bg);
  border-bottom-width: var(--gen-border-width);
  box-shadow: inset 0 0 0 9999px var(--gen-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--gen-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--gen-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--gen-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --gen-table-accent-bg: var(--gen-table-striped-bg);
  color: var(--gen-table-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --gen-table-accent-bg: var(--gen-table-striped-bg);
  color: var(--gen-table-striped-color);
}

.table-active {
  --gen-table-accent-bg: var(--gen-table-active-bg);
  color: var(--gen-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --gen-table-accent-bg: var(--gen-table-hover-bg);
  color: var(--gen-table-hover-color);
}

.table-primary {
  --gen-table-color: #000;
  --gen-table-bg: #e1dbff;
  --gen-table-border-color: #cbc5e6;
  --gen-table-striped-bg: #d6d0f2;
  --gen-table-striped-color: #000;
  --gen-table-active-bg: #cbc5e6;
  --gen-table-active-color: #000;
  --gen-table-hover-bg: #d0cbec;
  --gen-table-hover-color: #000;
  color: var(--gen-table-color);
  border-color: var(--gen-table-border-color);
}

.table-secondary {
  --gen-table-color: #000;
  --gen-table-bg: #e2e3e5;
  --gen-table-border-color: #cbccce;
  --gen-table-striped-bg: #d7d8da;
  --gen-table-striped-color: #000;
  --gen-table-active-bg: #cbccce;
  --gen-table-active-color: #000;
  --gen-table-hover-bg: #d1d2d4;
  --gen-table-hover-color: #000;
  color: var(--gen-table-color);
  border-color: var(--gen-table-border-color);
}

.table-success {
  --gen-table-color: #000;
  --gen-table-bg: #d1e7dd;
  --gen-table-border-color: #bcd0c7;
  --gen-table-striped-bg: #c7dbd2;
  --gen-table-striped-color: #000;
  --gen-table-active-bg: #bcd0c7;
  --gen-table-active-color: #000;
  --gen-table-hover-bg: #c1d6cc;
  --gen-table-hover-color: #000;
  color: var(--gen-table-color);
  border-color: var(--gen-table-border-color);
}

.table-info {
  --gen-table-color: #000;
  --gen-table-bg: #cff4fc;
  --gen-table-border-color: #badce3;
  --gen-table-striped-bg: #c5e8ef;
  --gen-table-striped-color: #000;
  --gen-table-active-bg: #badce3;
  --gen-table-active-color: #000;
  --gen-table-hover-bg: #bfe2e9;
  --gen-table-hover-color: #000;
  color: var(--gen-table-color);
  border-color: var(--gen-table-border-color);
}

.table-warning {
  --gen-table-color: #000;
  --gen-table-bg: #fff3cd;
  --gen-table-border-color: #e6dbb9;
  --gen-table-striped-bg: #f2e7c3;
  --gen-table-striped-color: #000;
  --gen-table-active-bg: #e6dbb9;
  --gen-table-active-color: #000;
  --gen-table-hover-bg: #ece1be;
  --gen-table-hover-color: #000;
  color: var(--gen-table-color);
  border-color: var(--gen-table-border-color);
}

.table-danger {
  --gen-table-color: #000;
  --gen-table-bg: #f8d7da;
  --gen-table-border-color: #dfc2c4;
  --gen-table-striped-bg: #eccccf;
  --gen-table-striped-color: #000;
  --gen-table-active-bg: #dfc2c4;
  --gen-table-active-color: #000;
  --gen-table-hover-bg: #e5c7ca;
  --gen-table-hover-color: #000;
  color: var(--gen-table-color);
  border-color: var(--gen-table-border-color);
}

.table-light {
  --gen-table-color: #000;
  --gen-table-bg: #bdbfd4;
  --gen-table-border-color: #aaacbf;
  --gen-table-striped-bg: #b4b5c9;
  --gen-table-striped-color: #000;
  --gen-table-active-bg: #aaacbf;
  --gen-table-active-color: #000;
  --gen-table-hover-bg: #afb1c4;
  --gen-table-hover-color: #000;
  color: var(--gen-table-color);
  border-color: var(--gen-table-border-color);
}

.table-dark {
  --gen-table-color: #fff;
  --gen-table-bg: #11121c;
  --gen-table-border-color: #292a33;
  --gen-table-striped-bg: #1d1e27;
  --gen-table-striped-color: #fff;
  --gen-table-active-bg: #292a33;
  --gen-table-active-color: #fff;
  --gen-table-hover-bg: #23242d;
  --gen-table-hover-color: #fff;
  color: var(--gen-table-color);
  border-color: var(--gen-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.75rem + var(--gen-border-width));
  padding-bottom: calc(0.75rem + var(--gen-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(1rem + var(--gen-border-width));
  padding-bottom: calc(1rem + var(--gen-border-width));
  font-size: 1.125rem;
}

.col-form-label-sm {
  padding-top: calc(0.5rem + var(--gen-border-width));
  padding-bottom: calc(0.5rem + var(--gen-border-width));
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--gen-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--gen-emphasis-color);
  background-color: var(--gen-body-bg);
  background-clip: padding-box;
  border: var(--gen-border-width) solid var(--gen-border-color);
  appearance: none;
  border-radius: var(--gen-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--gen-emphasis-color);
  background-color: var(--gen-body-bg);
  border-color: #6a4dff;
  outline: 0;
  box-shadow: none;
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: var(--gen-secondary-color);
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--gen-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.75rem 1.5rem;
  margin: -0.75rem -1.5rem;
  margin-inline-end: 1.5rem;
  color: var(--gen-emphasis-color);
  background-color: var(--gen-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--gen-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--gen-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.75rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--gen-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--gen-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 1rem + calc(var(--gen-border-width) * 2));
  padding: 0.5rem 1.25rem;
  font-size: 0.875rem;
  border-radius: var(--gen-border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.5rem 1.25rem;
  margin: -0.5rem -1.25rem;
  margin-inline-end: 1.25rem;
}

.form-control-lg {
  min-height: calc(1.5em + 2rem + calc(var(--gen-border-width) * 2));
  padding: 1rem 2rem;
  font-size: 1.125rem;
  border-radius: var(--gen-border-radius-lg);
}
.form-control-lg::file-selector-button {
  padding: 1rem 2rem;
  margin: -1rem -2rem;
  margin-inline-end: 2rem;
}

textarea.form-control {
  min-height: calc(1.5em + 1.5rem + calc(var(--gen-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 1rem + calc(var(--gen-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 2rem + calc(var(--gen-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 1.5rem + calc(var(--gen-border-width) * 2));
  padding: 0.75rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--gen-border-radius);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--gen-border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 1rem + calc(var(--gen-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 2rem + calc(var(--gen-border-width) * 2));
}

.form-select {
  --gen-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.75rem 4.5rem 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--gen-emphasis-color);
  background-color: var(--gen-body-bg);
  background-image: var(--gen-form-select-bg-img), var(--gen-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 1.5rem center;
  background-size: 16px 12px;
  border: var(--gen-border-width) solid var(--gen-border-color);
  border-radius: var(--gen-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #6a4dff;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(106, 77, 255, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1.5rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--gen-secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--gen-emphasis-color);
}

.form-select-sm {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.25rem;
  font-size: 0.875rem;
  border-radius: var(--gen-border-radius-sm);
}

.form-select-lg {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  font-size: 1.125rem;
  border-radius: var(--gen-border-radius-lg);
}

[data-bs-theme=dark] .form-select {
  --gen-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23bdbfd4' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --gen-form-check-bg: var(--gen-body-bg);
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: var(--gen-form-check-bg);
  background-image: var(--gen-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--gen-border-width) solid var(--gen-border-color);
  appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #6a4dff;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(106, 77, 255, 0.25);
}
.form-check-input:checked {
  background-color: #6a4dff;
  border-color: #6a4dff;
}
.form-check-input:checked[type=checkbox] {
  --gen-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --gen-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #6a4dff;
  border-color: #6a4dff;
  --gen-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --gen-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--gen-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --gen-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%236a4dff'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --gen-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --gen-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, none;
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, none;
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #6a4dff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #d2caff;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--gen-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #6a4dff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #d2caff;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--gen-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--gen-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--gen-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--gen-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 1.5rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--gen-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 1.5rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--gen-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.75rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--gen-body-bg);
  border-radius: var(--gen-border-radius);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--gen-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--gen-border-width) 0;
}
.form-floating > :disabled ~ label {
  color: #6c757d;
}
.form-floating > :disabled ~ label::after {
  background-color: var(--gen-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--gen-emphasis-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--gen-tertiary-bg);
  border: var(--gen-border-width) solid var(--gen-border-color);
  border-radius: var(--gen-border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 1rem 2rem;
  font-size: 1.125rem;
  border-radius: var(--gen-border-radius-lg);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.5rem 1.25rem;
  font-size: 0.875rem;
  border-radius: var(--gen-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 6rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--gen-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--gen-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--gen-success);
  border-radius: var(--gen-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--gen-form-valid-border-color);
  padding-right: calc(1.5em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.375rem) center;
  background-size: calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--gen-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--gen-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.5rem);
  background-position: top calc(0.375em + 0.375rem) right calc(0.375em + 0.375rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--gen-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --gen-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 8.25rem;
  background-position: right 1.5rem center, center right 4.5rem;
  background-size: 16px 12px, calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--gen-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--gen-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 1.5rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--gen-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--gen-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--gen-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--gen-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--gen-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--gen-danger);
  border-radius: var(--gen-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--gen-form-invalid-border-color);
  padding-right: calc(1.5em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.375rem) center;
  background-size: calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--gen-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--gen-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.5rem);
  background-position: top calc(0.375em + 0.375rem) right calc(0.375em + 0.375rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--gen-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --gen-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  padding-right: 8.25rem;
  background-position: right 1.5rem center, center right 4.5rem;
  background-size: 16px 12px, calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--gen-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--gen-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 1.5rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--gen-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--gen-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--gen-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--gen-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --gen-btn-padding-x: 1.5rem;
  --gen-btn-padding-y: 0.75rem;
  --gen-btn-font-family: ;
  --gen-btn-font-size: 1rem;
  --gen-btn-font-weight: 500;
  --gen-btn-line-height: 1.5;
  --gen-btn-color: var(--gen-body-color);
  --gen-btn-bg: transparent;
  --gen-btn-border-width: var(--gen-border-width);
  --gen-btn-border-color: transparent;
  --gen-btn-border-radius: var(--gen-border-radius);
  --gen-btn-hover-border-color: transparent;
  --gen-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --gen-btn-disabled-opacity: 0.65;
  --gen-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--gen-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--gen-btn-padding-y) var(--gen-btn-padding-x);
  font-family: var(--gen-btn-font-family);
  font-size: var(--gen-btn-font-size);
  font-weight: var(--gen-btn-font-weight);
  line-height: var(--gen-btn-line-height);
  color: var(--gen-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--gen-btn-border-width) solid var(--gen-btn-border-color);
  border-radius: var(--gen-btn-border-radius);
  background-color: var(--gen-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--gen-btn-hover-color);
  background-color: var(--gen-btn-hover-bg);
  border-color: var(--gen-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--gen-btn-color);
  background-color: var(--gen-btn-bg);
  border-color: var(--gen-btn-border-color);
}
.btn:focus-visible {
  color: var(--gen-btn-hover-color);
  background-color: var(--gen-btn-hover-bg);
  border-color: var(--gen-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--gen-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--gen-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--gen-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--gen-btn-active-color);
  background-color: var(--gen-btn-active-bg);
  border-color: var(--gen-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--gen-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--gen-btn-disabled-color);
  pointer-events: none;
  background-color: var(--gen-btn-disabled-bg);
  border-color: var(--gen-btn-disabled-border-color);
  opacity: var(--gen-btn-disabled-opacity);
}

.btn-primary {
  --gen-btn-color: #fff;
  --gen-btn-bg: #6a4dff;
  --gen-btn-border-color: #6a4dff;
  --gen-btn-hover-color: #fff;
  --gen-btn-hover-bg: #5a41d9;
  --gen-btn-hover-border-color: #553ecc;
  --gen-btn-focus-shadow-rgb: 128, 104, 255;
  --gen-btn-active-color: #fff;
  --gen-btn-active-bg: #553ecc;
  --gen-btn-active-border-color: #503abf;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #fff;
  --gen-btn-disabled-bg: #6a4dff;
  --gen-btn-disabled-border-color: #6a4dff;
}

.btn-secondary {
  --gen-btn-color: #fff;
  --gen-btn-bg: #6c757d;
  --gen-btn-border-color: #6c757d;
  --gen-btn-hover-color: #fff;
  --gen-btn-hover-bg: #5c636a;
  --gen-btn-hover-border-color: #565e64;
  --gen-btn-focus-shadow-rgb: 130, 138, 145;
  --gen-btn-active-color: #fff;
  --gen-btn-active-bg: #565e64;
  --gen-btn-active-border-color: #51585e;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #fff;
  --gen-btn-disabled-bg: #6c757d;
  --gen-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --gen-btn-color: #fff;
  --gen-btn-bg: #198754;
  --gen-btn-border-color: #198754;
  --gen-btn-hover-color: #fff;
  --gen-btn-hover-bg: #157347;
  --gen-btn-hover-border-color: #146c43;
  --gen-btn-focus-shadow-rgb: 60, 153, 110;
  --gen-btn-active-color: #fff;
  --gen-btn-active-bg: #146c43;
  --gen-btn-active-border-color: #13653f;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #fff;
  --gen-btn-disabled-bg: #198754;
  --gen-btn-disabled-border-color: #198754;
}

.btn-info {
  --gen-btn-color: #000;
  --gen-btn-bg: #0dcaf0;
  --gen-btn-border-color: #0dcaf0;
  --gen-btn-hover-color: #000;
  --gen-btn-hover-bg: #31d2f2;
  --gen-btn-hover-border-color: #25cff2;
  --gen-btn-focus-shadow-rgb: 11, 172, 204;
  --gen-btn-active-color: #000;
  --gen-btn-active-bg: #3dd5f3;
  --gen-btn-active-border-color: #25cff2;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #000;
  --gen-btn-disabled-bg: #0dcaf0;
  --gen-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --gen-btn-color: #000;
  --gen-btn-bg: #ffc107;
  --gen-btn-border-color: #ffc107;
  --gen-btn-hover-color: #000;
  --gen-btn-hover-bg: #ffca2c;
  --gen-btn-hover-border-color: #ffc720;
  --gen-btn-focus-shadow-rgb: 217, 164, 6;
  --gen-btn-active-color: #000;
  --gen-btn-active-bg: #ffcd39;
  --gen-btn-active-border-color: #ffc720;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #000;
  --gen-btn-disabled-bg: #ffc107;
  --gen-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --gen-btn-color: #fff;
  --gen-btn-bg: #dc3545;
  --gen-btn-border-color: #dc3545;
  --gen-btn-hover-color: #fff;
  --gen-btn-hover-bg: #bb2d3b;
  --gen-btn-hover-border-color: #b02a37;
  --gen-btn-focus-shadow-rgb: 225, 83, 97;
  --gen-btn-active-color: #fff;
  --gen-btn-active-bg: #b02a37;
  --gen-btn-active-border-color: #a52834;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #fff;
  --gen-btn-disabled-bg: #dc3545;
  --gen-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --gen-btn-color: #000;
  --gen-btn-bg: #bdbfd4;
  --gen-btn-border-color: #bdbfd4;
  --gen-btn-hover-color: #000;
  --gen-btn-hover-bg: #a1a2b4;
  --gen-btn-hover-border-color: #9799aa;
  --gen-btn-focus-shadow-rgb: 161, 162, 180;
  --gen-btn-active-color: #000;
  --gen-btn-active-bg: #9799aa;
  --gen-btn-active-border-color: #8e8f9f;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #000;
  --gen-btn-disabled-bg: #bdbfd4;
  --gen-btn-disabled-border-color: #bdbfd4;
}

.btn-dark {
  --gen-btn-color: #fff;
  --gen-btn-bg: #11121c;
  --gen-btn-border-color: #11121c;
  --gen-btn-hover-color: #fff;
  --gen-btn-hover-bg: #35363e;
  --gen-btn-hover-border-color: #292a33;
  --gen-btn-focus-shadow-rgb: 53, 54, 62;
  --gen-btn-active-color: #fff;
  --gen-btn-active-bg: #414149;
  --gen-btn-active-border-color: #292a33;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #fff;
  --gen-btn-disabled-bg: #11121c;
  --gen-btn-disabled-border-color: #11121c;
}

.btn-white {
  --gen-btn-color: #000;
  --gen-btn-bg: #fff;
  --gen-btn-border-color: #fff;
  --gen-btn-hover-color: #000;
  --gen-btn-hover-bg: white;
  --gen-btn-hover-border-color: white;
  --gen-btn-focus-shadow-rgb: 217, 217, 217;
  --gen-btn-active-color: #000;
  --gen-btn-active-bg: white;
  --gen-btn-active-border-color: white;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #000;
  --gen-btn-disabled-bg: #fff;
  --gen-btn-disabled-border-color: #fff;
}

.btn-black {
  --gen-btn-color: #fff;
  --gen-btn-bg: #000;
  --gen-btn-border-color: #000;
  --gen-btn-hover-color: #fff;
  --gen-btn-hover-bg: black;
  --gen-btn-hover-border-color: black;
  --gen-btn-focus-shadow-rgb: 38, 38, 38;
  --gen-btn-active-color: #fff;
  --gen-btn-active-bg: black;
  --gen-btn-active-border-color: black;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #fff;
  --gen-btn-disabled-bg: #000;
  --gen-btn-disabled-border-color: #000;
}

.btn-yellow {
  --gen-btn-color: #000;
  --gen-btn-bg: #e6f85e;
  --gen-btn-border-color: #e6f85e;
  --gen-btn-hover-color: #000;
  --gen-btn-hover-bg: #eaf976;
  --gen-btn-hover-border-color: #e9f96e;
  --gen-btn-focus-shadow-rgb: 196, 211, 80;
  --gen-btn-active-color: #000;
  --gen-btn-active-bg: #ebf97e;
  --gen-btn-active-border-color: #e9f96e;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #000;
  --gen-btn-disabled-bg: #e6f85e;
  --gen-btn-disabled-border-color: #e6f85e;
}

.btn-blue {
  --gen-btn-color: #fff;
  --gen-btn-bg: #6a4dff;
  --gen-btn-border-color: #6a4dff;
  --gen-btn-hover-color: #fff;
  --gen-btn-hover-bg: #5a41d9;
  --gen-btn-hover-border-color: #553ecc;
  --gen-btn-focus-shadow-rgb: 128, 104, 255;
  --gen-btn-active-color: #fff;
  --gen-btn-active-bg: #553ecc;
  --gen-btn-active-border-color: #503abf;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #fff;
  --gen-btn-disabled-bg: #6a4dff;
  --gen-btn-disabled-border-color: #6a4dff;
}

.btn-light-blue {
  --gen-btn-color: #000;
  --gen-btn-bg: #f3f8ff;
  --gen-btn-border-color: #f3f8ff;
  --gen-btn-hover-color: #000;
  --gen-btn-hover-bg: #f5f9ff;
  --gen-btn-hover-border-color: #f4f9ff;
  --gen-btn-focus-shadow-rgb: 207, 211, 217;
  --gen-btn-active-color: #000;
  --gen-btn-active-bg: #f5f9ff;
  --gen-btn-active-border-color: #f4f9ff;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #000;
  --gen-btn-disabled-bg: #f3f8ff;
  --gen-btn-disabled-border-color: #f3f8ff;
}

.btn-light-blue-2 {
  --gen-btn-color: #000;
  --gen-btn-bg: #eae6ff;
  --gen-btn-border-color: #eae6ff;
  --gen-btn-hover-color: #000;
  --gen-btn-hover-bg: #edeaff;
  --gen-btn-hover-border-color: #ece9ff;
  --gen-btn-focus-shadow-rgb: 199, 196, 217;
  --gen-btn-active-color: #000;
  --gen-btn-active-bg: #eeebff;
  --gen-btn-active-border-color: #ece9ff;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #000;
  --gen-btn-disabled-bg: #eae6ff;
  --gen-btn-disabled-border-color: #eae6ff;
}

.btn-light-blue-3 {
  --gen-btn-color: #000;
  --gen-btn-bg: #bdbfd4;
  --gen-btn-border-color: #bdbfd4;
  --gen-btn-hover-color: #000;
  --gen-btn-hover-bg: #c7c9da;
  --gen-btn-hover-border-color: #c4c5d8;
  --gen-btn-focus-shadow-rgb: 161, 162, 180;
  --gen-btn-active-color: #000;
  --gen-btn-active-bg: #caccdd;
  --gen-btn-active-border-color: #c4c5d8;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #000;
  --gen-btn-disabled-bg: #bdbfd4;
  --gen-btn-disabled-border-color: #bdbfd4;
}

.btn-light-blue-4 {
  --gen-btn-color: #000;
  --gen-btn-bg: #c3d1e3;
  --gen-btn-border-color: #c3d1e3;
  --gen-btn-hover-color: #000;
  --gen-btn-hover-bg: #ccd8e7;
  --gen-btn-hover-border-color: #c9d6e6;
  --gen-btn-focus-shadow-rgb: 166, 178, 193;
  --gen-btn-active-color: #000;
  --gen-btn-active-bg: #cfdae9;
  --gen-btn-active-border-color: #c9d6e6;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #000;
  --gen-btn-disabled-bg: #c3d1e3;
  --gen-btn-disabled-border-color: #c3d1e3;
}

.btn-light-blue-5 {
  --gen-btn-color: #000;
  --gen-btn-bg: #b7a9ff;
  --gen-btn-border-color: #b7a9ff;
  --gen-btn-hover-color: #000;
  --gen-btn-hover-bg: #c2b6ff;
  --gen-btn-hover-border-color: #beb2ff;
  --gen-btn-focus-shadow-rgb: 156, 144, 217;
  --gen-btn-active-color: #000;
  --gen-btn-active-bg: #c5baff;
  --gen-btn-active-border-color: #beb2ff;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #000;
  --gen-btn-disabled-bg: #b7a9ff;
  --gen-btn-disabled-border-color: #b7a9ff;
}

.btn-light-blue-6 {
  --gen-btn-color: #000;
  --gen-btn-bg: #747694;
  --gen-btn-border-color: #747694;
  --gen-btn-hover-color: #000;
  --gen-btn-hover-bg: #898ba4;
  --gen-btn-hover-border-color: #82849f;
  --gen-btn-focus-shadow-rgb: 99, 100, 126;
  --gen-btn-active-color: #000;
  --gen-btn-active-bg: #9091a9;
  --gen-btn-active-border-color: #82849f;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #000;
  --gen-btn-disabled-bg: #747694;
  --gen-btn-disabled-border-color: #747694;
}

.btn-dark-blue {
  --gen-btn-color: #fff;
  --gen-btn-bg: #2e2f45;
  --gen-btn-border-color: #2e2f45;
  --gen-btn-hover-color: #fff;
  --gen-btn-hover-bg: #27283b;
  --gen-btn-hover-border-color: #252637;
  --gen-btn-focus-shadow-rgb: 77, 78, 97;
  --gen-btn-active-color: #fff;
  --gen-btn-active-bg: #252637;
  --gen-btn-active-border-color: #232334;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #fff;
  --gen-btn-disabled-bg: #2e2f45;
  --gen-btn-disabled-border-color: #2e2f45;
}

.btn-dark-blue-2 {
  --gen-btn-color: #fff;
  --gen-btn-bg: #262838;
  --gen-btn-border-color: #262838;
  --gen-btn-hover-color: #fff;
  --gen-btn-hover-bg: #202230;
  --gen-btn-hover-border-color: #1e202d;
  --gen-btn-focus-shadow-rgb: 71, 72, 86;
  --gen-btn-active-color: #fff;
  --gen-btn-active-bg: #1e202d;
  --gen-btn-active-border-color: #1d1e2a;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #fff;
  --gen-btn-disabled-bg: #262838;
  --gen-btn-disabled-border-color: #262838;
}

.btn-dark-blue-3 {
  --gen-btn-color: #fff;
  --gen-btn-bg: #191a28;
  --gen-btn-border-color: #191a28;
  --gen-btn-hover-color: #fff;
  --gen-btn-hover-bg: #151622;
  --gen-btn-hover-border-color: #141520;
  --gen-btn-focus-shadow-rgb: 60, 60, 72;
  --gen-btn-active-color: #fff;
  --gen-btn-active-bg: #141520;
  --gen-btn-active-border-color: #13141e;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #fff;
  --gen-btn-disabled-bg: #191a28;
  --gen-btn-disabled-border-color: #191a28;
}

.btn-dark-blue-4 {
  --gen-btn-color: #fff;
  --gen-btn-bg: #171825;
  --gen-btn-border-color: #171825;
  --gen-btn-hover-color: #fff;
  --gen-btn-hover-bg: #14141f;
  --gen-btn-hover-border-color: #12131e;
  --gen-btn-focus-shadow-rgb: 58, 59, 70;
  --gen-btn-active-color: #fff;
  --gen-btn-active-bg: #12131e;
  --gen-btn-active-border-color: #11121c;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #fff;
  --gen-btn-disabled-bg: #171825;
  --gen-btn-disabled-border-color: #171825;
}

.btn-dark-blue-5 {
  --gen-btn-color: #fff;
  --gen-btn-bg: #11121c;
  --gen-btn-border-color: #11121c;
  --gen-btn-hover-color: #fff;
  --gen-btn-hover-bg: #0e0f18;
  --gen-btn-hover-border-color: #0e0e16;
  --gen-btn-focus-shadow-rgb: 53, 54, 62;
  --gen-btn-active-color: #fff;
  --gen-btn-active-bg: #0e0e16;
  --gen-btn-active-border-color: #0d0e15;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #fff;
  --gen-btn-disabled-bg: #11121c;
  --gen-btn-disabled-border-color: #11121c;
}

.btn-primary-dark {
  --gen-btn-color: #000;
  --gen-btn-bg: #e6f85e;
  --gen-btn-border-color: #e6f85e;
  --gen-btn-hover-color: #000;
  --gen-btn-hover-bg: #eaf976;
  --gen-btn-hover-border-color: #e9f96e;
  --gen-btn-focus-shadow-rgb: 196, 211, 80;
  --gen-btn-active-color: #000;
  --gen-btn-active-bg: #ebf97e;
  --gen-btn-active-border-color: #e9f96e;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #000;
  --gen-btn-disabled-bg: #e6f85e;
  --gen-btn-disabled-border-color: #e6f85e;
}

.btn-outline-primary {
  --gen-btn-color: #6a4dff;
  --gen-btn-border-color: #6a4dff;
  --gen-btn-hover-color: #fff;
  --gen-btn-hover-bg: #6a4dff;
  --gen-btn-hover-border-color: #6a4dff;
  --gen-btn-focus-shadow-rgb: 106, 77, 255;
  --gen-btn-active-color: #fff;
  --gen-btn-active-bg: #6a4dff;
  --gen-btn-active-border-color: #6a4dff;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #6a4dff;
  --gen-btn-disabled-bg: transparent;
  --gen-btn-disabled-border-color: #6a4dff;
  --gen-gradient: none;
}

.btn-outline-secondary {
  --gen-btn-color: #6c757d;
  --gen-btn-border-color: #6c757d;
  --gen-btn-hover-color: #fff;
  --gen-btn-hover-bg: #6c757d;
  --gen-btn-hover-border-color: #6c757d;
  --gen-btn-focus-shadow-rgb: 108, 117, 125;
  --gen-btn-active-color: #fff;
  --gen-btn-active-bg: #6c757d;
  --gen-btn-active-border-color: #6c757d;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #6c757d;
  --gen-btn-disabled-bg: transparent;
  --gen-btn-disabled-border-color: #6c757d;
  --gen-gradient: none;
}

.btn-outline-success {
  --gen-btn-color: #198754;
  --gen-btn-border-color: #198754;
  --gen-btn-hover-color: #fff;
  --gen-btn-hover-bg: #198754;
  --gen-btn-hover-border-color: #198754;
  --gen-btn-focus-shadow-rgb: 25, 135, 84;
  --gen-btn-active-color: #fff;
  --gen-btn-active-bg: #198754;
  --gen-btn-active-border-color: #198754;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #198754;
  --gen-btn-disabled-bg: transparent;
  --gen-btn-disabled-border-color: #198754;
  --gen-gradient: none;
}

.btn-outline-info {
  --gen-btn-color: #0dcaf0;
  --gen-btn-border-color: #0dcaf0;
  --gen-btn-hover-color: #000;
  --gen-btn-hover-bg: #0dcaf0;
  --gen-btn-hover-border-color: #0dcaf0;
  --gen-btn-focus-shadow-rgb: 13, 202, 240;
  --gen-btn-active-color: #000;
  --gen-btn-active-bg: #0dcaf0;
  --gen-btn-active-border-color: #0dcaf0;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #0dcaf0;
  --gen-btn-disabled-bg: transparent;
  --gen-btn-disabled-border-color: #0dcaf0;
  --gen-gradient: none;
}

.btn-outline-warning {
  --gen-btn-color: #ffc107;
  --gen-btn-border-color: #ffc107;
  --gen-btn-hover-color: #000;
  --gen-btn-hover-bg: #ffc107;
  --gen-btn-hover-border-color: #ffc107;
  --gen-btn-focus-shadow-rgb: 255, 193, 7;
  --gen-btn-active-color: #000;
  --gen-btn-active-bg: #ffc107;
  --gen-btn-active-border-color: #ffc107;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #ffc107;
  --gen-btn-disabled-bg: transparent;
  --gen-btn-disabled-border-color: #ffc107;
  --gen-gradient: none;
}

.btn-outline-danger {
  --gen-btn-color: #dc3545;
  --gen-btn-border-color: #dc3545;
  --gen-btn-hover-color: #fff;
  --gen-btn-hover-bg: #dc3545;
  --gen-btn-hover-border-color: #dc3545;
  --gen-btn-focus-shadow-rgb: 220, 53, 69;
  --gen-btn-active-color: #fff;
  --gen-btn-active-bg: #dc3545;
  --gen-btn-active-border-color: #dc3545;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #dc3545;
  --gen-btn-disabled-bg: transparent;
  --gen-btn-disabled-border-color: #dc3545;
  --gen-gradient: none;
}

.btn-outline-light {
  --gen-btn-color: #bdbfd4;
  --gen-btn-border-color: #bdbfd4;
  --gen-btn-hover-color: #000;
  --gen-btn-hover-bg: #bdbfd4;
  --gen-btn-hover-border-color: #bdbfd4;
  --gen-btn-focus-shadow-rgb: 189, 191, 212;
  --gen-btn-active-color: #000;
  --gen-btn-active-bg: #bdbfd4;
  --gen-btn-active-border-color: #bdbfd4;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #bdbfd4;
  --gen-btn-disabled-bg: transparent;
  --gen-btn-disabled-border-color: #bdbfd4;
  --gen-gradient: none;
}

.btn-outline-dark {
  --gen-btn-color: #11121c;
  --gen-btn-border-color: #11121c;
  --gen-btn-hover-color: #fff;
  --gen-btn-hover-bg: #11121c;
  --gen-btn-hover-border-color: #11121c;
  --gen-btn-focus-shadow-rgb: 17, 18, 28;
  --gen-btn-active-color: #fff;
  --gen-btn-active-bg: #11121c;
  --gen-btn-active-border-color: #11121c;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #11121c;
  --gen-btn-disabled-bg: transparent;
  --gen-btn-disabled-border-color: #11121c;
  --gen-gradient: none;
}

.btn-outline-white {
  --gen-btn-color: #fff;
  --gen-btn-border-color: #fff;
  --gen-btn-hover-color: #000;
  --gen-btn-hover-bg: #fff;
  --gen-btn-hover-border-color: #fff;
  --gen-btn-focus-shadow-rgb: 255, 255, 255;
  --gen-btn-active-color: #000;
  --gen-btn-active-bg: #fff;
  --gen-btn-active-border-color: #fff;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #fff;
  --gen-btn-disabled-bg: transparent;
  --gen-btn-disabled-border-color: #fff;
  --gen-gradient: none;
}

.btn-outline-black {
  --gen-btn-color: #000;
  --gen-btn-border-color: #000;
  --gen-btn-hover-color: #fff;
  --gen-btn-hover-bg: #000;
  --gen-btn-hover-border-color: #000;
  --gen-btn-focus-shadow-rgb: 0, 0, 0;
  --gen-btn-active-color: #fff;
  --gen-btn-active-bg: #000;
  --gen-btn-active-border-color: #000;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #000;
  --gen-btn-disabled-bg: transparent;
  --gen-btn-disabled-border-color: #000;
  --gen-gradient: none;
}

.btn-outline-yellow {
  --gen-btn-color: #e6f85e;
  --gen-btn-border-color: #e6f85e;
  --gen-btn-hover-color: #000;
  --gen-btn-hover-bg: #e6f85e;
  --gen-btn-hover-border-color: #e6f85e;
  --gen-btn-focus-shadow-rgb: 230, 248, 94;
  --gen-btn-active-color: #000;
  --gen-btn-active-bg: #e6f85e;
  --gen-btn-active-border-color: #e6f85e;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #e6f85e;
  --gen-btn-disabled-bg: transparent;
  --gen-btn-disabled-border-color: #e6f85e;
  --gen-gradient: none;
}

.btn-outline-blue {
  --gen-btn-color: #6a4dff;
  --gen-btn-border-color: #6a4dff;
  --gen-btn-hover-color: #fff;
  --gen-btn-hover-bg: #6a4dff;
  --gen-btn-hover-border-color: #6a4dff;
  --gen-btn-focus-shadow-rgb: 106, 77, 255;
  --gen-btn-active-color: #fff;
  --gen-btn-active-bg: #6a4dff;
  --gen-btn-active-border-color: #6a4dff;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #6a4dff;
  --gen-btn-disabled-bg: transparent;
  --gen-btn-disabled-border-color: #6a4dff;
  --gen-gradient: none;
}

.btn-outline-light-blue {
  --gen-btn-color: #f3f8ff;
  --gen-btn-border-color: #f3f8ff;
  --gen-btn-hover-color: #000;
  --gen-btn-hover-bg: #f3f8ff;
  --gen-btn-hover-border-color: #f3f8ff;
  --gen-btn-focus-shadow-rgb: 243, 248, 255;
  --gen-btn-active-color: #000;
  --gen-btn-active-bg: #f3f8ff;
  --gen-btn-active-border-color: #f3f8ff;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #f3f8ff;
  --gen-btn-disabled-bg: transparent;
  --gen-btn-disabled-border-color: #f3f8ff;
  --gen-gradient: none;
}

.btn-outline-light-blue-2 {
  --gen-btn-color: #eae6ff;
  --gen-btn-border-color: #eae6ff;
  --gen-btn-hover-color: #000;
  --gen-btn-hover-bg: #eae6ff;
  --gen-btn-hover-border-color: #eae6ff;
  --gen-btn-focus-shadow-rgb: 234, 230, 255;
  --gen-btn-active-color: #000;
  --gen-btn-active-bg: #eae6ff;
  --gen-btn-active-border-color: #eae6ff;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #eae6ff;
  --gen-btn-disabled-bg: transparent;
  --gen-btn-disabled-border-color: #eae6ff;
  --gen-gradient: none;
}

.btn-outline-light-blue-3 {
  --gen-btn-color: #bdbfd4;
  --gen-btn-border-color: #bdbfd4;
  --gen-btn-hover-color: #000;
  --gen-btn-hover-bg: #bdbfd4;
  --gen-btn-hover-border-color: #bdbfd4;
  --gen-btn-focus-shadow-rgb: 189, 191, 212;
  --gen-btn-active-color: #000;
  --gen-btn-active-bg: #bdbfd4;
  --gen-btn-active-border-color: #bdbfd4;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #bdbfd4;
  --gen-btn-disabled-bg: transparent;
  --gen-btn-disabled-border-color: #bdbfd4;
  --gen-gradient: none;
}

.btn-outline-light-blue-4 {
  --gen-btn-color: #c3d1e3;
  --gen-btn-border-color: #c3d1e3;
  --gen-btn-hover-color: #000;
  --gen-btn-hover-bg: #c3d1e3;
  --gen-btn-hover-border-color: #c3d1e3;
  --gen-btn-focus-shadow-rgb: 195, 209, 227;
  --gen-btn-active-color: #000;
  --gen-btn-active-bg: #c3d1e3;
  --gen-btn-active-border-color: #c3d1e3;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #c3d1e3;
  --gen-btn-disabled-bg: transparent;
  --gen-btn-disabled-border-color: #c3d1e3;
  --gen-gradient: none;
}

.btn-outline-light-blue-5 {
  --gen-btn-color: #b7a9ff;
  --gen-btn-border-color: #b7a9ff;
  --gen-btn-hover-color: #000;
  --gen-btn-hover-bg: #b7a9ff;
  --gen-btn-hover-border-color: #b7a9ff;
  --gen-btn-focus-shadow-rgb: 183, 169, 255;
  --gen-btn-active-color: #000;
  --gen-btn-active-bg: #b7a9ff;
  --gen-btn-active-border-color: #b7a9ff;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #b7a9ff;
  --gen-btn-disabled-bg: transparent;
  --gen-btn-disabled-border-color: #b7a9ff;
  --gen-gradient: none;
}

.btn-outline-light-blue-6 {
  --gen-btn-color: #747694;
  --gen-btn-border-color: #747694;
  --gen-btn-hover-color: #000;
  --gen-btn-hover-bg: #747694;
  --gen-btn-hover-border-color: #747694;
  --gen-btn-focus-shadow-rgb: 116, 118, 148;
  --gen-btn-active-color: #000;
  --gen-btn-active-bg: #747694;
  --gen-btn-active-border-color: #747694;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #747694;
  --gen-btn-disabled-bg: transparent;
  --gen-btn-disabled-border-color: #747694;
  --gen-gradient: none;
}

.btn-outline-dark-blue {
  --gen-btn-color: #2e2f45;
  --gen-btn-border-color: #2e2f45;
  --gen-btn-hover-color: #fff;
  --gen-btn-hover-bg: #2e2f45;
  --gen-btn-hover-border-color: #2e2f45;
  --gen-btn-focus-shadow-rgb: 46, 47, 69;
  --gen-btn-active-color: #fff;
  --gen-btn-active-bg: #2e2f45;
  --gen-btn-active-border-color: #2e2f45;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #2e2f45;
  --gen-btn-disabled-bg: transparent;
  --gen-btn-disabled-border-color: #2e2f45;
  --gen-gradient: none;
}

.btn-outline-dark-blue-2 {
  --gen-btn-color: #262838;
  --gen-btn-border-color: #262838;
  --gen-btn-hover-color: #fff;
  --gen-btn-hover-bg: #262838;
  --gen-btn-hover-border-color: #262838;
  --gen-btn-focus-shadow-rgb: 38, 40, 56;
  --gen-btn-active-color: #fff;
  --gen-btn-active-bg: #262838;
  --gen-btn-active-border-color: #262838;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #262838;
  --gen-btn-disabled-bg: transparent;
  --gen-btn-disabled-border-color: #262838;
  --gen-gradient: none;
}

.btn-outline-dark-blue-3 {
  --gen-btn-color: #191a28;
  --gen-btn-border-color: #191a28;
  --gen-btn-hover-color: #fff;
  --gen-btn-hover-bg: #191a28;
  --gen-btn-hover-border-color: #191a28;
  --gen-btn-focus-shadow-rgb: 25, 26, 40;
  --gen-btn-active-color: #fff;
  --gen-btn-active-bg: #191a28;
  --gen-btn-active-border-color: #191a28;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #191a28;
  --gen-btn-disabled-bg: transparent;
  --gen-btn-disabled-border-color: #191a28;
  --gen-gradient: none;
}

.btn-outline-dark-blue-4 {
  --gen-btn-color: #171825;
  --gen-btn-border-color: #171825;
  --gen-btn-hover-color: #fff;
  --gen-btn-hover-bg: #171825;
  --gen-btn-hover-border-color: #171825;
  --gen-btn-focus-shadow-rgb: 23, 24, 37;
  --gen-btn-active-color: #fff;
  --gen-btn-active-bg: #171825;
  --gen-btn-active-border-color: #171825;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #171825;
  --gen-btn-disabled-bg: transparent;
  --gen-btn-disabled-border-color: #171825;
  --gen-gradient: none;
}

.btn-outline-dark-blue-5 {
  --gen-btn-color: #11121c;
  --gen-btn-border-color: #11121c;
  --gen-btn-hover-color: #fff;
  --gen-btn-hover-bg: #11121c;
  --gen-btn-hover-border-color: #11121c;
  --gen-btn-focus-shadow-rgb: 17, 18, 28;
  --gen-btn-active-color: #fff;
  --gen-btn-active-bg: #11121c;
  --gen-btn-active-border-color: #11121c;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #11121c;
  --gen-btn-disabled-bg: transparent;
  --gen-btn-disabled-border-color: #11121c;
  --gen-gradient: none;
}

.btn-outline-primary-dark {
  --gen-btn-color: #e6f85e;
  --gen-btn-border-color: #e6f85e;
  --gen-btn-hover-color: #000;
  --gen-btn-hover-bg: #e6f85e;
  --gen-btn-hover-border-color: #e6f85e;
  --gen-btn-focus-shadow-rgb: 230, 248, 94;
  --gen-btn-active-color: #000;
  --gen-btn-active-bg: #e6f85e;
  --gen-btn-active-border-color: #e6f85e;
  --gen-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gen-btn-disabled-color: #e6f85e;
  --gen-btn-disabled-bg: transparent;
  --gen-btn-disabled-border-color: #e6f85e;
  --gen-gradient: none;
}

.btn-link {
  --gen-btn-font-weight: 400;
  --gen-btn-color: var(--gen-link-color);
  --gen-btn-bg: transparent;
  --gen-btn-border-color: transparent;
  --gen-btn-hover-color: var(--gen-link-hover-color);
  --gen-btn-hover-border-color: transparent;
  --gen-btn-active-color: var(--gen-link-hover-color);
  --gen-btn-active-border-color: transparent;
  --gen-btn-disabled-color: #6c757d;
  --gen-btn-disabled-border-color: transparent;
  --gen-btn-box-shadow: 0 0 0 #000;
  --gen-btn-focus-shadow-rgb: 128, 104, 255;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--gen-btn-color);
}
.btn-link:hover {
  color: var(--gen-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --gen-btn-padding-y: 1rem;
  --gen-btn-padding-x: 2rem;
  --gen-btn-font-size: 1.125rem;
  --gen-btn-border-radius: var(--gen-border-radius-lg);
}

.btn-sm, .btn-group-sm > .btn {
  --gen-btn-padding-y: 0.5rem;
  --gen-btn-padding-x: 1.25rem;
  --gen-btn-font-size: 0.875rem;
  --gen-btn-border-radius: var(--gen-border-radius-sm);
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --gen-dropdown-zindex: 1000;
  --gen-dropdown-min-width: 14rem;
  --gen-dropdown-padding-x: 0.5rem;
  --gen-dropdown-padding-y: 0.75rem;
  --gen-dropdown-spacer: 0.125rem;
  --gen-dropdown-font-size: 1rem;
  --gen-dropdown-color: var(--gen-body-color);
  --gen-dropdown-bg: var(--gen-body-bg);
  --gen-dropdown-border-color: var(--gen-border-color-translucent);
  --gen-dropdown-border-radius: var(--gen-border-radius);
  --gen-dropdown-border-width: var(--gen-border-width);
  --gen-dropdown-inner-border-radius: calc(var(--gen-border-radius) - var(--gen-border-width));
  --gen-dropdown-divider-bg: var(--gen-border-color-translucent);
  --gen-dropdown-divider-margin-y: 0.5rem;
  --gen-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --gen-dropdown-link-color: var(--gen-body-color);
  --gen-dropdown-link-hover-color: #6a4dff;
  --gen-dropdown-link-hover-bg: transparent;
  --gen-dropdown-link-active-color: #6a4dff;
  --gen-dropdown-link-active-bg: transparent;
  --gen-dropdown-link-disabled-color: var(--gen-tertiary-color);
  --gen-dropdown-item-padding-x: 1rem;
  --gen-dropdown-item-padding-y: 0.25rem;
  --gen-dropdown-header-color: #6c757d;
  --gen-dropdown-header-padding-x: 1rem;
  --gen-dropdown-header-padding-y: 0.75rem;
  position: absolute;
  z-index: var(--gen-dropdown-zindex);
  display: none;
  min-width: var(--gen-dropdown-min-width);
  padding: var(--gen-dropdown-padding-y) var(--gen-dropdown-padding-x);
  margin: 0;
  font-size: var(--gen-dropdown-font-size);
  color: var(--gen-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--gen-dropdown-bg);
  background-clip: padding-box;
  border: var(--gen-dropdown-border-width) solid var(--gen-dropdown-border-color);
  border-radius: var(--gen-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--gen-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--gen-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--gen-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--gen-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--gen-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--gen-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--gen-dropdown-item-padding-y) var(--gen-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--gen-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--gen-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--gen-dropdown-link-hover-color);
  background-color: var(--gen-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--gen-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--gen-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--gen-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--gen-dropdown-header-padding-y) var(--gen-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--gen-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--gen-dropdown-item-padding-y) var(--gen-dropdown-item-padding-x);
  color: var(--gen-dropdown-link-color);
}

.dropdown-menu-dark {
  --gen-dropdown-color: #dee2e6;
  --gen-dropdown-bg: #343a40;
  --gen-dropdown-border-color: var(--gen-border-color-translucent);
  --gen-dropdown-box-shadow: ;
  --gen-dropdown-link-color: #dee2e6;
  --gen-dropdown-link-hover-color: #fff;
  --gen-dropdown-divider-bg: var(--gen-border-color-translucent);
  --gen-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --gen-dropdown-link-active-color: #6a4dff;
  --gen-dropdown-link-active-bg: transparent;
  --gen-dropdown-link-disabled-color: #adb5bd;
  --gen-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--gen-border-radius);
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--gen-border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--gen-border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --gen-nav-link-padding-x: 1rem;
  --gen-nav-link-padding-y: 0.5rem;
  --gen-nav-link-font-weight: ;
  --gen-nav-link-color: var(--gen-link-color);
  --gen-nav-link-hover-color: var(--gen-link-hover-color);
  --gen-nav-link-disabled-color: var(--gen-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--gen-nav-link-padding-y) var(--gen-nav-link-padding-x);
  font-size: var(--gen-nav-link-font-size);
  font-weight: var(--gen-nav-link-font-weight);
  color: var(--gen-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--gen-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(106, 77, 255, 0.25);
}
.nav-link.disabled {
  color: var(--gen-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --gen-nav-tabs-border-width: var(--gen-border-width);
  --gen-nav-tabs-border-color: var(--gen-border-color);
  --gen-nav-tabs-border-radius: var(--gen-border-radius);
  --gen-nav-tabs-link-hover-border-color: var(--gen-secondary-bg) var(--gen-secondary-bg) var(--gen-border-color);
  --gen-nav-tabs-link-active-color: var(--gen-emphasis-color);
  --gen-nav-tabs-link-active-bg: var(--gen-body-bg);
  --gen-nav-tabs-link-active-border-color: var(--gen-border-color) var(--gen-border-color) var(--gen-body-bg);
  border-bottom: var(--gen-nav-tabs-border-width) solid var(--gen-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--gen-nav-tabs-border-width));
  border: var(--gen-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--gen-nav-tabs-border-radius);
  border-top-right-radius: var(--gen-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--gen-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--gen-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--gen-nav-tabs-link-active-color);
  background-color: var(--gen-nav-tabs-link-active-bg);
  border-color: var(--gen-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--gen-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --gen-nav-pills-border-radius: var(--gen-border-radius);
  --gen-nav-pills-link-active-color: #fff;
  --gen-nav-pills-link-active-bg: #6a4dff;
}
.nav-pills .nav-link {
  border-radius: var(--gen-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--gen-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--gen-nav-pills-link-active-color);
  background-color: var(--gen-nav-pills-link-active-bg);
}

.nav-underline {
  --gen-nav-underline-gap: 1rem;
  --gen-nav-underline-border-width: 0.125rem;
  --gen-nav-underline-link-active-color: var(--gen-emphasis-color);
  gap: var(--gen-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--gen-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--gen-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --gen-navbar-padding-x: 0;
  --gen-navbar-padding-y: 1rem;
  --gen-navbar-color: #11121c;
  --gen-navbar-hover-color: #6a4dff;
  --gen-navbar-disabled-color: rgba(17, 18, 28, 0.25);
  --gen-navbar-active-color: #6a4dff;
  --gen-navbar-brand-padding-y: 0.40625rem;
  --gen-navbar-brand-margin-end: 1rem;
  --gen-navbar-brand-font-size: 1.125rem;
  --gen-navbar-brand-color: #6a4dff;
  --gen-navbar-brand-hover-color: #6a4dff;
  --gen-navbar-nav-link-padding-x: 0.5rem;
  --gen-navbar-toggler-padding-y: 0.25rem;
  --gen-navbar-toggler-padding-x: 0.75rem;
  --gen-navbar-toggler-font-size: 1.125rem;
  --gen-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2817, 18, 28, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --gen-navbar-toggler-border-color: rgba(var(--gen-emphasis-color-rgb), 0.15);
  --gen-navbar-toggler-border-radius: var(--gen-border-radius);
  --gen-navbar-toggler-focus-width: 0.25rem;
  --gen-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--gen-navbar-padding-y) var(--gen-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--gen-navbar-brand-padding-y);
  padding-bottom: var(--gen-navbar-brand-padding-y);
  margin-right: var(--gen-navbar-brand-margin-end);
  font-size: var(--gen-navbar-brand-font-size);
  color: var(--gen-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--gen-navbar-brand-hover-color);
}

.navbar-nav {
  --gen-nav-link-padding-x: 0;
  --gen-nav-link-padding-y: 0.5rem;
  --gen-nav-link-font-weight: ;
  --gen-nav-link-color: var(--gen-navbar-color);
  --gen-nav-link-hover-color: var(--gen-navbar-hover-color);
  --gen-nav-link-disabled-color: var(--gen-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--gen-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--gen-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--gen-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--gen-navbar-toggler-padding-y) var(--gen-navbar-toggler-padding-x);
  font-size: var(--gen-navbar-toggler-font-size);
  line-height: 1;
  color: var(--gen-navbar-color);
  background-color: transparent;
  border: var(--gen-border-width) solid var(--gen-navbar-toggler-border-color);
  border-radius: var(--gen-navbar-toggler-border-radius);
  transition: var(--gen-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--gen-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--gen-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--gen-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--gen-navbar-nav-link-padding-x);
    padding-left: var(--gen-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--gen-navbar-nav-link-padding-x);
    padding-left: var(--gen-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--gen-navbar-nav-link-padding-x);
    padding-left: var(--gen-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--gen-navbar-nav-link-padding-x);
    padding-left: var(--gen-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--gen-navbar-nav-link-padding-x);
    padding-left: var(--gen-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--gen-navbar-nav-link-padding-x);
  padding-left: var(--gen-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --gen-navbar-color: #eae6ff;
  --gen-navbar-hover-color: #e6f85e;
  --gen-navbar-disabled-color: rgba(234, 230, 255, 0.25);
  --gen-navbar-active-color: #e6f85e;
  --gen-navbar-brand-color: #e6f85e;
  --gen-navbar-brand-hover-color: #e6f85e;
  --gen-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --gen-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23eae6ff' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --gen-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23eae6ff' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --gen-card-spacer-y: 1rem;
  --gen-card-spacer-x: 1rem;
  --gen-card-title-spacer-y: 0.5rem;
  --gen-card-title-color: ;
  --gen-card-subtitle-color: ;
  --gen-card-border-width: var(--gen-border-width);
  --gen-card-border-color: var(--gen-border-color-translucent);
  --gen-card-border-radius: var(--gen-border-radius);
  --gen-card-box-shadow: ;
  --gen-card-inner-border-radius: calc(var(--gen-border-radius) - (var(--gen-border-width)));
  --gen-card-cap-padding-y: 0.5rem;
  --gen-card-cap-padding-x: 1rem;
  --gen-card-cap-bg: rgba(var(--gen-body-color-rgb), 0.03);
  --gen-card-cap-color: ;
  --gen-card-height: ;
  --gen-card-color: ;
  --gen-card-bg: var(--gen-body-bg);
  --gen-card-img-overlay-padding: 1rem;
  --gen-card-group-margin: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--gen-card-height);
  color: var(--gen-body-color);
  word-wrap: break-word;
  background-color: var(--gen-card-bg);
  background-clip: border-box;
  border: var(--gen-card-border-width) solid var(--gen-card-border-color);
  border-radius: var(--gen-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--gen-card-inner-border-radius);
  border-top-right-radius: var(--gen-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--gen-card-inner-border-radius);
  border-bottom-left-radius: var(--gen-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--gen-card-spacer-y) var(--gen-card-spacer-x);
  color: var(--gen-card-color);
}

.card-title {
  margin-bottom: var(--gen-card-title-spacer-y);
  color: var(--gen-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--gen-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--gen-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--gen-card-spacer-x);
}

.card-header {
  padding: var(--gen-card-cap-padding-y) var(--gen-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--gen-card-cap-color);
  background-color: var(--gen-card-cap-bg);
  border-bottom: var(--gen-card-border-width) solid var(--gen-card-border-color);
}
.card-header:first-child {
  border-radius: var(--gen-card-inner-border-radius) var(--gen-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--gen-card-cap-padding-y) var(--gen-card-cap-padding-x);
  color: var(--gen-card-cap-color);
  background-color: var(--gen-card-cap-bg);
  border-top: var(--gen-card-border-width) solid var(--gen-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--gen-card-inner-border-radius) var(--gen-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--gen-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--gen-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--gen-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--gen-card-bg);
  border-bottom-color: var(--gen-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--gen-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--gen-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--gen-card-img-overlay-padding);
  border-radius: var(--gen-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--gen-card-inner-border-radius);
  border-top-right-radius: var(--gen-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--gen-card-inner-border-radius);
  border-bottom-left-radius: var(--gen-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--gen-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --gen-accordion-color: var(--gen-body-color);
  --gen-accordion-bg: var(--gen-body-bg);
  --gen-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --gen-accordion-border-color: var(--gen-border-color);
  --gen-accordion-border-width: var(--gen-border-width);
  --gen-accordion-border-radius: var(--gen-border-radius);
  --gen-accordion-inner-border-radius: calc(var(--gen-border-radius) - (var(--gen-border-width)));
  --gen-accordion-btn-padding-x: 1.25rem;
  --gen-accordion-btn-padding-y: 1rem;
  --gen-accordion-btn-color: var(--gen-body-color);
  --gen-accordion-btn-bg: transparent;
  --gen-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2311121c'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --gen-accordion-btn-icon-width: 1.25rem;
  --gen-accordion-btn-icon-transform: rotate(-180deg);
  --gen-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --gen-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232a1f66'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --gen-accordion-btn-focus-border-color: #6a4dff;
  --gen-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(106, 77, 255, 0.25);
  --gen-accordion-body-padding-x: 1.25rem;
  --gen-accordion-body-padding-y: 1rem;
  --gen-accordion-active-color: var(--gen-primary-text-emphasis);
  --gen-accordion-active-bg: transparent;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--gen-accordion-btn-padding-y) var(--gen-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--gen-accordion-btn-color);
  text-align: left;
  background-color: var(--gen-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--gen-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--gen-accordion-active-color);
  background-color: var(--gen-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--gen-accordion-border-width)) 0 var(--gen-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--gen-accordion-btn-active-icon);
  transform: var(--gen-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--gen-accordion-btn-icon-width);
  height: var(--gen-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--gen-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--gen-accordion-btn-icon-width);
  transition: var(--gen-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--gen-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--gen-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--gen-accordion-color);
  background-color: var(--gen-accordion-bg);
  border: var(--gen-accordion-border-width) solid var(--gen-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--gen-accordion-border-radius);
  border-top-right-radius: var(--gen-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--gen-accordion-inner-border-radius);
  border-top-right-radius: var(--gen-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--gen-accordion-border-radius);
  border-bottom-left-radius: var(--gen-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--gen-accordion-inner-border-radius);
  border-bottom-left-radius: var(--gen-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--gen-accordion-border-radius);
  border-bottom-left-radius: var(--gen-accordion-border-radius);
}

.accordion-body {
  padding: var(--gen-accordion-body-padding-y) var(--gen-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --gen-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23a694ff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --gen-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23a694ff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --gen-breadcrumb-padding-x: 0;
  --gen-breadcrumb-padding-y: 0;
  --gen-breadcrumb-margin-bottom: 1rem;
  --gen-breadcrumb-bg: ;
  --gen-breadcrumb-border-radius: ;
  --gen-breadcrumb-divider-color: var(--gen-secondary-color);
  --gen-breadcrumb-item-padding-x: 0.5rem;
  --gen-breadcrumb-item-active-color: var(--gen-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--gen-breadcrumb-padding-y) var(--gen-breadcrumb-padding-x);
  margin-bottom: var(--gen-breadcrumb-margin-bottom);
  font-size: var(--gen-breadcrumb-font-size);
  list-style: none;
  background-color: var(--gen-breadcrumb-bg);
  border-radius: var(--gen-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--gen-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--gen-breadcrumb-item-padding-x);
  color: var(--gen-breadcrumb-divider-color);
  content: var(--gen-breadcrumb-divider, "/") /* rtl: var(--gen-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--gen-breadcrumb-item-active-color);
}

.pagination {
  --gen-pagination-padding-x: 0.5rem;
  --gen-pagination-padding-y: 0.5rem;
  --gen-pagination-font-size: 1rem;
  --gen-pagination-color: var(--gen-body-color);
  --gen-pagination-bg: var(--gen-body-bg);
  --gen-pagination-border-width: var(--gen-border-width);
  --gen-pagination-border-color: var(--gen-border-color);
  --gen-pagination-border-radius: var(--gen-border-radius);
  --gen-pagination-hover-color: var(--gen-link-hover-color);
  --gen-pagination-hover-bg: var(--gen-tertiary-bg);
  --gen-pagination-hover-border-color: var(--gen-link-hover-color);
  --gen-pagination-focus-color: var(--gen-link-hover-color);
  --gen-pagination-focus-bg: var(--gen-secondary-bg);
  --gen-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(106, 77, 255, 0.25);
  --gen-pagination-active-color: #fff;
  --gen-pagination-active-bg: #6a4dff;
  --gen-pagination-active-border-color: #6a4dff;
  --gen-pagination-disabled-color: var(--gen-secondary-color);
  --gen-pagination-disabled-bg: var(--gen-secondary-bg);
  --gen-pagination-disabled-border-color: var(--gen-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--gen-pagination-padding-y) var(--gen-pagination-padding-x);
  font-size: var(--gen-pagination-font-size);
  color: var(--gen-pagination-color);
  text-decoration: none;
  background-color: var(--gen-pagination-bg);
  border: var(--gen-pagination-border-width) solid var(--gen-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--gen-pagination-hover-color);
  background-color: var(--gen-pagination-hover-bg);
  border-color: var(--gen-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--gen-pagination-focus-color);
  background-color: var(--gen-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--gen-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--gen-pagination-active-color);
  background-color: var(--gen-pagination-active-bg);
  border-color: var(--gen-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--gen-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--gen-pagination-disabled-bg);
  border-color: var(--gen-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--gen-border-width) * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--gen-pagination-border-radius);
  border-bottom-left-radius: var(--gen-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--gen-pagination-border-radius);
  border-bottom-right-radius: var(--gen-pagination-border-radius);
}

.pagination-lg {
  --gen-pagination-padding-x: 1.5rem;
  --gen-pagination-padding-y: 0.75rem;
  --gen-pagination-font-size: 1.125rem;
  --gen-pagination-border-radius: var(--gen-border-radius-lg);
}

.pagination-sm {
  --gen-pagination-padding-x: 0.5rem;
  --gen-pagination-padding-y: 0.25rem;
  --gen-pagination-font-size: 0.875rem;
  --gen-pagination-border-radius: var(--gen-border-radius-sm);
}

.badge {
  --gen-badge-padding-x: 0.1rem;
  --gen-badge-padding-y: 0.5rem;
  --gen-badge-font-size: 1rem;
  --gen-badge-font-weight: 500;
  --gen-badge-color: #fff;
  --gen-badge-border-radius: var(--gen-border-radius);
  display: inline-block;
  padding: var(--gen-badge-padding-y) var(--gen-badge-padding-x);
  font-size: var(--gen-badge-font-size);
  font-weight: var(--gen-badge-font-weight);
  line-height: 1;
  color: var(--gen-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--gen-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --gen-alert-bg: transparent;
  --gen-alert-padding-x: 1rem;
  --gen-alert-padding-y: 1rem;
  --gen-alert-margin-bottom: 1rem;
  --gen-alert-color: inherit;
  --gen-alert-border-color: transparent;
  --gen-alert-border: var(--gen-border-width) solid var(--gen-alert-border-color);
  --gen-alert-border-radius: var(--gen-border-radius);
  --gen-alert-link-color: inherit;
  position: relative;
  padding: var(--gen-alert-padding-y) var(--gen-alert-padding-x);
  margin-bottom: var(--gen-alert-margin-bottom);
  color: var(--gen-alert-color);
  background-color: var(--gen-alert-bg);
  border: var(--gen-alert-border);
  border-radius: var(--gen-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--gen-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --gen-alert-color: var(--gen-primary-text-emphasis);
  --gen-alert-bg: var(--gen-primary-bg-subtle);
  --gen-alert-border-color: var(--gen-primary-border-subtle);
  --gen-alert-link-color: var(--gen-primary-text-emphasis);
}

.alert-secondary {
  --gen-alert-color: var(--gen-secondary-text-emphasis);
  --gen-alert-bg: var(--gen-secondary-bg-subtle);
  --gen-alert-border-color: var(--gen-secondary-border-subtle);
  --gen-alert-link-color: var(--gen-secondary-text-emphasis);
}

.alert-success {
  --gen-alert-color: var(--gen-success-text-emphasis);
  --gen-alert-bg: var(--gen-success-bg-subtle);
  --gen-alert-border-color: var(--gen-success-border-subtle);
  --gen-alert-link-color: var(--gen-success-text-emphasis);
}

.alert-info {
  --gen-alert-color: var(--gen-info-text-emphasis);
  --gen-alert-bg: var(--gen-info-bg-subtle);
  --gen-alert-border-color: var(--gen-info-border-subtle);
  --gen-alert-link-color: var(--gen-info-text-emphasis);
}

.alert-warning {
  --gen-alert-color: var(--gen-warning-text-emphasis);
  --gen-alert-bg: var(--gen-warning-bg-subtle);
  --gen-alert-border-color: var(--gen-warning-border-subtle);
  --gen-alert-link-color: var(--gen-warning-text-emphasis);
}

.alert-danger {
  --gen-alert-color: var(--gen-danger-text-emphasis);
  --gen-alert-bg: var(--gen-danger-bg-subtle);
  --gen-alert-border-color: var(--gen-danger-border-subtle);
  --gen-alert-link-color: var(--gen-danger-text-emphasis);
}

.alert-light {
  --gen-alert-color: var(--gen-light-text-emphasis);
  --gen-alert-bg: var(--gen-light-bg-subtle);
  --gen-alert-border-color: var(--gen-light-border-subtle);
  --gen-alert-link-color: var(--gen-light-text-emphasis);
}

.alert-dark {
  --gen-alert-color: var(--gen-dark-text-emphasis);
  --gen-alert-bg: var(--gen-dark-bg-subtle);
  --gen-alert-border-color: var(--gen-dark-border-subtle);
  --gen-alert-link-color: var(--gen-dark-text-emphasis);
}

.alert-white {
  --gen-alert-color: var(--gen-white-text-emphasis);
  --gen-alert-bg: var(--gen-white-bg-subtle);
  --gen-alert-border-color: var(--gen-white-border-subtle);
  --gen-alert-link-color: var(--gen-white-text-emphasis);
}

.alert-black {
  --gen-alert-color: var(--gen-black-text-emphasis);
  --gen-alert-bg: var(--gen-black-bg-subtle);
  --gen-alert-border-color: var(--gen-black-border-subtle);
  --gen-alert-link-color: var(--gen-black-text-emphasis);
}

.alert-yellow {
  --gen-alert-color: var(--gen-yellow-text-emphasis);
  --gen-alert-bg: var(--gen-yellow-bg-subtle);
  --gen-alert-border-color: var(--gen-yellow-border-subtle);
  --gen-alert-link-color: var(--gen-yellow-text-emphasis);
}

.alert-blue {
  --gen-alert-color: var(--gen-blue-text-emphasis);
  --gen-alert-bg: var(--gen-blue-bg-subtle);
  --gen-alert-border-color: var(--gen-blue-border-subtle);
  --gen-alert-link-color: var(--gen-blue-text-emphasis);
}

.alert-light-blue {
  --gen-alert-color: var(--gen-light-blue-text-emphasis);
  --gen-alert-bg: var(--gen-light-blue-bg-subtle);
  --gen-alert-border-color: var(--gen-light-blue-border-subtle);
  --gen-alert-link-color: var(--gen-light-blue-text-emphasis);
}

.alert-light-blue-2 {
  --gen-alert-color: var(--gen-light-blue-2-text-emphasis);
  --gen-alert-bg: var(--gen-light-blue-2-bg-subtle);
  --gen-alert-border-color: var(--gen-light-blue-2-border-subtle);
  --gen-alert-link-color: var(--gen-light-blue-2-text-emphasis);
}

.alert-light-blue-3 {
  --gen-alert-color: var(--gen-light-blue-3-text-emphasis);
  --gen-alert-bg: var(--gen-light-blue-3-bg-subtle);
  --gen-alert-border-color: var(--gen-light-blue-3-border-subtle);
  --gen-alert-link-color: var(--gen-light-blue-3-text-emphasis);
}

.alert-light-blue-4 {
  --gen-alert-color: var(--gen-light-blue-4-text-emphasis);
  --gen-alert-bg: var(--gen-light-blue-4-bg-subtle);
  --gen-alert-border-color: var(--gen-light-blue-4-border-subtle);
  --gen-alert-link-color: var(--gen-light-blue-4-text-emphasis);
}

.alert-light-blue-5 {
  --gen-alert-color: var(--gen-light-blue-5-text-emphasis);
  --gen-alert-bg: var(--gen-light-blue-5-bg-subtle);
  --gen-alert-border-color: var(--gen-light-blue-5-border-subtle);
  --gen-alert-link-color: var(--gen-light-blue-5-text-emphasis);
}

.alert-light-blue-6 {
  --gen-alert-color: var(--gen-light-blue-6-text-emphasis);
  --gen-alert-bg: var(--gen-light-blue-6-bg-subtle);
  --gen-alert-border-color: var(--gen-light-blue-6-border-subtle);
  --gen-alert-link-color: var(--gen-light-blue-6-text-emphasis);
}

.alert-dark-blue {
  --gen-alert-color: var(--gen-dark-blue-text-emphasis);
  --gen-alert-bg: var(--gen-dark-blue-bg-subtle);
  --gen-alert-border-color: var(--gen-dark-blue-border-subtle);
  --gen-alert-link-color: var(--gen-dark-blue-text-emphasis);
}

.alert-dark-blue-2 {
  --gen-alert-color: var(--gen-dark-blue-2-text-emphasis);
  --gen-alert-bg: var(--gen-dark-blue-2-bg-subtle);
  --gen-alert-border-color: var(--gen-dark-blue-2-border-subtle);
  --gen-alert-link-color: var(--gen-dark-blue-2-text-emphasis);
}

.alert-dark-blue-3 {
  --gen-alert-color: var(--gen-dark-blue-3-text-emphasis);
  --gen-alert-bg: var(--gen-dark-blue-3-bg-subtle);
  --gen-alert-border-color: var(--gen-dark-blue-3-border-subtle);
  --gen-alert-link-color: var(--gen-dark-blue-3-text-emphasis);
}

.alert-dark-blue-4 {
  --gen-alert-color: var(--gen-dark-blue-4-text-emphasis);
  --gen-alert-bg: var(--gen-dark-blue-4-bg-subtle);
  --gen-alert-border-color: var(--gen-dark-blue-4-border-subtle);
  --gen-alert-link-color: var(--gen-dark-blue-4-text-emphasis);
}

.alert-dark-blue-5 {
  --gen-alert-color: var(--gen-dark-blue-5-text-emphasis);
  --gen-alert-bg: var(--gen-dark-blue-5-bg-subtle);
  --gen-alert-border-color: var(--gen-dark-blue-5-border-subtle);
  --gen-alert-link-color: var(--gen-dark-blue-5-text-emphasis);
}

.alert-primary-dark {
  --gen-alert-color: var(--gen-primary-dark-text-emphasis);
  --gen-alert-bg: var(--gen-primary-dark-bg-subtle);
  --gen-alert-border-color: var(--gen-primary-dark-border-subtle);
  --gen-alert-link-color: var(--gen-primary-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --gen-progress-height: 1rem;
  --gen-progress-font-size: 0.75rem;
  --gen-progress-bg: var(--gen-secondary-bg);
  --gen-progress-border-radius: var(--gen-border-radius);
  --gen-progress-box-shadow: var(--gen-box-shadow-inset);
  --gen-progress-bar-color: #fff;
  --gen-progress-bar-bg: #6a4dff;
  --gen-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--gen-progress-height);
  overflow: hidden;
  font-size: var(--gen-progress-font-size);
  background-color: var(--gen-progress-bg);
  border-radius: var(--gen-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--gen-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--gen-progress-bar-bg);
  transition: var(--gen-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--gen-progress-height) var(--gen-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --gen-list-group-color: var(--gen-body-color);
  --gen-list-group-bg: var(--gen-body-bg);
  --gen-list-group-border-color: var(--gen-border-color);
  --gen-list-group-border-width: var(--gen-border-width);
  --gen-list-group-border-radius: var(--gen-border-radius);
  --gen-list-group-item-padding-x: 1rem;
  --gen-list-group-item-padding-y: 0.5rem;
  --gen-list-group-action-color: var(--gen-secondary-color);
  --gen-list-group-action-hover-color: var(--gen-emphasis-color);
  --gen-list-group-action-hover-bg: var(--gen-tertiary-bg);
  --gen-list-group-action-active-color: var(--gen-body-color);
  --gen-list-group-action-active-bg: var(--gen-secondary-bg);
  --gen-list-group-disabled-color: var(--gen-secondary-color);
  --gen-list-group-disabled-bg: var(--gen-body-bg);
  --gen-list-group-active-color: #fff;
  --gen-list-group-active-bg: #6a4dff;
  --gen-list-group-active-border-color: #6a4dff;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--gen-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--gen-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--gen-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--gen-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--gen-list-group-action-active-color);
  background-color: var(--gen-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--gen-list-group-item-padding-y) var(--gen-list-group-item-padding-x);
  color: var(--gen-list-group-color);
  text-decoration: none;
  background-color: var(--gen-list-group-bg);
  border: var(--gen-list-group-border-width) solid var(--gen-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--gen-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--gen-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--gen-list-group-active-color);
  background-color: var(--gen-list-group-active-bg);
  border-color: var(--gen-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--gen-list-group-border-width));
  border-top-width: var(--gen-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--gen-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--gen-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--gen-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--gen-list-group-border-width));
  border-left-width: var(--gen-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--gen-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--gen-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--gen-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--gen-list-group-border-width));
    border-left-width: var(--gen-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--gen-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--gen-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--gen-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--gen-list-group-border-width));
    border-left-width: var(--gen-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--gen-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--gen-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--gen-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--gen-list-group-border-width));
    border-left-width: var(--gen-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--gen-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--gen-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--gen-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--gen-list-group-border-width));
    border-left-width: var(--gen-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--gen-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--gen-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--gen-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--gen-list-group-border-width));
    border-left-width: var(--gen-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--gen-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --gen-list-group-color: var(--gen-primary-text-emphasis);
  --gen-list-group-bg: var(--gen-primary-bg-subtle);
  --gen-list-group-border-color: var(--gen-primary-border-subtle);
  --gen-list-group-action-hover-color: var(--gen-emphasis-color);
  --gen-list-group-action-hover-bg: var(--gen-primary-border-subtle);
  --gen-list-group-action-active-color: var(--gen-emphasis-color);
  --gen-list-group-action-active-bg: var(--gen-primary-border-subtle);
  --gen-list-group-active-color: var(--gen-primary-bg-subtle);
  --gen-list-group-active-bg: var(--gen-primary-text-emphasis);
  --gen-list-group-active-border-color: var(--gen-primary-text-emphasis);
}

.list-group-item-secondary {
  --gen-list-group-color: var(--gen-secondary-text-emphasis);
  --gen-list-group-bg: var(--gen-secondary-bg-subtle);
  --gen-list-group-border-color: var(--gen-secondary-border-subtle);
  --gen-list-group-action-hover-color: var(--gen-emphasis-color);
  --gen-list-group-action-hover-bg: var(--gen-secondary-border-subtle);
  --gen-list-group-action-active-color: var(--gen-emphasis-color);
  --gen-list-group-action-active-bg: var(--gen-secondary-border-subtle);
  --gen-list-group-active-color: var(--gen-secondary-bg-subtle);
  --gen-list-group-active-bg: var(--gen-secondary-text-emphasis);
  --gen-list-group-active-border-color: var(--gen-secondary-text-emphasis);
}

.list-group-item-success {
  --gen-list-group-color: var(--gen-success-text-emphasis);
  --gen-list-group-bg: var(--gen-success-bg-subtle);
  --gen-list-group-border-color: var(--gen-success-border-subtle);
  --gen-list-group-action-hover-color: var(--gen-emphasis-color);
  --gen-list-group-action-hover-bg: var(--gen-success-border-subtle);
  --gen-list-group-action-active-color: var(--gen-emphasis-color);
  --gen-list-group-action-active-bg: var(--gen-success-border-subtle);
  --gen-list-group-active-color: var(--gen-success-bg-subtle);
  --gen-list-group-active-bg: var(--gen-success-text-emphasis);
  --gen-list-group-active-border-color: var(--gen-success-text-emphasis);
}

.list-group-item-info {
  --gen-list-group-color: var(--gen-info-text-emphasis);
  --gen-list-group-bg: var(--gen-info-bg-subtle);
  --gen-list-group-border-color: var(--gen-info-border-subtle);
  --gen-list-group-action-hover-color: var(--gen-emphasis-color);
  --gen-list-group-action-hover-bg: var(--gen-info-border-subtle);
  --gen-list-group-action-active-color: var(--gen-emphasis-color);
  --gen-list-group-action-active-bg: var(--gen-info-border-subtle);
  --gen-list-group-active-color: var(--gen-info-bg-subtle);
  --gen-list-group-active-bg: var(--gen-info-text-emphasis);
  --gen-list-group-active-border-color: var(--gen-info-text-emphasis);
}

.list-group-item-warning {
  --gen-list-group-color: var(--gen-warning-text-emphasis);
  --gen-list-group-bg: var(--gen-warning-bg-subtle);
  --gen-list-group-border-color: var(--gen-warning-border-subtle);
  --gen-list-group-action-hover-color: var(--gen-emphasis-color);
  --gen-list-group-action-hover-bg: var(--gen-warning-border-subtle);
  --gen-list-group-action-active-color: var(--gen-emphasis-color);
  --gen-list-group-action-active-bg: var(--gen-warning-border-subtle);
  --gen-list-group-active-color: var(--gen-warning-bg-subtle);
  --gen-list-group-active-bg: var(--gen-warning-text-emphasis);
  --gen-list-group-active-border-color: var(--gen-warning-text-emphasis);
}

.list-group-item-danger {
  --gen-list-group-color: var(--gen-danger-text-emphasis);
  --gen-list-group-bg: var(--gen-danger-bg-subtle);
  --gen-list-group-border-color: var(--gen-danger-border-subtle);
  --gen-list-group-action-hover-color: var(--gen-emphasis-color);
  --gen-list-group-action-hover-bg: var(--gen-danger-border-subtle);
  --gen-list-group-action-active-color: var(--gen-emphasis-color);
  --gen-list-group-action-active-bg: var(--gen-danger-border-subtle);
  --gen-list-group-active-color: var(--gen-danger-bg-subtle);
  --gen-list-group-active-bg: var(--gen-danger-text-emphasis);
  --gen-list-group-active-border-color: var(--gen-danger-text-emphasis);
}

.list-group-item-light {
  --gen-list-group-color: var(--gen-light-text-emphasis);
  --gen-list-group-bg: var(--gen-light-bg-subtle);
  --gen-list-group-border-color: var(--gen-light-border-subtle);
  --gen-list-group-action-hover-color: var(--gen-emphasis-color);
  --gen-list-group-action-hover-bg: var(--gen-light-border-subtle);
  --gen-list-group-action-active-color: var(--gen-emphasis-color);
  --gen-list-group-action-active-bg: var(--gen-light-border-subtle);
  --gen-list-group-active-color: var(--gen-light-bg-subtle);
  --gen-list-group-active-bg: var(--gen-light-text-emphasis);
  --gen-list-group-active-border-color: var(--gen-light-text-emphasis);
}

.list-group-item-dark {
  --gen-list-group-color: var(--gen-dark-text-emphasis);
  --gen-list-group-bg: var(--gen-dark-bg-subtle);
  --gen-list-group-border-color: var(--gen-dark-border-subtle);
  --gen-list-group-action-hover-color: var(--gen-emphasis-color);
  --gen-list-group-action-hover-bg: var(--gen-dark-border-subtle);
  --gen-list-group-action-active-color: var(--gen-emphasis-color);
  --gen-list-group-action-active-bg: var(--gen-dark-border-subtle);
  --gen-list-group-active-color: var(--gen-dark-bg-subtle);
  --gen-list-group-active-bg: var(--gen-dark-text-emphasis);
  --gen-list-group-active-border-color: var(--gen-dark-text-emphasis);
}

.list-group-item-white {
  --gen-list-group-color: var(--gen-white-text-emphasis);
  --gen-list-group-bg: var(--gen-white-bg-subtle);
  --gen-list-group-border-color: var(--gen-white-border-subtle);
  --gen-list-group-action-hover-color: var(--gen-emphasis-color);
  --gen-list-group-action-hover-bg: var(--gen-white-border-subtle);
  --gen-list-group-action-active-color: var(--gen-emphasis-color);
  --gen-list-group-action-active-bg: var(--gen-white-border-subtle);
  --gen-list-group-active-color: var(--gen-white-bg-subtle);
  --gen-list-group-active-bg: var(--gen-white-text-emphasis);
  --gen-list-group-active-border-color: var(--gen-white-text-emphasis);
}

.list-group-item-black {
  --gen-list-group-color: var(--gen-black-text-emphasis);
  --gen-list-group-bg: var(--gen-black-bg-subtle);
  --gen-list-group-border-color: var(--gen-black-border-subtle);
  --gen-list-group-action-hover-color: var(--gen-emphasis-color);
  --gen-list-group-action-hover-bg: var(--gen-black-border-subtle);
  --gen-list-group-action-active-color: var(--gen-emphasis-color);
  --gen-list-group-action-active-bg: var(--gen-black-border-subtle);
  --gen-list-group-active-color: var(--gen-black-bg-subtle);
  --gen-list-group-active-bg: var(--gen-black-text-emphasis);
  --gen-list-group-active-border-color: var(--gen-black-text-emphasis);
}

.list-group-item-yellow {
  --gen-list-group-color: var(--gen-yellow-text-emphasis);
  --gen-list-group-bg: var(--gen-yellow-bg-subtle);
  --gen-list-group-border-color: var(--gen-yellow-border-subtle);
  --gen-list-group-action-hover-color: var(--gen-emphasis-color);
  --gen-list-group-action-hover-bg: var(--gen-yellow-border-subtle);
  --gen-list-group-action-active-color: var(--gen-emphasis-color);
  --gen-list-group-action-active-bg: var(--gen-yellow-border-subtle);
  --gen-list-group-active-color: var(--gen-yellow-bg-subtle);
  --gen-list-group-active-bg: var(--gen-yellow-text-emphasis);
  --gen-list-group-active-border-color: var(--gen-yellow-text-emphasis);
}

.list-group-item-blue {
  --gen-list-group-color: var(--gen-blue-text-emphasis);
  --gen-list-group-bg: var(--gen-blue-bg-subtle);
  --gen-list-group-border-color: var(--gen-blue-border-subtle);
  --gen-list-group-action-hover-color: var(--gen-emphasis-color);
  --gen-list-group-action-hover-bg: var(--gen-blue-border-subtle);
  --gen-list-group-action-active-color: var(--gen-emphasis-color);
  --gen-list-group-action-active-bg: var(--gen-blue-border-subtle);
  --gen-list-group-active-color: var(--gen-blue-bg-subtle);
  --gen-list-group-active-bg: var(--gen-blue-text-emphasis);
  --gen-list-group-active-border-color: var(--gen-blue-text-emphasis);
}

.list-group-item-light-blue {
  --gen-list-group-color: var(--gen-light-blue-text-emphasis);
  --gen-list-group-bg: var(--gen-light-blue-bg-subtle);
  --gen-list-group-border-color: var(--gen-light-blue-border-subtle);
  --gen-list-group-action-hover-color: var(--gen-emphasis-color);
  --gen-list-group-action-hover-bg: var(--gen-light-blue-border-subtle);
  --gen-list-group-action-active-color: var(--gen-emphasis-color);
  --gen-list-group-action-active-bg: var(--gen-light-blue-border-subtle);
  --gen-list-group-active-color: var(--gen-light-blue-bg-subtle);
  --gen-list-group-active-bg: var(--gen-light-blue-text-emphasis);
  --gen-list-group-active-border-color: var(--gen-light-blue-text-emphasis);
}

.list-group-item-light-blue-2 {
  --gen-list-group-color: var(--gen-light-blue-2-text-emphasis);
  --gen-list-group-bg: var(--gen-light-blue-2-bg-subtle);
  --gen-list-group-border-color: var(--gen-light-blue-2-border-subtle);
  --gen-list-group-action-hover-color: var(--gen-emphasis-color);
  --gen-list-group-action-hover-bg: var(--gen-light-blue-2-border-subtle);
  --gen-list-group-action-active-color: var(--gen-emphasis-color);
  --gen-list-group-action-active-bg: var(--gen-light-blue-2-border-subtle);
  --gen-list-group-active-color: var(--gen-light-blue-2-bg-subtle);
  --gen-list-group-active-bg: var(--gen-light-blue-2-text-emphasis);
  --gen-list-group-active-border-color: var(--gen-light-blue-2-text-emphasis);
}

.list-group-item-light-blue-3 {
  --gen-list-group-color: var(--gen-light-blue-3-text-emphasis);
  --gen-list-group-bg: var(--gen-light-blue-3-bg-subtle);
  --gen-list-group-border-color: var(--gen-light-blue-3-border-subtle);
  --gen-list-group-action-hover-color: var(--gen-emphasis-color);
  --gen-list-group-action-hover-bg: var(--gen-light-blue-3-border-subtle);
  --gen-list-group-action-active-color: var(--gen-emphasis-color);
  --gen-list-group-action-active-bg: var(--gen-light-blue-3-border-subtle);
  --gen-list-group-active-color: var(--gen-light-blue-3-bg-subtle);
  --gen-list-group-active-bg: var(--gen-light-blue-3-text-emphasis);
  --gen-list-group-active-border-color: var(--gen-light-blue-3-text-emphasis);
}

.list-group-item-light-blue-4 {
  --gen-list-group-color: var(--gen-light-blue-4-text-emphasis);
  --gen-list-group-bg: var(--gen-light-blue-4-bg-subtle);
  --gen-list-group-border-color: var(--gen-light-blue-4-border-subtle);
  --gen-list-group-action-hover-color: var(--gen-emphasis-color);
  --gen-list-group-action-hover-bg: var(--gen-light-blue-4-border-subtle);
  --gen-list-group-action-active-color: var(--gen-emphasis-color);
  --gen-list-group-action-active-bg: var(--gen-light-blue-4-border-subtle);
  --gen-list-group-active-color: var(--gen-light-blue-4-bg-subtle);
  --gen-list-group-active-bg: var(--gen-light-blue-4-text-emphasis);
  --gen-list-group-active-border-color: var(--gen-light-blue-4-text-emphasis);
}

.list-group-item-light-blue-5 {
  --gen-list-group-color: var(--gen-light-blue-5-text-emphasis);
  --gen-list-group-bg: var(--gen-light-blue-5-bg-subtle);
  --gen-list-group-border-color: var(--gen-light-blue-5-border-subtle);
  --gen-list-group-action-hover-color: var(--gen-emphasis-color);
  --gen-list-group-action-hover-bg: var(--gen-light-blue-5-border-subtle);
  --gen-list-group-action-active-color: var(--gen-emphasis-color);
  --gen-list-group-action-active-bg: var(--gen-light-blue-5-border-subtle);
  --gen-list-group-active-color: var(--gen-light-blue-5-bg-subtle);
  --gen-list-group-active-bg: var(--gen-light-blue-5-text-emphasis);
  --gen-list-group-active-border-color: var(--gen-light-blue-5-text-emphasis);
}

.list-group-item-light-blue-6 {
  --gen-list-group-color: var(--gen-light-blue-6-text-emphasis);
  --gen-list-group-bg: var(--gen-light-blue-6-bg-subtle);
  --gen-list-group-border-color: var(--gen-light-blue-6-border-subtle);
  --gen-list-group-action-hover-color: var(--gen-emphasis-color);
  --gen-list-group-action-hover-bg: var(--gen-light-blue-6-border-subtle);
  --gen-list-group-action-active-color: var(--gen-emphasis-color);
  --gen-list-group-action-active-bg: var(--gen-light-blue-6-border-subtle);
  --gen-list-group-active-color: var(--gen-light-blue-6-bg-subtle);
  --gen-list-group-active-bg: var(--gen-light-blue-6-text-emphasis);
  --gen-list-group-active-border-color: var(--gen-light-blue-6-text-emphasis);
}

.list-group-item-dark-blue {
  --gen-list-group-color: var(--gen-dark-blue-text-emphasis);
  --gen-list-group-bg: var(--gen-dark-blue-bg-subtle);
  --gen-list-group-border-color: var(--gen-dark-blue-border-subtle);
  --gen-list-group-action-hover-color: var(--gen-emphasis-color);
  --gen-list-group-action-hover-bg: var(--gen-dark-blue-border-subtle);
  --gen-list-group-action-active-color: var(--gen-emphasis-color);
  --gen-list-group-action-active-bg: var(--gen-dark-blue-border-subtle);
  --gen-list-group-active-color: var(--gen-dark-blue-bg-subtle);
  --gen-list-group-active-bg: var(--gen-dark-blue-text-emphasis);
  --gen-list-group-active-border-color: var(--gen-dark-blue-text-emphasis);
}

.list-group-item-dark-blue-2 {
  --gen-list-group-color: var(--gen-dark-blue-2-text-emphasis);
  --gen-list-group-bg: var(--gen-dark-blue-2-bg-subtle);
  --gen-list-group-border-color: var(--gen-dark-blue-2-border-subtle);
  --gen-list-group-action-hover-color: var(--gen-emphasis-color);
  --gen-list-group-action-hover-bg: var(--gen-dark-blue-2-border-subtle);
  --gen-list-group-action-active-color: var(--gen-emphasis-color);
  --gen-list-group-action-active-bg: var(--gen-dark-blue-2-border-subtle);
  --gen-list-group-active-color: var(--gen-dark-blue-2-bg-subtle);
  --gen-list-group-active-bg: var(--gen-dark-blue-2-text-emphasis);
  --gen-list-group-active-border-color: var(--gen-dark-blue-2-text-emphasis);
}

.list-group-item-dark-blue-3 {
  --gen-list-group-color: var(--gen-dark-blue-3-text-emphasis);
  --gen-list-group-bg: var(--gen-dark-blue-3-bg-subtle);
  --gen-list-group-border-color: var(--gen-dark-blue-3-border-subtle);
  --gen-list-group-action-hover-color: var(--gen-emphasis-color);
  --gen-list-group-action-hover-bg: var(--gen-dark-blue-3-border-subtle);
  --gen-list-group-action-active-color: var(--gen-emphasis-color);
  --gen-list-group-action-active-bg: var(--gen-dark-blue-3-border-subtle);
  --gen-list-group-active-color: var(--gen-dark-blue-3-bg-subtle);
  --gen-list-group-active-bg: var(--gen-dark-blue-3-text-emphasis);
  --gen-list-group-active-border-color: var(--gen-dark-blue-3-text-emphasis);
}

.list-group-item-dark-blue-4 {
  --gen-list-group-color: var(--gen-dark-blue-4-text-emphasis);
  --gen-list-group-bg: var(--gen-dark-blue-4-bg-subtle);
  --gen-list-group-border-color: var(--gen-dark-blue-4-border-subtle);
  --gen-list-group-action-hover-color: var(--gen-emphasis-color);
  --gen-list-group-action-hover-bg: var(--gen-dark-blue-4-border-subtle);
  --gen-list-group-action-active-color: var(--gen-emphasis-color);
  --gen-list-group-action-active-bg: var(--gen-dark-blue-4-border-subtle);
  --gen-list-group-active-color: var(--gen-dark-blue-4-bg-subtle);
  --gen-list-group-active-bg: var(--gen-dark-blue-4-text-emphasis);
  --gen-list-group-active-border-color: var(--gen-dark-blue-4-text-emphasis);
}

.list-group-item-dark-blue-5 {
  --gen-list-group-color: var(--gen-dark-blue-5-text-emphasis);
  --gen-list-group-bg: var(--gen-dark-blue-5-bg-subtle);
  --gen-list-group-border-color: var(--gen-dark-blue-5-border-subtle);
  --gen-list-group-action-hover-color: var(--gen-emphasis-color);
  --gen-list-group-action-hover-bg: var(--gen-dark-blue-5-border-subtle);
  --gen-list-group-action-active-color: var(--gen-emphasis-color);
  --gen-list-group-action-active-bg: var(--gen-dark-blue-5-border-subtle);
  --gen-list-group-active-color: var(--gen-dark-blue-5-bg-subtle);
  --gen-list-group-active-bg: var(--gen-dark-blue-5-text-emphasis);
  --gen-list-group-active-border-color: var(--gen-dark-blue-5-text-emphasis);
}

.list-group-item-primary-dark {
  --gen-list-group-color: var(--gen-primary-dark-text-emphasis);
  --gen-list-group-bg: var(--gen-primary-dark-bg-subtle);
  --gen-list-group-border-color: var(--gen-primary-dark-border-subtle);
  --gen-list-group-action-hover-color: var(--gen-emphasis-color);
  --gen-list-group-action-hover-bg: var(--gen-primary-dark-border-subtle);
  --gen-list-group-action-active-color: var(--gen-emphasis-color);
  --gen-list-group-action-active-bg: var(--gen-primary-dark-border-subtle);
  --gen-list-group-active-color: var(--gen-primary-dark-bg-subtle);
  --gen-list-group-active-bg: var(--gen-primary-dark-text-emphasis);
  --gen-list-group-active-border-color: var(--gen-primary-dark-text-emphasis);
}

.btn-close {
  --gen-btn-close-color: #000;
  --gen-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --gen-btn-close-opacity: 0.5;
  --gen-btn-close-hover-opacity: 0.75;
  --gen-btn-close-focus-shadow: 0 0 0 0.25rem rgba(106, 77, 255, 0.25);
  --gen-btn-close-focus-opacity: 1;
  --gen-btn-close-disabled-opacity: 0.25;
  --gen-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--gen-btn-close-color);
  background: transparent var(--gen-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.5rem;
  opacity: var(--gen-btn-close-opacity);
}
.btn-close:hover {
  color: var(--gen-btn-close-color);
  text-decoration: none;
  opacity: var(--gen-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--gen-btn-close-focus-shadow);
  opacity: var(--gen-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--gen-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--gen-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--gen-btn-close-white-filter);
}

.toast {
  --gen-toast-zindex: 1090;
  --gen-toast-padding-x: 0.75rem;
  --gen-toast-padding-y: 0.5rem;
  --gen-toast-spacing: 3rem;
  --gen-toast-max-width: 350px;
  --gen-toast-font-size: 0.875rem;
  --gen-toast-color: ;
  --gen-toast-bg: rgba(var(--gen-body-bg-rgb), 0.85);
  --gen-toast-border-width: var(--gen-border-width);
  --gen-toast-border-color: var(--gen-border-color-translucent);
  --gen-toast-border-radius: var(--gen-border-radius);
  --gen-toast-box-shadow: var(--gen-box-shadow);
  --gen-toast-header-color: var(--gen-secondary-color);
  --gen-toast-header-bg: rgba(var(--gen-body-bg-rgb), 0.85);
  --gen-toast-header-border-color: var(--gen-border-color-translucent);
  width: var(--gen-toast-max-width);
  max-width: 100%;
  font-size: var(--gen-toast-font-size);
  color: var(--gen-toast-color);
  pointer-events: auto;
  background-color: var(--gen-toast-bg);
  background-clip: padding-box;
  border: var(--gen-toast-border-width) solid var(--gen-toast-border-color);
  box-shadow: var(--gen-toast-box-shadow);
  border-radius: var(--gen-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --gen-toast-zindex: 1090;
  position: absolute;
  z-index: var(--gen-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--gen-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--gen-toast-padding-y) var(--gen-toast-padding-x);
  color: var(--gen-toast-header-color);
  background-color: var(--gen-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--gen-toast-border-width) solid var(--gen-toast-header-border-color);
  border-top-left-radius: calc(var(--gen-toast-border-radius) - var(--gen-toast-border-width));
  border-top-right-radius: calc(var(--gen-toast-border-radius) - var(--gen-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--gen-toast-padding-x));
  margin-left: var(--gen-toast-padding-x);
}

.toast-body {
  padding: var(--gen-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --gen-modal-zindex: 1055;
  --gen-modal-width: 500px;
  --gen-modal-padding: 1rem;
  --gen-modal-margin: 0.5rem;
  --gen-modal-color: ;
  --gen-modal-bg: var(--gen-body-bg);
  --gen-modal-border-color: var(--gen-border-color-translucent);
  --gen-modal-border-width: var(--gen-border-width);
  --gen-modal-border-radius: var(--gen-border-radius-lg);
  --gen-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --gen-modal-inner-border-radius: calc(var(--gen-border-radius-lg) - (var(--gen-border-width)));
  --gen-modal-header-padding-x: 1rem;
  --gen-modal-header-padding-y: 1rem;
  --gen-modal-header-padding: 1rem 1rem;
  --gen-modal-header-border-color: var(--gen-border-color);
  --gen-modal-header-border-width: var(--gen-border-width);
  --gen-modal-title-line-height: 1.5;
  --gen-modal-footer-gap: 0.5rem;
  --gen-modal-footer-bg: ;
  --gen-modal-footer-border-color: var(--gen-border-color);
  --gen-modal-footer-border-width: var(--gen-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--gen-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--gen-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--gen-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--gen-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--gen-modal-color);
  pointer-events: auto;
  background-color: var(--gen-modal-bg);
  background-clip: padding-box;
  border: var(--gen-modal-border-width) solid var(--gen-modal-border-color);
  border-radius: var(--gen-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --gen-backdrop-zindex: 1050;
  --gen-backdrop-bg: #000;
  --gen-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--gen-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--gen-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--gen-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--gen-modal-header-padding);
  border-bottom: var(--gen-modal-header-border-width) solid var(--gen-modal-header-border-color);
  border-top-left-radius: var(--gen-modal-inner-border-radius);
  border-top-right-radius: var(--gen-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--gen-modal-header-padding-y) * 0.5) calc(var(--gen-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--gen-modal-header-padding-y)) calc(-0.5 * var(--gen-modal-header-padding-x)) calc(-0.5 * var(--gen-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--gen-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--gen-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--gen-modal-padding) - var(--gen-modal-footer-gap) * 0.5);
  background-color: var(--gen-modal-footer-bg);
  border-top: var(--gen-modal-footer-border-width) solid var(--gen-modal-footer-border-color);
  border-bottom-right-radius: var(--gen-modal-inner-border-radius);
  border-bottom-left-radius: var(--gen-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--gen-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --gen-modal-margin: 1.75rem;
    --gen-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .modal-dialog {
    max-width: var(--gen-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --gen-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --gen-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --gen-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --gen-tooltip-zindex: 1080;
  --gen-tooltip-max-width: 200px;
  --gen-tooltip-padding-x: 0.5rem;
  --gen-tooltip-padding-y: 0.25rem;
  --gen-tooltip-margin: ;
  --gen-tooltip-font-size: 0.875rem;
  --gen-tooltip-color: var(--gen-body-bg);
  --gen-tooltip-bg: var(--gen-emphasis-color);
  --gen-tooltip-border-radius: var(--gen-border-radius);
  --gen-tooltip-opacity: 0.9;
  --gen-tooltip-arrow-width: 0.8rem;
  --gen-tooltip-arrow-height: 0.4rem;
  z-index: var(--gen-tooltip-zindex);
  display: block;
  margin: var(--gen-tooltip-margin);
  font-family: var(--gen-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--gen-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--gen-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--gen-tooltip-arrow-width);
  height: var(--gen-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--gen-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--gen-tooltip-arrow-height) calc(var(--gen-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--gen-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--gen-tooltip-arrow-height));
  width: var(--gen-tooltip-arrow-height);
  height: var(--gen-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--gen-tooltip-arrow-width) * 0.5) var(--gen-tooltip-arrow-height) calc(var(--gen-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--gen-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--gen-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--gen-tooltip-arrow-width) * 0.5) var(--gen-tooltip-arrow-height);
  border-bottom-color: var(--gen-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--gen-tooltip-arrow-height));
  width: var(--gen-tooltip-arrow-height);
  height: var(--gen-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--gen-tooltip-arrow-width) * 0.5) 0 calc(var(--gen-tooltip-arrow-width) * 0.5) var(--gen-tooltip-arrow-height);
  border-left-color: var(--gen-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--gen-tooltip-max-width);
  padding: var(--gen-tooltip-padding-y) var(--gen-tooltip-padding-x);
  color: var(--gen-tooltip-color);
  text-align: center;
  background-color: var(--gen-tooltip-bg);
  border-radius: var(--gen-tooltip-border-radius);
}

.popover {
  --gen-popover-zindex: 1070;
  --gen-popover-max-width: 276px;
  --gen-popover-font-size: 0.875rem;
  --gen-popover-bg: var(--gen-body-bg);
  --gen-popover-border-width: var(--gen-border-width);
  --gen-popover-border-color: var(--gen-border-color-translucent);
  --gen-popover-border-radius: var(--gen-border-radius-lg);
  --gen-popover-inner-border-radius: calc(var(--gen-border-radius-lg) - var(--gen-border-width));
  --gen-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --gen-popover-header-padding-x: 1rem;
  --gen-popover-header-padding-y: 0.5rem;
  --gen-popover-header-font-size: 1rem;
  --gen-popover-header-color: ;
  --gen-popover-header-bg: var(--gen-secondary-bg);
  --gen-popover-body-padding-x: 1rem;
  --gen-popover-body-padding-y: 1rem;
  --gen-popover-body-color: var(--gen-body-color);
  --gen-popover-arrow-width: 1rem;
  --gen-popover-arrow-height: 0.5rem;
  --gen-popover-arrow-border: var(--gen-popover-border-color);
  z-index: var(--gen-popover-zindex);
  display: block;
  max-width: var(--gen-popover-max-width);
  font-family: var(--gen-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--gen-popover-font-size);
  word-wrap: break-word;
  background-color: var(--gen-popover-bg);
  background-clip: padding-box;
  border: var(--gen-popover-border-width) solid var(--gen-popover-border-color);
  border-radius: var(--gen-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--gen-popover-arrow-width);
  height: var(--gen-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--gen-popover-arrow-height)) - var(--gen-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--gen-popover-arrow-height) calc(var(--gen-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--gen-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--gen-popover-border-width);
  border-top-color: var(--gen-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--gen-popover-arrow-height)) - var(--gen-popover-border-width));
  width: var(--gen-popover-arrow-height);
  height: var(--gen-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--gen-popover-arrow-width) * 0.5) var(--gen-popover-arrow-height) calc(var(--gen-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--gen-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--gen-popover-border-width);
  border-right-color: var(--gen-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--gen-popover-arrow-height)) - var(--gen-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--gen-popover-arrow-width) * 0.5) var(--gen-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--gen-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--gen-popover-border-width);
  border-bottom-color: var(--gen-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--gen-popover-arrow-width);
  margin-left: calc(-0.5 * var(--gen-popover-arrow-width));
  content: "";
  border-bottom: var(--gen-popover-border-width) solid var(--gen-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--gen-popover-arrow-height)) - var(--gen-popover-border-width));
  width: var(--gen-popover-arrow-height);
  height: var(--gen-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--gen-popover-arrow-width) * 0.5) 0 calc(var(--gen-popover-arrow-width) * 0.5) var(--gen-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--gen-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--gen-popover-border-width);
  border-left-color: var(--gen-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--gen-popover-header-padding-y) var(--gen-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--gen-popover-header-font-size);
  color: var(--gen-popover-header-color);
  background-color: var(--gen-popover-header-bg);
  border-bottom: var(--gen-popover-border-width) solid var(--gen-popover-border-color);
  border-top-left-radius: var(--gen-popover-inner-border-radius);
  border-top-right-radius: var(--gen-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--gen-popover-body-padding-y) var(--gen-popover-body-padding-x);
  color: var(--gen-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--gen-spinner-width);
  height: var(--gen-spinner-height);
  vertical-align: var(--gen-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--gen-spinner-animation-speed) linear infinite var(--gen-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --gen-spinner-width: 2rem;
  --gen-spinner-height: 2rem;
  --gen-spinner-vertical-align: -0.125em;
  --gen-spinner-border-width: 0.25em;
  --gen-spinner-animation-speed: 0.75s;
  --gen-spinner-animation-name: spinner-border;
  border: var(--gen-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --gen-spinner-width: 1rem;
  --gen-spinner-height: 1rem;
  --gen-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --gen-spinner-width: 2rem;
  --gen-spinner-height: 2rem;
  --gen-spinner-vertical-align: -0.125em;
  --gen-spinner-animation-speed: 0.75s;
  --gen-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --gen-spinner-width: 1rem;
  --gen-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --gen-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --gen-offcanvas-zindex: 1045;
  --gen-offcanvas-width: 400px;
  --gen-offcanvas-height: 30vh;
  --gen-offcanvas-padding-x: 1rem;
  --gen-offcanvas-padding-y: 1rem;
  --gen-offcanvas-color: var(--gen-body-color);
  --gen-offcanvas-bg: var(--gen-body-bg);
  --gen-offcanvas-border-width: var(--gen-border-width);
  --gen-offcanvas-border-color: var(--gen-border-color-translucent);
  --gen-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --gen-offcanvas-transition: transform 0.3s ease-in-out;
  --gen-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--gen-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--gen-offcanvas-color);
    visibility: hidden;
    background-color: var(--gen-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--gen-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--gen-offcanvas-width);
    border-right: var(--gen-offcanvas-border-width) solid var(--gen-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--gen-offcanvas-width);
    border-left: var(--gen-offcanvas-border-width) solid var(--gen-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--gen-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--gen-offcanvas-border-width) solid var(--gen-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--gen-offcanvas-height);
    max-height: 100%;
    border-top: var(--gen-offcanvas-border-width) solid var(--gen-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --gen-offcanvas-height: auto;
    --gen-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--gen-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--gen-offcanvas-color);
    visibility: hidden;
    background-color: var(--gen-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--gen-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--gen-offcanvas-width);
    border-right: var(--gen-offcanvas-border-width) solid var(--gen-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--gen-offcanvas-width);
    border-left: var(--gen-offcanvas-border-width) solid var(--gen-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--gen-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--gen-offcanvas-border-width) solid var(--gen-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--gen-offcanvas-height);
    max-height: 100%;
    border-top: var(--gen-offcanvas-border-width) solid var(--gen-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --gen-offcanvas-height: auto;
    --gen-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--gen-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--gen-offcanvas-color);
    visibility: hidden;
    background-color: var(--gen-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--gen-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--gen-offcanvas-width);
    border-right: var(--gen-offcanvas-border-width) solid var(--gen-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--gen-offcanvas-width);
    border-left: var(--gen-offcanvas-border-width) solid var(--gen-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--gen-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--gen-offcanvas-border-width) solid var(--gen-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--gen-offcanvas-height);
    max-height: 100%;
    border-top: var(--gen-offcanvas-border-width) solid var(--gen-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --gen-offcanvas-height: auto;
    --gen-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--gen-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--gen-offcanvas-color);
    visibility: hidden;
    background-color: var(--gen-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--gen-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--gen-offcanvas-width);
    border-right: var(--gen-offcanvas-border-width) solid var(--gen-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--gen-offcanvas-width);
    border-left: var(--gen-offcanvas-border-width) solid var(--gen-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--gen-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--gen-offcanvas-border-width) solid var(--gen-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--gen-offcanvas-height);
    max-height: 100%;
    border-top: var(--gen-offcanvas-border-width) solid var(--gen-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --gen-offcanvas-height: auto;
    --gen-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--gen-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--gen-offcanvas-color);
    visibility: hidden;
    background-color: var(--gen-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--gen-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--gen-offcanvas-width);
    border-right: var(--gen-offcanvas-border-width) solid var(--gen-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--gen-offcanvas-width);
    border-left: var(--gen-offcanvas-border-width) solid var(--gen-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--gen-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--gen-offcanvas-border-width) solid var(--gen-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--gen-offcanvas-height);
    max-height: 100%;
    border-top: var(--gen-offcanvas-border-width) solid var(--gen-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --gen-offcanvas-height: auto;
    --gen-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--gen-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--gen-offcanvas-color);
  visibility: hidden;
  background-color: var(--gen-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--gen-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--gen-offcanvas-width);
  border-right: var(--gen-offcanvas-border-width) solid var(--gen-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--gen-offcanvas-width);
  border-left: var(--gen-offcanvas-border-width) solid var(--gen-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--gen-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--gen-offcanvas-border-width) solid var(--gen-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--gen-offcanvas-height);
  max-height: 100%;
  border-top: var(--gen-offcanvas-border-width) solid var(--gen-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--gen-offcanvas-padding-y) var(--gen-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--gen-offcanvas-padding-y) * 0.5) calc(var(--gen-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--gen-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--gen-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--gen-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--gen-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--gen-offcanvas-padding-y) var(--gen-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(106, 77, 255, var(--gen-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--gen-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--gen-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--gen-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--gen-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--gen-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(189, 191, 212, var(--gen-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(17, 18, 28, var(--gen-bg-opacity, 1)) !important;
}

.text-bg-white {
  color: #000 !important;
  background-color: RGBA(255, 255, 255, var(--gen-bg-opacity, 1)) !important;
}

.text-bg-black {
  color: #fff !important;
  background-color: RGBA(0, 0, 0, var(--gen-bg-opacity, 1)) !important;
}

.text-bg-yellow {
  color: #000 !important;
  background-color: RGBA(230, 248, 94, var(--gen-bg-opacity, 1)) !important;
}

.text-bg-blue {
  color: #fff !important;
  background-color: RGBA(106, 77, 255, var(--gen-bg-opacity, 1)) !important;
}

.text-bg-light-blue {
  color: #000 !important;
  background-color: RGBA(243, 248, 255, var(--gen-bg-opacity, 1)) !important;
}

.text-bg-light-blue-2 {
  color: #000 !important;
  background-color: RGBA(234, 230, 255, var(--gen-bg-opacity, 1)) !important;
}

.text-bg-light-blue-3 {
  color: #000 !important;
  background-color: RGBA(189, 191, 212, var(--gen-bg-opacity, 1)) !important;
}

.text-bg-light-blue-4 {
  color: #000 !important;
  background-color: RGBA(195, 209, 227, var(--gen-bg-opacity, 1)) !important;
}

.text-bg-light-blue-5 {
  color: #000 !important;
  background-color: RGBA(183, 169, 255, var(--gen-bg-opacity, 1)) !important;
}

.text-bg-light-blue-6 {
  color: #000 !important;
  background-color: RGBA(116, 118, 148, var(--gen-bg-opacity, 1)) !important;
}

.text-bg-dark-blue {
  color: #fff !important;
  background-color: RGBA(46, 47, 69, var(--gen-bg-opacity, 1)) !important;
}

.text-bg-dark-blue-2 {
  color: #fff !important;
  background-color: RGBA(38, 40, 56, var(--gen-bg-opacity, 1)) !important;
}

.text-bg-dark-blue-3 {
  color: #fff !important;
  background-color: RGBA(25, 26, 40, var(--gen-bg-opacity, 1)) !important;
}

.text-bg-dark-blue-4 {
  color: #fff !important;
  background-color: RGBA(23, 24, 37, var(--gen-bg-opacity, 1)) !important;
}

.text-bg-dark-blue-5 {
  color: #fff !important;
  background-color: RGBA(17, 18, 28, var(--gen-bg-opacity, 1)) !important;
}

.text-bg-primary-dark {
  color: #000 !important;
  background-color: RGBA(230, 248, 94, var(--gen-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--gen-primary-rgb, var(--gen-link-opacity, 1)));
  text-decoration-color: RGBA(var(--gen-primary-rgb), var(--gen-link-underline-opacity, 1));
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(85, 62, 204, var(--gen-link-opacity, 1));
  text-decoration-color: RGBA(85, 62, 204, var(--gen-link-underline-opacity, 1));
}

.link-secondary {
  color: RGBA(var(--gen-secondary-rgb, var(--gen-link-opacity, 1)));
  text-decoration-color: RGBA(var(--gen-secondary-rgb), var(--gen-link-underline-opacity, 1));
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(86, 94, 100, var(--gen-link-opacity, 1));
  text-decoration-color: RGBA(86, 94, 100, var(--gen-link-underline-opacity, 1));
}

.link-success {
  color: RGBA(var(--gen-success-rgb, var(--gen-link-opacity, 1)));
  text-decoration-color: RGBA(var(--gen-success-rgb), var(--gen-link-underline-opacity, 1));
}
.link-success:hover, .link-success:focus {
  color: RGBA(20, 108, 67, var(--gen-link-opacity, 1));
  text-decoration-color: RGBA(20, 108, 67, var(--gen-link-underline-opacity, 1));
}

.link-info {
  color: RGBA(var(--gen-info-rgb, var(--gen-link-opacity, 1)));
  text-decoration-color: RGBA(var(--gen-info-rgb), var(--gen-link-underline-opacity, 1));
}
.link-info:hover, .link-info:focus {
  color: RGBA(61, 213, 243, var(--gen-link-opacity, 1));
  text-decoration-color: RGBA(61, 213, 243, var(--gen-link-underline-opacity, 1));
}

.link-warning {
  color: RGBA(var(--gen-warning-rgb, var(--gen-link-opacity, 1)));
  text-decoration-color: RGBA(var(--gen-warning-rgb), var(--gen-link-underline-opacity, 1));
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 205, 57, var(--gen-link-opacity, 1));
  text-decoration-color: RGBA(255, 205, 57, var(--gen-link-underline-opacity, 1));
}

.link-danger {
  color: RGBA(var(--gen-danger-rgb, var(--gen-link-opacity, 1)));
  text-decoration-color: RGBA(var(--gen-danger-rgb), var(--gen-link-underline-opacity, 1));
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(176, 42, 55, var(--gen-link-opacity, 1));
  text-decoration-color: RGBA(176, 42, 55, var(--gen-link-underline-opacity, 1));
}

.link-light {
  color: RGBA(var(--gen-light-rgb, var(--gen-link-opacity, 1)));
  text-decoration-color: RGBA(var(--gen-light-rgb), var(--gen-link-underline-opacity, 1));
}
.link-light:hover, .link-light:focus {
  color: RGBA(202, 204, 221, var(--gen-link-opacity, 1));
  text-decoration-color: RGBA(202, 204, 221, var(--gen-link-underline-opacity, 1));
}

.link-dark {
  color: RGBA(var(--gen-dark-rgb, var(--gen-link-opacity, 1)));
  text-decoration-color: RGBA(var(--gen-dark-rgb), var(--gen-link-underline-opacity, 1));
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(14, 14, 22, var(--gen-link-opacity, 1));
  text-decoration-color: RGBA(14, 14, 22, var(--gen-link-underline-opacity, 1));
}

.link-white {
  color: RGBA(var(--gen-white-rgb, var(--gen-link-opacity, 1)));
  text-decoration-color: RGBA(var(--gen-white-rgb), var(--gen-link-underline-opacity, 1));
}
.link-white:hover, .link-white:focus {
  color: RGBA(255, 255, 255, var(--gen-link-opacity, 1));
  text-decoration-color: RGBA(255, 255, 255, var(--gen-link-underline-opacity, 1));
}

.link-black {
  color: RGBA(var(--gen-black-rgb, var(--gen-link-opacity, 1)));
  text-decoration-color: RGBA(var(--gen-black-rgb), var(--gen-link-underline-opacity, 1));
}
.link-black:hover, .link-black:focus {
  color: RGBA(0, 0, 0, var(--gen-link-opacity, 1));
  text-decoration-color: RGBA(0, 0, 0, var(--gen-link-underline-opacity, 1));
}

.link-yellow {
  color: RGBA(var(--gen-yellow-rgb, var(--gen-link-opacity, 1)));
  text-decoration-color: RGBA(var(--gen-yellow-rgb), var(--gen-link-underline-opacity, 1));
}
.link-yellow:hover, .link-yellow:focus {
  color: RGBA(235, 249, 126, var(--gen-link-opacity, 1));
  text-decoration-color: RGBA(235, 249, 126, var(--gen-link-underline-opacity, 1));
}

.link-blue {
  color: RGBA(var(--gen-blue-rgb, var(--gen-link-opacity, 1)));
  text-decoration-color: RGBA(var(--gen-blue-rgb), var(--gen-link-underline-opacity, 1));
}
.link-blue:hover, .link-blue:focus {
  color: RGBA(85, 62, 204, var(--gen-link-opacity, 1));
  text-decoration-color: RGBA(85, 62, 204, var(--gen-link-underline-opacity, 1));
}

.link-light-blue {
  color: RGBA(var(--gen-light-blue-rgb, var(--gen-link-opacity, 1)));
  text-decoration-color: RGBA(var(--gen-light-blue-rgb), var(--gen-link-underline-opacity, 1));
}
.link-light-blue:hover, .link-light-blue:focus {
  color: RGBA(245, 249, 255, var(--gen-link-opacity, 1));
  text-decoration-color: RGBA(245, 249, 255, var(--gen-link-underline-opacity, 1));
}

.link-light-blue-2 {
  color: RGBA(var(--gen-light-blue-2-rgb, var(--gen-link-opacity, 1)));
  text-decoration-color: RGBA(var(--gen-light-blue-2-rgb), var(--gen-link-underline-opacity, 1));
}
.link-light-blue-2:hover, .link-light-blue-2:focus {
  color: RGBA(238, 235, 255, var(--gen-link-opacity, 1));
  text-decoration-color: RGBA(238, 235, 255, var(--gen-link-underline-opacity, 1));
}

.link-light-blue-3 {
  color: RGBA(var(--gen-light-blue-3-rgb, var(--gen-link-opacity, 1)));
  text-decoration-color: RGBA(var(--gen-light-blue-3-rgb), var(--gen-link-underline-opacity, 1));
}
.link-light-blue-3:hover, .link-light-blue-3:focus {
  color: RGBA(202, 204, 221, var(--gen-link-opacity, 1));
  text-decoration-color: RGBA(202, 204, 221, var(--gen-link-underline-opacity, 1));
}

.link-light-blue-4 {
  color: RGBA(var(--gen-light-blue-4-rgb, var(--gen-link-opacity, 1)));
  text-decoration-color: RGBA(var(--gen-light-blue-4-rgb), var(--gen-link-underline-opacity, 1));
}
.link-light-blue-4:hover, .link-light-blue-4:focus {
  color: RGBA(207, 218, 233, var(--gen-link-opacity, 1));
  text-decoration-color: RGBA(207, 218, 233, var(--gen-link-underline-opacity, 1));
}

.link-light-blue-5 {
  color: RGBA(var(--gen-light-blue-5-rgb, var(--gen-link-opacity, 1)));
  text-decoration-color: RGBA(var(--gen-light-blue-5-rgb), var(--gen-link-underline-opacity, 1));
}
.link-light-blue-5:hover, .link-light-blue-5:focus {
  color: RGBA(197, 186, 255, var(--gen-link-opacity, 1));
  text-decoration-color: RGBA(197, 186, 255, var(--gen-link-underline-opacity, 1));
}

.link-light-blue-6 {
  color: RGBA(var(--gen-light-blue-6-rgb, var(--gen-link-opacity, 1)));
  text-decoration-color: RGBA(var(--gen-light-blue-6-rgb), var(--gen-link-underline-opacity, 1));
}
.link-light-blue-6:hover, .link-light-blue-6:focus {
  color: RGBA(144, 145, 169, var(--gen-link-opacity, 1));
  text-decoration-color: RGBA(144, 145, 169, var(--gen-link-underline-opacity, 1));
}

.link-dark-blue {
  color: RGBA(var(--gen-dark-blue-rgb, var(--gen-link-opacity, 1)));
  text-decoration-color: RGBA(var(--gen-dark-blue-rgb), var(--gen-link-underline-opacity, 1));
}
.link-dark-blue:hover, .link-dark-blue:focus {
  color: RGBA(37, 38, 55, var(--gen-link-opacity, 1));
  text-decoration-color: RGBA(37, 38, 55, var(--gen-link-underline-opacity, 1));
}

.link-dark-blue-2 {
  color: RGBA(var(--gen-dark-blue-2-rgb, var(--gen-link-opacity, 1)));
  text-decoration-color: RGBA(var(--gen-dark-blue-2-rgb), var(--gen-link-underline-opacity, 1));
}
.link-dark-blue-2:hover, .link-dark-blue-2:focus {
  color: RGBA(30, 32, 45, var(--gen-link-opacity, 1));
  text-decoration-color: RGBA(30, 32, 45, var(--gen-link-underline-opacity, 1));
}

.link-dark-blue-3 {
  color: RGBA(var(--gen-dark-blue-3-rgb, var(--gen-link-opacity, 1)));
  text-decoration-color: RGBA(var(--gen-dark-blue-3-rgb), var(--gen-link-underline-opacity, 1));
}
.link-dark-blue-3:hover, .link-dark-blue-3:focus {
  color: RGBA(20, 21, 32, var(--gen-link-opacity, 1));
  text-decoration-color: RGBA(20, 21, 32, var(--gen-link-underline-opacity, 1));
}

.link-dark-blue-4 {
  color: RGBA(var(--gen-dark-blue-4-rgb, var(--gen-link-opacity, 1)));
  text-decoration-color: RGBA(var(--gen-dark-blue-4-rgb), var(--gen-link-underline-opacity, 1));
}
.link-dark-blue-4:hover, .link-dark-blue-4:focus {
  color: RGBA(18, 19, 30, var(--gen-link-opacity, 1));
  text-decoration-color: RGBA(18, 19, 30, var(--gen-link-underline-opacity, 1));
}

.link-dark-blue-5 {
  color: RGBA(var(--gen-dark-blue-5-rgb, var(--gen-link-opacity, 1)));
  text-decoration-color: RGBA(var(--gen-dark-blue-5-rgb), var(--gen-link-underline-opacity, 1));
}
.link-dark-blue-5:hover, .link-dark-blue-5:focus {
  color: RGBA(14, 14, 22, var(--gen-link-opacity, 1));
  text-decoration-color: RGBA(14, 14, 22, var(--gen-link-underline-opacity, 1));
}

.link-primary-dark {
  color: RGBA(var(--gen-primary-dark-rgb, var(--gen-link-opacity, 1)));
  text-decoration-color: RGBA(var(--gen-primary-dark-rgb), var(--gen-link-underline-opacity, 1));
}
.link-primary-dark:hover, .link-primary-dark:focus {
  color: RGBA(235, 249, 126, var(--gen-link-opacity, 1));
  text-decoration-color: RGBA(235, 249, 126, var(--gen-link-underline-opacity, 1));
}

.link-body-emphasis {
  color: RGBA(var(--gen-emphasis-color-rgb), var(--gen-link-opacity, 1));
  text-decoration-color: RGBA(var(--gen-emphasis-color-rgb), var(--gen-link-underline-opacity, 1));
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--gen-emphasis-color-rgb), var(--gen-link-opacity, 0.75));
  text-decoration-color: RGBA(var(--gen-emphasis-color-rgb), var(--gen-link-underline-opacity, 0.75));
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--gen-focus-ring-x, 0) var(--gen-focus-ring-y, 0) var(--gen-focus-ring-blur, 0) var(--gen-focus-ring-width) var(--gen-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--gen-link-color-rgb), var(--gen-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--gen-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--gen-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --gen-aspect-ratio: 100%;
}

.ratio-4x3 {
  --gen-aspect-ratio: 75%;
}

.ratio-6x4 {
  --gen-aspect-ratio: 66.6666666667%;
}

.ratio-16x9 {
  --gen-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --gen-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-contain {
  object-fit: contain !important;
}

.object-cover {
  object-fit: cover !important;
}

.object-fill {
  object-fit: fill !important;
}

.object-scale {
  object-fit: scale-down !important;
}

.object-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --gen-focus-ring-color: rgba(var(--gen-primary-rgb), var(--gen-focus-ring-opacity));
}

.focus-ring-secondary {
  --gen-focus-ring-color: rgba(var(--gen-secondary-rgb), var(--gen-focus-ring-opacity));
}

.focus-ring-success {
  --gen-focus-ring-color: rgba(var(--gen-success-rgb), var(--gen-focus-ring-opacity));
}

.focus-ring-info {
  --gen-focus-ring-color: rgba(var(--gen-info-rgb), var(--gen-focus-ring-opacity));
}

.focus-ring-warning {
  --gen-focus-ring-color: rgba(var(--gen-warning-rgb), var(--gen-focus-ring-opacity));
}

.focus-ring-danger {
  --gen-focus-ring-color: rgba(var(--gen-danger-rgb), var(--gen-focus-ring-opacity));
}

.focus-ring-light {
  --gen-focus-ring-color: rgba(var(--gen-light-rgb), var(--gen-focus-ring-opacity));
}

.focus-ring-dark {
  --gen-focus-ring-color: rgba(var(--gen-dark-rgb), var(--gen-focus-ring-opacity));
}

.focus-ring-white {
  --gen-focus-ring-color: rgba(var(--gen-white-rgb), var(--gen-focus-ring-opacity));
}

.focus-ring-black {
  --gen-focus-ring-color: rgba(var(--gen-black-rgb), var(--gen-focus-ring-opacity));
}

.focus-ring-yellow {
  --gen-focus-ring-color: rgba(var(--gen-yellow-rgb), var(--gen-focus-ring-opacity));
}

.focus-ring-blue {
  --gen-focus-ring-color: rgba(var(--gen-blue-rgb), var(--gen-focus-ring-opacity));
}

.focus-ring-light-blue {
  --gen-focus-ring-color: rgba(var(--gen-light-blue-rgb), var(--gen-focus-ring-opacity));
}

.focus-ring-light-blue-2 {
  --gen-focus-ring-color: rgba(var(--gen-light-blue-2-rgb), var(--gen-focus-ring-opacity));
}

.focus-ring-light-blue-3 {
  --gen-focus-ring-color: rgba(var(--gen-light-blue-3-rgb), var(--gen-focus-ring-opacity));
}

.focus-ring-light-blue-4 {
  --gen-focus-ring-color: rgba(var(--gen-light-blue-4-rgb), var(--gen-focus-ring-opacity));
}

.focus-ring-light-blue-5 {
  --gen-focus-ring-color: rgba(var(--gen-light-blue-5-rgb), var(--gen-focus-ring-opacity));
}

.focus-ring-light-blue-6 {
  --gen-focus-ring-color: rgba(var(--gen-light-blue-6-rgb), var(--gen-focus-ring-opacity));
}

.focus-ring-dark-blue {
  --gen-focus-ring-color: rgba(var(--gen-dark-blue-rgb), var(--gen-focus-ring-opacity));
}

.focus-ring-dark-blue-2 {
  --gen-focus-ring-color: rgba(var(--gen-dark-blue-2-rgb), var(--gen-focus-ring-opacity));
}

.focus-ring-dark-blue-3 {
  --gen-focus-ring-color: rgba(var(--gen-dark-blue-3-rgb), var(--gen-focus-ring-opacity));
}

.focus-ring-dark-blue-4 {
  --gen-focus-ring-color: rgba(var(--gen-dark-blue-4-rgb), var(--gen-focus-ring-opacity));
}

.focus-ring-dark-blue-5 {
  --gen-focus-ring-color: rgba(var(--gen-dark-blue-5-rgb), var(--gen-focus-ring-opacity));
}

.focus-ring-primary-dark {
  --gen-focus-ring-color: rgba(var(--gen-primary-dark-rgb), var(--gen-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--gen-border-width) var(--gen-border-style) var(--gen-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--gen-border-width) var(--gen-border-style) var(--gen-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--gen-border-width) var(--gen-border-style) var(--gen-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--gen-border-width) var(--gen-border-style) var(--gen-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--gen-border-width) var(--gen-border-style) var(--gen-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --gen-border-opacity: 1;
  border-color: rgba(var(--gen-primary-rgb), var(--gen-border-opacity)) !important;
}

.border-secondary {
  --gen-border-opacity: 1;
  border-color: rgba(var(--gen-secondary-rgb), var(--gen-border-opacity)) !important;
}

.border-success {
  --gen-border-opacity: 1;
  border-color: rgba(var(--gen-success-rgb), var(--gen-border-opacity)) !important;
}

.border-info {
  --gen-border-opacity: 1;
  border-color: rgba(var(--gen-info-rgb), var(--gen-border-opacity)) !important;
}

.border-warning {
  --gen-border-opacity: 1;
  border-color: rgba(var(--gen-warning-rgb), var(--gen-border-opacity)) !important;
}

.border-danger {
  --gen-border-opacity: 1;
  border-color: rgba(var(--gen-danger-rgb), var(--gen-border-opacity)) !important;
}

.border-light {
  --gen-border-opacity: 1;
  border-color: rgba(var(--gen-light-rgb), var(--gen-border-opacity)) !important;
}

.border-dark {
  --gen-border-opacity: 1;
  border-color: rgba(var(--gen-dark-rgb), var(--gen-border-opacity)) !important;
}

.border-white {
  --gen-border-opacity: 1;
  border-color: rgba(var(--gen-white-rgb), var(--gen-border-opacity)) !important;
}

.border-black {
  --gen-border-opacity: 1;
  border-color: rgba(var(--gen-black-rgb), var(--gen-border-opacity)) !important;
}

.border-yellow {
  --gen-border-opacity: 1;
  border-color: rgba(var(--gen-yellow-rgb), var(--gen-border-opacity)) !important;
}

.border-blue {
  --gen-border-opacity: 1;
  border-color: rgba(var(--gen-blue-rgb), var(--gen-border-opacity)) !important;
}

.border-light-blue {
  --gen-border-opacity: 1;
  border-color: rgba(var(--gen-light-blue-rgb), var(--gen-border-opacity)) !important;
}

.border-light-blue-2 {
  --gen-border-opacity: 1;
  border-color: rgba(var(--gen-light-blue-2-rgb), var(--gen-border-opacity)) !important;
}

.border-light-blue-3 {
  --gen-border-opacity: 1;
  border-color: rgba(var(--gen-light-blue-3-rgb), var(--gen-border-opacity)) !important;
}

.border-light-blue-4 {
  --gen-border-opacity: 1;
  border-color: rgba(var(--gen-light-blue-4-rgb), var(--gen-border-opacity)) !important;
}

.border-light-blue-5 {
  --gen-border-opacity: 1;
  border-color: rgba(var(--gen-light-blue-5-rgb), var(--gen-border-opacity)) !important;
}

.border-light-blue-6 {
  --gen-border-opacity: 1;
  border-color: rgba(var(--gen-light-blue-6-rgb), var(--gen-border-opacity)) !important;
}

.border-dark-blue {
  --gen-border-opacity: 1;
  border-color: rgba(var(--gen-dark-blue-rgb), var(--gen-border-opacity)) !important;
}

.border-dark-blue-2 {
  --gen-border-opacity: 1;
  border-color: rgba(var(--gen-dark-blue-2-rgb), var(--gen-border-opacity)) !important;
}

.border-dark-blue-3 {
  --gen-border-opacity: 1;
  border-color: rgba(var(--gen-dark-blue-3-rgb), var(--gen-border-opacity)) !important;
}

.border-dark-blue-4 {
  --gen-border-opacity: 1;
  border-color: rgba(var(--gen-dark-blue-4-rgb), var(--gen-border-opacity)) !important;
}

.border-dark-blue-5 {
  --gen-border-opacity: 1;
  border-color: rgba(var(--gen-dark-blue-5-rgb), var(--gen-border-opacity)) !important;
}

.border-primary-dark {
  --gen-border-opacity: 1;
  border-color: rgba(var(--gen-primary-dark-rgb), var(--gen-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--gen-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--gen-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--gen-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--gen-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--gen-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--gen-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--gen-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--gen-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --gen-border-opacity: 0.1;
}

.border-opacity-25 {
  --gen-border-opacity: 0.25;
}

.border-opacity-50 {
  --gen-border-opacity: 0.5;
}

.border-opacity-75 {
  --gen-border-opacity: 0.75;
}

.border-opacity-100 {
  --gen-border-opacity: 1;
}

.w-0 {
  width: 0 !important;
}

.w-px {
  width: 1px !important;
}

.w-2px {
  width: 2px !important;
}

.w-1 {
  width: 0.25rem !important;
}

.w-2 {
  width: 0.5rem !important;
}

.w-3 {
  width: 0.75rem !important;
}

.w-4 {
  width: 1rem !important;
}

.w-5 {
  width: 1.25rem !important;
}

.w-6 {
  width: 1.5rem !important;
}

.w-7 {
  width: 1.75rem !important;
}

.w-8 {
  width: 2rem !important;
}

.w-9 {
  width: 2.25rem !important;
}

.w-10 {
  width: 2.5rem !important;
}

.w-11 {
  width: 2.75rem !important;
}

.w-12 {
  width: 3rem !important;
}

.w-13 {
  width: 3.25rem !important;
}

.w-14 {
  width: 3.5rem !important;
}

.w-15 {
  width: 3.75rem !important;
}

.w-16 {
  width: 4rem !important;
}

.w-17 {
  width: 4.25rem !important;
}

.w-18 {
  width: 4.5rem !important;
}

.w-19 {
  width: 4.75rem !important;
}

.w-20 {
  width: 5rem !important;
}

.w-21 {
  width: 5.25rem !important;
}

.w-22 {
  width: 5.5rem !important;
}

.w-23 {
  width: 5.75rem !important;
}

.w-24 {
  width: 6rem !important;
}

.w-25 {
  width: 6.25rem !important;
}

.w-26 {
  width: 6.5rem !important;
}

.w-27 {
  width: 6.75rem !important;
}

.w-28 {
  width: 7rem !important;
}

.w-29 {
  width: 7.25rem !important;
}

.w-30 {
  width: 7.5rem !important;
}

.w-31 {
  width: 7.75rem !important;
}

.w-32 {
  width: 8rem !important;
}

.w-quarter {
  width: 25% !important;
}

.w-half {
  width: 50% !important;
}

.w-3quarter {
  width: 75% !important;
}

.w-full {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-0 {
  height: 0 !important;
}

.h-px {
  height: 1px !important;
}

.h-2px {
  height: 2px !important;
}

.h-1 {
  height: 0.25rem !important;
}

.h-2 {
  height: 0.5rem !important;
}

.h-3 {
  height: 0.75rem !important;
}

.h-4 {
  height: 1rem !important;
}

.h-5 {
  height: 1.25rem !important;
}

.h-6 {
  height: 1.5rem !important;
}

.h-7 {
  height: 1.75rem !important;
}

.h-8 {
  height: 2rem !important;
}

.h-9 {
  height: 2.25rem !important;
}

.h-10 {
  height: 2.5rem !important;
}

.h-11 {
  height: 2.75rem !important;
}

.h-12 {
  height: 3rem !important;
}

.h-13 {
  height: 3.25rem !important;
}

.h-14 {
  height: 3.5rem !important;
}

.h-15 {
  height: 3.75rem !important;
}

.h-16 {
  height: 4rem !important;
}

.h-17 {
  height: 4.25rem !important;
}

.h-18 {
  height: 4.5rem !important;
}

.h-19 {
  height: 4.75rem !important;
}

.h-20 {
  height: 5rem !important;
}

.h-21 {
  height: 5.25rem !important;
}

.h-22 {
  height: 5.5rem !important;
}

.h-23 {
  height: 5.75rem !important;
}

.h-24 {
  height: 6rem !important;
}

.h-25 {
  height: 6.25rem !important;
}

.h-26 {
  height: 6.5rem !important;
}

.h-27 {
  height: 6.75rem !important;
}

.h-28 {
  height: 7rem !important;
}

.h-29 {
  height: 7.25rem !important;
}

.h-30 {
  height: 7.5rem !important;
}

.h-31 {
  height: 7.75rem !important;
}

.h-32 {
  height: 8rem !important;
}

.h-quarter {
  height: 25% !important;
}

.h-half {
  height: 50% !important;
}

.h-3quarter {
  height: 75% !important;
}

.h-full {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-around {
  justify-content: space-around !important;
}

.justify-evenly {
  justify-content: space-evenly !important;
}

.align-start {
  align-items: flex-start !important;
}

.align-end {
  align-items: flex-end !important;
}

.align-center {
  align-items: center !important;
}

.align-baseline {
  align-items: baseline !important;
}

.align-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.m-5 {
  margin: 1.25rem !important;
}

.m-6 {
  margin: 1.5rem !important;
}

.m-7 {
  margin: 1.75rem !important;
}

.m-8 {
  margin: 2rem !important;
}

.m-9 {
  margin: 2.25rem !important;
}

.m-10 {
  margin: 2.5rem !important;
}

.m-11 {
  margin: 2.75rem !important;
}

.m-12 {
  margin: 3rem !important;
}

.m-13 {
  margin: 3.25rem !important;
}

.m-14 {
  margin: 3.5rem !important;
}

.m-15 {
  margin: 3.75rem !important;
}

.m-16 {
  margin: 4rem !important;
}

.m-17 {
  margin: 4.25rem !important;
}

.m-18 {
  margin: 4.5rem !important;
}

.m-19 {
  margin: 4.75rem !important;
}

.m-20 {
  margin: 5rem !important;
}

.m-21 {
  margin: 5.25rem !important;
}

.m-22 {
  margin: 5.5rem !important;
}

.m-23 {
  margin: 5.75rem !important;
}

.m-24 {
  margin: 6rem !important;
}

.m-25 {
  margin: 6.25rem !important;
}

.m-26 {
  margin: 6.5rem !important;
}

.m-27 {
  margin: 6.75rem !important;
}

.m-28 {
  margin: 7rem !important;
}

.m-29 {
  margin: 7.25rem !important;
}

.m-30 {
  margin: 7.5rem !important;
}

.m-31 {
  margin: 7.75rem !important;
}

.m-32 {
  margin: 8.25rem !important;
}

.m-33 {
  margin: 8.5rem !important;
}

.m-34 {
  margin: 8.75rem !important;
}

.m-35 {
  margin: 9rem !important;
}

.m-36 {
  margin: 9.25rem !important;
}

.m-37 {
  margin: 9.5rem !important;
}

.m-38 {
  margin: 9.75rem !important;
}

.m-39 {
  margin: 10rem !important;
}

.m-40 {
  margin: 10.25rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}

.mx-4 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-5 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important;
}

.mx-6 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-7 {
  margin-right: 1.75rem !important;
  margin-left: 1.75rem !important;
}

.mx-8 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-9 {
  margin-right: 2.25rem !important;
  margin-left: 2.25rem !important;
}

.mx-10 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-11 {
  margin-right: 2.75rem !important;
  margin-left: 2.75rem !important;
}

.mx-12 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-13 {
  margin-right: 3.25rem !important;
  margin-left: 3.25rem !important;
}

.mx-14 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;
}

.mx-15 {
  margin-right: 3.75rem !important;
  margin-left: 3.75rem !important;
}

.mx-16 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-17 {
  margin-right: 4.25rem !important;
  margin-left: 4.25rem !important;
}

.mx-18 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}

.mx-19 {
  margin-right: 4.75rem !important;
  margin-left: 4.75rem !important;
}

.mx-20 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-21 {
  margin-right: 5.25rem !important;
  margin-left: 5.25rem !important;
}

.mx-22 {
  margin-right: 5.5rem !important;
  margin-left: 5.5rem !important;
}

.mx-23 {
  margin-right: 5.75rem !important;
  margin-left: 5.75rem !important;
}

.mx-24 {
  margin-right: 6rem !important;
  margin-left: 6rem !important;
}

.mx-25 {
  margin-right: 6.25rem !important;
  margin-left: 6.25rem !important;
}

.mx-26 {
  margin-right: 6.5rem !important;
  margin-left: 6.5rem !important;
}

.mx-27 {
  margin-right: 6.75rem !important;
  margin-left: 6.75rem !important;
}

.mx-28 {
  margin-right: 7rem !important;
  margin-left: 7rem !important;
}

.mx-29 {
  margin-right: 7.25rem !important;
  margin-left: 7.25rem !important;
}

.mx-30 {
  margin-right: 7.5rem !important;
  margin-left: 7.5rem !important;
}

.mx-31 {
  margin-right: 7.75rem !important;
  margin-left: 7.75rem !important;
}

.mx-32 {
  margin-right: 8.25rem !important;
  margin-left: 8.25rem !important;
}

.mx-33 {
  margin-right: 8.5rem !important;
  margin-left: 8.5rem !important;
}

.mx-34 {
  margin-right: 8.75rem !important;
  margin-left: 8.75rem !important;
}

.mx-35 {
  margin-right: 9rem !important;
  margin-left: 9rem !important;
}

.mx-36 {
  margin-right: 9.25rem !important;
  margin-left: 9.25rem !important;
}

.mx-37 {
  margin-right: 9.5rem !important;
  margin-left: 9.5rem !important;
}

.mx-38 {
  margin-right: 9.75rem !important;
  margin-left: 9.75rem !important;
}

.mx-39 {
  margin-right: 10rem !important;
  margin-left: 10rem !important;
}

.mx-40 {
  margin-right: 10.25rem !important;
  margin-left: 10.25rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-7 {
  margin-top: 1.75rem !important;
  margin-bottom: 1.75rem !important;
}

.my-8 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-9 {
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important;
}

.my-10 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-11 {
  margin-top: 2.75rem !important;
  margin-bottom: 2.75rem !important;
}

.my-12 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-13 {
  margin-top: 3.25rem !important;
  margin-bottom: 3.25rem !important;
}

.my-14 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-15 {
  margin-top: 3.75rem !important;
  margin-bottom: 3.75rem !important;
}

.my-16 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-17 {
  margin-top: 4.25rem !important;
  margin-bottom: 4.25rem !important;
}

.my-18 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-19 {
  margin-top: 4.75rem !important;
  margin-bottom: 4.75rem !important;
}

.my-20 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-21 {
  margin-top: 5.25rem !important;
  margin-bottom: 5.25rem !important;
}

.my-22 {
  margin-top: 5.5rem !important;
  margin-bottom: 5.5rem !important;
}

.my-23 {
  margin-top: 5.75rem !important;
  margin-bottom: 5.75rem !important;
}

.my-24 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-25 {
  margin-top: 6.25rem !important;
  margin-bottom: 6.25rem !important;
}

.my-26 {
  margin-top: 6.5rem !important;
  margin-bottom: 6.5rem !important;
}

.my-27 {
  margin-top: 6.75rem !important;
  margin-bottom: 6.75rem !important;
}

.my-28 {
  margin-top: 7rem !important;
  margin-bottom: 7rem !important;
}

.my-29 {
  margin-top: 7.25rem !important;
  margin-bottom: 7.25rem !important;
}

.my-30 {
  margin-top: 7.5rem !important;
  margin-bottom: 7.5rem !important;
}

.my-31 {
  margin-top: 7.75rem !important;
  margin-bottom: 7.75rem !important;
}

.my-32 {
  margin-top: 8.25rem !important;
  margin-bottom: 8.25rem !important;
}

.my-33 {
  margin-top: 8.5rem !important;
  margin-bottom: 8.5rem !important;
}

.my-34 {
  margin-top: 8.75rem !important;
  margin-bottom: 8.75rem !important;
}

.my-35 {
  margin-top: 9rem !important;
  margin-bottom: 9rem !important;
}

.my-36 {
  margin-top: 9.25rem !important;
  margin-bottom: 9.25rem !important;
}

.my-37 {
  margin-top: 9.5rem !important;
  margin-bottom: 9.5rem !important;
}

.my-38 {
  margin-top: 9.75rem !important;
  margin-bottom: 9.75rem !important;
}

.my-39 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.my-40 {
  margin-top: 10.25rem !important;
  margin-bottom: 10.25rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mt-7 {
  margin-top: 1.75rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.mt-9 {
  margin-top: 2.25rem !important;
}

.mt-10 {
  margin-top: 2.5rem !important;
}

.mt-11 {
  margin-top: 2.75rem !important;
}

.mt-12 {
  margin-top: 3rem !important;
}

.mt-13 {
  margin-top: 3.25rem !important;
}

.mt-14 {
  margin-top: 3.5rem !important;
}

.mt-15 {
  margin-top: 3.75rem !important;
}

.mt-16 {
  margin-top: 4rem !important;
}

.mt-17 {
  margin-top: 4.25rem !important;
}

.mt-18 {
  margin-top: 4.5rem !important;
}

.mt-19 {
  margin-top: 4.75rem !important;
}

.mt-20 {
  margin-top: 5rem !important;
}

.mt-21 {
  margin-top: 5.25rem !important;
}

.mt-22 {
  margin-top: 5.5rem !important;
}

.mt-23 {
  margin-top: 5.75rem !important;
}

.mt-24 {
  margin-top: 6rem !important;
}

.mt-25 {
  margin-top: 6.25rem !important;
}

.mt-26 {
  margin-top: 6.5rem !important;
}

.mt-27 {
  margin-top: 6.75rem !important;
}

.mt-28 {
  margin-top: 7rem !important;
}

.mt-29 {
  margin-top: 7.25rem !important;
}

.mt-30 {
  margin-top: 7.5rem !important;
}

.mt-31 {
  margin-top: 7.75rem !important;
}

.mt-32 {
  margin-top: 8.25rem !important;
}

.mt-33 {
  margin-top: 8.5rem !important;
}

.mt-34 {
  margin-top: 8.75rem !important;
}

.mt-35 {
  margin-top: 9rem !important;
}

.mt-36 {
  margin-top: 9.25rem !important;
}

.mt-37 {
  margin-top: 9.5rem !important;
}

.mt-38 {
  margin-top: 9.75rem !important;
}

.mt-39 {
  margin-top: 10rem !important;
}

.mt-40 {
  margin-top: 10.25rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 0.75rem !important;
}

.me-4 {
  margin-right: 1rem !important;
}

.me-5 {
  margin-right: 1.25rem !important;
}

.me-6 {
  margin-right: 1.5rem !important;
}

.me-7 {
  margin-right: 1.75rem !important;
}

.me-8 {
  margin-right: 2rem !important;
}

.me-9 {
  margin-right: 2.25rem !important;
}

.me-10 {
  margin-right: 2.5rem !important;
}

.me-11 {
  margin-right: 2.75rem !important;
}

.me-12 {
  margin-right: 3rem !important;
}

.me-13 {
  margin-right: 3.25rem !important;
}

.me-14 {
  margin-right: 3.5rem !important;
}

.me-15 {
  margin-right: 3.75rem !important;
}

.me-16 {
  margin-right: 4rem !important;
}

.me-17 {
  margin-right: 4.25rem !important;
}

.me-18 {
  margin-right: 4.5rem !important;
}

.me-19 {
  margin-right: 4.75rem !important;
}

.me-20 {
  margin-right: 5rem !important;
}

.me-21 {
  margin-right: 5.25rem !important;
}

.me-22 {
  margin-right: 5.5rem !important;
}

.me-23 {
  margin-right: 5.75rem !important;
}

.me-24 {
  margin-right: 6rem !important;
}

.me-25 {
  margin-right: 6.25rem !important;
}

.me-26 {
  margin-right: 6.5rem !important;
}

.me-27 {
  margin-right: 6.75rem !important;
}

.me-28 {
  margin-right: 7rem !important;
}

.me-29 {
  margin-right: 7.25rem !important;
}

.me-30 {
  margin-right: 7.5rem !important;
}

.me-31 {
  margin-right: 7.75rem !important;
}

.me-32 {
  margin-right: 8.25rem !important;
}

.me-33 {
  margin-right: 8.5rem !important;
}

.me-34 {
  margin-right: 8.75rem !important;
}

.me-35 {
  margin-right: 9rem !important;
}

.me-36 {
  margin-right: 9.25rem !important;
}

.me-37 {
  margin-right: 9.5rem !important;
}

.me-38 {
  margin-right: 9.75rem !important;
}

.me-39 {
  margin-right: 10rem !important;
}

.me-40 {
  margin-right: 10.25rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.mb-7 {
  margin-bottom: 1.75rem !important;
}

.mb-8 {
  margin-bottom: 2rem !important;
}

.mb-9 {
  margin-bottom: 2.25rem !important;
}

.mb-10 {
  margin-bottom: 2.5rem !important;
}

.mb-11 {
  margin-bottom: 2.75rem !important;
}

.mb-12 {
  margin-bottom: 3rem !important;
}

.mb-13 {
  margin-bottom: 3.25rem !important;
}

.mb-14 {
  margin-bottom: 3.5rem !important;
}

.mb-15 {
  margin-bottom: 3.75rem !important;
}

.mb-16 {
  margin-bottom: 4rem !important;
}

.mb-17 {
  margin-bottom: 4.25rem !important;
}

.mb-18 {
  margin-bottom: 4.5rem !important;
}

.mb-19 {
  margin-bottom: 4.75rem !important;
}

.mb-20 {
  margin-bottom: 5rem !important;
}

.mb-21 {
  margin-bottom: 5.25rem !important;
}

.mb-22 {
  margin-bottom: 5.5rem !important;
}

.mb-23 {
  margin-bottom: 5.75rem !important;
}

.mb-24 {
  margin-bottom: 6rem !important;
}

.mb-25 {
  margin-bottom: 6.25rem !important;
}

.mb-26 {
  margin-bottom: 6.5rem !important;
}

.mb-27 {
  margin-bottom: 6.75rem !important;
}

.mb-28 {
  margin-bottom: 7rem !important;
}

.mb-29 {
  margin-bottom: 7.25rem !important;
}

.mb-30 {
  margin-bottom: 7.5rem !important;
}

.mb-31 {
  margin-bottom: 7.75rem !important;
}

.mb-32 {
  margin-bottom: 8.25rem !important;
}

.mb-33 {
  margin-bottom: 8.5rem !important;
}

.mb-34 {
  margin-bottom: 8.75rem !important;
}

.mb-35 {
  margin-bottom: 9rem !important;
}

.mb-36 {
  margin-bottom: 9.25rem !important;
}

.mb-37 {
  margin-bottom: 9.5rem !important;
}

.mb-38 {
  margin-bottom: 9.75rem !important;
}

.mb-39 {
  margin-bottom: 10rem !important;
}

.mb-40 {
  margin-bottom: 10.25rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 0.75rem !important;
}

.ms-4 {
  margin-left: 1rem !important;
}

.ms-5 {
  margin-left: 1.25rem !important;
}

.ms-6 {
  margin-left: 1.5rem !important;
}

.ms-7 {
  margin-left: 1.75rem !important;
}

.ms-8 {
  margin-left: 2rem !important;
}

.ms-9 {
  margin-left: 2.25rem !important;
}

.ms-10 {
  margin-left: 2.5rem !important;
}

.ms-11 {
  margin-left: 2.75rem !important;
}

.ms-12 {
  margin-left: 3rem !important;
}

.ms-13 {
  margin-left: 3.25rem !important;
}

.ms-14 {
  margin-left: 3.5rem !important;
}

.ms-15 {
  margin-left: 3.75rem !important;
}

.ms-16 {
  margin-left: 4rem !important;
}

.ms-17 {
  margin-left: 4.25rem !important;
}

.ms-18 {
  margin-left: 4.5rem !important;
}

.ms-19 {
  margin-left: 4.75rem !important;
}

.ms-20 {
  margin-left: 5rem !important;
}

.ms-21 {
  margin-left: 5.25rem !important;
}

.ms-22 {
  margin-left: 5.5rem !important;
}

.ms-23 {
  margin-left: 5.75rem !important;
}

.ms-24 {
  margin-left: 6rem !important;
}

.ms-25 {
  margin-left: 6.25rem !important;
}

.ms-26 {
  margin-left: 6.5rem !important;
}

.ms-27 {
  margin-left: 6.75rem !important;
}

.ms-28 {
  margin-left: 7rem !important;
}

.ms-29 {
  margin-left: 7.25rem !important;
}

.ms-30 {
  margin-left: 7.5rem !important;
}

.ms-31 {
  margin-left: 7.75rem !important;
}

.ms-32 {
  margin-left: 8.25rem !important;
}

.ms-33 {
  margin-left: 8.5rem !important;
}

.ms-34 {
  margin-left: 8.75rem !important;
}

.ms-35 {
  margin-left: 9rem !important;
}

.ms-36 {
  margin-left: 9.25rem !important;
}

.ms-37 {
  margin-left: 9.5rem !important;
}

.ms-38 {
  margin-left: 9.75rem !important;
}

.ms-39 {
  margin-left: 10rem !important;
}

.ms-40 {
  margin-left: 10.25rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -0.75rem !important;
}

.m-n4 {
  margin: -1rem !important;
}

.m-n5 {
  margin: -1.25rem !important;
}

.m-n6 {
  margin: -1.5rem !important;
}

.m-n7 {
  margin: -1.75rem !important;
}

.m-n8 {
  margin: -2rem !important;
}

.m-n9 {
  margin: -2.25rem !important;
}

.m-n10 {
  margin: -2.5rem !important;
}

.m-n11 {
  margin: -2.75rem !important;
}

.m-n12 {
  margin: -3rem !important;
}

.m-n13 {
  margin: -3.25rem !important;
}

.m-n14 {
  margin: -3.5rem !important;
}

.m-n15 {
  margin: -3.75rem !important;
}

.m-n16 {
  margin: -4rem !important;
}

.m-n17 {
  margin: -4.25rem !important;
}

.m-n18 {
  margin: -4.5rem !important;
}

.m-n19 {
  margin: -4.75rem !important;
}

.m-n20 {
  margin: -5rem !important;
}

.m-n21 {
  margin: -5.25rem !important;
}

.m-n22 {
  margin: -5.5rem !important;
}

.m-n23 {
  margin: -5.75rem !important;
}

.m-n24 {
  margin: -6rem !important;
}

.m-n25 {
  margin: -6.25rem !important;
}

.m-n26 {
  margin: -6.5rem !important;
}

.m-n27 {
  margin: -6.75rem !important;
}

.m-n28 {
  margin: -7rem !important;
}

.m-n29 {
  margin: -7.25rem !important;
}

.m-n30 {
  margin: -7.5rem !important;
}

.m-n31 {
  margin: -7.75rem !important;
}

.m-n32 {
  margin: -8.25rem !important;
}

.m-n33 {
  margin: -8.5rem !important;
}

.m-n34 {
  margin: -8.75rem !important;
}

.m-n35 {
  margin: -9rem !important;
}

.m-n36 {
  margin: -9.25rem !important;
}

.m-n37 {
  margin: -9.5rem !important;
}

.m-n38 {
  margin: -9.75rem !important;
}

.m-n39 {
  margin: -10rem !important;
}

.m-n40 {
  margin: -10.25rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -0.75rem !important;
  margin-left: -0.75rem !important;
}

.mx-n4 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n5 {
  margin-right: -1.25rem !important;
  margin-left: -1.25rem !important;
}

.mx-n6 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n7 {
  margin-right: -1.75rem !important;
  margin-left: -1.75rem !important;
}

.mx-n8 {
  margin-right: -2rem !important;
  margin-left: -2rem !important;
}

.mx-n9 {
  margin-right: -2.25rem !important;
  margin-left: -2.25rem !important;
}

.mx-n10 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}

.mx-n11 {
  margin-right: -2.75rem !important;
  margin-left: -2.75rem !important;
}

.mx-n12 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n13 {
  margin-right: -3.25rem !important;
  margin-left: -3.25rem !important;
}

.mx-n14 {
  margin-right: -3.5rem !important;
  margin-left: -3.5rem !important;
}

.mx-n15 {
  margin-right: -3.75rem !important;
  margin-left: -3.75rem !important;
}

.mx-n16 {
  margin-right: -4rem !important;
  margin-left: -4rem !important;
}

.mx-n17 {
  margin-right: -4.25rem !important;
  margin-left: -4.25rem !important;
}

.mx-n18 {
  margin-right: -4.5rem !important;
  margin-left: -4.5rem !important;
}

.mx-n19 {
  margin-right: -4.75rem !important;
  margin-left: -4.75rem !important;
}

.mx-n20 {
  margin-right: -5rem !important;
  margin-left: -5rem !important;
}

.mx-n21 {
  margin-right: -5.25rem !important;
  margin-left: -5.25rem !important;
}

.mx-n22 {
  margin-right: -5.5rem !important;
  margin-left: -5.5rem !important;
}

.mx-n23 {
  margin-right: -5.75rem !important;
  margin-left: -5.75rem !important;
}

.mx-n24 {
  margin-right: -6rem !important;
  margin-left: -6rem !important;
}

.mx-n25 {
  margin-right: -6.25rem !important;
  margin-left: -6.25rem !important;
}

.mx-n26 {
  margin-right: -6.5rem !important;
  margin-left: -6.5rem !important;
}

.mx-n27 {
  margin-right: -6.75rem !important;
  margin-left: -6.75rem !important;
}

.mx-n28 {
  margin-right: -7rem !important;
  margin-left: -7rem !important;
}

.mx-n29 {
  margin-right: -7.25rem !important;
  margin-left: -7.25rem !important;
}

.mx-n30 {
  margin-right: -7.5rem !important;
  margin-left: -7.5rem !important;
}

.mx-n31 {
  margin-right: -7.75rem !important;
  margin-left: -7.75rem !important;
}

.mx-n32 {
  margin-right: -8.25rem !important;
  margin-left: -8.25rem !important;
}

.mx-n33 {
  margin-right: -8.5rem !important;
  margin-left: -8.5rem !important;
}

.mx-n34 {
  margin-right: -8.75rem !important;
  margin-left: -8.75rem !important;
}

.mx-n35 {
  margin-right: -9rem !important;
  margin-left: -9rem !important;
}

.mx-n36 {
  margin-right: -9.25rem !important;
  margin-left: -9.25rem !important;
}

.mx-n37 {
  margin-right: -9.5rem !important;
  margin-left: -9.5rem !important;
}

.mx-n38 {
  margin-right: -9.75rem !important;
  margin-left: -9.75rem !important;
}

.mx-n39 {
  margin-right: -10rem !important;
  margin-left: -10rem !important;
}

.mx-n40 {
  margin-right: -10.25rem !important;
  margin-left: -10.25rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}

.my-n4 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n5 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.my-n6 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n7 {
  margin-top: -1.75rem !important;
  margin-bottom: -1.75rem !important;
}

.my-n8 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-n9 {
  margin-top: -2.25rem !important;
  margin-bottom: -2.25rem !important;
}

.my-n10 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n11 {
  margin-top: -2.75rem !important;
  margin-bottom: -2.75rem !important;
}

.my-n12 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n13 {
  margin-top: -3.25rem !important;
  margin-bottom: -3.25rem !important;
}

.my-n14 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

.my-n15 {
  margin-top: -3.75rem !important;
  margin-bottom: -3.75rem !important;
}

.my-n16 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.my-n17 {
  margin-top: -4.25rem !important;
  margin-bottom: -4.25rem !important;
}

.my-n18 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}

.my-n19 {
  margin-top: -4.75rem !important;
  margin-bottom: -4.75rem !important;
}

.my-n20 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.my-n21 {
  margin-top: -5.25rem !important;
  margin-bottom: -5.25rem !important;
}

.my-n22 {
  margin-top: -5.5rem !important;
  margin-bottom: -5.5rem !important;
}

.my-n23 {
  margin-top: -5.75rem !important;
  margin-bottom: -5.75rem !important;
}

.my-n24 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}

.my-n25 {
  margin-top: -6.25rem !important;
  margin-bottom: -6.25rem !important;
}

.my-n26 {
  margin-top: -6.5rem !important;
  margin-bottom: -6.5rem !important;
}

.my-n27 {
  margin-top: -6.75rem !important;
  margin-bottom: -6.75rem !important;
}

.my-n28 {
  margin-top: -7rem !important;
  margin-bottom: -7rem !important;
}

.my-n29 {
  margin-top: -7.25rem !important;
  margin-bottom: -7.25rem !important;
}

.my-n30 {
  margin-top: -7.5rem !important;
  margin-bottom: -7.5rem !important;
}

.my-n31 {
  margin-top: -7.75rem !important;
  margin-bottom: -7.75rem !important;
}

.my-n32 {
  margin-top: -8.25rem !important;
  margin-bottom: -8.25rem !important;
}

.my-n33 {
  margin-top: -8.5rem !important;
  margin-bottom: -8.5rem !important;
}

.my-n34 {
  margin-top: -8.75rem !important;
  margin-bottom: -8.75rem !important;
}

.my-n35 {
  margin-top: -9rem !important;
  margin-bottom: -9rem !important;
}

.my-n36 {
  margin-top: -9.25rem !important;
  margin-bottom: -9.25rem !important;
}

.my-n37 {
  margin-top: -9.5rem !important;
  margin-bottom: -9.5rem !important;
}

.my-n38 {
  margin-top: -9.75rem !important;
  margin-bottom: -9.75rem !important;
}

.my-n39 {
  margin-top: -10rem !important;
  margin-bottom: -10rem !important;
}

.my-n40 {
  margin-top: -10.25rem !important;
  margin-bottom: -10.25rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -0.75rem !important;
}

.mt-n4 {
  margin-top: -1rem !important;
}

.mt-n5 {
  margin-top: -1.25rem !important;
}

.mt-n6 {
  margin-top: -1.5rem !important;
}

.mt-n7 {
  margin-top: -1.75rem !important;
}

.mt-n8 {
  margin-top: -2rem !important;
}

.mt-n9 {
  margin-top: -2.25rem !important;
}

.mt-n10 {
  margin-top: -2.5rem !important;
}

.mt-n11 {
  margin-top: -2.75rem !important;
}

.mt-n12 {
  margin-top: -3rem !important;
}

.mt-n13 {
  margin-top: -3.25rem !important;
}

.mt-n14 {
  margin-top: -3.5rem !important;
}

.mt-n15 {
  margin-top: -3.75rem !important;
}

.mt-n16 {
  margin-top: -4rem !important;
}

.mt-n17 {
  margin-top: -4.25rem !important;
}

.mt-n18 {
  margin-top: -4.5rem !important;
}

.mt-n19 {
  margin-top: -4.75rem !important;
}

.mt-n20 {
  margin-top: -5rem !important;
}

.mt-n21 {
  margin-top: -5.25rem !important;
}

.mt-n22 {
  margin-top: -5.5rem !important;
}

.mt-n23 {
  margin-top: -5.75rem !important;
}

.mt-n24 {
  margin-top: -6rem !important;
}

.mt-n25 {
  margin-top: -6.25rem !important;
}

.mt-n26 {
  margin-top: -6.5rem !important;
}

.mt-n27 {
  margin-top: -6.75rem !important;
}

.mt-n28 {
  margin-top: -7rem !important;
}

.mt-n29 {
  margin-top: -7.25rem !important;
}

.mt-n30 {
  margin-top: -7.5rem !important;
}

.mt-n31 {
  margin-top: -7.75rem !important;
}

.mt-n32 {
  margin-top: -8.25rem !important;
}

.mt-n33 {
  margin-top: -8.5rem !important;
}

.mt-n34 {
  margin-top: -8.75rem !important;
}

.mt-n35 {
  margin-top: -9rem !important;
}

.mt-n36 {
  margin-top: -9.25rem !important;
}

.mt-n37 {
  margin-top: -9.5rem !important;
}

.mt-n38 {
  margin-top: -9.75rem !important;
}

.mt-n39 {
  margin-top: -10rem !important;
}

.mt-n40 {
  margin-top: -10.25rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -0.75rem !important;
}

.me-n4 {
  margin-right: -1rem !important;
}

.me-n5 {
  margin-right: -1.25rem !important;
}

.me-n6 {
  margin-right: -1.5rem !important;
}

.me-n7 {
  margin-right: -1.75rem !important;
}

.me-n8 {
  margin-right: -2rem !important;
}

.me-n9 {
  margin-right: -2.25rem !important;
}

.me-n10 {
  margin-right: -2.5rem !important;
}

.me-n11 {
  margin-right: -2.75rem !important;
}

.me-n12 {
  margin-right: -3rem !important;
}

.me-n13 {
  margin-right: -3.25rem !important;
}

.me-n14 {
  margin-right: -3.5rem !important;
}

.me-n15 {
  margin-right: -3.75rem !important;
}

.me-n16 {
  margin-right: -4rem !important;
}

.me-n17 {
  margin-right: -4.25rem !important;
}

.me-n18 {
  margin-right: -4.5rem !important;
}

.me-n19 {
  margin-right: -4.75rem !important;
}

.me-n20 {
  margin-right: -5rem !important;
}

.me-n21 {
  margin-right: -5.25rem !important;
}

.me-n22 {
  margin-right: -5.5rem !important;
}

.me-n23 {
  margin-right: -5.75rem !important;
}

.me-n24 {
  margin-right: -6rem !important;
}

.me-n25 {
  margin-right: -6.25rem !important;
}

.me-n26 {
  margin-right: -6.5rem !important;
}

.me-n27 {
  margin-right: -6.75rem !important;
}

.me-n28 {
  margin-right: -7rem !important;
}

.me-n29 {
  margin-right: -7.25rem !important;
}

.me-n30 {
  margin-right: -7.5rem !important;
}

.me-n31 {
  margin-right: -7.75rem !important;
}

.me-n32 {
  margin-right: -8.25rem !important;
}

.me-n33 {
  margin-right: -8.5rem !important;
}

.me-n34 {
  margin-right: -8.75rem !important;
}

.me-n35 {
  margin-right: -9rem !important;
}

.me-n36 {
  margin-right: -9.25rem !important;
}

.me-n37 {
  margin-right: -9.5rem !important;
}

.me-n38 {
  margin-right: -9.75rem !important;
}

.me-n39 {
  margin-right: -10rem !important;
}

.me-n40 {
  margin-right: -10.25rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -0.75rem !important;
}

.mb-n4 {
  margin-bottom: -1rem !important;
}

.mb-n5 {
  margin-bottom: -1.25rem !important;
}

.mb-n6 {
  margin-bottom: -1.5rem !important;
}

.mb-n7 {
  margin-bottom: -1.75rem !important;
}

.mb-n8 {
  margin-bottom: -2rem !important;
}

.mb-n9 {
  margin-bottom: -2.25rem !important;
}

.mb-n10 {
  margin-bottom: -2.5rem !important;
}

.mb-n11 {
  margin-bottom: -2.75rem !important;
}

.mb-n12 {
  margin-bottom: -3rem !important;
}

.mb-n13 {
  margin-bottom: -3.25rem !important;
}

.mb-n14 {
  margin-bottom: -3.5rem !important;
}

.mb-n15 {
  margin-bottom: -3.75rem !important;
}

.mb-n16 {
  margin-bottom: -4rem !important;
}

.mb-n17 {
  margin-bottom: -4.25rem !important;
}

.mb-n18 {
  margin-bottom: -4.5rem !important;
}

.mb-n19 {
  margin-bottom: -4.75rem !important;
}

.mb-n20 {
  margin-bottom: -5rem !important;
}

.mb-n21 {
  margin-bottom: -5.25rem !important;
}

.mb-n22 {
  margin-bottom: -5.5rem !important;
}

.mb-n23 {
  margin-bottom: -5.75rem !important;
}

.mb-n24 {
  margin-bottom: -6rem !important;
}

.mb-n25 {
  margin-bottom: -6.25rem !important;
}

.mb-n26 {
  margin-bottom: -6.5rem !important;
}

.mb-n27 {
  margin-bottom: -6.75rem !important;
}

.mb-n28 {
  margin-bottom: -7rem !important;
}

.mb-n29 {
  margin-bottom: -7.25rem !important;
}

.mb-n30 {
  margin-bottom: -7.5rem !important;
}

.mb-n31 {
  margin-bottom: -7.75rem !important;
}

.mb-n32 {
  margin-bottom: -8.25rem !important;
}

.mb-n33 {
  margin-bottom: -8.5rem !important;
}

.mb-n34 {
  margin-bottom: -8.75rem !important;
}

.mb-n35 {
  margin-bottom: -9rem !important;
}

.mb-n36 {
  margin-bottom: -9.25rem !important;
}

.mb-n37 {
  margin-bottom: -9.5rem !important;
}

.mb-n38 {
  margin-bottom: -9.75rem !important;
}

.mb-n39 {
  margin-bottom: -10rem !important;
}

.mb-n40 {
  margin-bottom: -10.25rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -0.75rem !important;
}

.ms-n4 {
  margin-left: -1rem !important;
}

.ms-n5 {
  margin-left: -1.25rem !important;
}

.ms-n6 {
  margin-left: -1.5rem !important;
}

.ms-n7 {
  margin-left: -1.75rem !important;
}

.ms-n8 {
  margin-left: -2rem !important;
}

.ms-n9 {
  margin-left: -2.25rem !important;
}

.ms-n10 {
  margin-left: -2.5rem !important;
}

.ms-n11 {
  margin-left: -2.75rem !important;
}

.ms-n12 {
  margin-left: -3rem !important;
}

.ms-n13 {
  margin-left: -3.25rem !important;
}

.ms-n14 {
  margin-left: -3.5rem !important;
}

.ms-n15 {
  margin-left: -3.75rem !important;
}

.ms-n16 {
  margin-left: -4rem !important;
}

.ms-n17 {
  margin-left: -4.25rem !important;
}

.ms-n18 {
  margin-left: -4.5rem !important;
}

.ms-n19 {
  margin-left: -4.75rem !important;
}

.ms-n20 {
  margin-left: -5rem !important;
}

.ms-n21 {
  margin-left: -5.25rem !important;
}

.ms-n22 {
  margin-left: -5.5rem !important;
}

.ms-n23 {
  margin-left: -5.75rem !important;
}

.ms-n24 {
  margin-left: -6rem !important;
}

.ms-n25 {
  margin-left: -6.25rem !important;
}

.ms-n26 {
  margin-left: -6.5rem !important;
}

.ms-n27 {
  margin-left: -6.75rem !important;
}

.ms-n28 {
  margin-left: -7rem !important;
}

.ms-n29 {
  margin-left: -7.25rem !important;
}

.ms-n30 {
  margin-left: -7.5rem !important;
}

.ms-n31 {
  margin-left: -7.75rem !important;
}

.ms-n32 {
  margin-left: -8.25rem !important;
}

.ms-n33 {
  margin-left: -8.5rem !important;
}

.ms-n34 {
  margin-left: -8.75rem !important;
}

.ms-n35 {
  margin-left: -9rem !important;
}

.ms-n36 {
  margin-left: -9.25rem !important;
}

.ms-n37 {
  margin-left: -9.5rem !important;
}

.ms-n38 {
  margin-left: -9.75rem !important;
}

.ms-n39 {
  margin-left: -10rem !important;
}

.ms-n40 {
  margin-left: -10.25rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.p-7 {
  padding: 1.75rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.p-9 {
  padding: 2.25rem !important;
}

.p-10 {
  padding: 2.5rem !important;
}

.p-11 {
  padding: 2.75rem !important;
}

.p-12 {
  padding: 3rem !important;
}

.p-13 {
  padding: 3.25rem !important;
}

.p-14 {
  padding: 3.5rem !important;
}

.p-15 {
  padding: 3.75rem !important;
}

.p-16 {
  padding: 4rem !important;
}

.p-17 {
  padding: 4.25rem !important;
}

.p-18 {
  padding: 4.5rem !important;
}

.p-19 {
  padding: 4.75rem !important;
}

.p-20 {
  padding: 5rem !important;
}

.p-21 {
  padding: 5.25rem !important;
}

.p-22 {
  padding: 5.5rem !important;
}

.p-23 {
  padding: 5.75rem !important;
}

.p-24 {
  padding: 6rem !important;
}

.p-25 {
  padding: 6.25rem !important;
}

.p-26 {
  padding: 6.5rem !important;
}

.p-27 {
  padding: 6.75rem !important;
}

.p-28 {
  padding: 7rem !important;
}

.p-29 {
  padding: 7.25rem !important;
}

.p-30 {
  padding: 7.5rem !important;
}

.p-31 {
  padding: 7.75rem !important;
}

.p-32 {
  padding: 8.25rem !important;
}

.p-33 {
  padding: 8.5rem !important;
}

.p-34 {
  padding: 8.75rem !important;
}

.p-35 {
  padding: 9rem !important;
}

.p-36 {
  padding: 9.25rem !important;
}

.p-37 {
  padding: 9.5rem !important;
}

.p-38 {
  padding: 9.75rem !important;
}

.p-39 {
  padding: 10rem !important;
}

.p-40 {
  padding: 10.25rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}

.px-4 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-5 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}

.px-6 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-7 {
  padding-right: 1.75rem !important;
  padding-left: 1.75rem !important;
}

.px-8 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-9 {
  padding-right: 2.25rem !important;
  padding-left: 2.25rem !important;
}

.px-10 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-11 {
  padding-right: 2.75rem !important;
  padding-left: 2.75rem !important;
}

.px-12 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-13 {
  padding-right: 3.25rem !important;
  padding-left: 3.25rem !important;
}

.px-14 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
}

.px-15 {
  padding-right: 3.75rem !important;
  padding-left: 3.75rem !important;
}

.px-16 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-17 {
  padding-right: 4.25rem !important;
  padding-left: 4.25rem !important;
}

.px-18 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

.px-19 {
  padding-right: 4.75rem !important;
  padding-left: 4.75rem !important;
}

.px-20 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.px-21 {
  padding-right: 5.25rem !important;
  padding-left: 5.25rem !important;
}

.px-22 {
  padding-right: 5.5rem !important;
  padding-left: 5.5rem !important;
}

.px-23 {
  padding-right: 5.75rem !important;
  padding-left: 5.75rem !important;
}

.px-24 {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}

.px-25 {
  padding-right: 6.25rem !important;
  padding-left: 6.25rem !important;
}

.px-26 {
  padding-right: 6.5rem !important;
  padding-left: 6.5rem !important;
}

.px-27 {
  padding-right: 6.75rem !important;
  padding-left: 6.75rem !important;
}

.px-28 {
  padding-right: 7rem !important;
  padding-left: 7rem !important;
}

.px-29 {
  padding-right: 7.25rem !important;
  padding-left: 7.25rem !important;
}

.px-30 {
  padding-right: 7.5rem !important;
  padding-left: 7.5rem !important;
}

.px-31 {
  padding-right: 7.75rem !important;
  padding-left: 7.75rem !important;
}

.px-32 {
  padding-right: 8.25rem !important;
  padding-left: 8.25rem !important;
}

.px-33 {
  padding-right: 8.5rem !important;
  padding-left: 8.5rem !important;
}

.px-34 {
  padding-right: 8.75rem !important;
  padding-left: 8.75rem !important;
}

.px-35 {
  padding-right: 9rem !important;
  padding-left: 9rem !important;
}

.px-36 {
  padding-right: 9.25rem !important;
  padding-left: 9.25rem !important;
}

.px-37 {
  padding-right: 9.5rem !important;
  padding-left: 9.5rem !important;
}

.px-38 {
  padding-right: 9.75rem !important;
  padding-left: 9.75rem !important;
}

.px-39 {
  padding-right: 10rem !important;
  padding-left: 10rem !important;
}

.px-40 {
  padding-right: 10.25rem !important;
  padding-left: 10.25rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-7 {
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
}

.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-9 {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}

.py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-11 {
  padding-top: 2.75rem !important;
  padding-bottom: 2.75rem !important;
}

.py-12 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-13 {
  padding-top: 3.25rem !important;
  padding-bottom: 3.25rem !important;
}

.py-14 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-15 {
  padding-top: 3.75rem !important;
  padding-bottom: 3.75rem !important;
}

.py-16 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-17 {
  padding-top: 4.25rem !important;
  padding-bottom: 4.25rem !important;
}

.py-18 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.py-19 {
  padding-top: 4.75rem !important;
  padding-bottom: 4.75rem !important;
}

.py-20 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-21 {
  padding-top: 5.25rem !important;
  padding-bottom: 5.25rem !important;
}

.py-22 {
  padding-top: 5.5rem !important;
  padding-bottom: 5.5rem !important;
}

.py-23 {
  padding-top: 5.75rem !important;
  padding-bottom: 5.75rem !important;
}

.py-24 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.py-25 {
  padding-top: 6.25rem !important;
  padding-bottom: 6.25rem !important;
}

.py-26 {
  padding-top: 6.5rem !important;
  padding-bottom: 6.5rem !important;
}

.py-27 {
  padding-top: 6.75rem !important;
  padding-bottom: 6.75rem !important;
}

.py-28 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}

.py-29 {
  padding-top: 7.25rem !important;
  padding-bottom: 7.25rem !important;
}

.py-30 {
  padding-top: 7.5rem !important;
  padding-bottom: 7.5rem !important;
}

.py-31 {
  padding-top: 7.75rem !important;
  padding-bottom: 7.75rem !important;
}

.py-32 {
  padding-top: 8.25rem !important;
  padding-bottom: 8.25rem !important;
}

.py-33 {
  padding-top: 8.5rem !important;
  padding-bottom: 8.5rem !important;
}

.py-34 {
  padding-top: 8.75rem !important;
  padding-bottom: 8.75rem !important;
}

.py-35 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important;
}

.py-36 {
  padding-top: 9.25rem !important;
  padding-bottom: 9.25rem !important;
}

.py-37 {
  padding-top: 9.5rem !important;
  padding-bottom: 9.5rem !important;
}

.py-38 {
  padding-top: 9.75rem !important;
  padding-bottom: 9.75rem !important;
}

.py-39 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.py-40 {
  padding-top: 10.25rem !important;
  padding-bottom: 10.25rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 0.75rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pt-5 {
  padding-top: 1.25rem !important;
}

.pt-6 {
  padding-top: 1.5rem !important;
}

.pt-7 {
  padding-top: 1.75rem !important;
}

.pt-8 {
  padding-top: 2rem !important;
}

.pt-9 {
  padding-top: 2.25rem !important;
}

.pt-10 {
  padding-top: 2.5rem !important;
}

.pt-11 {
  padding-top: 2.75rem !important;
}

.pt-12 {
  padding-top: 3rem !important;
}

.pt-13 {
  padding-top: 3.25rem !important;
}

.pt-14 {
  padding-top: 3.5rem !important;
}

.pt-15 {
  padding-top: 3.75rem !important;
}

.pt-16 {
  padding-top: 4rem !important;
}

.pt-17 {
  padding-top: 4.25rem !important;
}

.pt-18 {
  padding-top: 4.5rem !important;
}

.pt-19 {
  padding-top: 4.75rem !important;
}

.pt-20 {
  padding-top: 5rem !important;
}

.pt-21 {
  padding-top: 5.25rem !important;
}

.pt-22 {
  padding-top: 5.5rem !important;
}

.pt-23 {
  padding-top: 5.75rem !important;
}

.pt-24 {
  padding-top: 6rem !important;
}

.pt-25 {
  padding-top: 6.25rem !important;
}

.pt-26 {
  padding-top: 6.5rem !important;
}

.pt-27 {
  padding-top: 6.75rem !important;
}

.pt-28 {
  padding-top: 7rem !important;
}

.pt-29 {
  padding-top: 7.25rem !important;
}

.pt-30 {
  padding-top: 7.5rem !important;
}

.pt-31 {
  padding-top: 7.75rem !important;
}

.pt-32 {
  padding-top: 8.25rem !important;
}

.pt-33 {
  padding-top: 8.5rem !important;
}

.pt-34 {
  padding-top: 8.75rem !important;
}

.pt-35 {
  padding-top: 9rem !important;
}

.pt-36 {
  padding-top: 9.25rem !important;
}

.pt-37 {
  padding-top: 9.5rem !important;
}

.pt-38 {
  padding-top: 9.75rem !important;
}

.pt-39 {
  padding-top: 10rem !important;
}

.pt-40 {
  padding-top: 10.25rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 0.75rem !important;
}

.pe-4 {
  padding-right: 1rem !important;
}

.pe-5 {
  padding-right: 1.25rem !important;
}

.pe-6 {
  padding-right: 1.5rem !important;
}

.pe-7 {
  padding-right: 1.75rem !important;
}

.pe-8 {
  padding-right: 2rem !important;
}

.pe-9 {
  padding-right: 2.25rem !important;
}

.pe-10 {
  padding-right: 2.5rem !important;
}

.pe-11 {
  padding-right: 2.75rem !important;
}

.pe-12 {
  padding-right: 3rem !important;
}

.pe-13 {
  padding-right: 3.25rem !important;
}

.pe-14 {
  padding-right: 3.5rem !important;
}

.pe-15 {
  padding-right: 3.75rem !important;
}

.pe-16 {
  padding-right: 4rem !important;
}

.pe-17 {
  padding-right: 4.25rem !important;
}

.pe-18 {
  padding-right: 4.5rem !important;
}

.pe-19 {
  padding-right: 4.75rem !important;
}

.pe-20 {
  padding-right: 5rem !important;
}

.pe-21 {
  padding-right: 5.25rem !important;
}

.pe-22 {
  padding-right: 5.5rem !important;
}

.pe-23 {
  padding-right: 5.75rem !important;
}

.pe-24 {
  padding-right: 6rem !important;
}

.pe-25 {
  padding-right: 6.25rem !important;
}

.pe-26 {
  padding-right: 6.5rem !important;
}

.pe-27 {
  padding-right: 6.75rem !important;
}

.pe-28 {
  padding-right: 7rem !important;
}

.pe-29 {
  padding-right: 7.25rem !important;
}

.pe-30 {
  padding-right: 7.5rem !important;
}

.pe-31 {
  padding-right: 7.75rem !important;
}

.pe-32 {
  padding-right: 8.25rem !important;
}

.pe-33 {
  padding-right: 8.5rem !important;
}

.pe-34 {
  padding-right: 8.75rem !important;
}

.pe-35 {
  padding-right: 9rem !important;
}

.pe-36 {
  padding-right: 9.25rem !important;
}

.pe-37 {
  padding-right: 9.5rem !important;
}

.pe-38 {
  padding-right: 9.75rem !important;
}

.pe-39 {
  padding-right: 10rem !important;
}

.pe-40 {
  padding-right: 10.25rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 0.75rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pb-5 {
  padding-bottom: 1.25rem !important;
}

.pb-6 {
  padding-bottom: 1.5rem !important;
}

.pb-7 {
  padding-bottom: 1.75rem !important;
}

.pb-8 {
  padding-bottom: 2rem !important;
}

.pb-9 {
  padding-bottom: 2.25rem !important;
}

.pb-10 {
  padding-bottom: 2.5rem !important;
}

.pb-11 {
  padding-bottom: 2.75rem !important;
}

.pb-12 {
  padding-bottom: 3rem !important;
}

.pb-13 {
  padding-bottom: 3.25rem !important;
}

.pb-14 {
  padding-bottom: 3.5rem !important;
}

.pb-15 {
  padding-bottom: 3.75rem !important;
}

.pb-16 {
  padding-bottom: 4rem !important;
}

.pb-17 {
  padding-bottom: 4.25rem !important;
}

.pb-18 {
  padding-bottom: 4.5rem !important;
}

.pb-19 {
  padding-bottom: 4.75rem !important;
}

.pb-20 {
  padding-bottom: 5rem !important;
}

.pb-21 {
  padding-bottom: 5.25rem !important;
}

.pb-22 {
  padding-bottom: 5.5rem !important;
}

.pb-23 {
  padding-bottom: 5.75rem !important;
}

.pb-24 {
  padding-bottom: 6rem !important;
}

.pb-25 {
  padding-bottom: 6.25rem !important;
}

.pb-26 {
  padding-bottom: 6.5rem !important;
}

.pb-27 {
  padding-bottom: 6.75rem !important;
}

.pb-28 {
  padding-bottom: 7rem !important;
}

.pb-29 {
  padding-bottom: 7.25rem !important;
}

.pb-30 {
  padding-bottom: 7.5rem !important;
}

.pb-31 {
  padding-bottom: 7.75rem !important;
}

.pb-32 {
  padding-bottom: 8.25rem !important;
}

.pb-33 {
  padding-bottom: 8.5rem !important;
}

.pb-34 {
  padding-bottom: 8.75rem !important;
}

.pb-35 {
  padding-bottom: 9rem !important;
}

.pb-36 {
  padding-bottom: 9.25rem !important;
}

.pb-37 {
  padding-bottom: 9.5rem !important;
}

.pb-38 {
  padding-bottom: 9.75rem !important;
}

.pb-39 {
  padding-bottom: 10rem !important;
}

.pb-40 {
  padding-bottom: 10.25rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 0.75rem !important;
}

.ps-4 {
  padding-left: 1rem !important;
}

.ps-5 {
  padding-left: 1.25rem !important;
}

.ps-6 {
  padding-left: 1.5rem !important;
}

.ps-7 {
  padding-left: 1.75rem !important;
}

.ps-8 {
  padding-left: 2rem !important;
}

.ps-9 {
  padding-left: 2.25rem !important;
}

.ps-10 {
  padding-left: 2.5rem !important;
}

.ps-11 {
  padding-left: 2.75rem !important;
}

.ps-12 {
  padding-left: 3rem !important;
}

.ps-13 {
  padding-left: 3.25rem !important;
}

.ps-14 {
  padding-left: 3.5rem !important;
}

.ps-15 {
  padding-left: 3.75rem !important;
}

.ps-16 {
  padding-left: 4rem !important;
}

.ps-17 {
  padding-left: 4.25rem !important;
}

.ps-18 {
  padding-left: 4.5rem !important;
}

.ps-19 {
  padding-left: 4.75rem !important;
}

.ps-20 {
  padding-left: 5rem !important;
}

.ps-21 {
  padding-left: 5.25rem !important;
}

.ps-22 {
  padding-left: 5.5rem !important;
}

.ps-23 {
  padding-left: 5.75rem !important;
}

.ps-24 {
  padding-left: 6rem !important;
}

.ps-25 {
  padding-left: 6.25rem !important;
}

.ps-26 {
  padding-left: 6.5rem !important;
}

.ps-27 {
  padding-left: 6.75rem !important;
}

.ps-28 {
  padding-left: 7rem !important;
}

.ps-29 {
  padding-left: 7.25rem !important;
}

.ps-30 {
  padding-left: 7.5rem !important;
}

.ps-31 {
  padding-left: 7.75rem !important;
}

.ps-32 {
  padding-left: 8.25rem !important;
}

.ps-33 {
  padding-left: 8.5rem !important;
}

.ps-34 {
  padding-left: 8.75rem !important;
}

.ps-35 {
  padding-left: 9rem !important;
}

.ps-36 {
  padding-left: 9.25rem !important;
}

.ps-37 {
  padding-left: 9.5rem !important;
}

.ps-38 {
  padding-left: 9.75rem !important;
}

.ps-39 {
  padding-left: 10rem !important;
}

.ps-40 {
  padding-left: 10.25rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 0.75rem !important;
}

.gap-4 {
  gap: 1rem !important;
}

.gap-5 {
  gap: 1.25rem !important;
}

.gap-6 {
  gap: 1.5rem !important;
}

.gap-7 {
  gap: 1.75rem !important;
}

.gap-8 {
  gap: 2rem !important;
}

.gap-9 {
  gap: 2.25rem !important;
}

.gap-10 {
  gap: 2.5rem !important;
}

.gap-11 {
  gap: 2.75rem !important;
}

.gap-12 {
  gap: 3rem !important;
}

.gap-13 {
  gap: 3.25rem !important;
}

.gap-14 {
  gap: 3.5rem !important;
}

.gap-15 {
  gap: 3.75rem !important;
}

.gap-16 {
  gap: 4rem !important;
}

.gap-17 {
  gap: 4.25rem !important;
}

.gap-18 {
  gap: 4.5rem !important;
}

.gap-19 {
  gap: 4.75rem !important;
}

.gap-20 {
  gap: 5rem !important;
}

.gap-21 {
  gap: 5.25rem !important;
}

.gap-22 {
  gap: 5.5rem !important;
}

.gap-23 {
  gap: 5.75rem !important;
}

.gap-24 {
  gap: 6rem !important;
}

.gap-25 {
  gap: 6.25rem !important;
}

.gap-26 {
  gap: 6.5rem !important;
}

.gap-27 {
  gap: 6.75rem !important;
}

.gap-28 {
  gap: 7rem !important;
}

.gap-29 {
  gap: 7.25rem !important;
}

.gap-30 {
  gap: 7.5rem !important;
}

.gap-31 {
  gap: 7.75rem !important;
}

.gap-32 {
  gap: 8.25rem !important;
}

.gap-33 {
  gap: 8.5rem !important;
}

.gap-34 {
  gap: 8.75rem !important;
}

.gap-35 {
  gap: 9rem !important;
}

.gap-36 {
  gap: 9.25rem !important;
}

.gap-37 {
  gap: 9.5rem !important;
}

.gap-38 {
  gap: 9.75rem !important;
}

.gap-39 {
  gap: 10rem !important;
}

.gap-40 {
  gap: 10.25rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 0.75rem !important;
}

.row-gap-4 {
  row-gap: 1rem !important;
}

.row-gap-5 {
  row-gap: 1.25rem !important;
}

.row-gap-6 {
  row-gap: 1.5rem !important;
}

.row-gap-7 {
  row-gap: 1.75rem !important;
}

.row-gap-8 {
  row-gap: 2rem !important;
}

.row-gap-9 {
  row-gap: 2.25rem !important;
}

.row-gap-10 {
  row-gap: 2.5rem !important;
}

.row-gap-11 {
  row-gap: 2.75rem !important;
}

.row-gap-12 {
  row-gap: 3rem !important;
}

.row-gap-13 {
  row-gap: 3.25rem !important;
}

.row-gap-14 {
  row-gap: 3.5rem !important;
}

.row-gap-15 {
  row-gap: 3.75rem !important;
}

.row-gap-16 {
  row-gap: 4rem !important;
}

.row-gap-17 {
  row-gap: 4.25rem !important;
}

.row-gap-18 {
  row-gap: 4.5rem !important;
}

.row-gap-19 {
  row-gap: 4.75rem !important;
}

.row-gap-20 {
  row-gap: 5rem !important;
}

.row-gap-21 {
  row-gap: 5.25rem !important;
}

.row-gap-22 {
  row-gap: 5.5rem !important;
}

.row-gap-23 {
  row-gap: 5.75rem !important;
}

.row-gap-24 {
  row-gap: 6rem !important;
}

.row-gap-25 {
  row-gap: 6.25rem !important;
}

.row-gap-26 {
  row-gap: 6.5rem !important;
}

.row-gap-27 {
  row-gap: 6.75rem !important;
}

.row-gap-28 {
  row-gap: 7rem !important;
}

.row-gap-29 {
  row-gap: 7.25rem !important;
}

.row-gap-30 {
  row-gap: 7.5rem !important;
}

.row-gap-31 {
  row-gap: 7.75rem !important;
}

.row-gap-32 {
  row-gap: 8.25rem !important;
}

.row-gap-33 {
  row-gap: 8.5rem !important;
}

.row-gap-34 {
  row-gap: 8.75rem !important;
}

.row-gap-35 {
  row-gap: 9rem !important;
}

.row-gap-36 {
  row-gap: 9.25rem !important;
}

.row-gap-37 {
  row-gap: 9.5rem !important;
}

.row-gap-38 {
  row-gap: 9.75rem !important;
}

.row-gap-39 {
  row-gap: 10rem !important;
}

.row-gap-40 {
  row-gap: 10.25rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 0.75rem !important;
}

.column-gap-4 {
  column-gap: 1rem !important;
}

.column-gap-5 {
  column-gap: 1.25rem !important;
}

.column-gap-6 {
  column-gap: 1.5rem !important;
}

.column-gap-7 {
  column-gap: 1.75rem !important;
}

.column-gap-8 {
  column-gap: 2rem !important;
}

.column-gap-9 {
  column-gap: 2.25rem !important;
}

.column-gap-10 {
  column-gap: 2.5rem !important;
}

.column-gap-11 {
  column-gap: 2.75rem !important;
}

.column-gap-12 {
  column-gap: 3rem !important;
}

.column-gap-13 {
  column-gap: 3.25rem !important;
}

.column-gap-14 {
  column-gap: 3.5rem !important;
}

.column-gap-15 {
  column-gap: 3.75rem !important;
}

.column-gap-16 {
  column-gap: 4rem !important;
}

.column-gap-17 {
  column-gap: 4.25rem !important;
}

.column-gap-18 {
  column-gap: 4.5rem !important;
}

.column-gap-19 {
  column-gap: 4.75rem !important;
}

.column-gap-20 {
  column-gap: 5rem !important;
}

.column-gap-21 {
  column-gap: 5.25rem !important;
}

.column-gap-22 {
  column-gap: 5.5rem !important;
}

.column-gap-23 {
  column-gap: 5.75rem !important;
}

.column-gap-24 {
  column-gap: 6rem !important;
}

.column-gap-25 {
  column-gap: 6.25rem !important;
}

.column-gap-26 {
  column-gap: 6.5rem !important;
}

.column-gap-27 {
  column-gap: 6.75rem !important;
}

.column-gap-28 {
  column-gap: 7rem !important;
}

.column-gap-29 {
  column-gap: 7.25rem !important;
}

.column-gap-30 {
  column-gap: 7.5rem !important;
}

.column-gap-31 {
  column-gap: 7.75rem !important;
}

.column-gap-32 {
  column-gap: 8.25rem !important;
}

.column-gap-33 {
  column-gap: 8.5rem !important;
}

.column-gap-34 {
  column-gap: 8.75rem !important;
}

.column-gap-35 {
  column-gap: 9rem !important;
}

.column-gap-36 {
  column-gap: 9.25rem !important;
}

.column-gap-37 {
  column-gap: 9.5rem !important;
}

.column-gap-38 {
  column-gap: 9.75rem !important;
}

.column-gap-39 {
  column-gap: 10rem !important;
}

.column-gap-40 {
  column-gap: 10.25rem !important;
}

.font-monospace {
  font-family: var(--gen-font-monospace) !important;
}

.fs-1 {
  font-size: clamp(2.25rem, 1.7143rem + 1.7143vw, 3rem) !important;
}

.fs-2 {
  font-size: clamp(2rem, 1.6429rem + 1.1429vw, 2.5rem) !important;
}

.fs-3 {
  font-size: clamp(1.75rem, 1.5714rem + 0.5714vw, 2rem) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1.125rem !important;
}

.fs-sm {
  font-size: 0.875rem !important;
}

.fs-lg {
  font-size: 1.125rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --gen-text-opacity: 1;
  color: rgba(var(--gen-primary-rgb), var(--gen-text-opacity)) !important;
}

.text-secondary {
  --gen-text-opacity: 1;
  color: rgba(var(--gen-secondary-rgb), var(--gen-text-opacity)) !important;
}

.text-success {
  --gen-text-opacity: 1;
  color: rgba(var(--gen-success-rgb), var(--gen-text-opacity)) !important;
}

.text-info {
  --gen-text-opacity: 1;
  color: rgba(var(--gen-info-rgb), var(--gen-text-opacity)) !important;
}

.text-warning {
  --gen-text-opacity: 1;
  color: rgba(var(--gen-warning-rgb), var(--gen-text-opacity)) !important;
}

.text-danger {
  --gen-text-opacity: 1;
  color: rgba(var(--gen-danger-rgb), var(--gen-text-opacity)) !important;
}

.text-light {
  --gen-text-opacity: 1;
  color: rgba(var(--gen-light-rgb), var(--gen-text-opacity)) !important;
}

.text-dark {
  --gen-text-opacity: 1;
  color: rgba(var(--gen-dark-rgb), var(--gen-text-opacity)) !important;
}

.text-white {
  --gen-text-opacity: 1;
  color: rgba(var(--gen-white-rgb), var(--gen-text-opacity)) !important;
}

.text-black {
  --gen-text-opacity: 1;
  color: rgba(var(--gen-black-rgb), var(--gen-text-opacity)) !important;
}

.text-yellow {
  --gen-text-opacity: 1;
  color: rgba(var(--gen-yellow-rgb), var(--gen-text-opacity)) !important;
}

.text-blue {
  --gen-text-opacity: 1;
  color: rgba(var(--gen-blue-rgb), var(--gen-text-opacity)) !important;
}

.text-light-blue {
  --gen-text-opacity: 1;
  color: rgba(var(--gen-light-blue-rgb), var(--gen-text-opacity)) !important;
}

.text-light-blue-2 {
  --gen-text-opacity: 1;
  color: rgba(var(--gen-light-blue-2-rgb), var(--gen-text-opacity)) !important;
}

.text-light-blue-3 {
  --gen-text-opacity: 1;
  color: rgba(var(--gen-light-blue-3-rgb), var(--gen-text-opacity)) !important;
}

.text-light-blue-4 {
  --gen-text-opacity: 1;
  color: rgba(var(--gen-light-blue-4-rgb), var(--gen-text-opacity)) !important;
}

.text-light-blue-5 {
  --gen-text-opacity: 1;
  color: rgba(var(--gen-light-blue-5-rgb), var(--gen-text-opacity)) !important;
}

.text-light-blue-6 {
  --gen-text-opacity: 1;
  color: rgba(var(--gen-light-blue-6-rgb), var(--gen-text-opacity)) !important;
}

.text-dark-blue {
  --gen-text-opacity: 1;
  color: rgba(var(--gen-dark-blue-rgb), var(--gen-text-opacity)) !important;
}

.text-dark-blue-2 {
  --gen-text-opacity: 1;
  color: rgba(var(--gen-dark-blue-2-rgb), var(--gen-text-opacity)) !important;
}

.text-dark-blue-3 {
  --gen-text-opacity: 1;
  color: rgba(var(--gen-dark-blue-3-rgb), var(--gen-text-opacity)) !important;
}

.text-dark-blue-4 {
  --gen-text-opacity: 1;
  color: rgba(var(--gen-dark-blue-4-rgb), var(--gen-text-opacity)) !important;
}

.text-dark-blue-5 {
  --gen-text-opacity: 1;
  color: rgba(var(--gen-dark-blue-5-rgb), var(--gen-text-opacity)) !important;
}

.text-primary-dark {
  --gen-text-opacity: 1;
  color: rgba(var(--gen-primary-dark-rgb), var(--gen-text-opacity)) !important;
}

.text-body {
  --gen-text-opacity: 1;
  color: rgba(var(--gen-body-color-rgb), var(--gen-text-opacity)) !important;
}

.text-muted {
  --gen-text-opacity: 1;
  color: var(--gen-secondary-color) !important;
}

.text-black-50 {
  --gen-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --gen-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --gen-text-opacity: 1;
  color: var(--gen-secondary-color) !important;
}

.text-body-tertiary {
  --gen-text-opacity: 1;
  color: var(--gen-tertiary-color) !important;
}

.text-body-emphasis {
  --gen-text-opacity: 1;
  color: var(--gen-emphasis-color) !important;
}

.text-reset {
  --gen-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --gen-text-opacity: 0.25;
}

.text-opacity-50 {
  --gen-text-opacity: 0.5;
}

.text-opacity-75 {
  --gen-text-opacity: 0.75;
}

.text-opacity-100 {
  --gen-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--gen-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--gen-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--gen-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--gen-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--gen-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--gen-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--gen-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--gen-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --gen-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --gen-link-opacity: 0.1;
}

.link-opacity-25 {
  --gen-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --gen-link-opacity: 0.25;
}

.link-opacity-50 {
  --gen-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --gen-link-opacity: 0.5;
}

.link-opacity-75 {
  --gen-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --gen-link-opacity: 0.75;
}

.link-opacity-100 {
  --gen-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --gen-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --gen-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--gen-primary-rgb), var(--gen-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --gen-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--gen-secondary-rgb), var(--gen-link-underline-opacity)) !important;
}

.link-underline-success {
  --gen-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--gen-success-rgb), var(--gen-link-underline-opacity)) !important;
}

.link-underline-info {
  --gen-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--gen-info-rgb), var(--gen-link-underline-opacity)) !important;
}

.link-underline-warning {
  --gen-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--gen-warning-rgb), var(--gen-link-underline-opacity)) !important;
}

.link-underline-danger {
  --gen-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--gen-danger-rgb), var(--gen-link-underline-opacity)) !important;
}

.link-underline-light {
  --gen-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--gen-light-rgb), var(--gen-link-underline-opacity)) !important;
}

.link-underline-dark {
  --gen-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--gen-dark-rgb), var(--gen-link-underline-opacity)) !important;
}

.link-underline-white {
  --gen-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--gen-white-rgb), var(--gen-link-underline-opacity)) !important;
}

.link-underline-black {
  --gen-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--gen-black-rgb), var(--gen-link-underline-opacity)) !important;
}

.link-underline-yellow {
  --gen-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--gen-yellow-rgb), var(--gen-link-underline-opacity)) !important;
}

.link-underline-blue {
  --gen-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--gen-blue-rgb), var(--gen-link-underline-opacity)) !important;
}

.link-underline-light-blue {
  --gen-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--gen-light-blue-rgb), var(--gen-link-underline-opacity)) !important;
}

.link-underline-light-blue-2 {
  --gen-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--gen-light-blue-2-rgb), var(--gen-link-underline-opacity)) !important;
}

.link-underline-light-blue-3 {
  --gen-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--gen-light-blue-3-rgb), var(--gen-link-underline-opacity)) !important;
}

.link-underline-light-blue-4 {
  --gen-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--gen-light-blue-4-rgb), var(--gen-link-underline-opacity)) !important;
}

.link-underline-light-blue-5 {
  --gen-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--gen-light-blue-5-rgb), var(--gen-link-underline-opacity)) !important;
}

.link-underline-light-blue-6 {
  --gen-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--gen-light-blue-6-rgb), var(--gen-link-underline-opacity)) !important;
}

.link-underline-dark-blue {
  --gen-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--gen-dark-blue-rgb), var(--gen-link-underline-opacity)) !important;
}

.link-underline-dark-blue-2 {
  --gen-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--gen-dark-blue-2-rgb), var(--gen-link-underline-opacity)) !important;
}

.link-underline-dark-blue-3 {
  --gen-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--gen-dark-blue-3-rgb), var(--gen-link-underline-opacity)) !important;
}

.link-underline-dark-blue-4 {
  --gen-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--gen-dark-blue-4-rgb), var(--gen-link-underline-opacity)) !important;
}

.link-underline-dark-blue-5 {
  --gen-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--gen-dark-blue-5-rgb), var(--gen-link-underline-opacity)) !important;
}

.link-underline-primary-dark {
  --gen-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--gen-primary-dark-rgb), var(--gen-link-underline-opacity)) !important;
}

.link-underline {
  --gen-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--gen-link-color-rgb), var(--gen-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --gen-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --gen-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --gen-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --gen-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --gen-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --gen-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --gen-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --gen-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --gen-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --gen-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --gen-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --gen-link-underline-opacity: 1;
}

.bg-primary {
  --gen-bg-opacity: 1;
  background-color: rgba(var(--gen-primary-rgb), var(--gen-bg-opacity)) !important;
}

.bg-secondary {
  --gen-bg-opacity: 1;
  background-color: rgba(var(--gen-secondary-rgb), var(--gen-bg-opacity)) !important;
}

.bg-success {
  --gen-bg-opacity: 1;
  background-color: rgba(var(--gen-success-rgb), var(--gen-bg-opacity)) !important;
}

.bg-info {
  --gen-bg-opacity: 1;
  background-color: rgba(var(--gen-info-rgb), var(--gen-bg-opacity)) !important;
}

.bg-warning {
  --gen-bg-opacity: 1;
  background-color: rgba(var(--gen-warning-rgb), var(--gen-bg-opacity)) !important;
}

.bg-danger {
  --gen-bg-opacity: 1;
  background-color: rgba(var(--gen-danger-rgb), var(--gen-bg-opacity)) !important;
}

.bg-light {
  --gen-bg-opacity: 1;
  background-color: rgba(var(--gen-light-rgb), var(--gen-bg-opacity)) !important;
}

.bg-dark {
  --gen-bg-opacity: 1;
  background-color: rgba(var(--gen-dark-rgb), var(--gen-bg-opacity)) !important;
}

.bg-white {
  --gen-bg-opacity: 1;
  background-color: rgba(var(--gen-white-rgb), var(--gen-bg-opacity)) !important;
}

.bg-black {
  --gen-bg-opacity: 1;
  background-color: rgba(var(--gen-black-rgb), var(--gen-bg-opacity)) !important;
}

.bg-yellow {
  --gen-bg-opacity: 1;
  background-color: rgba(var(--gen-yellow-rgb), var(--gen-bg-opacity)) !important;
}

.bg-blue {
  --gen-bg-opacity: 1;
  background-color: rgba(var(--gen-blue-rgb), var(--gen-bg-opacity)) !important;
}

.bg-light-blue {
  --gen-bg-opacity: 1;
  background-color: rgba(var(--gen-light-blue-rgb), var(--gen-bg-opacity)) !important;
}

.bg-light-blue-2 {
  --gen-bg-opacity: 1;
  background-color: rgba(var(--gen-light-blue-2-rgb), var(--gen-bg-opacity)) !important;
}

.bg-light-blue-3 {
  --gen-bg-opacity: 1;
  background-color: rgba(var(--gen-light-blue-3-rgb), var(--gen-bg-opacity)) !important;
}

.bg-light-blue-4 {
  --gen-bg-opacity: 1;
  background-color: rgba(var(--gen-light-blue-4-rgb), var(--gen-bg-opacity)) !important;
}

.bg-light-blue-5 {
  --gen-bg-opacity: 1;
  background-color: rgba(var(--gen-light-blue-5-rgb), var(--gen-bg-opacity)) !important;
}

.bg-light-blue-6 {
  --gen-bg-opacity: 1;
  background-color: rgba(var(--gen-light-blue-6-rgb), var(--gen-bg-opacity)) !important;
}

.bg-dark-blue {
  --gen-bg-opacity: 1;
  background-color: rgba(var(--gen-dark-blue-rgb), var(--gen-bg-opacity)) !important;
}

.bg-dark-blue-2 {
  --gen-bg-opacity: 1;
  background-color: rgba(var(--gen-dark-blue-2-rgb), var(--gen-bg-opacity)) !important;
}

.bg-dark-blue-3 {
  --gen-bg-opacity: 1;
  background-color: rgba(var(--gen-dark-blue-3-rgb), var(--gen-bg-opacity)) !important;
}

.bg-dark-blue-4 {
  --gen-bg-opacity: 1;
  background-color: rgba(var(--gen-dark-blue-4-rgb), var(--gen-bg-opacity)) !important;
}

.bg-dark-blue-5 {
  --gen-bg-opacity: 1;
  background-color: rgba(var(--gen-dark-blue-5-rgb), var(--gen-bg-opacity)) !important;
}

.bg-primary-dark {
  --gen-bg-opacity: 1;
  background-color: rgba(var(--gen-primary-dark-rgb), var(--gen-bg-opacity)) !important;
}

.bg-body {
  --gen-bg-opacity: 1;
  background-color: rgba(var(--gen-body-bg-rgb), var(--gen-bg-opacity)) !important;
}

.bg-transparent {
  --gen-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --gen-bg-opacity: 1;
  background-color: rgba(var(--gen-secondary-bg-rgb), var(--gen-bg-opacity)) !important;
}

.bg-body-tertiary {
  --gen-bg-opacity: 1;
  background-color: rgba(var(--gen-tertiary-bg-rgb), var(--gen-bg-opacity)) !important;
}

.bg-opacity-10 {
  --gen-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --gen-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --gen-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --gen-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --gen-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--gen-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--gen-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--gen-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--gen-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--gen-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--gen-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--gen-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--gen-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--gen-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--gen-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--gen-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--gen-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--gen-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--gen-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--gen-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--gen-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--gen-border-radius) !important;
  border-top-right-radius: var(--gen-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--gen-border-radius-sm) !important;
  border-top-right-radius: var(--gen-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--gen-border-radius) !important;
  border-top-right-radius: var(--gen-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--gen-border-radius-lg) !important;
  border-top-right-radius: var(--gen-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--gen-border-radius-xl) !important;
  border-top-right-radius: var(--gen-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--gen-border-radius-xxl) !important;
  border-top-right-radius: var(--gen-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--gen-border-radius-pill) !important;
  border-top-right-radius: var(--gen-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--gen-border-radius) !important;
  border-bottom-right-radius: var(--gen-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--gen-border-radius-sm) !important;
  border-bottom-right-radius: var(--gen-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--gen-border-radius) !important;
  border-bottom-right-radius: var(--gen-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--gen-border-radius-lg) !important;
  border-bottom-right-radius: var(--gen-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--gen-border-radius-xl) !important;
  border-bottom-right-radius: var(--gen-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--gen-border-radius-xxl) !important;
  border-bottom-right-radius: var(--gen-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--gen-border-radius-pill) !important;
  border-bottom-right-radius: var(--gen-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--gen-border-radius) !important;
  border-bottom-left-radius: var(--gen-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--gen-border-radius-sm) !important;
  border-bottom-left-radius: var(--gen-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--gen-border-radius) !important;
  border-bottom-left-radius: var(--gen-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--gen-border-radius-lg) !important;
  border-bottom-left-radius: var(--gen-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--gen-border-radius-xl) !important;
  border-bottom-left-radius: var(--gen-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--gen-border-radius-xxl) !important;
  border-bottom-left-radius: var(--gen-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--gen-border-radius-pill) !important;
  border-bottom-left-radius: var(--gen-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--gen-border-radius) !important;
  border-top-left-radius: var(--gen-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--gen-border-radius-sm) !important;
  border-top-left-radius: var(--gen-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--gen-border-radius) !important;
  border-top-left-radius: var(--gen-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--gen-border-radius-lg) !important;
  border-top-left-radius: var(--gen-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--gen-border-radius-xl) !important;
  border-top-left-radius: var(--gen-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--gen-border-radius-xxl) !important;
  border-top-left-radius: var(--gen-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--gen-border-radius-pill) !important;
  border-top-left-radius: var(--gen-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-sm-contain {
    object-fit: contain !important;
  }
  .object-sm-cover {
    object-fit: cover !important;
  }
  .object-sm-fill {
    object-fit: fill !important;
  }
  .object-sm-scale {
    object-fit: scale-down !important;
  }
  .object-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .w-sm-0 {
    width: 0 !important;
  }
  .w-sm-px {
    width: 1px !important;
  }
  .w-sm-2px {
    width: 2px !important;
  }
  .w-sm-1 {
    width: 0.25rem !important;
  }
  .w-sm-2 {
    width: 0.5rem !important;
  }
  .w-sm-3 {
    width: 0.75rem !important;
  }
  .w-sm-4 {
    width: 1rem !important;
  }
  .w-sm-5 {
    width: 1.25rem !important;
  }
  .w-sm-6 {
    width: 1.5rem !important;
  }
  .w-sm-7 {
    width: 1.75rem !important;
  }
  .w-sm-8 {
    width: 2rem !important;
  }
  .w-sm-9 {
    width: 2.25rem !important;
  }
  .w-sm-10 {
    width: 2.5rem !important;
  }
  .w-sm-11 {
    width: 2.75rem !important;
  }
  .w-sm-12 {
    width: 3rem !important;
  }
  .w-sm-13 {
    width: 3.25rem !important;
  }
  .w-sm-14 {
    width: 3.5rem !important;
  }
  .w-sm-15 {
    width: 3.75rem !important;
  }
  .w-sm-16 {
    width: 4rem !important;
  }
  .w-sm-17 {
    width: 4.25rem !important;
  }
  .w-sm-18 {
    width: 4.5rem !important;
  }
  .w-sm-19 {
    width: 4.75rem !important;
  }
  .w-sm-20 {
    width: 5rem !important;
  }
  .w-sm-21 {
    width: 5.25rem !important;
  }
  .w-sm-22 {
    width: 5.5rem !important;
  }
  .w-sm-23 {
    width: 5.75rem !important;
  }
  .w-sm-24 {
    width: 6rem !important;
  }
  .w-sm-25 {
    width: 6.25rem !important;
  }
  .w-sm-26 {
    width: 6.5rem !important;
  }
  .w-sm-27 {
    width: 6.75rem !important;
  }
  .w-sm-28 {
    width: 7rem !important;
  }
  .w-sm-29 {
    width: 7.25rem !important;
  }
  .w-sm-30 {
    width: 7.5rem !important;
  }
  .w-sm-31 {
    width: 7.75rem !important;
  }
  .w-sm-32 {
    width: 8rem !important;
  }
  .w-sm-quarter {
    width: 25% !important;
  }
  .w-sm-half {
    width: 50% !important;
  }
  .w-sm-3quarter {
    width: 75% !important;
  }
  .w-sm-full {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .h-sm-0 {
    height: 0 !important;
  }
  .h-sm-px {
    height: 1px !important;
  }
  .h-sm-2px {
    height: 2px !important;
  }
  .h-sm-1 {
    height: 0.25rem !important;
  }
  .h-sm-2 {
    height: 0.5rem !important;
  }
  .h-sm-3 {
    height: 0.75rem !important;
  }
  .h-sm-4 {
    height: 1rem !important;
  }
  .h-sm-5 {
    height: 1.25rem !important;
  }
  .h-sm-6 {
    height: 1.5rem !important;
  }
  .h-sm-7 {
    height: 1.75rem !important;
  }
  .h-sm-8 {
    height: 2rem !important;
  }
  .h-sm-9 {
    height: 2.25rem !important;
  }
  .h-sm-10 {
    height: 2.5rem !important;
  }
  .h-sm-11 {
    height: 2.75rem !important;
  }
  .h-sm-12 {
    height: 3rem !important;
  }
  .h-sm-13 {
    height: 3.25rem !important;
  }
  .h-sm-14 {
    height: 3.5rem !important;
  }
  .h-sm-15 {
    height: 3.75rem !important;
  }
  .h-sm-16 {
    height: 4rem !important;
  }
  .h-sm-17 {
    height: 4.25rem !important;
  }
  .h-sm-18 {
    height: 4.5rem !important;
  }
  .h-sm-19 {
    height: 4.75rem !important;
  }
  .h-sm-20 {
    height: 5rem !important;
  }
  .h-sm-21 {
    height: 5.25rem !important;
  }
  .h-sm-22 {
    height: 5.5rem !important;
  }
  .h-sm-23 {
    height: 5.75rem !important;
  }
  .h-sm-24 {
    height: 6rem !important;
  }
  .h-sm-25 {
    height: 6.25rem !important;
  }
  .h-sm-26 {
    height: 6.5rem !important;
  }
  .h-sm-27 {
    height: 6.75rem !important;
  }
  .h-sm-28 {
    height: 7rem !important;
  }
  .h-sm-29 {
    height: 7.25rem !important;
  }
  .h-sm-30 {
    height: 7.5rem !important;
  }
  .h-sm-31 {
    height: 7.75rem !important;
  }
  .h-sm-32 {
    height: 8rem !important;
  }
  .h-sm-quarter {
    height: 25% !important;
  }
  .h-sm-half {
    height: 50% !important;
  }
  .h-sm-3quarter {
    height: 75% !important;
  }
  .h-sm-full {
    height: 100% !important;
  }
  .h-sm-auto {
    height: auto !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-sm-start {
    justify-content: flex-start !important;
  }
  .justify-sm-end {
    justify-content: flex-end !important;
  }
  .justify-sm-center {
    justify-content: center !important;
  }
  .justify-sm-between {
    justify-content: space-between !important;
  }
  .justify-sm-around {
    justify-content: space-around !important;
  }
  .justify-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-sm-start {
    align-items: flex-start !important;
  }
  .align-sm-end {
    align-items: flex-end !important;
  }
  .align-sm-center {
    align-items: center !important;
  }
  .align-sm-baseline {
    align-items: baseline !important;
  }
  .align-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 0.75rem !important;
  }
  .m-sm-4 {
    margin: 1rem !important;
  }
  .m-sm-5 {
    margin: 1.25rem !important;
  }
  .m-sm-6 {
    margin: 1.5rem !important;
  }
  .m-sm-7 {
    margin: 1.75rem !important;
  }
  .m-sm-8 {
    margin: 2rem !important;
  }
  .m-sm-9 {
    margin: 2.25rem !important;
  }
  .m-sm-10 {
    margin: 2.5rem !important;
  }
  .m-sm-11 {
    margin: 2.75rem !important;
  }
  .m-sm-12 {
    margin: 3rem !important;
  }
  .m-sm-13 {
    margin: 3.25rem !important;
  }
  .m-sm-14 {
    margin: 3.5rem !important;
  }
  .m-sm-15 {
    margin: 3.75rem !important;
  }
  .m-sm-16 {
    margin: 4rem !important;
  }
  .m-sm-17 {
    margin: 4.25rem !important;
  }
  .m-sm-18 {
    margin: 4.5rem !important;
  }
  .m-sm-19 {
    margin: 4.75rem !important;
  }
  .m-sm-20 {
    margin: 5rem !important;
  }
  .m-sm-21 {
    margin: 5.25rem !important;
  }
  .m-sm-22 {
    margin: 5.5rem !important;
  }
  .m-sm-23 {
    margin: 5.75rem !important;
  }
  .m-sm-24 {
    margin: 6rem !important;
  }
  .m-sm-25 {
    margin: 6.25rem !important;
  }
  .m-sm-26 {
    margin: 6.5rem !important;
  }
  .m-sm-27 {
    margin: 6.75rem !important;
  }
  .m-sm-28 {
    margin: 7rem !important;
  }
  .m-sm-29 {
    margin: 7.25rem !important;
  }
  .m-sm-30 {
    margin: 7.5rem !important;
  }
  .m-sm-31 {
    margin: 7.75rem !important;
  }
  .m-sm-32 {
    margin: 8.25rem !important;
  }
  .m-sm-33 {
    margin: 8.5rem !important;
  }
  .m-sm-34 {
    margin: 8.75rem !important;
  }
  .m-sm-35 {
    margin: 9rem !important;
  }
  .m-sm-36 {
    margin: 9.25rem !important;
  }
  .m-sm-37 {
    margin: 9.5rem !important;
  }
  .m-sm-38 {
    margin: 9.75rem !important;
  }
  .m-sm-39 {
    margin: 10rem !important;
  }
  .m-sm-40 {
    margin: 10.25rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-sm-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-sm-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }
  .mx-sm-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-sm-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-sm-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-sm-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }
  .mx-sm-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }
  .mx-sm-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-sm-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-sm-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-sm-17 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }
  .mx-sm-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-sm-19 {
    margin-right: 4.75rem !important;
    margin-left: 4.75rem !important;
  }
  .mx-sm-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-sm-21 {
    margin-right: 5.25rem !important;
    margin-left: 5.25rem !important;
  }
  .mx-sm-22 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }
  .mx-sm-23 {
    margin-right: 5.75rem !important;
    margin-left: 5.75rem !important;
  }
  .mx-sm-24 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-sm-25 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }
  .mx-sm-26 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }
  .mx-sm-27 {
    margin-right: 6.75rem !important;
    margin-left: 6.75rem !important;
  }
  .mx-sm-28 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-sm-29 {
    margin-right: 7.25rem !important;
    margin-left: 7.25rem !important;
  }
  .mx-sm-30 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-sm-31 {
    margin-right: 7.75rem !important;
    margin-left: 7.75rem !important;
  }
  .mx-sm-32 {
    margin-right: 8.25rem !important;
    margin-left: 8.25rem !important;
  }
  .mx-sm-33 {
    margin-right: 8.5rem !important;
    margin-left: 8.5rem !important;
  }
  .mx-sm-34 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important;
  }
  .mx-sm-35 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-sm-36 {
    margin-right: 9.25rem !important;
    margin-left: 9.25rem !important;
  }
  .mx-sm-37 {
    margin-right: 9.5rem !important;
    margin-left: 9.5rem !important;
  }
  .mx-sm-38 {
    margin-right: 9.75rem !important;
    margin-left: 9.75rem !important;
  }
  .mx-sm-39 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-sm-40 {
    margin-right: 10.25rem !important;
    margin-left: 10.25rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-sm-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-sm-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }
  .my-sm-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-sm-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-sm-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-sm-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }
  .my-sm-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }
  .my-sm-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-sm-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-sm-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-sm-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }
  .my-sm-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-sm-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }
  .my-sm-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-sm-21 {
    margin-top: 5.25rem !important;
    margin-bottom: 5.25rem !important;
  }
  .my-sm-22 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }
  .my-sm-23 {
    margin-top: 5.75rem !important;
    margin-bottom: 5.75rem !important;
  }
  .my-sm-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-sm-25 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }
  .my-sm-26 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }
  .my-sm-27 {
    margin-top: 6.75rem !important;
    margin-bottom: 6.75rem !important;
  }
  .my-sm-28 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-sm-29 {
    margin-top: 7.25rem !important;
    margin-bottom: 7.25rem !important;
  }
  .my-sm-30 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-sm-31 {
    margin-top: 7.75rem !important;
    margin-bottom: 7.75rem !important;
  }
  .my-sm-32 {
    margin-top: 8.25rem !important;
    margin-bottom: 8.25rem !important;
  }
  .my-sm-33 {
    margin-top: 8.5rem !important;
    margin-bottom: 8.5rem !important;
  }
  .my-sm-34 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important;
  }
  .my-sm-35 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-sm-36 {
    margin-top: 9.25rem !important;
    margin-bottom: 9.25rem !important;
  }
  .my-sm-37 {
    margin-top: 9.5rem !important;
    margin-bottom: 9.5rem !important;
  }
  .my-sm-38 {
    margin-top: 9.75rem !important;
    margin-bottom: 9.75rem !important;
  }
  .my-sm-39 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-sm-40 {
    margin-top: 10.25rem !important;
    margin-bottom: 10.25rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 0.75rem !important;
  }
  .mt-sm-4 {
    margin-top: 1rem !important;
  }
  .mt-sm-5 {
    margin-top: 1.25rem !important;
  }
  .mt-sm-6 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-7 {
    margin-top: 1.75rem !important;
  }
  .mt-sm-8 {
    margin-top: 2rem !important;
  }
  .mt-sm-9 {
    margin-top: 2.25rem !important;
  }
  .mt-sm-10 {
    margin-top: 2.5rem !important;
  }
  .mt-sm-11 {
    margin-top: 2.75rem !important;
  }
  .mt-sm-12 {
    margin-top: 3rem !important;
  }
  .mt-sm-13 {
    margin-top: 3.25rem !important;
  }
  .mt-sm-14 {
    margin-top: 3.5rem !important;
  }
  .mt-sm-15 {
    margin-top: 3.75rem !important;
  }
  .mt-sm-16 {
    margin-top: 4rem !important;
  }
  .mt-sm-17 {
    margin-top: 4.25rem !important;
  }
  .mt-sm-18 {
    margin-top: 4.5rem !important;
  }
  .mt-sm-19 {
    margin-top: 4.75rem !important;
  }
  .mt-sm-20 {
    margin-top: 5rem !important;
  }
  .mt-sm-21 {
    margin-top: 5.25rem !important;
  }
  .mt-sm-22 {
    margin-top: 5.5rem !important;
  }
  .mt-sm-23 {
    margin-top: 5.75rem !important;
  }
  .mt-sm-24 {
    margin-top: 6rem !important;
  }
  .mt-sm-25 {
    margin-top: 6.25rem !important;
  }
  .mt-sm-26 {
    margin-top: 6.5rem !important;
  }
  .mt-sm-27 {
    margin-top: 6.75rem !important;
  }
  .mt-sm-28 {
    margin-top: 7rem !important;
  }
  .mt-sm-29 {
    margin-top: 7.25rem !important;
  }
  .mt-sm-30 {
    margin-top: 7.5rem !important;
  }
  .mt-sm-31 {
    margin-top: 7.75rem !important;
  }
  .mt-sm-32 {
    margin-top: 8.25rem !important;
  }
  .mt-sm-33 {
    margin-top: 8.5rem !important;
  }
  .mt-sm-34 {
    margin-top: 8.75rem !important;
  }
  .mt-sm-35 {
    margin-top: 9rem !important;
  }
  .mt-sm-36 {
    margin-top: 9.25rem !important;
  }
  .mt-sm-37 {
    margin-top: 9.5rem !important;
  }
  .mt-sm-38 {
    margin-top: 9.75rem !important;
  }
  .mt-sm-39 {
    margin-top: 10rem !important;
  }
  .mt-sm-40 {
    margin-top: 10.25rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 0.75rem !important;
  }
  .me-sm-4 {
    margin-right: 1rem !important;
  }
  .me-sm-5 {
    margin-right: 1.25rem !important;
  }
  .me-sm-6 {
    margin-right: 1.5rem !important;
  }
  .me-sm-7 {
    margin-right: 1.75rem !important;
  }
  .me-sm-8 {
    margin-right: 2rem !important;
  }
  .me-sm-9 {
    margin-right: 2.25rem !important;
  }
  .me-sm-10 {
    margin-right: 2.5rem !important;
  }
  .me-sm-11 {
    margin-right: 2.75rem !important;
  }
  .me-sm-12 {
    margin-right: 3rem !important;
  }
  .me-sm-13 {
    margin-right: 3.25rem !important;
  }
  .me-sm-14 {
    margin-right: 3.5rem !important;
  }
  .me-sm-15 {
    margin-right: 3.75rem !important;
  }
  .me-sm-16 {
    margin-right: 4rem !important;
  }
  .me-sm-17 {
    margin-right: 4.25rem !important;
  }
  .me-sm-18 {
    margin-right: 4.5rem !important;
  }
  .me-sm-19 {
    margin-right: 4.75rem !important;
  }
  .me-sm-20 {
    margin-right: 5rem !important;
  }
  .me-sm-21 {
    margin-right: 5.25rem !important;
  }
  .me-sm-22 {
    margin-right: 5.5rem !important;
  }
  .me-sm-23 {
    margin-right: 5.75rem !important;
  }
  .me-sm-24 {
    margin-right: 6rem !important;
  }
  .me-sm-25 {
    margin-right: 6.25rem !important;
  }
  .me-sm-26 {
    margin-right: 6.5rem !important;
  }
  .me-sm-27 {
    margin-right: 6.75rem !important;
  }
  .me-sm-28 {
    margin-right: 7rem !important;
  }
  .me-sm-29 {
    margin-right: 7.25rem !important;
  }
  .me-sm-30 {
    margin-right: 7.5rem !important;
  }
  .me-sm-31 {
    margin-right: 7.75rem !important;
  }
  .me-sm-32 {
    margin-right: 8.25rem !important;
  }
  .me-sm-33 {
    margin-right: 8.5rem !important;
  }
  .me-sm-34 {
    margin-right: 8.75rem !important;
  }
  .me-sm-35 {
    margin-right: 9rem !important;
  }
  .me-sm-36 {
    margin-right: 9.25rem !important;
  }
  .me-sm-37 {
    margin-right: 9.5rem !important;
  }
  .me-sm-38 {
    margin-right: 9.75rem !important;
  }
  .me-sm-39 {
    margin-right: 10rem !important;
  }
  .me-sm-40 {
    margin-right: 10.25rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 1.25rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 1.75rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 2rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 2.25rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 2.5rem !important;
  }
  .mb-sm-11 {
    margin-bottom: 2.75rem !important;
  }
  .mb-sm-12 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-13 {
    margin-bottom: 3.25rem !important;
  }
  .mb-sm-14 {
    margin-bottom: 3.5rem !important;
  }
  .mb-sm-15 {
    margin-bottom: 3.75rem !important;
  }
  .mb-sm-16 {
    margin-bottom: 4rem !important;
  }
  .mb-sm-17 {
    margin-bottom: 4.25rem !important;
  }
  .mb-sm-18 {
    margin-bottom: 4.5rem !important;
  }
  .mb-sm-19 {
    margin-bottom: 4.75rem !important;
  }
  .mb-sm-20 {
    margin-bottom: 5rem !important;
  }
  .mb-sm-21 {
    margin-bottom: 5.25rem !important;
  }
  .mb-sm-22 {
    margin-bottom: 5.5rem !important;
  }
  .mb-sm-23 {
    margin-bottom: 5.75rem !important;
  }
  .mb-sm-24 {
    margin-bottom: 6rem !important;
  }
  .mb-sm-25 {
    margin-bottom: 6.25rem !important;
  }
  .mb-sm-26 {
    margin-bottom: 6.5rem !important;
  }
  .mb-sm-27 {
    margin-bottom: 6.75rem !important;
  }
  .mb-sm-28 {
    margin-bottom: 7rem !important;
  }
  .mb-sm-29 {
    margin-bottom: 7.25rem !important;
  }
  .mb-sm-30 {
    margin-bottom: 7.5rem !important;
  }
  .mb-sm-31 {
    margin-bottom: 7.75rem !important;
  }
  .mb-sm-32 {
    margin-bottom: 8.25rem !important;
  }
  .mb-sm-33 {
    margin-bottom: 8.5rem !important;
  }
  .mb-sm-34 {
    margin-bottom: 8.75rem !important;
  }
  .mb-sm-35 {
    margin-bottom: 9rem !important;
  }
  .mb-sm-36 {
    margin-bottom: 9.25rem !important;
  }
  .mb-sm-37 {
    margin-bottom: 9.5rem !important;
  }
  .mb-sm-38 {
    margin-bottom: 9.75rem !important;
  }
  .mb-sm-39 {
    margin-bottom: 10rem !important;
  }
  .mb-sm-40 {
    margin-bottom: 10.25rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 0.75rem !important;
  }
  .ms-sm-4 {
    margin-left: 1rem !important;
  }
  .ms-sm-5 {
    margin-left: 1.25rem !important;
  }
  .ms-sm-6 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-7 {
    margin-left: 1.75rem !important;
  }
  .ms-sm-8 {
    margin-left: 2rem !important;
  }
  .ms-sm-9 {
    margin-left: 2.25rem !important;
  }
  .ms-sm-10 {
    margin-left: 2.5rem !important;
  }
  .ms-sm-11 {
    margin-left: 2.75rem !important;
  }
  .ms-sm-12 {
    margin-left: 3rem !important;
  }
  .ms-sm-13 {
    margin-left: 3.25rem !important;
  }
  .ms-sm-14 {
    margin-left: 3.5rem !important;
  }
  .ms-sm-15 {
    margin-left: 3.75rem !important;
  }
  .ms-sm-16 {
    margin-left: 4rem !important;
  }
  .ms-sm-17 {
    margin-left: 4.25rem !important;
  }
  .ms-sm-18 {
    margin-left: 4.5rem !important;
  }
  .ms-sm-19 {
    margin-left: 4.75rem !important;
  }
  .ms-sm-20 {
    margin-left: 5rem !important;
  }
  .ms-sm-21 {
    margin-left: 5.25rem !important;
  }
  .ms-sm-22 {
    margin-left: 5.5rem !important;
  }
  .ms-sm-23 {
    margin-left: 5.75rem !important;
  }
  .ms-sm-24 {
    margin-left: 6rem !important;
  }
  .ms-sm-25 {
    margin-left: 6.25rem !important;
  }
  .ms-sm-26 {
    margin-left: 6.5rem !important;
  }
  .ms-sm-27 {
    margin-left: 6.75rem !important;
  }
  .ms-sm-28 {
    margin-left: 7rem !important;
  }
  .ms-sm-29 {
    margin-left: 7.25rem !important;
  }
  .ms-sm-30 {
    margin-left: 7.5rem !important;
  }
  .ms-sm-31 {
    margin-left: 7.75rem !important;
  }
  .ms-sm-32 {
    margin-left: 8.25rem !important;
  }
  .ms-sm-33 {
    margin-left: 8.5rem !important;
  }
  .ms-sm-34 {
    margin-left: 8.75rem !important;
  }
  .ms-sm-35 {
    margin-left: 9rem !important;
  }
  .ms-sm-36 {
    margin-left: 9.25rem !important;
  }
  .ms-sm-37 {
    margin-left: 9.5rem !important;
  }
  .ms-sm-38 {
    margin-left: 9.75rem !important;
  }
  .ms-sm-39 {
    margin-left: 10rem !important;
  }
  .ms-sm-40 {
    margin-left: 10.25rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -0.75rem !important;
  }
  .m-sm-n4 {
    margin: -1rem !important;
  }
  .m-sm-n5 {
    margin: -1.25rem !important;
  }
  .m-sm-n6 {
    margin: -1.5rem !important;
  }
  .m-sm-n7 {
    margin: -1.75rem !important;
  }
  .m-sm-n8 {
    margin: -2rem !important;
  }
  .m-sm-n9 {
    margin: -2.25rem !important;
  }
  .m-sm-n10 {
    margin: -2.5rem !important;
  }
  .m-sm-n11 {
    margin: -2.75rem !important;
  }
  .m-sm-n12 {
    margin: -3rem !important;
  }
  .m-sm-n13 {
    margin: -3.25rem !important;
  }
  .m-sm-n14 {
    margin: -3.5rem !important;
  }
  .m-sm-n15 {
    margin: -3.75rem !important;
  }
  .m-sm-n16 {
    margin: -4rem !important;
  }
  .m-sm-n17 {
    margin: -4.25rem !important;
  }
  .m-sm-n18 {
    margin: -4.5rem !important;
  }
  .m-sm-n19 {
    margin: -4.75rem !important;
  }
  .m-sm-n20 {
    margin: -5rem !important;
  }
  .m-sm-n21 {
    margin: -5.25rem !important;
  }
  .m-sm-n22 {
    margin: -5.5rem !important;
  }
  .m-sm-n23 {
    margin: -5.75rem !important;
  }
  .m-sm-n24 {
    margin: -6rem !important;
  }
  .m-sm-n25 {
    margin: -6.25rem !important;
  }
  .m-sm-n26 {
    margin: -6.5rem !important;
  }
  .m-sm-n27 {
    margin: -6.75rem !important;
  }
  .m-sm-n28 {
    margin: -7rem !important;
  }
  .m-sm-n29 {
    margin: -7.25rem !important;
  }
  .m-sm-n30 {
    margin: -7.5rem !important;
  }
  .m-sm-n31 {
    margin: -7.75rem !important;
  }
  .m-sm-n32 {
    margin: -8.25rem !important;
  }
  .m-sm-n33 {
    margin: -8.5rem !important;
  }
  .m-sm-n34 {
    margin: -8.75rem !important;
  }
  .m-sm-n35 {
    margin: -9rem !important;
  }
  .m-sm-n36 {
    margin: -9.25rem !important;
  }
  .m-sm-n37 {
    margin: -9.5rem !important;
  }
  .m-sm-n38 {
    margin: -9.75rem !important;
  }
  .m-sm-n39 {
    margin: -10rem !important;
  }
  .m-sm-n40 {
    margin: -10.25rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-sm-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }
  .mx-sm-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-sm-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }
  .mx-sm-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-sm-n11 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }
  .mx-sm-n12 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-sm-n13 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }
  .mx-sm-n14 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-sm-n15 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }
  .mx-sm-n16 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-sm-n17 {
    margin-right: -4.25rem !important;
    margin-left: -4.25rem !important;
  }
  .mx-sm-n18 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .mx-sm-n19 {
    margin-right: -4.75rem !important;
    margin-left: -4.75rem !important;
  }
  .mx-sm-n20 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-sm-n21 {
    margin-right: -5.25rem !important;
    margin-left: -5.25rem !important;
  }
  .mx-sm-n22 {
    margin-right: -5.5rem !important;
    margin-left: -5.5rem !important;
  }
  .mx-sm-n23 {
    margin-right: -5.75rem !important;
    margin-left: -5.75rem !important;
  }
  .mx-sm-n24 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-sm-n25 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important;
  }
  .mx-sm-n26 {
    margin-right: -6.5rem !important;
    margin-left: -6.5rem !important;
  }
  .mx-sm-n27 {
    margin-right: -6.75rem !important;
    margin-left: -6.75rem !important;
  }
  .mx-sm-n28 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }
  .mx-sm-n29 {
    margin-right: -7.25rem !important;
    margin-left: -7.25rem !important;
  }
  .mx-sm-n30 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  .mx-sm-n31 {
    margin-right: -7.75rem !important;
    margin-left: -7.75rem !important;
  }
  .mx-sm-n32 {
    margin-right: -8.25rem !important;
    margin-left: -8.25rem !important;
  }
  .mx-sm-n33 {
    margin-right: -8.5rem !important;
    margin-left: -8.5rem !important;
  }
  .mx-sm-n34 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important;
  }
  .mx-sm-n35 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  .mx-sm-n36 {
    margin-right: -9.25rem !important;
    margin-left: -9.25rem !important;
  }
  .mx-sm-n37 {
    margin-right: -9.5rem !important;
    margin-left: -9.5rem !important;
  }
  .mx-sm-n38 {
    margin-right: -9.75rem !important;
    margin-left: -9.75rem !important;
  }
  .mx-sm-n39 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .mx-sm-n40 {
    margin-right: -10.25rem !important;
    margin-left: -10.25rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-sm-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-sm-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }
  .my-sm-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-sm-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }
  .my-sm-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-sm-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }
  .my-sm-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-sm-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }
  .my-sm-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-sm-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }
  .my-sm-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-sm-n17 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }
  .my-sm-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .my-sm-n19 {
    margin-top: -4.75rem !important;
    margin-bottom: -4.75rem !important;
  }
  .my-sm-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-sm-n21 {
    margin-top: -5.25rem !important;
    margin-bottom: -5.25rem !important;
  }
  .my-sm-n22 {
    margin-top: -5.5rem !important;
    margin-bottom: -5.5rem !important;
  }
  .my-sm-n23 {
    margin-top: -5.75rem !important;
    margin-bottom: -5.75rem !important;
  }
  .my-sm-n24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-sm-n25 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important;
  }
  .my-sm-n26 {
    margin-top: -6.5rem !important;
    margin-bottom: -6.5rem !important;
  }
  .my-sm-n27 {
    margin-top: -6.75rem !important;
    margin-bottom: -6.75rem !important;
  }
  .my-sm-n28 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }
  .my-sm-n29 {
    margin-top: -7.25rem !important;
    margin-bottom: -7.25rem !important;
  }
  .my-sm-n30 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .my-sm-n31 {
    margin-top: -7.75rem !important;
    margin-bottom: -7.75rem !important;
  }
  .my-sm-n32 {
    margin-top: -8.25rem !important;
    margin-bottom: -8.25rem !important;
  }
  .my-sm-n33 {
    margin-top: -8.5rem !important;
    margin-bottom: -8.5rem !important;
  }
  .my-sm-n34 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important;
  }
  .my-sm-n35 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  .my-sm-n36 {
    margin-top: -9.25rem !important;
    margin-bottom: -9.25rem !important;
  }
  .my-sm-n37 {
    margin-top: -9.5rem !important;
    margin-bottom: -9.5rem !important;
  }
  .my-sm-n38 {
    margin-top: -9.75rem !important;
    margin-bottom: -9.75rem !important;
  }
  .my-sm-n39 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .my-sm-n40 {
    margin-top: -10.25rem !important;
    margin-bottom: -10.25rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -0.75rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1rem !important;
  }
  .mt-sm-n5 {
    margin-top: -1.25rem !important;
  }
  .mt-sm-n6 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n7 {
    margin-top: -1.75rem !important;
  }
  .mt-sm-n8 {
    margin-top: -2rem !important;
  }
  .mt-sm-n9 {
    margin-top: -2.25rem !important;
  }
  .mt-sm-n10 {
    margin-top: -2.5rem !important;
  }
  .mt-sm-n11 {
    margin-top: -2.75rem !important;
  }
  .mt-sm-n12 {
    margin-top: -3rem !important;
  }
  .mt-sm-n13 {
    margin-top: -3.25rem !important;
  }
  .mt-sm-n14 {
    margin-top: -3.5rem !important;
  }
  .mt-sm-n15 {
    margin-top: -3.75rem !important;
  }
  .mt-sm-n16 {
    margin-top: -4rem !important;
  }
  .mt-sm-n17 {
    margin-top: -4.25rem !important;
  }
  .mt-sm-n18 {
    margin-top: -4.5rem !important;
  }
  .mt-sm-n19 {
    margin-top: -4.75rem !important;
  }
  .mt-sm-n20 {
    margin-top: -5rem !important;
  }
  .mt-sm-n21 {
    margin-top: -5.25rem !important;
  }
  .mt-sm-n22 {
    margin-top: -5.5rem !important;
  }
  .mt-sm-n23 {
    margin-top: -5.75rem !important;
  }
  .mt-sm-n24 {
    margin-top: -6rem !important;
  }
  .mt-sm-n25 {
    margin-top: -6.25rem !important;
  }
  .mt-sm-n26 {
    margin-top: -6.5rem !important;
  }
  .mt-sm-n27 {
    margin-top: -6.75rem !important;
  }
  .mt-sm-n28 {
    margin-top: -7rem !important;
  }
  .mt-sm-n29 {
    margin-top: -7.25rem !important;
  }
  .mt-sm-n30 {
    margin-top: -7.5rem !important;
  }
  .mt-sm-n31 {
    margin-top: -7.75rem !important;
  }
  .mt-sm-n32 {
    margin-top: -8.25rem !important;
  }
  .mt-sm-n33 {
    margin-top: -8.5rem !important;
  }
  .mt-sm-n34 {
    margin-top: -8.75rem !important;
  }
  .mt-sm-n35 {
    margin-top: -9rem !important;
  }
  .mt-sm-n36 {
    margin-top: -9.25rem !important;
  }
  .mt-sm-n37 {
    margin-top: -9.5rem !important;
  }
  .mt-sm-n38 {
    margin-top: -9.75rem !important;
  }
  .mt-sm-n39 {
    margin-top: -10rem !important;
  }
  .mt-sm-n40 {
    margin-top: -10.25rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -0.75rem !important;
  }
  .me-sm-n4 {
    margin-right: -1rem !important;
  }
  .me-sm-n5 {
    margin-right: -1.25rem !important;
  }
  .me-sm-n6 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n7 {
    margin-right: -1.75rem !important;
  }
  .me-sm-n8 {
    margin-right: -2rem !important;
  }
  .me-sm-n9 {
    margin-right: -2.25rem !important;
  }
  .me-sm-n10 {
    margin-right: -2.5rem !important;
  }
  .me-sm-n11 {
    margin-right: -2.75rem !important;
  }
  .me-sm-n12 {
    margin-right: -3rem !important;
  }
  .me-sm-n13 {
    margin-right: -3.25rem !important;
  }
  .me-sm-n14 {
    margin-right: -3.5rem !important;
  }
  .me-sm-n15 {
    margin-right: -3.75rem !important;
  }
  .me-sm-n16 {
    margin-right: -4rem !important;
  }
  .me-sm-n17 {
    margin-right: -4.25rem !important;
  }
  .me-sm-n18 {
    margin-right: -4.5rem !important;
  }
  .me-sm-n19 {
    margin-right: -4.75rem !important;
  }
  .me-sm-n20 {
    margin-right: -5rem !important;
  }
  .me-sm-n21 {
    margin-right: -5.25rem !important;
  }
  .me-sm-n22 {
    margin-right: -5.5rem !important;
  }
  .me-sm-n23 {
    margin-right: -5.75rem !important;
  }
  .me-sm-n24 {
    margin-right: -6rem !important;
  }
  .me-sm-n25 {
    margin-right: -6.25rem !important;
  }
  .me-sm-n26 {
    margin-right: -6.5rem !important;
  }
  .me-sm-n27 {
    margin-right: -6.75rem !important;
  }
  .me-sm-n28 {
    margin-right: -7rem !important;
  }
  .me-sm-n29 {
    margin-right: -7.25rem !important;
  }
  .me-sm-n30 {
    margin-right: -7.5rem !important;
  }
  .me-sm-n31 {
    margin-right: -7.75rem !important;
  }
  .me-sm-n32 {
    margin-right: -8.25rem !important;
  }
  .me-sm-n33 {
    margin-right: -8.5rem !important;
  }
  .me-sm-n34 {
    margin-right: -8.75rem !important;
  }
  .me-sm-n35 {
    margin-right: -9rem !important;
  }
  .me-sm-n36 {
    margin-right: -9.25rem !important;
  }
  .me-sm-n37 {
    margin-right: -9.5rem !important;
  }
  .me-sm-n38 {
    margin-right: -9.75rem !important;
  }
  .me-sm-n39 {
    margin-right: -10rem !important;
  }
  .me-sm-n40 {
    margin-right: -10.25rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -0.75rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -1.25rem !important;
  }
  .mb-sm-n6 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n7 {
    margin-bottom: -1.75rem !important;
  }
  .mb-sm-n8 {
    margin-bottom: -2rem !important;
  }
  .mb-sm-n9 {
    margin-bottom: -2.25rem !important;
  }
  .mb-sm-n10 {
    margin-bottom: -2.5rem !important;
  }
  .mb-sm-n11 {
    margin-bottom: -2.75rem !important;
  }
  .mb-sm-n12 {
    margin-bottom: -3rem !important;
  }
  .mb-sm-n13 {
    margin-bottom: -3.25rem !important;
  }
  .mb-sm-n14 {
    margin-bottom: -3.5rem !important;
  }
  .mb-sm-n15 {
    margin-bottom: -3.75rem !important;
  }
  .mb-sm-n16 {
    margin-bottom: -4rem !important;
  }
  .mb-sm-n17 {
    margin-bottom: -4.25rem !important;
  }
  .mb-sm-n18 {
    margin-bottom: -4.5rem !important;
  }
  .mb-sm-n19 {
    margin-bottom: -4.75rem !important;
  }
  .mb-sm-n20 {
    margin-bottom: -5rem !important;
  }
  .mb-sm-n21 {
    margin-bottom: -5.25rem !important;
  }
  .mb-sm-n22 {
    margin-bottom: -5.5rem !important;
  }
  .mb-sm-n23 {
    margin-bottom: -5.75rem !important;
  }
  .mb-sm-n24 {
    margin-bottom: -6rem !important;
  }
  .mb-sm-n25 {
    margin-bottom: -6.25rem !important;
  }
  .mb-sm-n26 {
    margin-bottom: -6.5rem !important;
  }
  .mb-sm-n27 {
    margin-bottom: -6.75rem !important;
  }
  .mb-sm-n28 {
    margin-bottom: -7rem !important;
  }
  .mb-sm-n29 {
    margin-bottom: -7.25rem !important;
  }
  .mb-sm-n30 {
    margin-bottom: -7.5rem !important;
  }
  .mb-sm-n31 {
    margin-bottom: -7.75rem !important;
  }
  .mb-sm-n32 {
    margin-bottom: -8.25rem !important;
  }
  .mb-sm-n33 {
    margin-bottom: -8.5rem !important;
  }
  .mb-sm-n34 {
    margin-bottom: -8.75rem !important;
  }
  .mb-sm-n35 {
    margin-bottom: -9rem !important;
  }
  .mb-sm-n36 {
    margin-bottom: -9.25rem !important;
  }
  .mb-sm-n37 {
    margin-bottom: -9.5rem !important;
  }
  .mb-sm-n38 {
    margin-bottom: -9.75rem !important;
  }
  .mb-sm-n39 {
    margin-bottom: -10rem !important;
  }
  .mb-sm-n40 {
    margin-bottom: -10.25rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -0.75rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1rem !important;
  }
  .ms-sm-n5 {
    margin-left: -1.25rem !important;
  }
  .ms-sm-n6 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n7 {
    margin-left: -1.75rem !important;
  }
  .ms-sm-n8 {
    margin-left: -2rem !important;
  }
  .ms-sm-n9 {
    margin-left: -2.25rem !important;
  }
  .ms-sm-n10 {
    margin-left: -2.5rem !important;
  }
  .ms-sm-n11 {
    margin-left: -2.75rem !important;
  }
  .ms-sm-n12 {
    margin-left: -3rem !important;
  }
  .ms-sm-n13 {
    margin-left: -3.25rem !important;
  }
  .ms-sm-n14 {
    margin-left: -3.5rem !important;
  }
  .ms-sm-n15 {
    margin-left: -3.75rem !important;
  }
  .ms-sm-n16 {
    margin-left: -4rem !important;
  }
  .ms-sm-n17 {
    margin-left: -4.25rem !important;
  }
  .ms-sm-n18 {
    margin-left: -4.5rem !important;
  }
  .ms-sm-n19 {
    margin-left: -4.75rem !important;
  }
  .ms-sm-n20 {
    margin-left: -5rem !important;
  }
  .ms-sm-n21 {
    margin-left: -5.25rem !important;
  }
  .ms-sm-n22 {
    margin-left: -5.5rem !important;
  }
  .ms-sm-n23 {
    margin-left: -5.75rem !important;
  }
  .ms-sm-n24 {
    margin-left: -6rem !important;
  }
  .ms-sm-n25 {
    margin-left: -6.25rem !important;
  }
  .ms-sm-n26 {
    margin-left: -6.5rem !important;
  }
  .ms-sm-n27 {
    margin-left: -6.75rem !important;
  }
  .ms-sm-n28 {
    margin-left: -7rem !important;
  }
  .ms-sm-n29 {
    margin-left: -7.25rem !important;
  }
  .ms-sm-n30 {
    margin-left: -7.5rem !important;
  }
  .ms-sm-n31 {
    margin-left: -7.75rem !important;
  }
  .ms-sm-n32 {
    margin-left: -8.25rem !important;
  }
  .ms-sm-n33 {
    margin-left: -8.5rem !important;
  }
  .ms-sm-n34 {
    margin-left: -8.75rem !important;
  }
  .ms-sm-n35 {
    margin-left: -9rem !important;
  }
  .ms-sm-n36 {
    margin-left: -9.25rem !important;
  }
  .ms-sm-n37 {
    margin-left: -9.5rem !important;
  }
  .ms-sm-n38 {
    margin-left: -9.75rem !important;
  }
  .ms-sm-n39 {
    margin-left: -10rem !important;
  }
  .ms-sm-n40 {
    margin-left: -10.25rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 0.75rem !important;
  }
  .p-sm-4 {
    padding: 1rem !important;
  }
  .p-sm-5 {
    padding: 1.25rem !important;
  }
  .p-sm-6 {
    padding: 1.5rem !important;
  }
  .p-sm-7 {
    padding: 1.75rem !important;
  }
  .p-sm-8 {
    padding: 2rem !important;
  }
  .p-sm-9 {
    padding: 2.25rem !important;
  }
  .p-sm-10 {
    padding: 2.5rem !important;
  }
  .p-sm-11 {
    padding: 2.75rem !important;
  }
  .p-sm-12 {
    padding: 3rem !important;
  }
  .p-sm-13 {
    padding: 3.25rem !important;
  }
  .p-sm-14 {
    padding: 3.5rem !important;
  }
  .p-sm-15 {
    padding: 3.75rem !important;
  }
  .p-sm-16 {
    padding: 4rem !important;
  }
  .p-sm-17 {
    padding: 4.25rem !important;
  }
  .p-sm-18 {
    padding: 4.5rem !important;
  }
  .p-sm-19 {
    padding: 4.75rem !important;
  }
  .p-sm-20 {
    padding: 5rem !important;
  }
  .p-sm-21 {
    padding: 5.25rem !important;
  }
  .p-sm-22 {
    padding: 5.5rem !important;
  }
  .p-sm-23 {
    padding: 5.75rem !important;
  }
  .p-sm-24 {
    padding: 6rem !important;
  }
  .p-sm-25 {
    padding: 6.25rem !important;
  }
  .p-sm-26 {
    padding: 6.5rem !important;
  }
  .p-sm-27 {
    padding: 6.75rem !important;
  }
  .p-sm-28 {
    padding: 7rem !important;
  }
  .p-sm-29 {
    padding: 7.25rem !important;
  }
  .p-sm-30 {
    padding: 7.5rem !important;
  }
  .p-sm-31 {
    padding: 7.75rem !important;
  }
  .p-sm-32 {
    padding: 8.25rem !important;
  }
  .p-sm-33 {
    padding: 8.5rem !important;
  }
  .p-sm-34 {
    padding: 8.75rem !important;
  }
  .p-sm-35 {
    padding: 9rem !important;
  }
  .p-sm-36 {
    padding: 9.25rem !important;
  }
  .p-sm-37 {
    padding: 9.5rem !important;
  }
  .p-sm-38 {
    padding: 9.75rem !important;
  }
  .p-sm-39 {
    padding: 10rem !important;
  }
  .p-sm-40 {
    padding: 10.25rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-sm-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-sm-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }
  .px-sm-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-sm-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-sm-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-sm-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }
  .px-sm-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-sm-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }
  .px-sm-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-sm-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-sm-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-sm-17 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }
  .px-sm-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-sm-19 {
    padding-right: 4.75rem !important;
    padding-left: 4.75rem !important;
  }
  .px-sm-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-sm-21 {
    padding-right: 5.25rem !important;
    padding-left: 5.25rem !important;
  }
  .px-sm-22 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }
  .px-sm-23 {
    padding-right: 5.75rem !important;
    padding-left: 5.75rem !important;
  }
  .px-sm-24 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-sm-25 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }
  .px-sm-26 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }
  .px-sm-27 {
    padding-right: 6.75rem !important;
    padding-left: 6.75rem !important;
  }
  .px-sm-28 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-sm-29 {
    padding-right: 7.25rem !important;
    padding-left: 7.25rem !important;
  }
  .px-sm-30 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-sm-31 {
    padding-right: 7.75rem !important;
    padding-left: 7.75rem !important;
  }
  .px-sm-32 {
    padding-right: 8.25rem !important;
    padding-left: 8.25rem !important;
  }
  .px-sm-33 {
    padding-right: 8.5rem !important;
    padding-left: 8.5rem !important;
  }
  .px-sm-34 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important;
  }
  .px-sm-35 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-sm-36 {
    padding-right: 9.25rem !important;
    padding-left: 9.25rem !important;
  }
  .px-sm-37 {
    padding-right: 9.5rem !important;
    padding-left: 9.5rem !important;
  }
  .px-sm-38 {
    padding-right: 9.75rem !important;
    padding-left: 9.75rem !important;
  }
  .px-sm-39 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-sm-40 {
    padding-right: 10.25rem !important;
    padding-left: 10.25rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-sm-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-sm-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }
  .py-sm-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-sm-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-sm-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-sm-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }
  .py-sm-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }
  .py-sm-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-sm-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-sm-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-sm-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }
  .py-sm-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-sm-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }
  .py-sm-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-sm-21 {
    padding-top: 5.25rem !important;
    padding-bottom: 5.25rem !important;
  }
  .py-sm-22 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }
  .py-sm-23 {
    padding-top: 5.75rem !important;
    padding-bottom: 5.75rem !important;
  }
  .py-sm-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-sm-25 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .py-sm-26 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }
  .py-sm-27 {
    padding-top: 6.75rem !important;
    padding-bottom: 6.75rem !important;
  }
  .py-sm-28 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-sm-29 {
    padding-top: 7.25rem !important;
    padding-bottom: 7.25rem !important;
  }
  .py-sm-30 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-sm-31 {
    padding-top: 7.75rem !important;
    padding-bottom: 7.75rem !important;
  }
  .py-sm-32 {
    padding-top: 8.25rem !important;
    padding-bottom: 8.25rem !important;
  }
  .py-sm-33 {
    padding-top: 8.5rem !important;
    padding-bottom: 8.5rem !important;
  }
  .py-sm-34 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important;
  }
  .py-sm-35 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-sm-36 {
    padding-top: 9.25rem !important;
    padding-bottom: 9.25rem !important;
  }
  .py-sm-37 {
    padding-top: 9.5rem !important;
    padding-bottom: 9.5rem !important;
  }
  .py-sm-38 {
    padding-top: 9.75rem !important;
    padding-bottom: 9.75rem !important;
  }
  .py-sm-39 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-sm-40 {
    padding-top: 10.25rem !important;
    padding-bottom: 10.25rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 0.75rem !important;
  }
  .pt-sm-4 {
    padding-top: 1rem !important;
  }
  .pt-sm-5 {
    padding-top: 1.25rem !important;
  }
  .pt-sm-6 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-7 {
    padding-top: 1.75rem !important;
  }
  .pt-sm-8 {
    padding-top: 2rem !important;
  }
  .pt-sm-9 {
    padding-top: 2.25rem !important;
  }
  .pt-sm-10 {
    padding-top: 2.5rem !important;
  }
  .pt-sm-11 {
    padding-top: 2.75rem !important;
  }
  .pt-sm-12 {
    padding-top: 3rem !important;
  }
  .pt-sm-13 {
    padding-top: 3.25rem !important;
  }
  .pt-sm-14 {
    padding-top: 3.5rem !important;
  }
  .pt-sm-15 {
    padding-top: 3.75rem !important;
  }
  .pt-sm-16 {
    padding-top: 4rem !important;
  }
  .pt-sm-17 {
    padding-top: 4.25rem !important;
  }
  .pt-sm-18 {
    padding-top: 4.5rem !important;
  }
  .pt-sm-19 {
    padding-top: 4.75rem !important;
  }
  .pt-sm-20 {
    padding-top: 5rem !important;
  }
  .pt-sm-21 {
    padding-top: 5.25rem !important;
  }
  .pt-sm-22 {
    padding-top: 5.5rem !important;
  }
  .pt-sm-23 {
    padding-top: 5.75rem !important;
  }
  .pt-sm-24 {
    padding-top: 6rem !important;
  }
  .pt-sm-25 {
    padding-top: 6.25rem !important;
  }
  .pt-sm-26 {
    padding-top: 6.5rem !important;
  }
  .pt-sm-27 {
    padding-top: 6.75rem !important;
  }
  .pt-sm-28 {
    padding-top: 7rem !important;
  }
  .pt-sm-29 {
    padding-top: 7.25rem !important;
  }
  .pt-sm-30 {
    padding-top: 7.5rem !important;
  }
  .pt-sm-31 {
    padding-top: 7.75rem !important;
  }
  .pt-sm-32 {
    padding-top: 8.25rem !important;
  }
  .pt-sm-33 {
    padding-top: 8.5rem !important;
  }
  .pt-sm-34 {
    padding-top: 8.75rem !important;
  }
  .pt-sm-35 {
    padding-top: 9rem !important;
  }
  .pt-sm-36 {
    padding-top: 9.25rem !important;
  }
  .pt-sm-37 {
    padding-top: 9.5rem !important;
  }
  .pt-sm-38 {
    padding-top: 9.75rem !important;
  }
  .pt-sm-39 {
    padding-top: 10rem !important;
  }
  .pt-sm-40 {
    padding-top: 10.25rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 0.75rem !important;
  }
  .pe-sm-4 {
    padding-right: 1rem !important;
  }
  .pe-sm-5 {
    padding-right: 1.25rem !important;
  }
  .pe-sm-6 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-7 {
    padding-right: 1.75rem !important;
  }
  .pe-sm-8 {
    padding-right: 2rem !important;
  }
  .pe-sm-9 {
    padding-right: 2.25rem !important;
  }
  .pe-sm-10 {
    padding-right: 2.5rem !important;
  }
  .pe-sm-11 {
    padding-right: 2.75rem !important;
  }
  .pe-sm-12 {
    padding-right: 3rem !important;
  }
  .pe-sm-13 {
    padding-right: 3.25rem !important;
  }
  .pe-sm-14 {
    padding-right: 3.5rem !important;
  }
  .pe-sm-15 {
    padding-right: 3.75rem !important;
  }
  .pe-sm-16 {
    padding-right: 4rem !important;
  }
  .pe-sm-17 {
    padding-right: 4.25rem !important;
  }
  .pe-sm-18 {
    padding-right: 4.5rem !important;
  }
  .pe-sm-19 {
    padding-right: 4.75rem !important;
  }
  .pe-sm-20 {
    padding-right: 5rem !important;
  }
  .pe-sm-21 {
    padding-right: 5.25rem !important;
  }
  .pe-sm-22 {
    padding-right: 5.5rem !important;
  }
  .pe-sm-23 {
    padding-right: 5.75rem !important;
  }
  .pe-sm-24 {
    padding-right: 6rem !important;
  }
  .pe-sm-25 {
    padding-right: 6.25rem !important;
  }
  .pe-sm-26 {
    padding-right: 6.5rem !important;
  }
  .pe-sm-27 {
    padding-right: 6.75rem !important;
  }
  .pe-sm-28 {
    padding-right: 7rem !important;
  }
  .pe-sm-29 {
    padding-right: 7.25rem !important;
  }
  .pe-sm-30 {
    padding-right: 7.5rem !important;
  }
  .pe-sm-31 {
    padding-right: 7.75rem !important;
  }
  .pe-sm-32 {
    padding-right: 8.25rem !important;
  }
  .pe-sm-33 {
    padding-right: 8.5rem !important;
  }
  .pe-sm-34 {
    padding-right: 8.75rem !important;
  }
  .pe-sm-35 {
    padding-right: 9rem !important;
  }
  .pe-sm-36 {
    padding-right: 9.25rem !important;
  }
  .pe-sm-37 {
    padding-right: 9.5rem !important;
  }
  .pe-sm-38 {
    padding-right: 9.75rem !important;
  }
  .pe-sm-39 {
    padding-right: 10rem !important;
  }
  .pe-sm-40 {
    padding-right: 10.25rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 1.25rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 1.75rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 2rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 2.25rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 2.5rem !important;
  }
  .pb-sm-11 {
    padding-bottom: 2.75rem !important;
  }
  .pb-sm-12 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-13 {
    padding-bottom: 3.25rem !important;
  }
  .pb-sm-14 {
    padding-bottom: 3.5rem !important;
  }
  .pb-sm-15 {
    padding-bottom: 3.75rem !important;
  }
  .pb-sm-16 {
    padding-bottom: 4rem !important;
  }
  .pb-sm-17 {
    padding-bottom: 4.25rem !important;
  }
  .pb-sm-18 {
    padding-bottom: 4.5rem !important;
  }
  .pb-sm-19 {
    padding-bottom: 4.75rem !important;
  }
  .pb-sm-20 {
    padding-bottom: 5rem !important;
  }
  .pb-sm-21 {
    padding-bottom: 5.25rem !important;
  }
  .pb-sm-22 {
    padding-bottom: 5.5rem !important;
  }
  .pb-sm-23 {
    padding-bottom: 5.75rem !important;
  }
  .pb-sm-24 {
    padding-bottom: 6rem !important;
  }
  .pb-sm-25 {
    padding-bottom: 6.25rem !important;
  }
  .pb-sm-26 {
    padding-bottom: 6.5rem !important;
  }
  .pb-sm-27 {
    padding-bottom: 6.75rem !important;
  }
  .pb-sm-28 {
    padding-bottom: 7rem !important;
  }
  .pb-sm-29 {
    padding-bottom: 7.25rem !important;
  }
  .pb-sm-30 {
    padding-bottom: 7.5rem !important;
  }
  .pb-sm-31 {
    padding-bottom: 7.75rem !important;
  }
  .pb-sm-32 {
    padding-bottom: 8.25rem !important;
  }
  .pb-sm-33 {
    padding-bottom: 8.5rem !important;
  }
  .pb-sm-34 {
    padding-bottom: 8.75rem !important;
  }
  .pb-sm-35 {
    padding-bottom: 9rem !important;
  }
  .pb-sm-36 {
    padding-bottom: 9.25rem !important;
  }
  .pb-sm-37 {
    padding-bottom: 9.5rem !important;
  }
  .pb-sm-38 {
    padding-bottom: 9.75rem !important;
  }
  .pb-sm-39 {
    padding-bottom: 10rem !important;
  }
  .pb-sm-40 {
    padding-bottom: 10.25rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 0.75rem !important;
  }
  .ps-sm-4 {
    padding-left: 1rem !important;
  }
  .ps-sm-5 {
    padding-left: 1.25rem !important;
  }
  .ps-sm-6 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-7 {
    padding-left: 1.75rem !important;
  }
  .ps-sm-8 {
    padding-left: 2rem !important;
  }
  .ps-sm-9 {
    padding-left: 2.25rem !important;
  }
  .ps-sm-10 {
    padding-left: 2.5rem !important;
  }
  .ps-sm-11 {
    padding-left: 2.75rem !important;
  }
  .ps-sm-12 {
    padding-left: 3rem !important;
  }
  .ps-sm-13 {
    padding-left: 3.25rem !important;
  }
  .ps-sm-14 {
    padding-left: 3.5rem !important;
  }
  .ps-sm-15 {
    padding-left: 3.75rem !important;
  }
  .ps-sm-16 {
    padding-left: 4rem !important;
  }
  .ps-sm-17 {
    padding-left: 4.25rem !important;
  }
  .ps-sm-18 {
    padding-left: 4.5rem !important;
  }
  .ps-sm-19 {
    padding-left: 4.75rem !important;
  }
  .ps-sm-20 {
    padding-left: 5rem !important;
  }
  .ps-sm-21 {
    padding-left: 5.25rem !important;
  }
  .ps-sm-22 {
    padding-left: 5.5rem !important;
  }
  .ps-sm-23 {
    padding-left: 5.75rem !important;
  }
  .ps-sm-24 {
    padding-left: 6rem !important;
  }
  .ps-sm-25 {
    padding-left: 6.25rem !important;
  }
  .ps-sm-26 {
    padding-left: 6.5rem !important;
  }
  .ps-sm-27 {
    padding-left: 6.75rem !important;
  }
  .ps-sm-28 {
    padding-left: 7rem !important;
  }
  .ps-sm-29 {
    padding-left: 7.25rem !important;
  }
  .ps-sm-30 {
    padding-left: 7.5rem !important;
  }
  .ps-sm-31 {
    padding-left: 7.75rem !important;
  }
  .ps-sm-32 {
    padding-left: 8.25rem !important;
  }
  .ps-sm-33 {
    padding-left: 8.5rem !important;
  }
  .ps-sm-34 {
    padding-left: 8.75rem !important;
  }
  .ps-sm-35 {
    padding-left: 9rem !important;
  }
  .ps-sm-36 {
    padding-left: 9.25rem !important;
  }
  .ps-sm-37 {
    padding-left: 9.5rem !important;
  }
  .ps-sm-38 {
    padding-left: 9.75rem !important;
  }
  .ps-sm-39 {
    padding-left: 10rem !important;
  }
  .ps-sm-40 {
    padding-left: 10.25rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 0.75rem !important;
  }
  .gap-sm-4 {
    gap: 1rem !important;
  }
  .gap-sm-5 {
    gap: 1.25rem !important;
  }
  .gap-sm-6 {
    gap: 1.5rem !important;
  }
  .gap-sm-7 {
    gap: 1.75rem !important;
  }
  .gap-sm-8 {
    gap: 2rem !important;
  }
  .gap-sm-9 {
    gap: 2.25rem !important;
  }
  .gap-sm-10 {
    gap: 2.5rem !important;
  }
  .gap-sm-11 {
    gap: 2.75rem !important;
  }
  .gap-sm-12 {
    gap: 3rem !important;
  }
  .gap-sm-13 {
    gap: 3.25rem !important;
  }
  .gap-sm-14 {
    gap: 3.5rem !important;
  }
  .gap-sm-15 {
    gap: 3.75rem !important;
  }
  .gap-sm-16 {
    gap: 4rem !important;
  }
  .gap-sm-17 {
    gap: 4.25rem !important;
  }
  .gap-sm-18 {
    gap: 4.5rem !important;
  }
  .gap-sm-19 {
    gap: 4.75rem !important;
  }
  .gap-sm-20 {
    gap: 5rem !important;
  }
  .gap-sm-21 {
    gap: 5.25rem !important;
  }
  .gap-sm-22 {
    gap: 5.5rem !important;
  }
  .gap-sm-23 {
    gap: 5.75rem !important;
  }
  .gap-sm-24 {
    gap: 6rem !important;
  }
  .gap-sm-25 {
    gap: 6.25rem !important;
  }
  .gap-sm-26 {
    gap: 6.5rem !important;
  }
  .gap-sm-27 {
    gap: 6.75rem !important;
  }
  .gap-sm-28 {
    gap: 7rem !important;
  }
  .gap-sm-29 {
    gap: 7.25rem !important;
  }
  .gap-sm-30 {
    gap: 7.5rem !important;
  }
  .gap-sm-31 {
    gap: 7.75rem !important;
  }
  .gap-sm-32 {
    gap: 8.25rem !important;
  }
  .gap-sm-33 {
    gap: 8.5rem !important;
  }
  .gap-sm-34 {
    gap: 8.75rem !important;
  }
  .gap-sm-35 {
    gap: 9rem !important;
  }
  .gap-sm-36 {
    gap: 9.25rem !important;
  }
  .gap-sm-37 {
    gap: 9.5rem !important;
  }
  .gap-sm-38 {
    gap: 9.75rem !important;
  }
  .gap-sm-39 {
    gap: 10rem !important;
  }
  .gap-sm-40 {
    gap: 10.25rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 0.75rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 1.25rem !important;
  }
  .row-gap-sm-6 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-7 {
    row-gap: 1.75rem !important;
  }
  .row-gap-sm-8 {
    row-gap: 2rem !important;
  }
  .row-gap-sm-9 {
    row-gap: 2.25rem !important;
  }
  .row-gap-sm-10 {
    row-gap: 2.5rem !important;
  }
  .row-gap-sm-11 {
    row-gap: 2.75rem !important;
  }
  .row-gap-sm-12 {
    row-gap: 3rem !important;
  }
  .row-gap-sm-13 {
    row-gap: 3.25rem !important;
  }
  .row-gap-sm-14 {
    row-gap: 3.5rem !important;
  }
  .row-gap-sm-15 {
    row-gap: 3.75rem !important;
  }
  .row-gap-sm-16 {
    row-gap: 4rem !important;
  }
  .row-gap-sm-17 {
    row-gap: 4.25rem !important;
  }
  .row-gap-sm-18 {
    row-gap: 4.5rem !important;
  }
  .row-gap-sm-19 {
    row-gap: 4.75rem !important;
  }
  .row-gap-sm-20 {
    row-gap: 5rem !important;
  }
  .row-gap-sm-21 {
    row-gap: 5.25rem !important;
  }
  .row-gap-sm-22 {
    row-gap: 5.5rem !important;
  }
  .row-gap-sm-23 {
    row-gap: 5.75rem !important;
  }
  .row-gap-sm-24 {
    row-gap: 6rem !important;
  }
  .row-gap-sm-25 {
    row-gap: 6.25rem !important;
  }
  .row-gap-sm-26 {
    row-gap: 6.5rem !important;
  }
  .row-gap-sm-27 {
    row-gap: 6.75rem !important;
  }
  .row-gap-sm-28 {
    row-gap: 7rem !important;
  }
  .row-gap-sm-29 {
    row-gap: 7.25rem !important;
  }
  .row-gap-sm-30 {
    row-gap: 7.5rem !important;
  }
  .row-gap-sm-31 {
    row-gap: 7.75rem !important;
  }
  .row-gap-sm-32 {
    row-gap: 8.25rem !important;
  }
  .row-gap-sm-33 {
    row-gap: 8.5rem !important;
  }
  .row-gap-sm-34 {
    row-gap: 8.75rem !important;
  }
  .row-gap-sm-35 {
    row-gap: 9rem !important;
  }
  .row-gap-sm-36 {
    row-gap: 9.25rem !important;
  }
  .row-gap-sm-37 {
    row-gap: 9.5rem !important;
  }
  .row-gap-sm-38 {
    row-gap: 9.75rem !important;
  }
  .row-gap-sm-39 {
    row-gap: 10rem !important;
  }
  .row-gap-sm-40 {
    row-gap: 10.25rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 0.75rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 1.25rem !important;
  }
  .column-gap-sm-6 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-7 {
    column-gap: 1.75rem !important;
  }
  .column-gap-sm-8 {
    column-gap: 2rem !important;
  }
  .column-gap-sm-9 {
    column-gap: 2.25rem !important;
  }
  .column-gap-sm-10 {
    column-gap: 2.5rem !important;
  }
  .column-gap-sm-11 {
    column-gap: 2.75rem !important;
  }
  .column-gap-sm-12 {
    column-gap: 3rem !important;
  }
  .column-gap-sm-13 {
    column-gap: 3.25rem !important;
  }
  .column-gap-sm-14 {
    column-gap: 3.5rem !important;
  }
  .column-gap-sm-15 {
    column-gap: 3.75rem !important;
  }
  .column-gap-sm-16 {
    column-gap: 4rem !important;
  }
  .column-gap-sm-17 {
    column-gap: 4.25rem !important;
  }
  .column-gap-sm-18 {
    column-gap: 4.5rem !important;
  }
  .column-gap-sm-19 {
    column-gap: 4.75rem !important;
  }
  .column-gap-sm-20 {
    column-gap: 5rem !important;
  }
  .column-gap-sm-21 {
    column-gap: 5.25rem !important;
  }
  .column-gap-sm-22 {
    column-gap: 5.5rem !important;
  }
  .column-gap-sm-23 {
    column-gap: 5.75rem !important;
  }
  .column-gap-sm-24 {
    column-gap: 6rem !important;
  }
  .column-gap-sm-25 {
    column-gap: 6.25rem !important;
  }
  .column-gap-sm-26 {
    column-gap: 6.5rem !important;
  }
  .column-gap-sm-27 {
    column-gap: 6.75rem !important;
  }
  .column-gap-sm-28 {
    column-gap: 7rem !important;
  }
  .column-gap-sm-29 {
    column-gap: 7.25rem !important;
  }
  .column-gap-sm-30 {
    column-gap: 7.5rem !important;
  }
  .column-gap-sm-31 {
    column-gap: 7.75rem !important;
  }
  .column-gap-sm-32 {
    column-gap: 8.25rem !important;
  }
  .column-gap-sm-33 {
    column-gap: 8.5rem !important;
  }
  .column-gap-sm-34 {
    column-gap: 8.75rem !important;
  }
  .column-gap-sm-35 {
    column-gap: 9rem !important;
  }
  .column-gap-sm-36 {
    column-gap: 9.25rem !important;
  }
  .column-gap-sm-37 {
    column-gap: 9.5rem !important;
  }
  .column-gap-sm-38 {
    column-gap: 9.75rem !important;
  }
  .column-gap-sm-39 {
    column-gap: 10rem !important;
  }
  .column-gap-sm-40 {
    column-gap: 10.25rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-md-contain {
    object-fit: contain !important;
  }
  .object-md-cover {
    object-fit: cover !important;
  }
  .object-md-fill {
    object-fit: fill !important;
  }
  .object-md-scale {
    object-fit: scale-down !important;
  }
  .object-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .w-md-0 {
    width: 0 !important;
  }
  .w-md-px {
    width: 1px !important;
  }
  .w-md-2px {
    width: 2px !important;
  }
  .w-md-1 {
    width: 0.25rem !important;
  }
  .w-md-2 {
    width: 0.5rem !important;
  }
  .w-md-3 {
    width: 0.75rem !important;
  }
  .w-md-4 {
    width: 1rem !important;
  }
  .w-md-5 {
    width: 1.25rem !important;
  }
  .w-md-6 {
    width: 1.5rem !important;
  }
  .w-md-7 {
    width: 1.75rem !important;
  }
  .w-md-8 {
    width: 2rem !important;
  }
  .w-md-9 {
    width: 2.25rem !important;
  }
  .w-md-10 {
    width: 2.5rem !important;
  }
  .w-md-11 {
    width: 2.75rem !important;
  }
  .w-md-12 {
    width: 3rem !important;
  }
  .w-md-13 {
    width: 3.25rem !important;
  }
  .w-md-14 {
    width: 3.5rem !important;
  }
  .w-md-15 {
    width: 3.75rem !important;
  }
  .w-md-16 {
    width: 4rem !important;
  }
  .w-md-17 {
    width: 4.25rem !important;
  }
  .w-md-18 {
    width: 4.5rem !important;
  }
  .w-md-19 {
    width: 4.75rem !important;
  }
  .w-md-20 {
    width: 5rem !important;
  }
  .w-md-21 {
    width: 5.25rem !important;
  }
  .w-md-22 {
    width: 5.5rem !important;
  }
  .w-md-23 {
    width: 5.75rem !important;
  }
  .w-md-24 {
    width: 6rem !important;
  }
  .w-md-25 {
    width: 6.25rem !important;
  }
  .w-md-26 {
    width: 6.5rem !important;
  }
  .w-md-27 {
    width: 6.75rem !important;
  }
  .w-md-28 {
    width: 7rem !important;
  }
  .w-md-29 {
    width: 7.25rem !important;
  }
  .w-md-30 {
    width: 7.5rem !important;
  }
  .w-md-31 {
    width: 7.75rem !important;
  }
  .w-md-32 {
    width: 8rem !important;
  }
  .w-md-quarter {
    width: 25% !important;
  }
  .w-md-half {
    width: 50% !important;
  }
  .w-md-3quarter {
    width: 75% !important;
  }
  .w-md-full {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .h-md-0 {
    height: 0 !important;
  }
  .h-md-px {
    height: 1px !important;
  }
  .h-md-2px {
    height: 2px !important;
  }
  .h-md-1 {
    height: 0.25rem !important;
  }
  .h-md-2 {
    height: 0.5rem !important;
  }
  .h-md-3 {
    height: 0.75rem !important;
  }
  .h-md-4 {
    height: 1rem !important;
  }
  .h-md-5 {
    height: 1.25rem !important;
  }
  .h-md-6 {
    height: 1.5rem !important;
  }
  .h-md-7 {
    height: 1.75rem !important;
  }
  .h-md-8 {
    height: 2rem !important;
  }
  .h-md-9 {
    height: 2.25rem !important;
  }
  .h-md-10 {
    height: 2.5rem !important;
  }
  .h-md-11 {
    height: 2.75rem !important;
  }
  .h-md-12 {
    height: 3rem !important;
  }
  .h-md-13 {
    height: 3.25rem !important;
  }
  .h-md-14 {
    height: 3.5rem !important;
  }
  .h-md-15 {
    height: 3.75rem !important;
  }
  .h-md-16 {
    height: 4rem !important;
  }
  .h-md-17 {
    height: 4.25rem !important;
  }
  .h-md-18 {
    height: 4.5rem !important;
  }
  .h-md-19 {
    height: 4.75rem !important;
  }
  .h-md-20 {
    height: 5rem !important;
  }
  .h-md-21 {
    height: 5.25rem !important;
  }
  .h-md-22 {
    height: 5.5rem !important;
  }
  .h-md-23 {
    height: 5.75rem !important;
  }
  .h-md-24 {
    height: 6rem !important;
  }
  .h-md-25 {
    height: 6.25rem !important;
  }
  .h-md-26 {
    height: 6.5rem !important;
  }
  .h-md-27 {
    height: 6.75rem !important;
  }
  .h-md-28 {
    height: 7rem !important;
  }
  .h-md-29 {
    height: 7.25rem !important;
  }
  .h-md-30 {
    height: 7.5rem !important;
  }
  .h-md-31 {
    height: 7.75rem !important;
  }
  .h-md-32 {
    height: 8rem !important;
  }
  .h-md-quarter {
    height: 25% !important;
  }
  .h-md-half {
    height: 50% !important;
  }
  .h-md-3quarter {
    height: 75% !important;
  }
  .h-md-full {
    height: 100% !important;
  }
  .h-md-auto {
    height: auto !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-md-start {
    justify-content: flex-start !important;
  }
  .justify-md-end {
    justify-content: flex-end !important;
  }
  .justify-md-center {
    justify-content: center !important;
  }
  .justify-md-between {
    justify-content: space-between !important;
  }
  .justify-md-around {
    justify-content: space-around !important;
  }
  .justify-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-md-start {
    align-items: flex-start !important;
  }
  .align-md-end {
    align-items: flex-end !important;
  }
  .align-md-center {
    align-items: center !important;
  }
  .align-md-baseline {
    align-items: baseline !important;
  }
  .align-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 0.75rem !important;
  }
  .m-md-4 {
    margin: 1rem !important;
  }
  .m-md-5 {
    margin: 1.25rem !important;
  }
  .m-md-6 {
    margin: 1.5rem !important;
  }
  .m-md-7 {
    margin: 1.75rem !important;
  }
  .m-md-8 {
    margin: 2rem !important;
  }
  .m-md-9 {
    margin: 2.25rem !important;
  }
  .m-md-10 {
    margin: 2.5rem !important;
  }
  .m-md-11 {
    margin: 2.75rem !important;
  }
  .m-md-12 {
    margin: 3rem !important;
  }
  .m-md-13 {
    margin: 3.25rem !important;
  }
  .m-md-14 {
    margin: 3.5rem !important;
  }
  .m-md-15 {
    margin: 3.75rem !important;
  }
  .m-md-16 {
    margin: 4rem !important;
  }
  .m-md-17 {
    margin: 4.25rem !important;
  }
  .m-md-18 {
    margin: 4.5rem !important;
  }
  .m-md-19 {
    margin: 4.75rem !important;
  }
  .m-md-20 {
    margin: 5rem !important;
  }
  .m-md-21 {
    margin: 5.25rem !important;
  }
  .m-md-22 {
    margin: 5.5rem !important;
  }
  .m-md-23 {
    margin: 5.75rem !important;
  }
  .m-md-24 {
    margin: 6rem !important;
  }
  .m-md-25 {
    margin: 6.25rem !important;
  }
  .m-md-26 {
    margin: 6.5rem !important;
  }
  .m-md-27 {
    margin: 6.75rem !important;
  }
  .m-md-28 {
    margin: 7rem !important;
  }
  .m-md-29 {
    margin: 7.25rem !important;
  }
  .m-md-30 {
    margin: 7.5rem !important;
  }
  .m-md-31 {
    margin: 7.75rem !important;
  }
  .m-md-32 {
    margin: 8.25rem !important;
  }
  .m-md-33 {
    margin: 8.5rem !important;
  }
  .m-md-34 {
    margin: 8.75rem !important;
  }
  .m-md-35 {
    margin: 9rem !important;
  }
  .m-md-36 {
    margin: 9.25rem !important;
  }
  .m-md-37 {
    margin: 9.5rem !important;
  }
  .m-md-38 {
    margin: 9.75rem !important;
  }
  .m-md-39 {
    margin: 10rem !important;
  }
  .m-md-40 {
    margin: 10.25rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-md-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-md-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }
  .mx-md-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-md-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-md-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-md-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }
  .mx-md-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }
  .mx-md-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-md-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-md-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-md-17 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }
  .mx-md-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-md-19 {
    margin-right: 4.75rem !important;
    margin-left: 4.75rem !important;
  }
  .mx-md-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-md-21 {
    margin-right: 5.25rem !important;
    margin-left: 5.25rem !important;
  }
  .mx-md-22 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }
  .mx-md-23 {
    margin-right: 5.75rem !important;
    margin-left: 5.75rem !important;
  }
  .mx-md-24 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-md-25 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }
  .mx-md-26 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }
  .mx-md-27 {
    margin-right: 6.75rem !important;
    margin-left: 6.75rem !important;
  }
  .mx-md-28 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-md-29 {
    margin-right: 7.25rem !important;
    margin-left: 7.25rem !important;
  }
  .mx-md-30 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-md-31 {
    margin-right: 7.75rem !important;
    margin-left: 7.75rem !important;
  }
  .mx-md-32 {
    margin-right: 8.25rem !important;
    margin-left: 8.25rem !important;
  }
  .mx-md-33 {
    margin-right: 8.5rem !important;
    margin-left: 8.5rem !important;
  }
  .mx-md-34 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important;
  }
  .mx-md-35 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-md-36 {
    margin-right: 9.25rem !important;
    margin-left: 9.25rem !important;
  }
  .mx-md-37 {
    margin-right: 9.5rem !important;
    margin-left: 9.5rem !important;
  }
  .mx-md-38 {
    margin-right: 9.75rem !important;
    margin-left: 9.75rem !important;
  }
  .mx-md-39 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-md-40 {
    margin-right: 10.25rem !important;
    margin-left: 10.25rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-md-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-md-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }
  .my-md-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-md-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-md-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-md-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }
  .my-md-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }
  .my-md-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-md-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-md-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-md-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }
  .my-md-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-md-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }
  .my-md-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-md-21 {
    margin-top: 5.25rem !important;
    margin-bottom: 5.25rem !important;
  }
  .my-md-22 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }
  .my-md-23 {
    margin-top: 5.75rem !important;
    margin-bottom: 5.75rem !important;
  }
  .my-md-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-md-25 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }
  .my-md-26 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }
  .my-md-27 {
    margin-top: 6.75rem !important;
    margin-bottom: 6.75rem !important;
  }
  .my-md-28 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-md-29 {
    margin-top: 7.25rem !important;
    margin-bottom: 7.25rem !important;
  }
  .my-md-30 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-md-31 {
    margin-top: 7.75rem !important;
    margin-bottom: 7.75rem !important;
  }
  .my-md-32 {
    margin-top: 8.25rem !important;
    margin-bottom: 8.25rem !important;
  }
  .my-md-33 {
    margin-top: 8.5rem !important;
    margin-bottom: 8.5rem !important;
  }
  .my-md-34 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important;
  }
  .my-md-35 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-md-36 {
    margin-top: 9.25rem !important;
    margin-bottom: 9.25rem !important;
  }
  .my-md-37 {
    margin-top: 9.5rem !important;
    margin-bottom: 9.5rem !important;
  }
  .my-md-38 {
    margin-top: 9.75rem !important;
    margin-bottom: 9.75rem !important;
  }
  .my-md-39 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-md-40 {
    margin-top: 10.25rem !important;
    margin-bottom: 10.25rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 0.75rem !important;
  }
  .mt-md-4 {
    margin-top: 1rem !important;
  }
  .mt-md-5 {
    margin-top: 1.25rem !important;
  }
  .mt-md-6 {
    margin-top: 1.5rem !important;
  }
  .mt-md-7 {
    margin-top: 1.75rem !important;
  }
  .mt-md-8 {
    margin-top: 2rem !important;
  }
  .mt-md-9 {
    margin-top: 2.25rem !important;
  }
  .mt-md-10 {
    margin-top: 2.5rem !important;
  }
  .mt-md-11 {
    margin-top: 2.75rem !important;
  }
  .mt-md-12 {
    margin-top: 3rem !important;
  }
  .mt-md-13 {
    margin-top: 3.25rem !important;
  }
  .mt-md-14 {
    margin-top: 3.5rem !important;
  }
  .mt-md-15 {
    margin-top: 3.75rem !important;
  }
  .mt-md-16 {
    margin-top: 4rem !important;
  }
  .mt-md-17 {
    margin-top: 4.25rem !important;
  }
  .mt-md-18 {
    margin-top: 4.5rem !important;
  }
  .mt-md-19 {
    margin-top: 4.75rem !important;
  }
  .mt-md-20 {
    margin-top: 5rem !important;
  }
  .mt-md-21 {
    margin-top: 5.25rem !important;
  }
  .mt-md-22 {
    margin-top: 5.5rem !important;
  }
  .mt-md-23 {
    margin-top: 5.75rem !important;
  }
  .mt-md-24 {
    margin-top: 6rem !important;
  }
  .mt-md-25 {
    margin-top: 6.25rem !important;
  }
  .mt-md-26 {
    margin-top: 6.5rem !important;
  }
  .mt-md-27 {
    margin-top: 6.75rem !important;
  }
  .mt-md-28 {
    margin-top: 7rem !important;
  }
  .mt-md-29 {
    margin-top: 7.25rem !important;
  }
  .mt-md-30 {
    margin-top: 7.5rem !important;
  }
  .mt-md-31 {
    margin-top: 7.75rem !important;
  }
  .mt-md-32 {
    margin-top: 8.25rem !important;
  }
  .mt-md-33 {
    margin-top: 8.5rem !important;
  }
  .mt-md-34 {
    margin-top: 8.75rem !important;
  }
  .mt-md-35 {
    margin-top: 9rem !important;
  }
  .mt-md-36 {
    margin-top: 9.25rem !important;
  }
  .mt-md-37 {
    margin-top: 9.5rem !important;
  }
  .mt-md-38 {
    margin-top: 9.75rem !important;
  }
  .mt-md-39 {
    margin-top: 10rem !important;
  }
  .mt-md-40 {
    margin-top: 10.25rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 0.75rem !important;
  }
  .me-md-4 {
    margin-right: 1rem !important;
  }
  .me-md-5 {
    margin-right: 1.25rem !important;
  }
  .me-md-6 {
    margin-right: 1.5rem !important;
  }
  .me-md-7 {
    margin-right: 1.75rem !important;
  }
  .me-md-8 {
    margin-right: 2rem !important;
  }
  .me-md-9 {
    margin-right: 2.25rem !important;
  }
  .me-md-10 {
    margin-right: 2.5rem !important;
  }
  .me-md-11 {
    margin-right: 2.75rem !important;
  }
  .me-md-12 {
    margin-right: 3rem !important;
  }
  .me-md-13 {
    margin-right: 3.25rem !important;
  }
  .me-md-14 {
    margin-right: 3.5rem !important;
  }
  .me-md-15 {
    margin-right: 3.75rem !important;
  }
  .me-md-16 {
    margin-right: 4rem !important;
  }
  .me-md-17 {
    margin-right: 4.25rem !important;
  }
  .me-md-18 {
    margin-right: 4.5rem !important;
  }
  .me-md-19 {
    margin-right: 4.75rem !important;
  }
  .me-md-20 {
    margin-right: 5rem !important;
  }
  .me-md-21 {
    margin-right: 5.25rem !important;
  }
  .me-md-22 {
    margin-right: 5.5rem !important;
  }
  .me-md-23 {
    margin-right: 5.75rem !important;
  }
  .me-md-24 {
    margin-right: 6rem !important;
  }
  .me-md-25 {
    margin-right: 6.25rem !important;
  }
  .me-md-26 {
    margin-right: 6.5rem !important;
  }
  .me-md-27 {
    margin-right: 6.75rem !important;
  }
  .me-md-28 {
    margin-right: 7rem !important;
  }
  .me-md-29 {
    margin-right: 7.25rem !important;
  }
  .me-md-30 {
    margin-right: 7.5rem !important;
  }
  .me-md-31 {
    margin-right: 7.75rem !important;
  }
  .me-md-32 {
    margin-right: 8.25rem !important;
  }
  .me-md-33 {
    margin-right: 8.5rem !important;
  }
  .me-md-34 {
    margin-right: 8.75rem !important;
  }
  .me-md-35 {
    margin-right: 9rem !important;
  }
  .me-md-36 {
    margin-right: 9.25rem !important;
  }
  .me-md-37 {
    margin-right: 9.5rem !important;
  }
  .me-md-38 {
    margin-right: 9.75rem !important;
  }
  .me-md-39 {
    margin-right: 10rem !important;
  }
  .me-md-40 {
    margin-right: 10.25rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1rem !important;
  }
  .mb-md-5 {
    margin-bottom: 1.25rem !important;
  }
  .mb-md-6 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-7 {
    margin-bottom: 1.75rem !important;
  }
  .mb-md-8 {
    margin-bottom: 2rem !important;
  }
  .mb-md-9 {
    margin-bottom: 2.25rem !important;
  }
  .mb-md-10 {
    margin-bottom: 2.5rem !important;
  }
  .mb-md-11 {
    margin-bottom: 2.75rem !important;
  }
  .mb-md-12 {
    margin-bottom: 3rem !important;
  }
  .mb-md-13 {
    margin-bottom: 3.25rem !important;
  }
  .mb-md-14 {
    margin-bottom: 3.5rem !important;
  }
  .mb-md-15 {
    margin-bottom: 3.75rem !important;
  }
  .mb-md-16 {
    margin-bottom: 4rem !important;
  }
  .mb-md-17 {
    margin-bottom: 4.25rem !important;
  }
  .mb-md-18 {
    margin-bottom: 4.5rem !important;
  }
  .mb-md-19 {
    margin-bottom: 4.75rem !important;
  }
  .mb-md-20 {
    margin-bottom: 5rem !important;
  }
  .mb-md-21 {
    margin-bottom: 5.25rem !important;
  }
  .mb-md-22 {
    margin-bottom: 5.5rem !important;
  }
  .mb-md-23 {
    margin-bottom: 5.75rem !important;
  }
  .mb-md-24 {
    margin-bottom: 6rem !important;
  }
  .mb-md-25 {
    margin-bottom: 6.25rem !important;
  }
  .mb-md-26 {
    margin-bottom: 6.5rem !important;
  }
  .mb-md-27 {
    margin-bottom: 6.75rem !important;
  }
  .mb-md-28 {
    margin-bottom: 7rem !important;
  }
  .mb-md-29 {
    margin-bottom: 7.25rem !important;
  }
  .mb-md-30 {
    margin-bottom: 7.5rem !important;
  }
  .mb-md-31 {
    margin-bottom: 7.75rem !important;
  }
  .mb-md-32 {
    margin-bottom: 8.25rem !important;
  }
  .mb-md-33 {
    margin-bottom: 8.5rem !important;
  }
  .mb-md-34 {
    margin-bottom: 8.75rem !important;
  }
  .mb-md-35 {
    margin-bottom: 9rem !important;
  }
  .mb-md-36 {
    margin-bottom: 9.25rem !important;
  }
  .mb-md-37 {
    margin-bottom: 9.5rem !important;
  }
  .mb-md-38 {
    margin-bottom: 9.75rem !important;
  }
  .mb-md-39 {
    margin-bottom: 10rem !important;
  }
  .mb-md-40 {
    margin-bottom: 10.25rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 0.75rem !important;
  }
  .ms-md-4 {
    margin-left: 1rem !important;
  }
  .ms-md-5 {
    margin-left: 1.25rem !important;
  }
  .ms-md-6 {
    margin-left: 1.5rem !important;
  }
  .ms-md-7 {
    margin-left: 1.75rem !important;
  }
  .ms-md-8 {
    margin-left: 2rem !important;
  }
  .ms-md-9 {
    margin-left: 2.25rem !important;
  }
  .ms-md-10 {
    margin-left: 2.5rem !important;
  }
  .ms-md-11 {
    margin-left: 2.75rem !important;
  }
  .ms-md-12 {
    margin-left: 3rem !important;
  }
  .ms-md-13 {
    margin-left: 3.25rem !important;
  }
  .ms-md-14 {
    margin-left: 3.5rem !important;
  }
  .ms-md-15 {
    margin-left: 3.75rem !important;
  }
  .ms-md-16 {
    margin-left: 4rem !important;
  }
  .ms-md-17 {
    margin-left: 4.25rem !important;
  }
  .ms-md-18 {
    margin-left: 4.5rem !important;
  }
  .ms-md-19 {
    margin-left: 4.75rem !important;
  }
  .ms-md-20 {
    margin-left: 5rem !important;
  }
  .ms-md-21 {
    margin-left: 5.25rem !important;
  }
  .ms-md-22 {
    margin-left: 5.5rem !important;
  }
  .ms-md-23 {
    margin-left: 5.75rem !important;
  }
  .ms-md-24 {
    margin-left: 6rem !important;
  }
  .ms-md-25 {
    margin-left: 6.25rem !important;
  }
  .ms-md-26 {
    margin-left: 6.5rem !important;
  }
  .ms-md-27 {
    margin-left: 6.75rem !important;
  }
  .ms-md-28 {
    margin-left: 7rem !important;
  }
  .ms-md-29 {
    margin-left: 7.25rem !important;
  }
  .ms-md-30 {
    margin-left: 7.5rem !important;
  }
  .ms-md-31 {
    margin-left: 7.75rem !important;
  }
  .ms-md-32 {
    margin-left: 8.25rem !important;
  }
  .ms-md-33 {
    margin-left: 8.5rem !important;
  }
  .ms-md-34 {
    margin-left: 8.75rem !important;
  }
  .ms-md-35 {
    margin-left: 9rem !important;
  }
  .ms-md-36 {
    margin-left: 9.25rem !important;
  }
  .ms-md-37 {
    margin-left: 9.5rem !important;
  }
  .ms-md-38 {
    margin-left: 9.75rem !important;
  }
  .ms-md-39 {
    margin-left: 10rem !important;
  }
  .ms-md-40 {
    margin-left: 10.25rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -0.75rem !important;
  }
  .m-md-n4 {
    margin: -1rem !important;
  }
  .m-md-n5 {
    margin: -1.25rem !important;
  }
  .m-md-n6 {
    margin: -1.5rem !important;
  }
  .m-md-n7 {
    margin: -1.75rem !important;
  }
  .m-md-n8 {
    margin: -2rem !important;
  }
  .m-md-n9 {
    margin: -2.25rem !important;
  }
  .m-md-n10 {
    margin: -2.5rem !important;
  }
  .m-md-n11 {
    margin: -2.75rem !important;
  }
  .m-md-n12 {
    margin: -3rem !important;
  }
  .m-md-n13 {
    margin: -3.25rem !important;
  }
  .m-md-n14 {
    margin: -3.5rem !important;
  }
  .m-md-n15 {
    margin: -3.75rem !important;
  }
  .m-md-n16 {
    margin: -4rem !important;
  }
  .m-md-n17 {
    margin: -4.25rem !important;
  }
  .m-md-n18 {
    margin: -4.5rem !important;
  }
  .m-md-n19 {
    margin: -4.75rem !important;
  }
  .m-md-n20 {
    margin: -5rem !important;
  }
  .m-md-n21 {
    margin: -5.25rem !important;
  }
  .m-md-n22 {
    margin: -5.5rem !important;
  }
  .m-md-n23 {
    margin: -5.75rem !important;
  }
  .m-md-n24 {
    margin: -6rem !important;
  }
  .m-md-n25 {
    margin: -6.25rem !important;
  }
  .m-md-n26 {
    margin: -6.5rem !important;
  }
  .m-md-n27 {
    margin: -6.75rem !important;
  }
  .m-md-n28 {
    margin: -7rem !important;
  }
  .m-md-n29 {
    margin: -7.25rem !important;
  }
  .m-md-n30 {
    margin: -7.5rem !important;
  }
  .m-md-n31 {
    margin: -7.75rem !important;
  }
  .m-md-n32 {
    margin: -8.25rem !important;
  }
  .m-md-n33 {
    margin: -8.5rem !important;
  }
  .m-md-n34 {
    margin: -8.75rem !important;
  }
  .m-md-n35 {
    margin: -9rem !important;
  }
  .m-md-n36 {
    margin: -9.25rem !important;
  }
  .m-md-n37 {
    margin: -9.5rem !important;
  }
  .m-md-n38 {
    margin: -9.75rem !important;
  }
  .m-md-n39 {
    margin: -10rem !important;
  }
  .m-md-n40 {
    margin: -10.25rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-md-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-md-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }
  .mx-md-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-md-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }
  .mx-md-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-md-n11 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }
  .mx-md-n12 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-md-n13 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }
  .mx-md-n14 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-md-n15 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }
  .mx-md-n16 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-md-n17 {
    margin-right: -4.25rem !important;
    margin-left: -4.25rem !important;
  }
  .mx-md-n18 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .mx-md-n19 {
    margin-right: -4.75rem !important;
    margin-left: -4.75rem !important;
  }
  .mx-md-n20 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-md-n21 {
    margin-right: -5.25rem !important;
    margin-left: -5.25rem !important;
  }
  .mx-md-n22 {
    margin-right: -5.5rem !important;
    margin-left: -5.5rem !important;
  }
  .mx-md-n23 {
    margin-right: -5.75rem !important;
    margin-left: -5.75rem !important;
  }
  .mx-md-n24 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-md-n25 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important;
  }
  .mx-md-n26 {
    margin-right: -6.5rem !important;
    margin-left: -6.5rem !important;
  }
  .mx-md-n27 {
    margin-right: -6.75rem !important;
    margin-left: -6.75rem !important;
  }
  .mx-md-n28 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }
  .mx-md-n29 {
    margin-right: -7.25rem !important;
    margin-left: -7.25rem !important;
  }
  .mx-md-n30 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  .mx-md-n31 {
    margin-right: -7.75rem !important;
    margin-left: -7.75rem !important;
  }
  .mx-md-n32 {
    margin-right: -8.25rem !important;
    margin-left: -8.25rem !important;
  }
  .mx-md-n33 {
    margin-right: -8.5rem !important;
    margin-left: -8.5rem !important;
  }
  .mx-md-n34 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important;
  }
  .mx-md-n35 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  .mx-md-n36 {
    margin-right: -9.25rem !important;
    margin-left: -9.25rem !important;
  }
  .mx-md-n37 {
    margin-right: -9.5rem !important;
    margin-left: -9.5rem !important;
  }
  .mx-md-n38 {
    margin-right: -9.75rem !important;
    margin-left: -9.75rem !important;
  }
  .mx-md-n39 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .mx-md-n40 {
    margin-right: -10.25rem !important;
    margin-left: -10.25rem !important;
  }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-md-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-md-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }
  .my-md-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-md-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }
  .my-md-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-md-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }
  .my-md-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-md-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }
  .my-md-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-md-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }
  .my-md-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-md-n17 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }
  .my-md-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .my-md-n19 {
    margin-top: -4.75rem !important;
    margin-bottom: -4.75rem !important;
  }
  .my-md-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-md-n21 {
    margin-top: -5.25rem !important;
    margin-bottom: -5.25rem !important;
  }
  .my-md-n22 {
    margin-top: -5.5rem !important;
    margin-bottom: -5.5rem !important;
  }
  .my-md-n23 {
    margin-top: -5.75rem !important;
    margin-bottom: -5.75rem !important;
  }
  .my-md-n24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-md-n25 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important;
  }
  .my-md-n26 {
    margin-top: -6.5rem !important;
    margin-bottom: -6.5rem !important;
  }
  .my-md-n27 {
    margin-top: -6.75rem !important;
    margin-bottom: -6.75rem !important;
  }
  .my-md-n28 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }
  .my-md-n29 {
    margin-top: -7.25rem !important;
    margin-bottom: -7.25rem !important;
  }
  .my-md-n30 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .my-md-n31 {
    margin-top: -7.75rem !important;
    margin-bottom: -7.75rem !important;
  }
  .my-md-n32 {
    margin-top: -8.25rem !important;
    margin-bottom: -8.25rem !important;
  }
  .my-md-n33 {
    margin-top: -8.5rem !important;
    margin-bottom: -8.5rem !important;
  }
  .my-md-n34 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important;
  }
  .my-md-n35 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  .my-md-n36 {
    margin-top: -9.25rem !important;
    margin-bottom: -9.25rem !important;
  }
  .my-md-n37 {
    margin-top: -9.5rem !important;
    margin-bottom: -9.5rem !important;
  }
  .my-md-n38 {
    margin-top: -9.75rem !important;
    margin-bottom: -9.75rem !important;
  }
  .my-md-n39 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .my-md-n40 {
    margin-top: -10.25rem !important;
    margin-bottom: -10.25rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -0.75rem !important;
  }
  .mt-md-n4 {
    margin-top: -1rem !important;
  }
  .mt-md-n5 {
    margin-top: -1.25rem !important;
  }
  .mt-md-n6 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n7 {
    margin-top: -1.75rem !important;
  }
  .mt-md-n8 {
    margin-top: -2rem !important;
  }
  .mt-md-n9 {
    margin-top: -2.25rem !important;
  }
  .mt-md-n10 {
    margin-top: -2.5rem !important;
  }
  .mt-md-n11 {
    margin-top: -2.75rem !important;
  }
  .mt-md-n12 {
    margin-top: -3rem !important;
  }
  .mt-md-n13 {
    margin-top: -3.25rem !important;
  }
  .mt-md-n14 {
    margin-top: -3.5rem !important;
  }
  .mt-md-n15 {
    margin-top: -3.75rem !important;
  }
  .mt-md-n16 {
    margin-top: -4rem !important;
  }
  .mt-md-n17 {
    margin-top: -4.25rem !important;
  }
  .mt-md-n18 {
    margin-top: -4.5rem !important;
  }
  .mt-md-n19 {
    margin-top: -4.75rem !important;
  }
  .mt-md-n20 {
    margin-top: -5rem !important;
  }
  .mt-md-n21 {
    margin-top: -5.25rem !important;
  }
  .mt-md-n22 {
    margin-top: -5.5rem !important;
  }
  .mt-md-n23 {
    margin-top: -5.75rem !important;
  }
  .mt-md-n24 {
    margin-top: -6rem !important;
  }
  .mt-md-n25 {
    margin-top: -6.25rem !important;
  }
  .mt-md-n26 {
    margin-top: -6.5rem !important;
  }
  .mt-md-n27 {
    margin-top: -6.75rem !important;
  }
  .mt-md-n28 {
    margin-top: -7rem !important;
  }
  .mt-md-n29 {
    margin-top: -7.25rem !important;
  }
  .mt-md-n30 {
    margin-top: -7.5rem !important;
  }
  .mt-md-n31 {
    margin-top: -7.75rem !important;
  }
  .mt-md-n32 {
    margin-top: -8.25rem !important;
  }
  .mt-md-n33 {
    margin-top: -8.5rem !important;
  }
  .mt-md-n34 {
    margin-top: -8.75rem !important;
  }
  .mt-md-n35 {
    margin-top: -9rem !important;
  }
  .mt-md-n36 {
    margin-top: -9.25rem !important;
  }
  .mt-md-n37 {
    margin-top: -9.5rem !important;
  }
  .mt-md-n38 {
    margin-top: -9.75rem !important;
  }
  .mt-md-n39 {
    margin-top: -10rem !important;
  }
  .mt-md-n40 {
    margin-top: -10.25rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -0.75rem !important;
  }
  .me-md-n4 {
    margin-right: -1rem !important;
  }
  .me-md-n5 {
    margin-right: -1.25rem !important;
  }
  .me-md-n6 {
    margin-right: -1.5rem !important;
  }
  .me-md-n7 {
    margin-right: -1.75rem !important;
  }
  .me-md-n8 {
    margin-right: -2rem !important;
  }
  .me-md-n9 {
    margin-right: -2.25rem !important;
  }
  .me-md-n10 {
    margin-right: -2.5rem !important;
  }
  .me-md-n11 {
    margin-right: -2.75rem !important;
  }
  .me-md-n12 {
    margin-right: -3rem !important;
  }
  .me-md-n13 {
    margin-right: -3.25rem !important;
  }
  .me-md-n14 {
    margin-right: -3.5rem !important;
  }
  .me-md-n15 {
    margin-right: -3.75rem !important;
  }
  .me-md-n16 {
    margin-right: -4rem !important;
  }
  .me-md-n17 {
    margin-right: -4.25rem !important;
  }
  .me-md-n18 {
    margin-right: -4.5rem !important;
  }
  .me-md-n19 {
    margin-right: -4.75rem !important;
  }
  .me-md-n20 {
    margin-right: -5rem !important;
  }
  .me-md-n21 {
    margin-right: -5.25rem !important;
  }
  .me-md-n22 {
    margin-right: -5.5rem !important;
  }
  .me-md-n23 {
    margin-right: -5.75rem !important;
  }
  .me-md-n24 {
    margin-right: -6rem !important;
  }
  .me-md-n25 {
    margin-right: -6.25rem !important;
  }
  .me-md-n26 {
    margin-right: -6.5rem !important;
  }
  .me-md-n27 {
    margin-right: -6.75rem !important;
  }
  .me-md-n28 {
    margin-right: -7rem !important;
  }
  .me-md-n29 {
    margin-right: -7.25rem !important;
  }
  .me-md-n30 {
    margin-right: -7.5rem !important;
  }
  .me-md-n31 {
    margin-right: -7.75rem !important;
  }
  .me-md-n32 {
    margin-right: -8.25rem !important;
  }
  .me-md-n33 {
    margin-right: -8.5rem !important;
  }
  .me-md-n34 {
    margin-right: -8.75rem !important;
  }
  .me-md-n35 {
    margin-right: -9rem !important;
  }
  .me-md-n36 {
    margin-right: -9.25rem !important;
  }
  .me-md-n37 {
    margin-right: -9.5rem !important;
  }
  .me-md-n38 {
    margin-right: -9.75rem !important;
  }
  .me-md-n39 {
    margin-right: -10rem !important;
  }
  .me-md-n40 {
    margin-right: -10.25rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -0.75rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -1.25rem !important;
  }
  .mb-md-n6 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n7 {
    margin-bottom: -1.75rem !important;
  }
  .mb-md-n8 {
    margin-bottom: -2rem !important;
  }
  .mb-md-n9 {
    margin-bottom: -2.25rem !important;
  }
  .mb-md-n10 {
    margin-bottom: -2.5rem !important;
  }
  .mb-md-n11 {
    margin-bottom: -2.75rem !important;
  }
  .mb-md-n12 {
    margin-bottom: -3rem !important;
  }
  .mb-md-n13 {
    margin-bottom: -3.25rem !important;
  }
  .mb-md-n14 {
    margin-bottom: -3.5rem !important;
  }
  .mb-md-n15 {
    margin-bottom: -3.75rem !important;
  }
  .mb-md-n16 {
    margin-bottom: -4rem !important;
  }
  .mb-md-n17 {
    margin-bottom: -4.25rem !important;
  }
  .mb-md-n18 {
    margin-bottom: -4.5rem !important;
  }
  .mb-md-n19 {
    margin-bottom: -4.75rem !important;
  }
  .mb-md-n20 {
    margin-bottom: -5rem !important;
  }
  .mb-md-n21 {
    margin-bottom: -5.25rem !important;
  }
  .mb-md-n22 {
    margin-bottom: -5.5rem !important;
  }
  .mb-md-n23 {
    margin-bottom: -5.75rem !important;
  }
  .mb-md-n24 {
    margin-bottom: -6rem !important;
  }
  .mb-md-n25 {
    margin-bottom: -6.25rem !important;
  }
  .mb-md-n26 {
    margin-bottom: -6.5rem !important;
  }
  .mb-md-n27 {
    margin-bottom: -6.75rem !important;
  }
  .mb-md-n28 {
    margin-bottom: -7rem !important;
  }
  .mb-md-n29 {
    margin-bottom: -7.25rem !important;
  }
  .mb-md-n30 {
    margin-bottom: -7.5rem !important;
  }
  .mb-md-n31 {
    margin-bottom: -7.75rem !important;
  }
  .mb-md-n32 {
    margin-bottom: -8.25rem !important;
  }
  .mb-md-n33 {
    margin-bottom: -8.5rem !important;
  }
  .mb-md-n34 {
    margin-bottom: -8.75rem !important;
  }
  .mb-md-n35 {
    margin-bottom: -9rem !important;
  }
  .mb-md-n36 {
    margin-bottom: -9.25rem !important;
  }
  .mb-md-n37 {
    margin-bottom: -9.5rem !important;
  }
  .mb-md-n38 {
    margin-bottom: -9.75rem !important;
  }
  .mb-md-n39 {
    margin-bottom: -10rem !important;
  }
  .mb-md-n40 {
    margin-bottom: -10.25rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -0.75rem !important;
  }
  .ms-md-n4 {
    margin-left: -1rem !important;
  }
  .ms-md-n5 {
    margin-left: -1.25rem !important;
  }
  .ms-md-n6 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n7 {
    margin-left: -1.75rem !important;
  }
  .ms-md-n8 {
    margin-left: -2rem !important;
  }
  .ms-md-n9 {
    margin-left: -2.25rem !important;
  }
  .ms-md-n10 {
    margin-left: -2.5rem !important;
  }
  .ms-md-n11 {
    margin-left: -2.75rem !important;
  }
  .ms-md-n12 {
    margin-left: -3rem !important;
  }
  .ms-md-n13 {
    margin-left: -3.25rem !important;
  }
  .ms-md-n14 {
    margin-left: -3.5rem !important;
  }
  .ms-md-n15 {
    margin-left: -3.75rem !important;
  }
  .ms-md-n16 {
    margin-left: -4rem !important;
  }
  .ms-md-n17 {
    margin-left: -4.25rem !important;
  }
  .ms-md-n18 {
    margin-left: -4.5rem !important;
  }
  .ms-md-n19 {
    margin-left: -4.75rem !important;
  }
  .ms-md-n20 {
    margin-left: -5rem !important;
  }
  .ms-md-n21 {
    margin-left: -5.25rem !important;
  }
  .ms-md-n22 {
    margin-left: -5.5rem !important;
  }
  .ms-md-n23 {
    margin-left: -5.75rem !important;
  }
  .ms-md-n24 {
    margin-left: -6rem !important;
  }
  .ms-md-n25 {
    margin-left: -6.25rem !important;
  }
  .ms-md-n26 {
    margin-left: -6.5rem !important;
  }
  .ms-md-n27 {
    margin-left: -6.75rem !important;
  }
  .ms-md-n28 {
    margin-left: -7rem !important;
  }
  .ms-md-n29 {
    margin-left: -7.25rem !important;
  }
  .ms-md-n30 {
    margin-left: -7.5rem !important;
  }
  .ms-md-n31 {
    margin-left: -7.75rem !important;
  }
  .ms-md-n32 {
    margin-left: -8.25rem !important;
  }
  .ms-md-n33 {
    margin-left: -8.5rem !important;
  }
  .ms-md-n34 {
    margin-left: -8.75rem !important;
  }
  .ms-md-n35 {
    margin-left: -9rem !important;
  }
  .ms-md-n36 {
    margin-left: -9.25rem !important;
  }
  .ms-md-n37 {
    margin-left: -9.5rem !important;
  }
  .ms-md-n38 {
    margin-left: -9.75rem !important;
  }
  .ms-md-n39 {
    margin-left: -10rem !important;
  }
  .ms-md-n40 {
    margin-left: -10.25rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 0.75rem !important;
  }
  .p-md-4 {
    padding: 1rem !important;
  }
  .p-md-5 {
    padding: 1.25rem !important;
  }
  .p-md-6 {
    padding: 1.5rem !important;
  }
  .p-md-7 {
    padding: 1.75rem !important;
  }
  .p-md-8 {
    padding: 2rem !important;
  }
  .p-md-9 {
    padding: 2.25rem !important;
  }
  .p-md-10 {
    padding: 2.5rem !important;
  }
  .p-md-11 {
    padding: 2.75rem !important;
  }
  .p-md-12 {
    padding: 3rem !important;
  }
  .p-md-13 {
    padding: 3.25rem !important;
  }
  .p-md-14 {
    padding: 3.5rem !important;
  }
  .p-md-15 {
    padding: 3.75rem !important;
  }
  .p-md-16 {
    padding: 4rem !important;
  }
  .p-md-17 {
    padding: 4.25rem !important;
  }
  .p-md-18 {
    padding: 4.5rem !important;
  }
  .p-md-19 {
    padding: 4.75rem !important;
  }
  .p-md-20 {
    padding: 5rem !important;
  }
  .p-md-21 {
    padding: 5.25rem !important;
  }
  .p-md-22 {
    padding: 5.5rem !important;
  }
  .p-md-23 {
    padding: 5.75rem !important;
  }
  .p-md-24 {
    padding: 6rem !important;
  }
  .p-md-25 {
    padding: 6.25rem !important;
  }
  .p-md-26 {
    padding: 6.5rem !important;
  }
  .p-md-27 {
    padding: 6.75rem !important;
  }
  .p-md-28 {
    padding: 7rem !important;
  }
  .p-md-29 {
    padding: 7.25rem !important;
  }
  .p-md-30 {
    padding: 7.5rem !important;
  }
  .p-md-31 {
    padding: 7.75rem !important;
  }
  .p-md-32 {
    padding: 8.25rem !important;
  }
  .p-md-33 {
    padding: 8.5rem !important;
  }
  .p-md-34 {
    padding: 8.75rem !important;
  }
  .p-md-35 {
    padding: 9rem !important;
  }
  .p-md-36 {
    padding: 9.25rem !important;
  }
  .p-md-37 {
    padding: 9.5rem !important;
  }
  .p-md-38 {
    padding: 9.75rem !important;
  }
  .p-md-39 {
    padding: 10rem !important;
  }
  .p-md-40 {
    padding: 10.25rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-md-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-md-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }
  .px-md-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-md-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-md-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-md-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }
  .px-md-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }
  .px-md-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-md-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-md-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-md-17 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }
  .px-md-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-md-19 {
    padding-right: 4.75rem !important;
    padding-left: 4.75rem !important;
  }
  .px-md-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-md-21 {
    padding-right: 5.25rem !important;
    padding-left: 5.25rem !important;
  }
  .px-md-22 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }
  .px-md-23 {
    padding-right: 5.75rem !important;
    padding-left: 5.75rem !important;
  }
  .px-md-24 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-md-25 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }
  .px-md-26 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }
  .px-md-27 {
    padding-right: 6.75rem !important;
    padding-left: 6.75rem !important;
  }
  .px-md-28 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-md-29 {
    padding-right: 7.25rem !important;
    padding-left: 7.25rem !important;
  }
  .px-md-30 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-md-31 {
    padding-right: 7.75rem !important;
    padding-left: 7.75rem !important;
  }
  .px-md-32 {
    padding-right: 8.25rem !important;
    padding-left: 8.25rem !important;
  }
  .px-md-33 {
    padding-right: 8.5rem !important;
    padding-left: 8.5rem !important;
  }
  .px-md-34 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important;
  }
  .px-md-35 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-md-36 {
    padding-right: 9.25rem !important;
    padding-left: 9.25rem !important;
  }
  .px-md-37 {
    padding-right: 9.5rem !important;
    padding-left: 9.5rem !important;
  }
  .px-md-38 {
    padding-right: 9.75rem !important;
    padding-left: 9.75rem !important;
  }
  .px-md-39 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-md-40 {
    padding-right: 10.25rem !important;
    padding-left: 10.25rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-md-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-md-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }
  .py-md-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-md-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-md-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-md-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }
  .py-md-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }
  .py-md-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-md-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-md-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-md-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }
  .py-md-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-md-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }
  .py-md-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-md-21 {
    padding-top: 5.25rem !important;
    padding-bottom: 5.25rem !important;
  }
  .py-md-22 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }
  .py-md-23 {
    padding-top: 5.75rem !important;
    padding-bottom: 5.75rem !important;
  }
  .py-md-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-md-25 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .py-md-26 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }
  .py-md-27 {
    padding-top: 6.75rem !important;
    padding-bottom: 6.75rem !important;
  }
  .py-md-28 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-md-29 {
    padding-top: 7.25rem !important;
    padding-bottom: 7.25rem !important;
  }
  .py-md-30 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-md-31 {
    padding-top: 7.75rem !important;
    padding-bottom: 7.75rem !important;
  }
  .py-md-32 {
    padding-top: 8.25rem !important;
    padding-bottom: 8.25rem !important;
  }
  .py-md-33 {
    padding-top: 8.5rem !important;
    padding-bottom: 8.5rem !important;
  }
  .py-md-34 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important;
  }
  .py-md-35 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-md-36 {
    padding-top: 9.25rem !important;
    padding-bottom: 9.25rem !important;
  }
  .py-md-37 {
    padding-top: 9.5rem !important;
    padding-bottom: 9.5rem !important;
  }
  .py-md-38 {
    padding-top: 9.75rem !important;
    padding-bottom: 9.75rem !important;
  }
  .py-md-39 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-md-40 {
    padding-top: 10.25rem !important;
    padding-bottom: 10.25rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 0.75rem !important;
  }
  .pt-md-4 {
    padding-top: 1rem !important;
  }
  .pt-md-5 {
    padding-top: 1.25rem !important;
  }
  .pt-md-6 {
    padding-top: 1.5rem !important;
  }
  .pt-md-7 {
    padding-top: 1.75rem !important;
  }
  .pt-md-8 {
    padding-top: 2rem !important;
  }
  .pt-md-9 {
    padding-top: 2.25rem !important;
  }
  .pt-md-10 {
    padding-top: 2.5rem !important;
  }
  .pt-md-11 {
    padding-top: 2.75rem !important;
  }
  .pt-md-12 {
    padding-top: 3rem !important;
  }
  .pt-md-13 {
    padding-top: 3.25rem !important;
  }
  .pt-md-14 {
    padding-top: 3.5rem !important;
  }
  .pt-md-15 {
    padding-top: 3.75rem !important;
  }
  .pt-md-16 {
    padding-top: 4rem !important;
  }
  .pt-md-17 {
    padding-top: 4.25rem !important;
  }
  .pt-md-18 {
    padding-top: 4.5rem !important;
  }
  .pt-md-19 {
    padding-top: 4.75rem !important;
  }
  .pt-md-20 {
    padding-top: 5rem !important;
  }
  .pt-md-21 {
    padding-top: 5.25rem !important;
  }
  .pt-md-22 {
    padding-top: 5.5rem !important;
  }
  .pt-md-23 {
    padding-top: 5.75rem !important;
  }
  .pt-md-24 {
    padding-top: 6rem !important;
  }
  .pt-md-25 {
    padding-top: 6.25rem !important;
  }
  .pt-md-26 {
    padding-top: 6.5rem !important;
  }
  .pt-md-27 {
    padding-top: 6.75rem !important;
  }
  .pt-md-28 {
    padding-top: 7rem !important;
  }
  .pt-md-29 {
    padding-top: 7.25rem !important;
  }
  .pt-md-30 {
    padding-top: 7.5rem !important;
  }
  .pt-md-31 {
    padding-top: 7.75rem !important;
  }
  .pt-md-32 {
    padding-top: 8.25rem !important;
  }
  .pt-md-33 {
    padding-top: 8.5rem !important;
  }
  .pt-md-34 {
    padding-top: 8.75rem !important;
  }
  .pt-md-35 {
    padding-top: 9rem !important;
  }
  .pt-md-36 {
    padding-top: 9.25rem !important;
  }
  .pt-md-37 {
    padding-top: 9.5rem !important;
  }
  .pt-md-38 {
    padding-top: 9.75rem !important;
  }
  .pt-md-39 {
    padding-top: 10rem !important;
  }
  .pt-md-40 {
    padding-top: 10.25rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 0.75rem !important;
  }
  .pe-md-4 {
    padding-right: 1rem !important;
  }
  .pe-md-5 {
    padding-right: 1.25rem !important;
  }
  .pe-md-6 {
    padding-right: 1.5rem !important;
  }
  .pe-md-7 {
    padding-right: 1.75rem !important;
  }
  .pe-md-8 {
    padding-right: 2rem !important;
  }
  .pe-md-9 {
    padding-right: 2.25rem !important;
  }
  .pe-md-10 {
    padding-right: 2.5rem !important;
  }
  .pe-md-11 {
    padding-right: 2.75rem !important;
  }
  .pe-md-12 {
    padding-right: 3rem !important;
  }
  .pe-md-13 {
    padding-right: 3.25rem !important;
  }
  .pe-md-14 {
    padding-right: 3.5rem !important;
  }
  .pe-md-15 {
    padding-right: 3.75rem !important;
  }
  .pe-md-16 {
    padding-right: 4rem !important;
  }
  .pe-md-17 {
    padding-right: 4.25rem !important;
  }
  .pe-md-18 {
    padding-right: 4.5rem !important;
  }
  .pe-md-19 {
    padding-right: 4.75rem !important;
  }
  .pe-md-20 {
    padding-right: 5rem !important;
  }
  .pe-md-21 {
    padding-right: 5.25rem !important;
  }
  .pe-md-22 {
    padding-right: 5.5rem !important;
  }
  .pe-md-23 {
    padding-right: 5.75rem !important;
  }
  .pe-md-24 {
    padding-right: 6rem !important;
  }
  .pe-md-25 {
    padding-right: 6.25rem !important;
  }
  .pe-md-26 {
    padding-right: 6.5rem !important;
  }
  .pe-md-27 {
    padding-right: 6.75rem !important;
  }
  .pe-md-28 {
    padding-right: 7rem !important;
  }
  .pe-md-29 {
    padding-right: 7.25rem !important;
  }
  .pe-md-30 {
    padding-right: 7.5rem !important;
  }
  .pe-md-31 {
    padding-right: 7.75rem !important;
  }
  .pe-md-32 {
    padding-right: 8.25rem !important;
  }
  .pe-md-33 {
    padding-right: 8.5rem !important;
  }
  .pe-md-34 {
    padding-right: 8.75rem !important;
  }
  .pe-md-35 {
    padding-right: 9rem !important;
  }
  .pe-md-36 {
    padding-right: 9.25rem !important;
  }
  .pe-md-37 {
    padding-right: 9.5rem !important;
  }
  .pe-md-38 {
    padding-right: 9.75rem !important;
  }
  .pe-md-39 {
    padding-right: 10rem !important;
  }
  .pe-md-40 {
    padding-right: 10.25rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1rem !important;
  }
  .pb-md-5 {
    padding-bottom: 1.25rem !important;
  }
  .pb-md-6 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-7 {
    padding-bottom: 1.75rem !important;
  }
  .pb-md-8 {
    padding-bottom: 2rem !important;
  }
  .pb-md-9 {
    padding-bottom: 2.25rem !important;
  }
  .pb-md-10 {
    padding-bottom: 2.5rem !important;
  }
  .pb-md-11 {
    padding-bottom: 2.75rem !important;
  }
  .pb-md-12 {
    padding-bottom: 3rem !important;
  }
  .pb-md-13 {
    padding-bottom: 3.25rem !important;
  }
  .pb-md-14 {
    padding-bottom: 3.5rem !important;
  }
  .pb-md-15 {
    padding-bottom: 3.75rem !important;
  }
  .pb-md-16 {
    padding-bottom: 4rem !important;
  }
  .pb-md-17 {
    padding-bottom: 4.25rem !important;
  }
  .pb-md-18 {
    padding-bottom: 4.5rem !important;
  }
  .pb-md-19 {
    padding-bottom: 4.75rem !important;
  }
  .pb-md-20 {
    padding-bottom: 5rem !important;
  }
  .pb-md-21 {
    padding-bottom: 5.25rem !important;
  }
  .pb-md-22 {
    padding-bottom: 5.5rem !important;
  }
  .pb-md-23 {
    padding-bottom: 5.75rem !important;
  }
  .pb-md-24 {
    padding-bottom: 6rem !important;
  }
  .pb-md-25 {
    padding-bottom: 6.25rem !important;
  }
  .pb-md-26 {
    padding-bottom: 6.5rem !important;
  }
  .pb-md-27 {
    padding-bottom: 6.75rem !important;
  }
  .pb-md-28 {
    padding-bottom: 7rem !important;
  }
  .pb-md-29 {
    padding-bottom: 7.25rem !important;
  }
  .pb-md-30 {
    padding-bottom: 7.5rem !important;
  }
  .pb-md-31 {
    padding-bottom: 7.75rem !important;
  }
  .pb-md-32 {
    padding-bottom: 8.25rem !important;
  }
  .pb-md-33 {
    padding-bottom: 8.5rem !important;
  }
  .pb-md-34 {
    padding-bottom: 8.75rem !important;
  }
  .pb-md-35 {
    padding-bottom: 9rem !important;
  }
  .pb-md-36 {
    padding-bottom: 9.25rem !important;
  }
  .pb-md-37 {
    padding-bottom: 9.5rem !important;
  }
  .pb-md-38 {
    padding-bottom: 9.75rem !important;
  }
  .pb-md-39 {
    padding-bottom: 10rem !important;
  }
  .pb-md-40 {
    padding-bottom: 10.25rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 0.75rem !important;
  }
  .ps-md-4 {
    padding-left: 1rem !important;
  }
  .ps-md-5 {
    padding-left: 1.25rem !important;
  }
  .ps-md-6 {
    padding-left: 1.5rem !important;
  }
  .ps-md-7 {
    padding-left: 1.75rem !important;
  }
  .ps-md-8 {
    padding-left: 2rem !important;
  }
  .ps-md-9 {
    padding-left: 2.25rem !important;
  }
  .ps-md-10 {
    padding-left: 2.5rem !important;
  }
  .ps-md-11 {
    padding-left: 2.75rem !important;
  }
  .ps-md-12 {
    padding-left: 3rem !important;
  }
  .ps-md-13 {
    padding-left: 3.25rem !important;
  }
  .ps-md-14 {
    padding-left: 3.5rem !important;
  }
  .ps-md-15 {
    padding-left: 3.75rem !important;
  }
  .ps-md-16 {
    padding-left: 4rem !important;
  }
  .ps-md-17 {
    padding-left: 4.25rem !important;
  }
  .ps-md-18 {
    padding-left: 4.5rem !important;
  }
  .ps-md-19 {
    padding-left: 4.75rem !important;
  }
  .ps-md-20 {
    padding-left: 5rem !important;
  }
  .ps-md-21 {
    padding-left: 5.25rem !important;
  }
  .ps-md-22 {
    padding-left: 5.5rem !important;
  }
  .ps-md-23 {
    padding-left: 5.75rem !important;
  }
  .ps-md-24 {
    padding-left: 6rem !important;
  }
  .ps-md-25 {
    padding-left: 6.25rem !important;
  }
  .ps-md-26 {
    padding-left: 6.5rem !important;
  }
  .ps-md-27 {
    padding-left: 6.75rem !important;
  }
  .ps-md-28 {
    padding-left: 7rem !important;
  }
  .ps-md-29 {
    padding-left: 7.25rem !important;
  }
  .ps-md-30 {
    padding-left: 7.5rem !important;
  }
  .ps-md-31 {
    padding-left: 7.75rem !important;
  }
  .ps-md-32 {
    padding-left: 8.25rem !important;
  }
  .ps-md-33 {
    padding-left: 8.5rem !important;
  }
  .ps-md-34 {
    padding-left: 8.75rem !important;
  }
  .ps-md-35 {
    padding-left: 9rem !important;
  }
  .ps-md-36 {
    padding-left: 9.25rem !important;
  }
  .ps-md-37 {
    padding-left: 9.5rem !important;
  }
  .ps-md-38 {
    padding-left: 9.75rem !important;
  }
  .ps-md-39 {
    padding-left: 10rem !important;
  }
  .ps-md-40 {
    padding-left: 10.25rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 0.75rem !important;
  }
  .gap-md-4 {
    gap: 1rem !important;
  }
  .gap-md-5 {
    gap: 1.25rem !important;
  }
  .gap-md-6 {
    gap: 1.5rem !important;
  }
  .gap-md-7 {
    gap: 1.75rem !important;
  }
  .gap-md-8 {
    gap: 2rem !important;
  }
  .gap-md-9 {
    gap: 2.25rem !important;
  }
  .gap-md-10 {
    gap: 2.5rem !important;
  }
  .gap-md-11 {
    gap: 2.75rem !important;
  }
  .gap-md-12 {
    gap: 3rem !important;
  }
  .gap-md-13 {
    gap: 3.25rem !important;
  }
  .gap-md-14 {
    gap: 3.5rem !important;
  }
  .gap-md-15 {
    gap: 3.75rem !important;
  }
  .gap-md-16 {
    gap: 4rem !important;
  }
  .gap-md-17 {
    gap: 4.25rem !important;
  }
  .gap-md-18 {
    gap: 4.5rem !important;
  }
  .gap-md-19 {
    gap: 4.75rem !important;
  }
  .gap-md-20 {
    gap: 5rem !important;
  }
  .gap-md-21 {
    gap: 5.25rem !important;
  }
  .gap-md-22 {
    gap: 5.5rem !important;
  }
  .gap-md-23 {
    gap: 5.75rem !important;
  }
  .gap-md-24 {
    gap: 6rem !important;
  }
  .gap-md-25 {
    gap: 6.25rem !important;
  }
  .gap-md-26 {
    gap: 6.5rem !important;
  }
  .gap-md-27 {
    gap: 6.75rem !important;
  }
  .gap-md-28 {
    gap: 7rem !important;
  }
  .gap-md-29 {
    gap: 7.25rem !important;
  }
  .gap-md-30 {
    gap: 7.5rem !important;
  }
  .gap-md-31 {
    gap: 7.75rem !important;
  }
  .gap-md-32 {
    gap: 8.25rem !important;
  }
  .gap-md-33 {
    gap: 8.5rem !important;
  }
  .gap-md-34 {
    gap: 8.75rem !important;
  }
  .gap-md-35 {
    gap: 9rem !important;
  }
  .gap-md-36 {
    gap: 9.25rem !important;
  }
  .gap-md-37 {
    gap: 9.5rem !important;
  }
  .gap-md-38 {
    gap: 9.75rem !important;
  }
  .gap-md-39 {
    gap: 10rem !important;
  }
  .gap-md-40 {
    gap: 10.25rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 0.75rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1rem !important;
  }
  .row-gap-md-5 {
    row-gap: 1.25rem !important;
  }
  .row-gap-md-6 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-7 {
    row-gap: 1.75rem !important;
  }
  .row-gap-md-8 {
    row-gap: 2rem !important;
  }
  .row-gap-md-9 {
    row-gap: 2.25rem !important;
  }
  .row-gap-md-10 {
    row-gap: 2.5rem !important;
  }
  .row-gap-md-11 {
    row-gap: 2.75rem !important;
  }
  .row-gap-md-12 {
    row-gap: 3rem !important;
  }
  .row-gap-md-13 {
    row-gap: 3.25rem !important;
  }
  .row-gap-md-14 {
    row-gap: 3.5rem !important;
  }
  .row-gap-md-15 {
    row-gap: 3.75rem !important;
  }
  .row-gap-md-16 {
    row-gap: 4rem !important;
  }
  .row-gap-md-17 {
    row-gap: 4.25rem !important;
  }
  .row-gap-md-18 {
    row-gap: 4.5rem !important;
  }
  .row-gap-md-19 {
    row-gap: 4.75rem !important;
  }
  .row-gap-md-20 {
    row-gap: 5rem !important;
  }
  .row-gap-md-21 {
    row-gap: 5.25rem !important;
  }
  .row-gap-md-22 {
    row-gap: 5.5rem !important;
  }
  .row-gap-md-23 {
    row-gap: 5.75rem !important;
  }
  .row-gap-md-24 {
    row-gap: 6rem !important;
  }
  .row-gap-md-25 {
    row-gap: 6.25rem !important;
  }
  .row-gap-md-26 {
    row-gap: 6.5rem !important;
  }
  .row-gap-md-27 {
    row-gap: 6.75rem !important;
  }
  .row-gap-md-28 {
    row-gap: 7rem !important;
  }
  .row-gap-md-29 {
    row-gap: 7.25rem !important;
  }
  .row-gap-md-30 {
    row-gap: 7.5rem !important;
  }
  .row-gap-md-31 {
    row-gap: 7.75rem !important;
  }
  .row-gap-md-32 {
    row-gap: 8.25rem !important;
  }
  .row-gap-md-33 {
    row-gap: 8.5rem !important;
  }
  .row-gap-md-34 {
    row-gap: 8.75rem !important;
  }
  .row-gap-md-35 {
    row-gap: 9rem !important;
  }
  .row-gap-md-36 {
    row-gap: 9.25rem !important;
  }
  .row-gap-md-37 {
    row-gap: 9.5rem !important;
  }
  .row-gap-md-38 {
    row-gap: 9.75rem !important;
  }
  .row-gap-md-39 {
    row-gap: 10rem !important;
  }
  .row-gap-md-40 {
    row-gap: 10.25rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    column-gap: 0.75rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1rem !important;
  }
  .column-gap-md-5 {
    column-gap: 1.25rem !important;
  }
  .column-gap-md-6 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-7 {
    column-gap: 1.75rem !important;
  }
  .column-gap-md-8 {
    column-gap: 2rem !important;
  }
  .column-gap-md-9 {
    column-gap: 2.25rem !important;
  }
  .column-gap-md-10 {
    column-gap: 2.5rem !important;
  }
  .column-gap-md-11 {
    column-gap: 2.75rem !important;
  }
  .column-gap-md-12 {
    column-gap: 3rem !important;
  }
  .column-gap-md-13 {
    column-gap: 3.25rem !important;
  }
  .column-gap-md-14 {
    column-gap: 3.5rem !important;
  }
  .column-gap-md-15 {
    column-gap: 3.75rem !important;
  }
  .column-gap-md-16 {
    column-gap: 4rem !important;
  }
  .column-gap-md-17 {
    column-gap: 4.25rem !important;
  }
  .column-gap-md-18 {
    column-gap: 4.5rem !important;
  }
  .column-gap-md-19 {
    column-gap: 4.75rem !important;
  }
  .column-gap-md-20 {
    column-gap: 5rem !important;
  }
  .column-gap-md-21 {
    column-gap: 5.25rem !important;
  }
  .column-gap-md-22 {
    column-gap: 5.5rem !important;
  }
  .column-gap-md-23 {
    column-gap: 5.75rem !important;
  }
  .column-gap-md-24 {
    column-gap: 6rem !important;
  }
  .column-gap-md-25 {
    column-gap: 6.25rem !important;
  }
  .column-gap-md-26 {
    column-gap: 6.5rem !important;
  }
  .column-gap-md-27 {
    column-gap: 6.75rem !important;
  }
  .column-gap-md-28 {
    column-gap: 7rem !important;
  }
  .column-gap-md-29 {
    column-gap: 7.25rem !important;
  }
  .column-gap-md-30 {
    column-gap: 7.5rem !important;
  }
  .column-gap-md-31 {
    column-gap: 7.75rem !important;
  }
  .column-gap-md-32 {
    column-gap: 8.25rem !important;
  }
  .column-gap-md-33 {
    column-gap: 8.5rem !important;
  }
  .column-gap-md-34 {
    column-gap: 8.75rem !important;
  }
  .column-gap-md-35 {
    column-gap: 9rem !important;
  }
  .column-gap-md-36 {
    column-gap: 9.25rem !important;
  }
  .column-gap-md-37 {
    column-gap: 9.5rem !important;
  }
  .column-gap-md-38 {
    column-gap: 9.75rem !important;
  }
  .column-gap-md-39 {
    column-gap: 10rem !important;
  }
  .column-gap-md-40 {
    column-gap: 10.25rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-lg-contain {
    object-fit: contain !important;
  }
  .object-lg-cover {
    object-fit: cover !important;
  }
  .object-lg-fill {
    object-fit: fill !important;
  }
  .object-lg-scale {
    object-fit: scale-down !important;
  }
  .object-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .w-lg-0 {
    width: 0 !important;
  }
  .w-lg-px {
    width: 1px !important;
  }
  .w-lg-2px {
    width: 2px !important;
  }
  .w-lg-1 {
    width: 0.25rem !important;
  }
  .w-lg-2 {
    width: 0.5rem !important;
  }
  .w-lg-3 {
    width: 0.75rem !important;
  }
  .w-lg-4 {
    width: 1rem !important;
  }
  .w-lg-5 {
    width: 1.25rem !important;
  }
  .w-lg-6 {
    width: 1.5rem !important;
  }
  .w-lg-7 {
    width: 1.75rem !important;
  }
  .w-lg-8 {
    width: 2rem !important;
  }
  .w-lg-9 {
    width: 2.25rem !important;
  }
  .w-lg-10 {
    width: 2.5rem !important;
  }
  .w-lg-11 {
    width: 2.75rem !important;
  }
  .w-lg-12 {
    width: 3rem !important;
  }
  .w-lg-13 {
    width: 3.25rem !important;
  }
  .w-lg-14 {
    width: 3.5rem !important;
  }
  .w-lg-15 {
    width: 3.75rem !important;
  }
  .w-lg-16 {
    width: 4rem !important;
  }
  .w-lg-17 {
    width: 4.25rem !important;
  }
  .w-lg-18 {
    width: 4.5rem !important;
  }
  .w-lg-19 {
    width: 4.75rem !important;
  }
  .w-lg-20 {
    width: 5rem !important;
  }
  .w-lg-21 {
    width: 5.25rem !important;
  }
  .w-lg-22 {
    width: 5.5rem !important;
  }
  .w-lg-23 {
    width: 5.75rem !important;
  }
  .w-lg-24 {
    width: 6rem !important;
  }
  .w-lg-25 {
    width: 6.25rem !important;
  }
  .w-lg-26 {
    width: 6.5rem !important;
  }
  .w-lg-27 {
    width: 6.75rem !important;
  }
  .w-lg-28 {
    width: 7rem !important;
  }
  .w-lg-29 {
    width: 7.25rem !important;
  }
  .w-lg-30 {
    width: 7.5rem !important;
  }
  .w-lg-31 {
    width: 7.75rem !important;
  }
  .w-lg-32 {
    width: 8rem !important;
  }
  .w-lg-quarter {
    width: 25% !important;
  }
  .w-lg-half {
    width: 50% !important;
  }
  .w-lg-3quarter {
    width: 75% !important;
  }
  .w-lg-full {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .h-lg-0 {
    height: 0 !important;
  }
  .h-lg-px {
    height: 1px !important;
  }
  .h-lg-2px {
    height: 2px !important;
  }
  .h-lg-1 {
    height: 0.25rem !important;
  }
  .h-lg-2 {
    height: 0.5rem !important;
  }
  .h-lg-3 {
    height: 0.75rem !important;
  }
  .h-lg-4 {
    height: 1rem !important;
  }
  .h-lg-5 {
    height: 1.25rem !important;
  }
  .h-lg-6 {
    height: 1.5rem !important;
  }
  .h-lg-7 {
    height: 1.75rem !important;
  }
  .h-lg-8 {
    height: 2rem !important;
  }
  .h-lg-9 {
    height: 2.25rem !important;
  }
  .h-lg-10 {
    height: 2.5rem !important;
  }
  .h-lg-11 {
    height: 2.75rem !important;
  }
  .h-lg-12 {
    height: 3rem !important;
  }
  .h-lg-13 {
    height: 3.25rem !important;
  }
  .h-lg-14 {
    height: 3.5rem !important;
  }
  .h-lg-15 {
    height: 3.75rem !important;
  }
  .h-lg-16 {
    height: 4rem !important;
  }
  .h-lg-17 {
    height: 4.25rem !important;
  }
  .h-lg-18 {
    height: 4.5rem !important;
  }
  .h-lg-19 {
    height: 4.75rem !important;
  }
  .h-lg-20 {
    height: 5rem !important;
  }
  .h-lg-21 {
    height: 5.25rem !important;
  }
  .h-lg-22 {
    height: 5.5rem !important;
  }
  .h-lg-23 {
    height: 5.75rem !important;
  }
  .h-lg-24 {
    height: 6rem !important;
  }
  .h-lg-25 {
    height: 6.25rem !important;
  }
  .h-lg-26 {
    height: 6.5rem !important;
  }
  .h-lg-27 {
    height: 6.75rem !important;
  }
  .h-lg-28 {
    height: 7rem !important;
  }
  .h-lg-29 {
    height: 7.25rem !important;
  }
  .h-lg-30 {
    height: 7.5rem !important;
  }
  .h-lg-31 {
    height: 7.75rem !important;
  }
  .h-lg-32 {
    height: 8rem !important;
  }
  .h-lg-quarter {
    height: 25% !important;
  }
  .h-lg-half {
    height: 50% !important;
  }
  .h-lg-3quarter {
    height: 75% !important;
  }
  .h-lg-full {
    height: 100% !important;
  }
  .h-lg-auto {
    height: auto !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-lg-start {
    justify-content: flex-start !important;
  }
  .justify-lg-end {
    justify-content: flex-end !important;
  }
  .justify-lg-center {
    justify-content: center !important;
  }
  .justify-lg-between {
    justify-content: space-between !important;
  }
  .justify-lg-around {
    justify-content: space-around !important;
  }
  .justify-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-lg-start {
    align-items: flex-start !important;
  }
  .align-lg-end {
    align-items: flex-end !important;
  }
  .align-lg-center {
    align-items: center !important;
  }
  .align-lg-baseline {
    align-items: baseline !important;
  }
  .align-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 0.75rem !important;
  }
  .m-lg-4 {
    margin: 1rem !important;
  }
  .m-lg-5 {
    margin: 1.25rem !important;
  }
  .m-lg-6 {
    margin: 1.5rem !important;
  }
  .m-lg-7 {
    margin: 1.75rem !important;
  }
  .m-lg-8 {
    margin: 2rem !important;
  }
  .m-lg-9 {
    margin: 2.25rem !important;
  }
  .m-lg-10 {
    margin: 2.5rem !important;
  }
  .m-lg-11 {
    margin: 2.75rem !important;
  }
  .m-lg-12 {
    margin: 3rem !important;
  }
  .m-lg-13 {
    margin: 3.25rem !important;
  }
  .m-lg-14 {
    margin: 3.5rem !important;
  }
  .m-lg-15 {
    margin: 3.75rem !important;
  }
  .m-lg-16 {
    margin: 4rem !important;
  }
  .m-lg-17 {
    margin: 4.25rem !important;
  }
  .m-lg-18 {
    margin: 4.5rem !important;
  }
  .m-lg-19 {
    margin: 4.75rem !important;
  }
  .m-lg-20 {
    margin: 5rem !important;
  }
  .m-lg-21 {
    margin: 5.25rem !important;
  }
  .m-lg-22 {
    margin: 5.5rem !important;
  }
  .m-lg-23 {
    margin: 5.75rem !important;
  }
  .m-lg-24 {
    margin: 6rem !important;
  }
  .m-lg-25 {
    margin: 6.25rem !important;
  }
  .m-lg-26 {
    margin: 6.5rem !important;
  }
  .m-lg-27 {
    margin: 6.75rem !important;
  }
  .m-lg-28 {
    margin: 7rem !important;
  }
  .m-lg-29 {
    margin: 7.25rem !important;
  }
  .m-lg-30 {
    margin: 7.5rem !important;
  }
  .m-lg-31 {
    margin: 7.75rem !important;
  }
  .m-lg-32 {
    margin: 8.25rem !important;
  }
  .m-lg-33 {
    margin: 8.5rem !important;
  }
  .m-lg-34 {
    margin: 8.75rem !important;
  }
  .m-lg-35 {
    margin: 9rem !important;
  }
  .m-lg-36 {
    margin: 9.25rem !important;
  }
  .m-lg-37 {
    margin: 9.5rem !important;
  }
  .m-lg-38 {
    margin: 9.75rem !important;
  }
  .m-lg-39 {
    margin: 10rem !important;
  }
  .m-lg-40 {
    margin: 10.25rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-lg-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-lg-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }
  .mx-lg-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-lg-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-lg-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-lg-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }
  .mx-lg-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }
  .mx-lg-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-lg-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-lg-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-lg-17 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }
  .mx-lg-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-lg-19 {
    margin-right: 4.75rem !important;
    margin-left: 4.75rem !important;
  }
  .mx-lg-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-lg-21 {
    margin-right: 5.25rem !important;
    margin-left: 5.25rem !important;
  }
  .mx-lg-22 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }
  .mx-lg-23 {
    margin-right: 5.75rem !important;
    margin-left: 5.75rem !important;
  }
  .mx-lg-24 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-lg-25 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }
  .mx-lg-26 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }
  .mx-lg-27 {
    margin-right: 6.75rem !important;
    margin-left: 6.75rem !important;
  }
  .mx-lg-28 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-lg-29 {
    margin-right: 7.25rem !important;
    margin-left: 7.25rem !important;
  }
  .mx-lg-30 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-lg-31 {
    margin-right: 7.75rem !important;
    margin-left: 7.75rem !important;
  }
  .mx-lg-32 {
    margin-right: 8.25rem !important;
    margin-left: 8.25rem !important;
  }
  .mx-lg-33 {
    margin-right: 8.5rem !important;
    margin-left: 8.5rem !important;
  }
  .mx-lg-34 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important;
  }
  .mx-lg-35 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-lg-36 {
    margin-right: 9.25rem !important;
    margin-left: 9.25rem !important;
  }
  .mx-lg-37 {
    margin-right: 9.5rem !important;
    margin-left: 9.5rem !important;
  }
  .mx-lg-38 {
    margin-right: 9.75rem !important;
    margin-left: 9.75rem !important;
  }
  .mx-lg-39 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-lg-40 {
    margin-right: 10.25rem !important;
    margin-left: 10.25rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-lg-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-lg-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }
  .my-lg-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-lg-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-lg-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-lg-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }
  .my-lg-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }
  .my-lg-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-lg-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-lg-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-lg-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }
  .my-lg-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-lg-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }
  .my-lg-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-lg-21 {
    margin-top: 5.25rem !important;
    margin-bottom: 5.25rem !important;
  }
  .my-lg-22 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }
  .my-lg-23 {
    margin-top: 5.75rem !important;
    margin-bottom: 5.75rem !important;
  }
  .my-lg-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-lg-25 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }
  .my-lg-26 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }
  .my-lg-27 {
    margin-top: 6.75rem !important;
    margin-bottom: 6.75rem !important;
  }
  .my-lg-28 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-lg-29 {
    margin-top: 7.25rem !important;
    margin-bottom: 7.25rem !important;
  }
  .my-lg-30 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-lg-31 {
    margin-top: 7.75rem !important;
    margin-bottom: 7.75rem !important;
  }
  .my-lg-32 {
    margin-top: 8.25rem !important;
    margin-bottom: 8.25rem !important;
  }
  .my-lg-33 {
    margin-top: 8.5rem !important;
    margin-bottom: 8.5rem !important;
  }
  .my-lg-34 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important;
  }
  .my-lg-35 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-lg-36 {
    margin-top: 9.25rem !important;
    margin-bottom: 9.25rem !important;
  }
  .my-lg-37 {
    margin-top: 9.5rem !important;
    margin-bottom: 9.5rem !important;
  }
  .my-lg-38 {
    margin-top: 9.75rem !important;
    margin-bottom: 9.75rem !important;
  }
  .my-lg-39 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-lg-40 {
    margin-top: 10.25rem !important;
    margin-bottom: 10.25rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 0.75rem !important;
  }
  .mt-lg-4 {
    margin-top: 1rem !important;
  }
  .mt-lg-5 {
    margin-top: 1.25rem !important;
  }
  .mt-lg-6 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-7 {
    margin-top: 1.75rem !important;
  }
  .mt-lg-8 {
    margin-top: 2rem !important;
  }
  .mt-lg-9 {
    margin-top: 2.25rem !important;
  }
  .mt-lg-10 {
    margin-top: 2.5rem !important;
  }
  .mt-lg-11 {
    margin-top: 2.75rem !important;
  }
  .mt-lg-12 {
    margin-top: 3rem !important;
  }
  .mt-lg-13 {
    margin-top: 3.25rem !important;
  }
  .mt-lg-14 {
    margin-top: 3.5rem !important;
  }
  .mt-lg-15 {
    margin-top: 3.75rem !important;
  }
  .mt-lg-16 {
    margin-top: 4rem !important;
  }
  .mt-lg-17 {
    margin-top: 4.25rem !important;
  }
  .mt-lg-18 {
    margin-top: 4.5rem !important;
  }
  .mt-lg-19 {
    margin-top: 4.75rem !important;
  }
  .mt-lg-20 {
    margin-top: 5rem !important;
  }
  .mt-lg-21 {
    margin-top: 5.25rem !important;
  }
  .mt-lg-22 {
    margin-top: 5.5rem !important;
  }
  .mt-lg-23 {
    margin-top: 5.75rem !important;
  }
  .mt-lg-24 {
    margin-top: 6rem !important;
  }
  .mt-lg-25 {
    margin-top: 6.25rem !important;
  }
  .mt-lg-26 {
    margin-top: 6.5rem !important;
  }
  .mt-lg-27 {
    margin-top: 6.75rem !important;
  }
  .mt-lg-28 {
    margin-top: 7rem !important;
  }
  .mt-lg-29 {
    margin-top: 7.25rem !important;
  }
  .mt-lg-30 {
    margin-top: 7.5rem !important;
  }
  .mt-lg-31 {
    margin-top: 7.75rem !important;
  }
  .mt-lg-32 {
    margin-top: 8.25rem !important;
  }
  .mt-lg-33 {
    margin-top: 8.5rem !important;
  }
  .mt-lg-34 {
    margin-top: 8.75rem !important;
  }
  .mt-lg-35 {
    margin-top: 9rem !important;
  }
  .mt-lg-36 {
    margin-top: 9.25rem !important;
  }
  .mt-lg-37 {
    margin-top: 9.5rem !important;
  }
  .mt-lg-38 {
    margin-top: 9.75rem !important;
  }
  .mt-lg-39 {
    margin-top: 10rem !important;
  }
  .mt-lg-40 {
    margin-top: 10.25rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 0.75rem !important;
  }
  .me-lg-4 {
    margin-right: 1rem !important;
  }
  .me-lg-5 {
    margin-right: 1.25rem !important;
  }
  .me-lg-6 {
    margin-right: 1.5rem !important;
  }
  .me-lg-7 {
    margin-right: 1.75rem !important;
  }
  .me-lg-8 {
    margin-right: 2rem !important;
  }
  .me-lg-9 {
    margin-right: 2.25rem !important;
  }
  .me-lg-10 {
    margin-right: 2.5rem !important;
  }
  .me-lg-11 {
    margin-right: 2.75rem !important;
  }
  .me-lg-12 {
    margin-right: 3rem !important;
  }
  .me-lg-13 {
    margin-right: 3.25rem !important;
  }
  .me-lg-14 {
    margin-right: 3.5rem !important;
  }
  .me-lg-15 {
    margin-right: 3.75rem !important;
  }
  .me-lg-16 {
    margin-right: 4rem !important;
  }
  .me-lg-17 {
    margin-right: 4.25rem !important;
  }
  .me-lg-18 {
    margin-right: 4.5rem !important;
  }
  .me-lg-19 {
    margin-right: 4.75rem !important;
  }
  .me-lg-20 {
    margin-right: 5rem !important;
  }
  .me-lg-21 {
    margin-right: 5.25rem !important;
  }
  .me-lg-22 {
    margin-right: 5.5rem !important;
  }
  .me-lg-23 {
    margin-right: 5.75rem !important;
  }
  .me-lg-24 {
    margin-right: 6rem !important;
  }
  .me-lg-25 {
    margin-right: 6.25rem !important;
  }
  .me-lg-26 {
    margin-right: 6.5rem !important;
  }
  .me-lg-27 {
    margin-right: 6.75rem !important;
  }
  .me-lg-28 {
    margin-right: 7rem !important;
  }
  .me-lg-29 {
    margin-right: 7.25rem !important;
  }
  .me-lg-30 {
    margin-right: 7.5rem !important;
  }
  .me-lg-31 {
    margin-right: 7.75rem !important;
  }
  .me-lg-32 {
    margin-right: 8.25rem !important;
  }
  .me-lg-33 {
    margin-right: 8.5rem !important;
  }
  .me-lg-34 {
    margin-right: 8.75rem !important;
  }
  .me-lg-35 {
    margin-right: 9rem !important;
  }
  .me-lg-36 {
    margin-right: 9.25rem !important;
  }
  .me-lg-37 {
    margin-right: 9.5rem !important;
  }
  .me-lg-38 {
    margin-right: 9.75rem !important;
  }
  .me-lg-39 {
    margin-right: 10rem !important;
  }
  .me-lg-40 {
    margin-right: 10.25rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 1.25rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 1.75rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 2rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 2.25rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 2.5rem !important;
  }
  .mb-lg-11 {
    margin-bottom: 2.75rem !important;
  }
  .mb-lg-12 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-13 {
    margin-bottom: 3.25rem !important;
  }
  .mb-lg-14 {
    margin-bottom: 3.5rem !important;
  }
  .mb-lg-15 {
    margin-bottom: 3.75rem !important;
  }
  .mb-lg-16 {
    margin-bottom: 4rem !important;
  }
  .mb-lg-17 {
    margin-bottom: 4.25rem !important;
  }
  .mb-lg-18 {
    margin-bottom: 4.5rem !important;
  }
  .mb-lg-19 {
    margin-bottom: 4.75rem !important;
  }
  .mb-lg-20 {
    margin-bottom: 5rem !important;
  }
  .mb-lg-21 {
    margin-bottom: 5.25rem !important;
  }
  .mb-lg-22 {
    margin-bottom: 5.5rem !important;
  }
  .mb-lg-23 {
    margin-bottom: 5.75rem !important;
  }
  .mb-lg-24 {
    margin-bottom: 6rem !important;
  }
  .mb-lg-25 {
    margin-bottom: 6.25rem !important;
  }
  .mb-lg-26 {
    margin-bottom: 6.5rem !important;
  }
  .mb-lg-27 {
    margin-bottom: 6.75rem !important;
  }
  .mb-lg-28 {
    margin-bottom: 7rem !important;
  }
  .mb-lg-29 {
    margin-bottom: 7.25rem !important;
  }
  .mb-lg-30 {
    margin-bottom: 7.5rem !important;
  }
  .mb-lg-31 {
    margin-bottom: 7.75rem !important;
  }
  .mb-lg-32 {
    margin-bottom: 8.25rem !important;
  }
  .mb-lg-33 {
    margin-bottom: 8.5rem !important;
  }
  .mb-lg-34 {
    margin-bottom: 8.75rem !important;
  }
  .mb-lg-35 {
    margin-bottom: 9rem !important;
  }
  .mb-lg-36 {
    margin-bottom: 9.25rem !important;
  }
  .mb-lg-37 {
    margin-bottom: 9.5rem !important;
  }
  .mb-lg-38 {
    margin-bottom: 9.75rem !important;
  }
  .mb-lg-39 {
    margin-bottom: 10rem !important;
  }
  .mb-lg-40 {
    margin-bottom: 10.25rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 0.75rem !important;
  }
  .ms-lg-4 {
    margin-left: 1rem !important;
  }
  .ms-lg-5 {
    margin-left: 1.25rem !important;
  }
  .ms-lg-6 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-7 {
    margin-left: 1.75rem !important;
  }
  .ms-lg-8 {
    margin-left: 2rem !important;
  }
  .ms-lg-9 {
    margin-left: 2.25rem !important;
  }
  .ms-lg-10 {
    margin-left: 2.5rem !important;
  }
  .ms-lg-11 {
    margin-left: 2.75rem !important;
  }
  .ms-lg-12 {
    margin-left: 3rem !important;
  }
  .ms-lg-13 {
    margin-left: 3.25rem !important;
  }
  .ms-lg-14 {
    margin-left: 3.5rem !important;
  }
  .ms-lg-15 {
    margin-left: 3.75rem !important;
  }
  .ms-lg-16 {
    margin-left: 4rem !important;
  }
  .ms-lg-17 {
    margin-left: 4.25rem !important;
  }
  .ms-lg-18 {
    margin-left: 4.5rem !important;
  }
  .ms-lg-19 {
    margin-left: 4.75rem !important;
  }
  .ms-lg-20 {
    margin-left: 5rem !important;
  }
  .ms-lg-21 {
    margin-left: 5.25rem !important;
  }
  .ms-lg-22 {
    margin-left: 5.5rem !important;
  }
  .ms-lg-23 {
    margin-left: 5.75rem !important;
  }
  .ms-lg-24 {
    margin-left: 6rem !important;
  }
  .ms-lg-25 {
    margin-left: 6.25rem !important;
  }
  .ms-lg-26 {
    margin-left: 6.5rem !important;
  }
  .ms-lg-27 {
    margin-left: 6.75rem !important;
  }
  .ms-lg-28 {
    margin-left: 7rem !important;
  }
  .ms-lg-29 {
    margin-left: 7.25rem !important;
  }
  .ms-lg-30 {
    margin-left: 7.5rem !important;
  }
  .ms-lg-31 {
    margin-left: 7.75rem !important;
  }
  .ms-lg-32 {
    margin-left: 8.25rem !important;
  }
  .ms-lg-33 {
    margin-left: 8.5rem !important;
  }
  .ms-lg-34 {
    margin-left: 8.75rem !important;
  }
  .ms-lg-35 {
    margin-left: 9rem !important;
  }
  .ms-lg-36 {
    margin-left: 9.25rem !important;
  }
  .ms-lg-37 {
    margin-left: 9.5rem !important;
  }
  .ms-lg-38 {
    margin-left: 9.75rem !important;
  }
  .ms-lg-39 {
    margin-left: 10rem !important;
  }
  .ms-lg-40 {
    margin-left: 10.25rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -0.75rem !important;
  }
  .m-lg-n4 {
    margin: -1rem !important;
  }
  .m-lg-n5 {
    margin: -1.25rem !important;
  }
  .m-lg-n6 {
    margin: -1.5rem !important;
  }
  .m-lg-n7 {
    margin: -1.75rem !important;
  }
  .m-lg-n8 {
    margin: -2rem !important;
  }
  .m-lg-n9 {
    margin: -2.25rem !important;
  }
  .m-lg-n10 {
    margin: -2.5rem !important;
  }
  .m-lg-n11 {
    margin: -2.75rem !important;
  }
  .m-lg-n12 {
    margin: -3rem !important;
  }
  .m-lg-n13 {
    margin: -3.25rem !important;
  }
  .m-lg-n14 {
    margin: -3.5rem !important;
  }
  .m-lg-n15 {
    margin: -3.75rem !important;
  }
  .m-lg-n16 {
    margin: -4rem !important;
  }
  .m-lg-n17 {
    margin: -4.25rem !important;
  }
  .m-lg-n18 {
    margin: -4.5rem !important;
  }
  .m-lg-n19 {
    margin: -4.75rem !important;
  }
  .m-lg-n20 {
    margin: -5rem !important;
  }
  .m-lg-n21 {
    margin: -5.25rem !important;
  }
  .m-lg-n22 {
    margin: -5.5rem !important;
  }
  .m-lg-n23 {
    margin: -5.75rem !important;
  }
  .m-lg-n24 {
    margin: -6rem !important;
  }
  .m-lg-n25 {
    margin: -6.25rem !important;
  }
  .m-lg-n26 {
    margin: -6.5rem !important;
  }
  .m-lg-n27 {
    margin: -6.75rem !important;
  }
  .m-lg-n28 {
    margin: -7rem !important;
  }
  .m-lg-n29 {
    margin: -7.25rem !important;
  }
  .m-lg-n30 {
    margin: -7.5rem !important;
  }
  .m-lg-n31 {
    margin: -7.75rem !important;
  }
  .m-lg-n32 {
    margin: -8.25rem !important;
  }
  .m-lg-n33 {
    margin: -8.5rem !important;
  }
  .m-lg-n34 {
    margin: -8.75rem !important;
  }
  .m-lg-n35 {
    margin: -9rem !important;
  }
  .m-lg-n36 {
    margin: -9.25rem !important;
  }
  .m-lg-n37 {
    margin: -9.5rem !important;
  }
  .m-lg-n38 {
    margin: -9.75rem !important;
  }
  .m-lg-n39 {
    margin: -10rem !important;
  }
  .m-lg-n40 {
    margin: -10.25rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-lg-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }
  .mx-lg-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-lg-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }
  .mx-lg-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-lg-n11 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }
  .mx-lg-n12 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-lg-n13 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }
  .mx-lg-n14 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-lg-n15 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }
  .mx-lg-n16 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-lg-n17 {
    margin-right: -4.25rem !important;
    margin-left: -4.25rem !important;
  }
  .mx-lg-n18 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .mx-lg-n19 {
    margin-right: -4.75rem !important;
    margin-left: -4.75rem !important;
  }
  .mx-lg-n20 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-lg-n21 {
    margin-right: -5.25rem !important;
    margin-left: -5.25rem !important;
  }
  .mx-lg-n22 {
    margin-right: -5.5rem !important;
    margin-left: -5.5rem !important;
  }
  .mx-lg-n23 {
    margin-right: -5.75rem !important;
    margin-left: -5.75rem !important;
  }
  .mx-lg-n24 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-lg-n25 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important;
  }
  .mx-lg-n26 {
    margin-right: -6.5rem !important;
    margin-left: -6.5rem !important;
  }
  .mx-lg-n27 {
    margin-right: -6.75rem !important;
    margin-left: -6.75rem !important;
  }
  .mx-lg-n28 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }
  .mx-lg-n29 {
    margin-right: -7.25rem !important;
    margin-left: -7.25rem !important;
  }
  .mx-lg-n30 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  .mx-lg-n31 {
    margin-right: -7.75rem !important;
    margin-left: -7.75rem !important;
  }
  .mx-lg-n32 {
    margin-right: -8.25rem !important;
    margin-left: -8.25rem !important;
  }
  .mx-lg-n33 {
    margin-right: -8.5rem !important;
    margin-left: -8.5rem !important;
  }
  .mx-lg-n34 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important;
  }
  .mx-lg-n35 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  .mx-lg-n36 {
    margin-right: -9.25rem !important;
    margin-left: -9.25rem !important;
  }
  .mx-lg-n37 {
    margin-right: -9.5rem !important;
    margin-left: -9.5rem !important;
  }
  .mx-lg-n38 {
    margin-right: -9.75rem !important;
    margin-left: -9.75rem !important;
  }
  .mx-lg-n39 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .mx-lg-n40 {
    margin-right: -10.25rem !important;
    margin-left: -10.25rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-lg-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-lg-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }
  .my-lg-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-lg-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }
  .my-lg-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-lg-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }
  .my-lg-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-lg-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }
  .my-lg-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-lg-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }
  .my-lg-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-lg-n17 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }
  .my-lg-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .my-lg-n19 {
    margin-top: -4.75rem !important;
    margin-bottom: -4.75rem !important;
  }
  .my-lg-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-lg-n21 {
    margin-top: -5.25rem !important;
    margin-bottom: -5.25rem !important;
  }
  .my-lg-n22 {
    margin-top: -5.5rem !important;
    margin-bottom: -5.5rem !important;
  }
  .my-lg-n23 {
    margin-top: -5.75rem !important;
    margin-bottom: -5.75rem !important;
  }
  .my-lg-n24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-lg-n25 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important;
  }
  .my-lg-n26 {
    margin-top: -6.5rem !important;
    margin-bottom: -6.5rem !important;
  }
  .my-lg-n27 {
    margin-top: -6.75rem !important;
    margin-bottom: -6.75rem !important;
  }
  .my-lg-n28 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }
  .my-lg-n29 {
    margin-top: -7.25rem !important;
    margin-bottom: -7.25rem !important;
  }
  .my-lg-n30 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .my-lg-n31 {
    margin-top: -7.75rem !important;
    margin-bottom: -7.75rem !important;
  }
  .my-lg-n32 {
    margin-top: -8.25rem !important;
    margin-bottom: -8.25rem !important;
  }
  .my-lg-n33 {
    margin-top: -8.5rem !important;
    margin-bottom: -8.5rem !important;
  }
  .my-lg-n34 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important;
  }
  .my-lg-n35 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  .my-lg-n36 {
    margin-top: -9.25rem !important;
    margin-bottom: -9.25rem !important;
  }
  .my-lg-n37 {
    margin-top: -9.5rem !important;
    margin-bottom: -9.5rem !important;
  }
  .my-lg-n38 {
    margin-top: -9.75rem !important;
    margin-bottom: -9.75rem !important;
  }
  .my-lg-n39 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .my-lg-n40 {
    margin-top: -10.25rem !important;
    margin-bottom: -10.25rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -0.75rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1rem !important;
  }
  .mt-lg-n5 {
    margin-top: -1.25rem !important;
  }
  .mt-lg-n6 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n7 {
    margin-top: -1.75rem !important;
  }
  .mt-lg-n8 {
    margin-top: -2rem !important;
  }
  .mt-lg-n9 {
    margin-top: -2.25rem !important;
  }
  .mt-lg-n10 {
    margin-top: -2.5rem !important;
  }
  .mt-lg-n11 {
    margin-top: -2.75rem !important;
  }
  .mt-lg-n12 {
    margin-top: -3rem !important;
  }
  .mt-lg-n13 {
    margin-top: -3.25rem !important;
  }
  .mt-lg-n14 {
    margin-top: -3.5rem !important;
  }
  .mt-lg-n15 {
    margin-top: -3.75rem !important;
  }
  .mt-lg-n16 {
    margin-top: -4rem !important;
  }
  .mt-lg-n17 {
    margin-top: -4.25rem !important;
  }
  .mt-lg-n18 {
    margin-top: -4.5rem !important;
  }
  .mt-lg-n19 {
    margin-top: -4.75rem !important;
  }
  .mt-lg-n20 {
    margin-top: -5rem !important;
  }
  .mt-lg-n21 {
    margin-top: -5.25rem !important;
  }
  .mt-lg-n22 {
    margin-top: -5.5rem !important;
  }
  .mt-lg-n23 {
    margin-top: -5.75rem !important;
  }
  .mt-lg-n24 {
    margin-top: -6rem !important;
  }
  .mt-lg-n25 {
    margin-top: -6.25rem !important;
  }
  .mt-lg-n26 {
    margin-top: -6.5rem !important;
  }
  .mt-lg-n27 {
    margin-top: -6.75rem !important;
  }
  .mt-lg-n28 {
    margin-top: -7rem !important;
  }
  .mt-lg-n29 {
    margin-top: -7.25rem !important;
  }
  .mt-lg-n30 {
    margin-top: -7.5rem !important;
  }
  .mt-lg-n31 {
    margin-top: -7.75rem !important;
  }
  .mt-lg-n32 {
    margin-top: -8.25rem !important;
  }
  .mt-lg-n33 {
    margin-top: -8.5rem !important;
  }
  .mt-lg-n34 {
    margin-top: -8.75rem !important;
  }
  .mt-lg-n35 {
    margin-top: -9rem !important;
  }
  .mt-lg-n36 {
    margin-top: -9.25rem !important;
  }
  .mt-lg-n37 {
    margin-top: -9.5rem !important;
  }
  .mt-lg-n38 {
    margin-top: -9.75rem !important;
  }
  .mt-lg-n39 {
    margin-top: -10rem !important;
  }
  .mt-lg-n40 {
    margin-top: -10.25rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -0.75rem !important;
  }
  .me-lg-n4 {
    margin-right: -1rem !important;
  }
  .me-lg-n5 {
    margin-right: -1.25rem !important;
  }
  .me-lg-n6 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n7 {
    margin-right: -1.75rem !important;
  }
  .me-lg-n8 {
    margin-right: -2rem !important;
  }
  .me-lg-n9 {
    margin-right: -2.25rem !important;
  }
  .me-lg-n10 {
    margin-right: -2.5rem !important;
  }
  .me-lg-n11 {
    margin-right: -2.75rem !important;
  }
  .me-lg-n12 {
    margin-right: -3rem !important;
  }
  .me-lg-n13 {
    margin-right: -3.25rem !important;
  }
  .me-lg-n14 {
    margin-right: -3.5rem !important;
  }
  .me-lg-n15 {
    margin-right: -3.75rem !important;
  }
  .me-lg-n16 {
    margin-right: -4rem !important;
  }
  .me-lg-n17 {
    margin-right: -4.25rem !important;
  }
  .me-lg-n18 {
    margin-right: -4.5rem !important;
  }
  .me-lg-n19 {
    margin-right: -4.75rem !important;
  }
  .me-lg-n20 {
    margin-right: -5rem !important;
  }
  .me-lg-n21 {
    margin-right: -5.25rem !important;
  }
  .me-lg-n22 {
    margin-right: -5.5rem !important;
  }
  .me-lg-n23 {
    margin-right: -5.75rem !important;
  }
  .me-lg-n24 {
    margin-right: -6rem !important;
  }
  .me-lg-n25 {
    margin-right: -6.25rem !important;
  }
  .me-lg-n26 {
    margin-right: -6.5rem !important;
  }
  .me-lg-n27 {
    margin-right: -6.75rem !important;
  }
  .me-lg-n28 {
    margin-right: -7rem !important;
  }
  .me-lg-n29 {
    margin-right: -7.25rem !important;
  }
  .me-lg-n30 {
    margin-right: -7.5rem !important;
  }
  .me-lg-n31 {
    margin-right: -7.75rem !important;
  }
  .me-lg-n32 {
    margin-right: -8.25rem !important;
  }
  .me-lg-n33 {
    margin-right: -8.5rem !important;
  }
  .me-lg-n34 {
    margin-right: -8.75rem !important;
  }
  .me-lg-n35 {
    margin-right: -9rem !important;
  }
  .me-lg-n36 {
    margin-right: -9.25rem !important;
  }
  .me-lg-n37 {
    margin-right: -9.5rem !important;
  }
  .me-lg-n38 {
    margin-right: -9.75rem !important;
  }
  .me-lg-n39 {
    margin-right: -10rem !important;
  }
  .me-lg-n40 {
    margin-right: -10.25rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -0.75rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -1.25rem !important;
  }
  .mb-lg-n6 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n7 {
    margin-bottom: -1.75rem !important;
  }
  .mb-lg-n8 {
    margin-bottom: -2rem !important;
  }
  .mb-lg-n9 {
    margin-bottom: -2.25rem !important;
  }
  .mb-lg-n10 {
    margin-bottom: -2.5rem !important;
  }
  .mb-lg-n11 {
    margin-bottom: -2.75rem !important;
  }
  .mb-lg-n12 {
    margin-bottom: -3rem !important;
  }
  .mb-lg-n13 {
    margin-bottom: -3.25rem !important;
  }
  .mb-lg-n14 {
    margin-bottom: -3.5rem !important;
  }
  .mb-lg-n15 {
    margin-bottom: -3.75rem !important;
  }
  .mb-lg-n16 {
    margin-bottom: -4rem !important;
  }
  .mb-lg-n17 {
    margin-bottom: -4.25rem !important;
  }
  .mb-lg-n18 {
    margin-bottom: -4.5rem !important;
  }
  .mb-lg-n19 {
    margin-bottom: -4.75rem !important;
  }
  .mb-lg-n20 {
    margin-bottom: -5rem !important;
  }
  .mb-lg-n21 {
    margin-bottom: -5.25rem !important;
  }
  .mb-lg-n22 {
    margin-bottom: -5.5rem !important;
  }
  .mb-lg-n23 {
    margin-bottom: -5.75rem !important;
  }
  .mb-lg-n24 {
    margin-bottom: -6rem !important;
  }
  .mb-lg-n25 {
    margin-bottom: -6.25rem !important;
  }
  .mb-lg-n26 {
    margin-bottom: -6.5rem !important;
  }
  .mb-lg-n27 {
    margin-bottom: -6.75rem !important;
  }
  .mb-lg-n28 {
    margin-bottom: -7rem !important;
  }
  .mb-lg-n29 {
    margin-bottom: -7.25rem !important;
  }
  .mb-lg-n30 {
    margin-bottom: -7.5rem !important;
  }
  .mb-lg-n31 {
    margin-bottom: -7.75rem !important;
  }
  .mb-lg-n32 {
    margin-bottom: -8.25rem !important;
  }
  .mb-lg-n33 {
    margin-bottom: -8.5rem !important;
  }
  .mb-lg-n34 {
    margin-bottom: -8.75rem !important;
  }
  .mb-lg-n35 {
    margin-bottom: -9rem !important;
  }
  .mb-lg-n36 {
    margin-bottom: -9.25rem !important;
  }
  .mb-lg-n37 {
    margin-bottom: -9.5rem !important;
  }
  .mb-lg-n38 {
    margin-bottom: -9.75rem !important;
  }
  .mb-lg-n39 {
    margin-bottom: -10rem !important;
  }
  .mb-lg-n40 {
    margin-bottom: -10.25rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -0.75rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1rem !important;
  }
  .ms-lg-n5 {
    margin-left: -1.25rem !important;
  }
  .ms-lg-n6 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n7 {
    margin-left: -1.75rem !important;
  }
  .ms-lg-n8 {
    margin-left: -2rem !important;
  }
  .ms-lg-n9 {
    margin-left: -2.25rem !important;
  }
  .ms-lg-n10 {
    margin-left: -2.5rem !important;
  }
  .ms-lg-n11 {
    margin-left: -2.75rem !important;
  }
  .ms-lg-n12 {
    margin-left: -3rem !important;
  }
  .ms-lg-n13 {
    margin-left: -3.25rem !important;
  }
  .ms-lg-n14 {
    margin-left: -3.5rem !important;
  }
  .ms-lg-n15 {
    margin-left: -3.75rem !important;
  }
  .ms-lg-n16 {
    margin-left: -4rem !important;
  }
  .ms-lg-n17 {
    margin-left: -4.25rem !important;
  }
  .ms-lg-n18 {
    margin-left: -4.5rem !important;
  }
  .ms-lg-n19 {
    margin-left: -4.75rem !important;
  }
  .ms-lg-n20 {
    margin-left: -5rem !important;
  }
  .ms-lg-n21 {
    margin-left: -5.25rem !important;
  }
  .ms-lg-n22 {
    margin-left: -5.5rem !important;
  }
  .ms-lg-n23 {
    margin-left: -5.75rem !important;
  }
  .ms-lg-n24 {
    margin-left: -6rem !important;
  }
  .ms-lg-n25 {
    margin-left: -6.25rem !important;
  }
  .ms-lg-n26 {
    margin-left: -6.5rem !important;
  }
  .ms-lg-n27 {
    margin-left: -6.75rem !important;
  }
  .ms-lg-n28 {
    margin-left: -7rem !important;
  }
  .ms-lg-n29 {
    margin-left: -7.25rem !important;
  }
  .ms-lg-n30 {
    margin-left: -7.5rem !important;
  }
  .ms-lg-n31 {
    margin-left: -7.75rem !important;
  }
  .ms-lg-n32 {
    margin-left: -8.25rem !important;
  }
  .ms-lg-n33 {
    margin-left: -8.5rem !important;
  }
  .ms-lg-n34 {
    margin-left: -8.75rem !important;
  }
  .ms-lg-n35 {
    margin-left: -9rem !important;
  }
  .ms-lg-n36 {
    margin-left: -9.25rem !important;
  }
  .ms-lg-n37 {
    margin-left: -9.5rem !important;
  }
  .ms-lg-n38 {
    margin-left: -9.75rem !important;
  }
  .ms-lg-n39 {
    margin-left: -10rem !important;
  }
  .ms-lg-n40 {
    margin-left: -10.25rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 0.75rem !important;
  }
  .p-lg-4 {
    padding: 1rem !important;
  }
  .p-lg-5 {
    padding: 1.25rem !important;
  }
  .p-lg-6 {
    padding: 1.5rem !important;
  }
  .p-lg-7 {
    padding: 1.75rem !important;
  }
  .p-lg-8 {
    padding: 2rem !important;
  }
  .p-lg-9 {
    padding: 2.25rem !important;
  }
  .p-lg-10 {
    padding: 2.5rem !important;
  }
  .p-lg-11 {
    padding: 2.75rem !important;
  }
  .p-lg-12 {
    padding: 3rem !important;
  }
  .p-lg-13 {
    padding: 3.25rem !important;
  }
  .p-lg-14 {
    padding: 3.5rem !important;
  }
  .p-lg-15 {
    padding: 3.75rem !important;
  }
  .p-lg-16 {
    padding: 4rem !important;
  }
  .p-lg-17 {
    padding: 4.25rem !important;
  }
  .p-lg-18 {
    padding: 4.5rem !important;
  }
  .p-lg-19 {
    padding: 4.75rem !important;
  }
  .p-lg-20 {
    padding: 5rem !important;
  }
  .p-lg-21 {
    padding: 5.25rem !important;
  }
  .p-lg-22 {
    padding: 5.5rem !important;
  }
  .p-lg-23 {
    padding: 5.75rem !important;
  }
  .p-lg-24 {
    padding: 6rem !important;
  }
  .p-lg-25 {
    padding: 6.25rem !important;
  }
  .p-lg-26 {
    padding: 6.5rem !important;
  }
  .p-lg-27 {
    padding: 6.75rem !important;
  }
  .p-lg-28 {
    padding: 7rem !important;
  }
  .p-lg-29 {
    padding: 7.25rem !important;
  }
  .p-lg-30 {
    padding: 7.5rem !important;
  }
  .p-lg-31 {
    padding: 7.75rem !important;
  }
  .p-lg-32 {
    padding: 8.25rem !important;
  }
  .p-lg-33 {
    padding: 8.5rem !important;
  }
  .p-lg-34 {
    padding: 8.75rem !important;
  }
  .p-lg-35 {
    padding: 9rem !important;
  }
  .p-lg-36 {
    padding: 9.25rem !important;
  }
  .p-lg-37 {
    padding: 9.5rem !important;
  }
  .p-lg-38 {
    padding: 9.75rem !important;
  }
  .p-lg-39 {
    padding: 10rem !important;
  }
  .p-lg-40 {
    padding: 10.25rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-lg-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-lg-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }
  .px-lg-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-lg-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-lg-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-lg-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }
  .px-lg-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-lg-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }
  .px-lg-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-lg-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-lg-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-lg-17 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }
  .px-lg-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-lg-19 {
    padding-right: 4.75rem !important;
    padding-left: 4.75rem !important;
  }
  .px-lg-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-lg-21 {
    padding-right: 5.25rem !important;
    padding-left: 5.25rem !important;
  }
  .px-lg-22 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }
  .px-lg-23 {
    padding-right: 5.75rem !important;
    padding-left: 5.75rem !important;
  }
  .px-lg-24 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-lg-25 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }
  .px-lg-26 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }
  .px-lg-27 {
    padding-right: 6.75rem !important;
    padding-left: 6.75rem !important;
  }
  .px-lg-28 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-lg-29 {
    padding-right: 7.25rem !important;
    padding-left: 7.25rem !important;
  }
  .px-lg-30 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-lg-31 {
    padding-right: 7.75rem !important;
    padding-left: 7.75rem !important;
  }
  .px-lg-32 {
    padding-right: 8.25rem !important;
    padding-left: 8.25rem !important;
  }
  .px-lg-33 {
    padding-right: 8.5rem !important;
    padding-left: 8.5rem !important;
  }
  .px-lg-34 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important;
  }
  .px-lg-35 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-lg-36 {
    padding-right: 9.25rem !important;
    padding-left: 9.25rem !important;
  }
  .px-lg-37 {
    padding-right: 9.5rem !important;
    padding-left: 9.5rem !important;
  }
  .px-lg-38 {
    padding-right: 9.75rem !important;
    padding-left: 9.75rem !important;
  }
  .px-lg-39 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-lg-40 {
    padding-right: 10.25rem !important;
    padding-left: 10.25rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-lg-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-lg-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }
  .py-lg-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-lg-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-lg-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-lg-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }
  .py-lg-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }
  .py-lg-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-lg-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-lg-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-lg-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }
  .py-lg-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-lg-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }
  .py-lg-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-lg-21 {
    padding-top: 5.25rem !important;
    padding-bottom: 5.25rem !important;
  }
  .py-lg-22 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }
  .py-lg-23 {
    padding-top: 5.75rem !important;
    padding-bottom: 5.75rem !important;
  }
  .py-lg-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-lg-25 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .py-lg-26 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }
  .py-lg-27 {
    padding-top: 6.75rem !important;
    padding-bottom: 6.75rem !important;
  }
  .py-lg-28 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-lg-29 {
    padding-top: 7.25rem !important;
    padding-bottom: 7.25rem !important;
  }
  .py-lg-30 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-lg-31 {
    padding-top: 7.75rem !important;
    padding-bottom: 7.75rem !important;
  }
  .py-lg-32 {
    padding-top: 8.25rem !important;
    padding-bottom: 8.25rem !important;
  }
  .py-lg-33 {
    padding-top: 8.5rem !important;
    padding-bottom: 8.5rem !important;
  }
  .py-lg-34 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important;
  }
  .py-lg-35 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-lg-36 {
    padding-top: 9.25rem !important;
    padding-bottom: 9.25rem !important;
  }
  .py-lg-37 {
    padding-top: 9.5rem !important;
    padding-bottom: 9.5rem !important;
  }
  .py-lg-38 {
    padding-top: 9.75rem !important;
    padding-bottom: 9.75rem !important;
  }
  .py-lg-39 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-lg-40 {
    padding-top: 10.25rem !important;
    padding-bottom: 10.25rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 0.75rem !important;
  }
  .pt-lg-4 {
    padding-top: 1rem !important;
  }
  .pt-lg-5 {
    padding-top: 1.25rem !important;
  }
  .pt-lg-6 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-7 {
    padding-top: 1.75rem !important;
  }
  .pt-lg-8 {
    padding-top: 2rem !important;
  }
  .pt-lg-9 {
    padding-top: 2.25rem !important;
  }
  .pt-lg-10 {
    padding-top: 2.5rem !important;
  }
  .pt-lg-11 {
    padding-top: 2.75rem !important;
  }
  .pt-lg-12 {
    padding-top: 3rem !important;
  }
  .pt-lg-13 {
    padding-top: 3.25rem !important;
  }
  .pt-lg-14 {
    padding-top: 3.5rem !important;
  }
  .pt-lg-15 {
    padding-top: 3.75rem !important;
  }
  .pt-lg-16 {
    padding-top: 4rem !important;
  }
  .pt-lg-17 {
    padding-top: 4.25rem !important;
  }
  .pt-lg-18 {
    padding-top: 4.5rem !important;
  }
  .pt-lg-19 {
    padding-top: 4.75rem !important;
  }
  .pt-lg-20 {
    padding-top: 5rem !important;
  }
  .pt-lg-21 {
    padding-top: 5.25rem !important;
  }
  .pt-lg-22 {
    padding-top: 5.5rem !important;
  }
  .pt-lg-23 {
    padding-top: 5.75rem !important;
  }
  .pt-lg-24 {
    padding-top: 6rem !important;
  }
  .pt-lg-25 {
    padding-top: 6.25rem !important;
  }
  .pt-lg-26 {
    padding-top: 6.5rem !important;
  }
  .pt-lg-27 {
    padding-top: 6.75rem !important;
  }
  .pt-lg-28 {
    padding-top: 7rem !important;
  }
  .pt-lg-29 {
    padding-top: 7.25rem !important;
  }
  .pt-lg-30 {
    padding-top: 7.5rem !important;
  }
  .pt-lg-31 {
    padding-top: 7.75rem !important;
  }
  .pt-lg-32 {
    padding-top: 8.25rem !important;
  }
  .pt-lg-33 {
    padding-top: 8.5rem !important;
  }
  .pt-lg-34 {
    padding-top: 8.75rem !important;
  }
  .pt-lg-35 {
    padding-top: 9rem !important;
  }
  .pt-lg-36 {
    padding-top: 9.25rem !important;
  }
  .pt-lg-37 {
    padding-top: 9.5rem !important;
  }
  .pt-lg-38 {
    padding-top: 9.75rem !important;
  }
  .pt-lg-39 {
    padding-top: 10rem !important;
  }
  .pt-lg-40 {
    padding-top: 10.25rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 0.75rem !important;
  }
  .pe-lg-4 {
    padding-right: 1rem !important;
  }
  .pe-lg-5 {
    padding-right: 1.25rem !important;
  }
  .pe-lg-6 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-7 {
    padding-right: 1.75rem !important;
  }
  .pe-lg-8 {
    padding-right: 2rem !important;
  }
  .pe-lg-9 {
    padding-right: 2.25rem !important;
  }
  .pe-lg-10 {
    padding-right: 2.5rem !important;
  }
  .pe-lg-11 {
    padding-right: 2.75rem !important;
  }
  .pe-lg-12 {
    padding-right: 3rem !important;
  }
  .pe-lg-13 {
    padding-right: 3.25rem !important;
  }
  .pe-lg-14 {
    padding-right: 3.5rem !important;
  }
  .pe-lg-15 {
    padding-right: 3.75rem !important;
  }
  .pe-lg-16 {
    padding-right: 4rem !important;
  }
  .pe-lg-17 {
    padding-right: 4.25rem !important;
  }
  .pe-lg-18 {
    padding-right: 4.5rem !important;
  }
  .pe-lg-19 {
    padding-right: 4.75rem !important;
  }
  .pe-lg-20 {
    padding-right: 5rem !important;
  }
  .pe-lg-21 {
    padding-right: 5.25rem !important;
  }
  .pe-lg-22 {
    padding-right: 5.5rem !important;
  }
  .pe-lg-23 {
    padding-right: 5.75rem !important;
  }
  .pe-lg-24 {
    padding-right: 6rem !important;
  }
  .pe-lg-25 {
    padding-right: 6.25rem !important;
  }
  .pe-lg-26 {
    padding-right: 6.5rem !important;
  }
  .pe-lg-27 {
    padding-right: 6.75rem !important;
  }
  .pe-lg-28 {
    padding-right: 7rem !important;
  }
  .pe-lg-29 {
    padding-right: 7.25rem !important;
  }
  .pe-lg-30 {
    padding-right: 7.5rem !important;
  }
  .pe-lg-31 {
    padding-right: 7.75rem !important;
  }
  .pe-lg-32 {
    padding-right: 8.25rem !important;
  }
  .pe-lg-33 {
    padding-right: 8.5rem !important;
  }
  .pe-lg-34 {
    padding-right: 8.75rem !important;
  }
  .pe-lg-35 {
    padding-right: 9rem !important;
  }
  .pe-lg-36 {
    padding-right: 9.25rem !important;
  }
  .pe-lg-37 {
    padding-right: 9.5rem !important;
  }
  .pe-lg-38 {
    padding-right: 9.75rem !important;
  }
  .pe-lg-39 {
    padding-right: 10rem !important;
  }
  .pe-lg-40 {
    padding-right: 10.25rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 1.25rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 1.75rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 2rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 2.25rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 2.5rem !important;
  }
  .pb-lg-11 {
    padding-bottom: 2.75rem !important;
  }
  .pb-lg-12 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-13 {
    padding-bottom: 3.25rem !important;
  }
  .pb-lg-14 {
    padding-bottom: 3.5rem !important;
  }
  .pb-lg-15 {
    padding-bottom: 3.75rem !important;
  }
  .pb-lg-16 {
    padding-bottom: 4rem !important;
  }
  .pb-lg-17 {
    padding-bottom: 4.25rem !important;
  }
  .pb-lg-18 {
    padding-bottom: 4.5rem !important;
  }
  .pb-lg-19 {
    padding-bottom: 4.75rem !important;
  }
  .pb-lg-20 {
    padding-bottom: 5rem !important;
  }
  .pb-lg-21 {
    padding-bottom: 5.25rem !important;
  }
  .pb-lg-22 {
    padding-bottom: 5.5rem !important;
  }
  .pb-lg-23 {
    padding-bottom: 5.75rem !important;
  }
  .pb-lg-24 {
    padding-bottom: 6rem !important;
  }
  .pb-lg-25 {
    padding-bottom: 6.25rem !important;
  }
  .pb-lg-26 {
    padding-bottom: 6.5rem !important;
  }
  .pb-lg-27 {
    padding-bottom: 6.75rem !important;
  }
  .pb-lg-28 {
    padding-bottom: 7rem !important;
  }
  .pb-lg-29 {
    padding-bottom: 7.25rem !important;
  }
  .pb-lg-30 {
    padding-bottom: 7.5rem !important;
  }
  .pb-lg-31 {
    padding-bottom: 7.75rem !important;
  }
  .pb-lg-32 {
    padding-bottom: 8.25rem !important;
  }
  .pb-lg-33 {
    padding-bottom: 8.5rem !important;
  }
  .pb-lg-34 {
    padding-bottom: 8.75rem !important;
  }
  .pb-lg-35 {
    padding-bottom: 9rem !important;
  }
  .pb-lg-36 {
    padding-bottom: 9.25rem !important;
  }
  .pb-lg-37 {
    padding-bottom: 9.5rem !important;
  }
  .pb-lg-38 {
    padding-bottom: 9.75rem !important;
  }
  .pb-lg-39 {
    padding-bottom: 10rem !important;
  }
  .pb-lg-40 {
    padding-bottom: 10.25rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 0.75rem !important;
  }
  .ps-lg-4 {
    padding-left: 1rem !important;
  }
  .ps-lg-5 {
    padding-left: 1.25rem !important;
  }
  .ps-lg-6 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-7 {
    padding-left: 1.75rem !important;
  }
  .ps-lg-8 {
    padding-left: 2rem !important;
  }
  .ps-lg-9 {
    padding-left: 2.25rem !important;
  }
  .ps-lg-10 {
    padding-left: 2.5rem !important;
  }
  .ps-lg-11 {
    padding-left: 2.75rem !important;
  }
  .ps-lg-12 {
    padding-left: 3rem !important;
  }
  .ps-lg-13 {
    padding-left: 3.25rem !important;
  }
  .ps-lg-14 {
    padding-left: 3.5rem !important;
  }
  .ps-lg-15 {
    padding-left: 3.75rem !important;
  }
  .ps-lg-16 {
    padding-left: 4rem !important;
  }
  .ps-lg-17 {
    padding-left: 4.25rem !important;
  }
  .ps-lg-18 {
    padding-left: 4.5rem !important;
  }
  .ps-lg-19 {
    padding-left: 4.75rem !important;
  }
  .ps-lg-20 {
    padding-left: 5rem !important;
  }
  .ps-lg-21 {
    padding-left: 5.25rem !important;
  }
  .ps-lg-22 {
    padding-left: 5.5rem !important;
  }
  .ps-lg-23 {
    padding-left: 5.75rem !important;
  }
  .ps-lg-24 {
    padding-left: 6rem !important;
  }
  .ps-lg-25 {
    padding-left: 6.25rem !important;
  }
  .ps-lg-26 {
    padding-left: 6.5rem !important;
  }
  .ps-lg-27 {
    padding-left: 6.75rem !important;
  }
  .ps-lg-28 {
    padding-left: 7rem !important;
  }
  .ps-lg-29 {
    padding-left: 7.25rem !important;
  }
  .ps-lg-30 {
    padding-left: 7.5rem !important;
  }
  .ps-lg-31 {
    padding-left: 7.75rem !important;
  }
  .ps-lg-32 {
    padding-left: 8.25rem !important;
  }
  .ps-lg-33 {
    padding-left: 8.5rem !important;
  }
  .ps-lg-34 {
    padding-left: 8.75rem !important;
  }
  .ps-lg-35 {
    padding-left: 9rem !important;
  }
  .ps-lg-36 {
    padding-left: 9.25rem !important;
  }
  .ps-lg-37 {
    padding-left: 9.5rem !important;
  }
  .ps-lg-38 {
    padding-left: 9.75rem !important;
  }
  .ps-lg-39 {
    padding-left: 10rem !important;
  }
  .ps-lg-40 {
    padding-left: 10.25rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 0.75rem !important;
  }
  .gap-lg-4 {
    gap: 1rem !important;
  }
  .gap-lg-5 {
    gap: 1.25rem !important;
  }
  .gap-lg-6 {
    gap: 1.5rem !important;
  }
  .gap-lg-7 {
    gap: 1.75rem !important;
  }
  .gap-lg-8 {
    gap: 2rem !important;
  }
  .gap-lg-9 {
    gap: 2.25rem !important;
  }
  .gap-lg-10 {
    gap: 2.5rem !important;
  }
  .gap-lg-11 {
    gap: 2.75rem !important;
  }
  .gap-lg-12 {
    gap: 3rem !important;
  }
  .gap-lg-13 {
    gap: 3.25rem !important;
  }
  .gap-lg-14 {
    gap: 3.5rem !important;
  }
  .gap-lg-15 {
    gap: 3.75rem !important;
  }
  .gap-lg-16 {
    gap: 4rem !important;
  }
  .gap-lg-17 {
    gap: 4.25rem !important;
  }
  .gap-lg-18 {
    gap: 4.5rem !important;
  }
  .gap-lg-19 {
    gap: 4.75rem !important;
  }
  .gap-lg-20 {
    gap: 5rem !important;
  }
  .gap-lg-21 {
    gap: 5.25rem !important;
  }
  .gap-lg-22 {
    gap: 5.5rem !important;
  }
  .gap-lg-23 {
    gap: 5.75rem !important;
  }
  .gap-lg-24 {
    gap: 6rem !important;
  }
  .gap-lg-25 {
    gap: 6.25rem !important;
  }
  .gap-lg-26 {
    gap: 6.5rem !important;
  }
  .gap-lg-27 {
    gap: 6.75rem !important;
  }
  .gap-lg-28 {
    gap: 7rem !important;
  }
  .gap-lg-29 {
    gap: 7.25rem !important;
  }
  .gap-lg-30 {
    gap: 7.5rem !important;
  }
  .gap-lg-31 {
    gap: 7.75rem !important;
  }
  .gap-lg-32 {
    gap: 8.25rem !important;
  }
  .gap-lg-33 {
    gap: 8.5rem !important;
  }
  .gap-lg-34 {
    gap: 8.75rem !important;
  }
  .gap-lg-35 {
    gap: 9rem !important;
  }
  .gap-lg-36 {
    gap: 9.25rem !important;
  }
  .gap-lg-37 {
    gap: 9.5rem !important;
  }
  .gap-lg-38 {
    gap: 9.75rem !important;
  }
  .gap-lg-39 {
    gap: 10rem !important;
  }
  .gap-lg-40 {
    gap: 10.25rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 0.75rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 1.25rem !important;
  }
  .row-gap-lg-6 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-7 {
    row-gap: 1.75rem !important;
  }
  .row-gap-lg-8 {
    row-gap: 2rem !important;
  }
  .row-gap-lg-9 {
    row-gap: 2.25rem !important;
  }
  .row-gap-lg-10 {
    row-gap: 2.5rem !important;
  }
  .row-gap-lg-11 {
    row-gap: 2.75rem !important;
  }
  .row-gap-lg-12 {
    row-gap: 3rem !important;
  }
  .row-gap-lg-13 {
    row-gap: 3.25rem !important;
  }
  .row-gap-lg-14 {
    row-gap: 3.5rem !important;
  }
  .row-gap-lg-15 {
    row-gap: 3.75rem !important;
  }
  .row-gap-lg-16 {
    row-gap: 4rem !important;
  }
  .row-gap-lg-17 {
    row-gap: 4.25rem !important;
  }
  .row-gap-lg-18 {
    row-gap: 4.5rem !important;
  }
  .row-gap-lg-19 {
    row-gap: 4.75rem !important;
  }
  .row-gap-lg-20 {
    row-gap: 5rem !important;
  }
  .row-gap-lg-21 {
    row-gap: 5.25rem !important;
  }
  .row-gap-lg-22 {
    row-gap: 5.5rem !important;
  }
  .row-gap-lg-23 {
    row-gap: 5.75rem !important;
  }
  .row-gap-lg-24 {
    row-gap: 6rem !important;
  }
  .row-gap-lg-25 {
    row-gap: 6.25rem !important;
  }
  .row-gap-lg-26 {
    row-gap: 6.5rem !important;
  }
  .row-gap-lg-27 {
    row-gap: 6.75rem !important;
  }
  .row-gap-lg-28 {
    row-gap: 7rem !important;
  }
  .row-gap-lg-29 {
    row-gap: 7.25rem !important;
  }
  .row-gap-lg-30 {
    row-gap: 7.5rem !important;
  }
  .row-gap-lg-31 {
    row-gap: 7.75rem !important;
  }
  .row-gap-lg-32 {
    row-gap: 8.25rem !important;
  }
  .row-gap-lg-33 {
    row-gap: 8.5rem !important;
  }
  .row-gap-lg-34 {
    row-gap: 8.75rem !important;
  }
  .row-gap-lg-35 {
    row-gap: 9rem !important;
  }
  .row-gap-lg-36 {
    row-gap: 9.25rem !important;
  }
  .row-gap-lg-37 {
    row-gap: 9.5rem !important;
  }
  .row-gap-lg-38 {
    row-gap: 9.75rem !important;
  }
  .row-gap-lg-39 {
    row-gap: 10rem !important;
  }
  .row-gap-lg-40 {
    row-gap: 10.25rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 0.75rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 1.25rem !important;
  }
  .column-gap-lg-6 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-7 {
    column-gap: 1.75rem !important;
  }
  .column-gap-lg-8 {
    column-gap: 2rem !important;
  }
  .column-gap-lg-9 {
    column-gap: 2.25rem !important;
  }
  .column-gap-lg-10 {
    column-gap: 2.5rem !important;
  }
  .column-gap-lg-11 {
    column-gap: 2.75rem !important;
  }
  .column-gap-lg-12 {
    column-gap: 3rem !important;
  }
  .column-gap-lg-13 {
    column-gap: 3.25rem !important;
  }
  .column-gap-lg-14 {
    column-gap: 3.5rem !important;
  }
  .column-gap-lg-15 {
    column-gap: 3.75rem !important;
  }
  .column-gap-lg-16 {
    column-gap: 4rem !important;
  }
  .column-gap-lg-17 {
    column-gap: 4.25rem !important;
  }
  .column-gap-lg-18 {
    column-gap: 4.5rem !important;
  }
  .column-gap-lg-19 {
    column-gap: 4.75rem !important;
  }
  .column-gap-lg-20 {
    column-gap: 5rem !important;
  }
  .column-gap-lg-21 {
    column-gap: 5.25rem !important;
  }
  .column-gap-lg-22 {
    column-gap: 5.5rem !important;
  }
  .column-gap-lg-23 {
    column-gap: 5.75rem !important;
  }
  .column-gap-lg-24 {
    column-gap: 6rem !important;
  }
  .column-gap-lg-25 {
    column-gap: 6.25rem !important;
  }
  .column-gap-lg-26 {
    column-gap: 6.5rem !important;
  }
  .column-gap-lg-27 {
    column-gap: 6.75rem !important;
  }
  .column-gap-lg-28 {
    column-gap: 7rem !important;
  }
  .column-gap-lg-29 {
    column-gap: 7.25rem !important;
  }
  .column-gap-lg-30 {
    column-gap: 7.5rem !important;
  }
  .column-gap-lg-31 {
    column-gap: 7.75rem !important;
  }
  .column-gap-lg-32 {
    column-gap: 8.25rem !important;
  }
  .column-gap-lg-33 {
    column-gap: 8.5rem !important;
  }
  .column-gap-lg-34 {
    column-gap: 8.75rem !important;
  }
  .column-gap-lg-35 {
    column-gap: 9rem !important;
  }
  .column-gap-lg-36 {
    column-gap: 9.25rem !important;
  }
  .column-gap-lg-37 {
    column-gap: 9.5rem !important;
  }
  .column-gap-lg-38 {
    column-gap: 9.75rem !important;
  }
  .column-gap-lg-39 {
    column-gap: 10rem !important;
  }
  .column-gap-lg-40 {
    column-gap: 10.25rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-xl-contain {
    object-fit: contain !important;
  }
  .object-xl-cover {
    object-fit: cover !important;
  }
  .object-xl-fill {
    object-fit: fill !important;
  }
  .object-xl-scale {
    object-fit: scale-down !important;
  }
  .object-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .w-xl-0 {
    width: 0 !important;
  }
  .w-xl-px {
    width: 1px !important;
  }
  .w-xl-2px {
    width: 2px !important;
  }
  .w-xl-1 {
    width: 0.25rem !important;
  }
  .w-xl-2 {
    width: 0.5rem !important;
  }
  .w-xl-3 {
    width: 0.75rem !important;
  }
  .w-xl-4 {
    width: 1rem !important;
  }
  .w-xl-5 {
    width: 1.25rem !important;
  }
  .w-xl-6 {
    width: 1.5rem !important;
  }
  .w-xl-7 {
    width: 1.75rem !important;
  }
  .w-xl-8 {
    width: 2rem !important;
  }
  .w-xl-9 {
    width: 2.25rem !important;
  }
  .w-xl-10 {
    width: 2.5rem !important;
  }
  .w-xl-11 {
    width: 2.75rem !important;
  }
  .w-xl-12 {
    width: 3rem !important;
  }
  .w-xl-13 {
    width: 3.25rem !important;
  }
  .w-xl-14 {
    width: 3.5rem !important;
  }
  .w-xl-15 {
    width: 3.75rem !important;
  }
  .w-xl-16 {
    width: 4rem !important;
  }
  .w-xl-17 {
    width: 4.25rem !important;
  }
  .w-xl-18 {
    width: 4.5rem !important;
  }
  .w-xl-19 {
    width: 4.75rem !important;
  }
  .w-xl-20 {
    width: 5rem !important;
  }
  .w-xl-21 {
    width: 5.25rem !important;
  }
  .w-xl-22 {
    width: 5.5rem !important;
  }
  .w-xl-23 {
    width: 5.75rem !important;
  }
  .w-xl-24 {
    width: 6rem !important;
  }
  .w-xl-25 {
    width: 6.25rem !important;
  }
  .w-xl-26 {
    width: 6.5rem !important;
  }
  .w-xl-27 {
    width: 6.75rem !important;
  }
  .w-xl-28 {
    width: 7rem !important;
  }
  .w-xl-29 {
    width: 7.25rem !important;
  }
  .w-xl-30 {
    width: 7.5rem !important;
  }
  .w-xl-31 {
    width: 7.75rem !important;
  }
  .w-xl-32 {
    width: 8rem !important;
  }
  .w-xl-quarter {
    width: 25% !important;
  }
  .w-xl-half {
    width: 50% !important;
  }
  .w-xl-3quarter {
    width: 75% !important;
  }
  .w-xl-full {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .h-xl-0 {
    height: 0 !important;
  }
  .h-xl-px {
    height: 1px !important;
  }
  .h-xl-2px {
    height: 2px !important;
  }
  .h-xl-1 {
    height: 0.25rem !important;
  }
  .h-xl-2 {
    height: 0.5rem !important;
  }
  .h-xl-3 {
    height: 0.75rem !important;
  }
  .h-xl-4 {
    height: 1rem !important;
  }
  .h-xl-5 {
    height: 1.25rem !important;
  }
  .h-xl-6 {
    height: 1.5rem !important;
  }
  .h-xl-7 {
    height: 1.75rem !important;
  }
  .h-xl-8 {
    height: 2rem !important;
  }
  .h-xl-9 {
    height: 2.25rem !important;
  }
  .h-xl-10 {
    height: 2.5rem !important;
  }
  .h-xl-11 {
    height: 2.75rem !important;
  }
  .h-xl-12 {
    height: 3rem !important;
  }
  .h-xl-13 {
    height: 3.25rem !important;
  }
  .h-xl-14 {
    height: 3.5rem !important;
  }
  .h-xl-15 {
    height: 3.75rem !important;
  }
  .h-xl-16 {
    height: 4rem !important;
  }
  .h-xl-17 {
    height: 4.25rem !important;
  }
  .h-xl-18 {
    height: 4.5rem !important;
  }
  .h-xl-19 {
    height: 4.75rem !important;
  }
  .h-xl-20 {
    height: 5rem !important;
  }
  .h-xl-21 {
    height: 5.25rem !important;
  }
  .h-xl-22 {
    height: 5.5rem !important;
  }
  .h-xl-23 {
    height: 5.75rem !important;
  }
  .h-xl-24 {
    height: 6rem !important;
  }
  .h-xl-25 {
    height: 6.25rem !important;
  }
  .h-xl-26 {
    height: 6.5rem !important;
  }
  .h-xl-27 {
    height: 6.75rem !important;
  }
  .h-xl-28 {
    height: 7rem !important;
  }
  .h-xl-29 {
    height: 7.25rem !important;
  }
  .h-xl-30 {
    height: 7.5rem !important;
  }
  .h-xl-31 {
    height: 7.75rem !important;
  }
  .h-xl-32 {
    height: 8rem !important;
  }
  .h-xl-quarter {
    height: 25% !important;
  }
  .h-xl-half {
    height: 50% !important;
  }
  .h-xl-3quarter {
    height: 75% !important;
  }
  .h-xl-full {
    height: 100% !important;
  }
  .h-xl-auto {
    height: auto !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-xl-start {
    justify-content: flex-start !important;
  }
  .justify-xl-end {
    justify-content: flex-end !important;
  }
  .justify-xl-center {
    justify-content: center !important;
  }
  .justify-xl-between {
    justify-content: space-between !important;
  }
  .justify-xl-around {
    justify-content: space-around !important;
  }
  .justify-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-xl-start {
    align-items: flex-start !important;
  }
  .align-xl-end {
    align-items: flex-end !important;
  }
  .align-xl-center {
    align-items: center !important;
  }
  .align-xl-baseline {
    align-items: baseline !important;
  }
  .align-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 0.75rem !important;
  }
  .m-xl-4 {
    margin: 1rem !important;
  }
  .m-xl-5 {
    margin: 1.25rem !important;
  }
  .m-xl-6 {
    margin: 1.5rem !important;
  }
  .m-xl-7 {
    margin: 1.75rem !important;
  }
  .m-xl-8 {
    margin: 2rem !important;
  }
  .m-xl-9 {
    margin: 2.25rem !important;
  }
  .m-xl-10 {
    margin: 2.5rem !important;
  }
  .m-xl-11 {
    margin: 2.75rem !important;
  }
  .m-xl-12 {
    margin: 3rem !important;
  }
  .m-xl-13 {
    margin: 3.25rem !important;
  }
  .m-xl-14 {
    margin: 3.5rem !important;
  }
  .m-xl-15 {
    margin: 3.75rem !important;
  }
  .m-xl-16 {
    margin: 4rem !important;
  }
  .m-xl-17 {
    margin: 4.25rem !important;
  }
  .m-xl-18 {
    margin: 4.5rem !important;
  }
  .m-xl-19 {
    margin: 4.75rem !important;
  }
  .m-xl-20 {
    margin: 5rem !important;
  }
  .m-xl-21 {
    margin: 5.25rem !important;
  }
  .m-xl-22 {
    margin: 5.5rem !important;
  }
  .m-xl-23 {
    margin: 5.75rem !important;
  }
  .m-xl-24 {
    margin: 6rem !important;
  }
  .m-xl-25 {
    margin: 6.25rem !important;
  }
  .m-xl-26 {
    margin: 6.5rem !important;
  }
  .m-xl-27 {
    margin: 6.75rem !important;
  }
  .m-xl-28 {
    margin: 7rem !important;
  }
  .m-xl-29 {
    margin: 7.25rem !important;
  }
  .m-xl-30 {
    margin: 7.5rem !important;
  }
  .m-xl-31 {
    margin: 7.75rem !important;
  }
  .m-xl-32 {
    margin: 8.25rem !important;
  }
  .m-xl-33 {
    margin: 8.5rem !important;
  }
  .m-xl-34 {
    margin: 8.75rem !important;
  }
  .m-xl-35 {
    margin: 9rem !important;
  }
  .m-xl-36 {
    margin: 9.25rem !important;
  }
  .m-xl-37 {
    margin: 9.5rem !important;
  }
  .m-xl-38 {
    margin: 9.75rem !important;
  }
  .m-xl-39 {
    margin: 10rem !important;
  }
  .m-xl-40 {
    margin: 10.25rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-xl-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-xl-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }
  .mx-xl-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xl-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-xl-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xl-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }
  .mx-xl-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }
  .mx-xl-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-xl-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-xl-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xl-17 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }
  .mx-xl-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-xl-19 {
    margin-right: 4.75rem !important;
    margin-left: 4.75rem !important;
  }
  .mx-xl-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xl-21 {
    margin-right: 5.25rem !important;
    margin-left: 5.25rem !important;
  }
  .mx-xl-22 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }
  .mx-xl-23 {
    margin-right: 5.75rem !important;
    margin-left: 5.75rem !important;
  }
  .mx-xl-24 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xl-25 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }
  .mx-xl-26 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }
  .mx-xl-27 {
    margin-right: 6.75rem !important;
    margin-left: 6.75rem !important;
  }
  .mx-xl-28 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-xl-29 {
    margin-right: 7.25rem !important;
    margin-left: 7.25rem !important;
  }
  .mx-xl-30 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-xl-31 {
    margin-right: 7.75rem !important;
    margin-left: 7.75rem !important;
  }
  .mx-xl-32 {
    margin-right: 8.25rem !important;
    margin-left: 8.25rem !important;
  }
  .mx-xl-33 {
    margin-right: 8.5rem !important;
    margin-left: 8.5rem !important;
  }
  .mx-xl-34 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important;
  }
  .mx-xl-35 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-xl-36 {
    margin-right: 9.25rem !important;
    margin-left: 9.25rem !important;
  }
  .mx-xl-37 {
    margin-right: 9.5rem !important;
    margin-left: 9.5rem !important;
  }
  .mx-xl-38 {
    margin-right: 9.75rem !important;
    margin-left: 9.75rem !important;
  }
  .mx-xl-39 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-xl-40 {
    margin-right: 10.25rem !important;
    margin-left: 10.25rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-xl-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-xl-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }
  .my-xl-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xl-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-xl-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xl-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }
  .my-xl-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }
  .my-xl-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-xl-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-xl-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xl-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }
  .my-xl-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-xl-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }
  .my-xl-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xl-21 {
    margin-top: 5.25rem !important;
    margin-bottom: 5.25rem !important;
  }
  .my-xl-22 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }
  .my-xl-23 {
    margin-top: 5.75rem !important;
    margin-bottom: 5.75rem !important;
  }
  .my-xl-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xl-25 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }
  .my-xl-26 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }
  .my-xl-27 {
    margin-top: 6.75rem !important;
    margin-bottom: 6.75rem !important;
  }
  .my-xl-28 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-xl-29 {
    margin-top: 7.25rem !important;
    margin-bottom: 7.25rem !important;
  }
  .my-xl-30 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-xl-31 {
    margin-top: 7.75rem !important;
    margin-bottom: 7.75rem !important;
  }
  .my-xl-32 {
    margin-top: 8.25rem !important;
    margin-bottom: 8.25rem !important;
  }
  .my-xl-33 {
    margin-top: 8.5rem !important;
    margin-bottom: 8.5rem !important;
  }
  .my-xl-34 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important;
  }
  .my-xl-35 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-xl-36 {
    margin-top: 9.25rem !important;
    margin-bottom: 9.25rem !important;
  }
  .my-xl-37 {
    margin-top: 9.5rem !important;
    margin-bottom: 9.5rem !important;
  }
  .my-xl-38 {
    margin-top: 9.75rem !important;
    margin-bottom: 9.75rem !important;
  }
  .my-xl-39 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-xl-40 {
    margin-top: 10.25rem !important;
    margin-bottom: 10.25rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 0.75rem !important;
  }
  .mt-xl-4 {
    margin-top: 1rem !important;
  }
  .mt-xl-5 {
    margin-top: 1.25rem !important;
  }
  .mt-xl-6 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-7 {
    margin-top: 1.75rem !important;
  }
  .mt-xl-8 {
    margin-top: 2rem !important;
  }
  .mt-xl-9 {
    margin-top: 2.25rem !important;
  }
  .mt-xl-10 {
    margin-top: 2.5rem !important;
  }
  .mt-xl-11 {
    margin-top: 2.75rem !important;
  }
  .mt-xl-12 {
    margin-top: 3rem !important;
  }
  .mt-xl-13 {
    margin-top: 3.25rem !important;
  }
  .mt-xl-14 {
    margin-top: 3.5rem !important;
  }
  .mt-xl-15 {
    margin-top: 3.75rem !important;
  }
  .mt-xl-16 {
    margin-top: 4rem !important;
  }
  .mt-xl-17 {
    margin-top: 4.25rem !important;
  }
  .mt-xl-18 {
    margin-top: 4.5rem !important;
  }
  .mt-xl-19 {
    margin-top: 4.75rem !important;
  }
  .mt-xl-20 {
    margin-top: 5rem !important;
  }
  .mt-xl-21 {
    margin-top: 5.25rem !important;
  }
  .mt-xl-22 {
    margin-top: 5.5rem !important;
  }
  .mt-xl-23 {
    margin-top: 5.75rem !important;
  }
  .mt-xl-24 {
    margin-top: 6rem !important;
  }
  .mt-xl-25 {
    margin-top: 6.25rem !important;
  }
  .mt-xl-26 {
    margin-top: 6.5rem !important;
  }
  .mt-xl-27 {
    margin-top: 6.75rem !important;
  }
  .mt-xl-28 {
    margin-top: 7rem !important;
  }
  .mt-xl-29 {
    margin-top: 7.25rem !important;
  }
  .mt-xl-30 {
    margin-top: 7.5rem !important;
  }
  .mt-xl-31 {
    margin-top: 7.75rem !important;
  }
  .mt-xl-32 {
    margin-top: 8.25rem !important;
  }
  .mt-xl-33 {
    margin-top: 8.5rem !important;
  }
  .mt-xl-34 {
    margin-top: 8.75rem !important;
  }
  .mt-xl-35 {
    margin-top: 9rem !important;
  }
  .mt-xl-36 {
    margin-top: 9.25rem !important;
  }
  .mt-xl-37 {
    margin-top: 9.5rem !important;
  }
  .mt-xl-38 {
    margin-top: 9.75rem !important;
  }
  .mt-xl-39 {
    margin-top: 10rem !important;
  }
  .mt-xl-40 {
    margin-top: 10.25rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 0.75rem !important;
  }
  .me-xl-4 {
    margin-right: 1rem !important;
  }
  .me-xl-5 {
    margin-right: 1.25rem !important;
  }
  .me-xl-6 {
    margin-right: 1.5rem !important;
  }
  .me-xl-7 {
    margin-right: 1.75rem !important;
  }
  .me-xl-8 {
    margin-right: 2rem !important;
  }
  .me-xl-9 {
    margin-right: 2.25rem !important;
  }
  .me-xl-10 {
    margin-right: 2.5rem !important;
  }
  .me-xl-11 {
    margin-right: 2.75rem !important;
  }
  .me-xl-12 {
    margin-right: 3rem !important;
  }
  .me-xl-13 {
    margin-right: 3.25rem !important;
  }
  .me-xl-14 {
    margin-right: 3.5rem !important;
  }
  .me-xl-15 {
    margin-right: 3.75rem !important;
  }
  .me-xl-16 {
    margin-right: 4rem !important;
  }
  .me-xl-17 {
    margin-right: 4.25rem !important;
  }
  .me-xl-18 {
    margin-right: 4.5rem !important;
  }
  .me-xl-19 {
    margin-right: 4.75rem !important;
  }
  .me-xl-20 {
    margin-right: 5rem !important;
  }
  .me-xl-21 {
    margin-right: 5.25rem !important;
  }
  .me-xl-22 {
    margin-right: 5.5rem !important;
  }
  .me-xl-23 {
    margin-right: 5.75rem !important;
  }
  .me-xl-24 {
    margin-right: 6rem !important;
  }
  .me-xl-25 {
    margin-right: 6.25rem !important;
  }
  .me-xl-26 {
    margin-right: 6.5rem !important;
  }
  .me-xl-27 {
    margin-right: 6.75rem !important;
  }
  .me-xl-28 {
    margin-right: 7rem !important;
  }
  .me-xl-29 {
    margin-right: 7.25rem !important;
  }
  .me-xl-30 {
    margin-right: 7.5rem !important;
  }
  .me-xl-31 {
    margin-right: 7.75rem !important;
  }
  .me-xl-32 {
    margin-right: 8.25rem !important;
  }
  .me-xl-33 {
    margin-right: 8.5rem !important;
  }
  .me-xl-34 {
    margin-right: 8.75rem !important;
  }
  .me-xl-35 {
    margin-right: 9rem !important;
  }
  .me-xl-36 {
    margin-right: 9.25rem !important;
  }
  .me-xl-37 {
    margin-right: 9.5rem !important;
  }
  .me-xl-38 {
    margin-right: 9.75rem !important;
  }
  .me-xl-39 {
    margin-right: 10rem !important;
  }
  .me-xl-40 {
    margin-right: 10.25rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 1.75rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 2rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 2.25rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xl-11 {
    margin-bottom: 2.75rem !important;
  }
  .mb-xl-12 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-13 {
    margin-bottom: 3.25rem !important;
  }
  .mb-xl-14 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xl-15 {
    margin-bottom: 3.75rem !important;
  }
  .mb-xl-16 {
    margin-bottom: 4rem !important;
  }
  .mb-xl-17 {
    margin-bottom: 4.25rem !important;
  }
  .mb-xl-18 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xl-19 {
    margin-bottom: 4.75rem !important;
  }
  .mb-xl-20 {
    margin-bottom: 5rem !important;
  }
  .mb-xl-21 {
    margin-bottom: 5.25rem !important;
  }
  .mb-xl-22 {
    margin-bottom: 5.5rem !important;
  }
  .mb-xl-23 {
    margin-bottom: 5.75rem !important;
  }
  .mb-xl-24 {
    margin-bottom: 6rem !important;
  }
  .mb-xl-25 {
    margin-bottom: 6.25rem !important;
  }
  .mb-xl-26 {
    margin-bottom: 6.5rem !important;
  }
  .mb-xl-27 {
    margin-bottom: 6.75rem !important;
  }
  .mb-xl-28 {
    margin-bottom: 7rem !important;
  }
  .mb-xl-29 {
    margin-bottom: 7.25rem !important;
  }
  .mb-xl-30 {
    margin-bottom: 7.5rem !important;
  }
  .mb-xl-31 {
    margin-bottom: 7.75rem !important;
  }
  .mb-xl-32 {
    margin-bottom: 8.25rem !important;
  }
  .mb-xl-33 {
    margin-bottom: 8.5rem !important;
  }
  .mb-xl-34 {
    margin-bottom: 8.75rem !important;
  }
  .mb-xl-35 {
    margin-bottom: 9rem !important;
  }
  .mb-xl-36 {
    margin-bottom: 9.25rem !important;
  }
  .mb-xl-37 {
    margin-bottom: 9.5rem !important;
  }
  .mb-xl-38 {
    margin-bottom: 9.75rem !important;
  }
  .mb-xl-39 {
    margin-bottom: 10rem !important;
  }
  .mb-xl-40 {
    margin-bottom: 10.25rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 0.75rem !important;
  }
  .ms-xl-4 {
    margin-left: 1rem !important;
  }
  .ms-xl-5 {
    margin-left: 1.25rem !important;
  }
  .ms-xl-6 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-7 {
    margin-left: 1.75rem !important;
  }
  .ms-xl-8 {
    margin-left: 2rem !important;
  }
  .ms-xl-9 {
    margin-left: 2.25rem !important;
  }
  .ms-xl-10 {
    margin-left: 2.5rem !important;
  }
  .ms-xl-11 {
    margin-left: 2.75rem !important;
  }
  .ms-xl-12 {
    margin-left: 3rem !important;
  }
  .ms-xl-13 {
    margin-left: 3.25rem !important;
  }
  .ms-xl-14 {
    margin-left: 3.5rem !important;
  }
  .ms-xl-15 {
    margin-left: 3.75rem !important;
  }
  .ms-xl-16 {
    margin-left: 4rem !important;
  }
  .ms-xl-17 {
    margin-left: 4.25rem !important;
  }
  .ms-xl-18 {
    margin-left: 4.5rem !important;
  }
  .ms-xl-19 {
    margin-left: 4.75rem !important;
  }
  .ms-xl-20 {
    margin-left: 5rem !important;
  }
  .ms-xl-21 {
    margin-left: 5.25rem !important;
  }
  .ms-xl-22 {
    margin-left: 5.5rem !important;
  }
  .ms-xl-23 {
    margin-left: 5.75rem !important;
  }
  .ms-xl-24 {
    margin-left: 6rem !important;
  }
  .ms-xl-25 {
    margin-left: 6.25rem !important;
  }
  .ms-xl-26 {
    margin-left: 6.5rem !important;
  }
  .ms-xl-27 {
    margin-left: 6.75rem !important;
  }
  .ms-xl-28 {
    margin-left: 7rem !important;
  }
  .ms-xl-29 {
    margin-left: 7.25rem !important;
  }
  .ms-xl-30 {
    margin-left: 7.5rem !important;
  }
  .ms-xl-31 {
    margin-left: 7.75rem !important;
  }
  .ms-xl-32 {
    margin-left: 8.25rem !important;
  }
  .ms-xl-33 {
    margin-left: 8.5rem !important;
  }
  .ms-xl-34 {
    margin-left: 8.75rem !important;
  }
  .ms-xl-35 {
    margin-left: 9rem !important;
  }
  .ms-xl-36 {
    margin-left: 9.25rem !important;
  }
  .ms-xl-37 {
    margin-left: 9.5rem !important;
  }
  .ms-xl-38 {
    margin-left: 9.75rem !important;
  }
  .ms-xl-39 {
    margin-left: 10rem !important;
  }
  .ms-xl-40 {
    margin-left: 10.25rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -0.75rem !important;
  }
  .m-xl-n4 {
    margin: -1rem !important;
  }
  .m-xl-n5 {
    margin: -1.25rem !important;
  }
  .m-xl-n6 {
    margin: -1.5rem !important;
  }
  .m-xl-n7 {
    margin: -1.75rem !important;
  }
  .m-xl-n8 {
    margin: -2rem !important;
  }
  .m-xl-n9 {
    margin: -2.25rem !important;
  }
  .m-xl-n10 {
    margin: -2.5rem !important;
  }
  .m-xl-n11 {
    margin: -2.75rem !important;
  }
  .m-xl-n12 {
    margin: -3rem !important;
  }
  .m-xl-n13 {
    margin: -3.25rem !important;
  }
  .m-xl-n14 {
    margin: -3.5rem !important;
  }
  .m-xl-n15 {
    margin: -3.75rem !important;
  }
  .m-xl-n16 {
    margin: -4rem !important;
  }
  .m-xl-n17 {
    margin: -4.25rem !important;
  }
  .m-xl-n18 {
    margin: -4.5rem !important;
  }
  .m-xl-n19 {
    margin: -4.75rem !important;
  }
  .m-xl-n20 {
    margin: -5rem !important;
  }
  .m-xl-n21 {
    margin: -5.25rem !important;
  }
  .m-xl-n22 {
    margin: -5.5rem !important;
  }
  .m-xl-n23 {
    margin: -5.75rem !important;
  }
  .m-xl-n24 {
    margin: -6rem !important;
  }
  .m-xl-n25 {
    margin: -6.25rem !important;
  }
  .m-xl-n26 {
    margin: -6.5rem !important;
  }
  .m-xl-n27 {
    margin: -6.75rem !important;
  }
  .m-xl-n28 {
    margin: -7rem !important;
  }
  .m-xl-n29 {
    margin: -7.25rem !important;
  }
  .m-xl-n30 {
    margin: -7.5rem !important;
  }
  .m-xl-n31 {
    margin: -7.75rem !important;
  }
  .m-xl-n32 {
    margin: -8.25rem !important;
  }
  .m-xl-n33 {
    margin: -8.5rem !important;
  }
  .m-xl-n34 {
    margin: -8.75rem !important;
  }
  .m-xl-n35 {
    margin: -9rem !important;
  }
  .m-xl-n36 {
    margin: -9.25rem !important;
  }
  .m-xl-n37 {
    margin: -9.5rem !important;
  }
  .m-xl-n38 {
    margin: -9.75rem !important;
  }
  .m-xl-n39 {
    margin: -10rem !important;
  }
  .m-xl-n40 {
    margin: -10.25rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-xl-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }
  .mx-xl-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-xl-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }
  .mx-xl-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-xl-n11 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }
  .mx-xl-n12 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xl-n13 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }
  .mx-xl-n14 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-xl-n15 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }
  .mx-xl-n16 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-xl-n17 {
    margin-right: -4.25rem !important;
    margin-left: -4.25rem !important;
  }
  .mx-xl-n18 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .mx-xl-n19 {
    margin-right: -4.75rem !important;
    margin-left: -4.75rem !important;
  }
  .mx-xl-n20 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-xl-n21 {
    margin-right: -5.25rem !important;
    margin-left: -5.25rem !important;
  }
  .mx-xl-n22 {
    margin-right: -5.5rem !important;
    margin-left: -5.5rem !important;
  }
  .mx-xl-n23 {
    margin-right: -5.75rem !important;
    margin-left: -5.75rem !important;
  }
  .mx-xl-n24 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-xl-n25 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important;
  }
  .mx-xl-n26 {
    margin-right: -6.5rem !important;
    margin-left: -6.5rem !important;
  }
  .mx-xl-n27 {
    margin-right: -6.75rem !important;
    margin-left: -6.75rem !important;
  }
  .mx-xl-n28 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }
  .mx-xl-n29 {
    margin-right: -7.25rem !important;
    margin-left: -7.25rem !important;
  }
  .mx-xl-n30 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  .mx-xl-n31 {
    margin-right: -7.75rem !important;
    margin-left: -7.75rem !important;
  }
  .mx-xl-n32 {
    margin-right: -8.25rem !important;
    margin-left: -8.25rem !important;
  }
  .mx-xl-n33 {
    margin-right: -8.5rem !important;
    margin-left: -8.5rem !important;
  }
  .mx-xl-n34 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important;
  }
  .mx-xl-n35 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  .mx-xl-n36 {
    margin-right: -9.25rem !important;
    margin-left: -9.25rem !important;
  }
  .mx-xl-n37 {
    margin-right: -9.5rem !important;
    margin-left: -9.5rem !important;
  }
  .mx-xl-n38 {
    margin-right: -9.75rem !important;
    margin-left: -9.75rem !important;
  }
  .mx-xl-n39 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .mx-xl-n40 {
    margin-right: -10.25rem !important;
    margin-left: -10.25rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-xl-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-xl-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }
  .my-xl-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-xl-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }
  .my-xl-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-xl-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }
  .my-xl-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xl-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }
  .my-xl-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-xl-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }
  .my-xl-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-xl-n17 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }
  .my-xl-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .my-xl-n19 {
    margin-top: -4.75rem !important;
    margin-bottom: -4.75rem !important;
  }
  .my-xl-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-xl-n21 {
    margin-top: -5.25rem !important;
    margin-bottom: -5.25rem !important;
  }
  .my-xl-n22 {
    margin-top: -5.5rem !important;
    margin-bottom: -5.5rem !important;
  }
  .my-xl-n23 {
    margin-top: -5.75rem !important;
    margin-bottom: -5.75rem !important;
  }
  .my-xl-n24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-xl-n25 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important;
  }
  .my-xl-n26 {
    margin-top: -6.5rem !important;
    margin-bottom: -6.5rem !important;
  }
  .my-xl-n27 {
    margin-top: -6.75rem !important;
    margin-bottom: -6.75rem !important;
  }
  .my-xl-n28 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }
  .my-xl-n29 {
    margin-top: -7.25rem !important;
    margin-bottom: -7.25rem !important;
  }
  .my-xl-n30 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .my-xl-n31 {
    margin-top: -7.75rem !important;
    margin-bottom: -7.75rem !important;
  }
  .my-xl-n32 {
    margin-top: -8.25rem !important;
    margin-bottom: -8.25rem !important;
  }
  .my-xl-n33 {
    margin-top: -8.5rem !important;
    margin-bottom: -8.5rem !important;
  }
  .my-xl-n34 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important;
  }
  .my-xl-n35 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  .my-xl-n36 {
    margin-top: -9.25rem !important;
    margin-bottom: -9.25rem !important;
  }
  .my-xl-n37 {
    margin-top: -9.5rem !important;
    margin-bottom: -9.5rem !important;
  }
  .my-xl-n38 {
    margin-top: -9.75rem !important;
    margin-bottom: -9.75rem !important;
  }
  .my-xl-n39 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .my-xl-n40 {
    margin-top: -10.25rem !important;
    margin-bottom: -10.25rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -0.75rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1rem !important;
  }
  .mt-xl-n5 {
    margin-top: -1.25rem !important;
  }
  .mt-xl-n6 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n7 {
    margin-top: -1.75rem !important;
  }
  .mt-xl-n8 {
    margin-top: -2rem !important;
  }
  .mt-xl-n9 {
    margin-top: -2.25rem !important;
  }
  .mt-xl-n10 {
    margin-top: -2.5rem !important;
  }
  .mt-xl-n11 {
    margin-top: -2.75rem !important;
  }
  .mt-xl-n12 {
    margin-top: -3rem !important;
  }
  .mt-xl-n13 {
    margin-top: -3.25rem !important;
  }
  .mt-xl-n14 {
    margin-top: -3.5rem !important;
  }
  .mt-xl-n15 {
    margin-top: -3.75rem !important;
  }
  .mt-xl-n16 {
    margin-top: -4rem !important;
  }
  .mt-xl-n17 {
    margin-top: -4.25rem !important;
  }
  .mt-xl-n18 {
    margin-top: -4.5rem !important;
  }
  .mt-xl-n19 {
    margin-top: -4.75rem !important;
  }
  .mt-xl-n20 {
    margin-top: -5rem !important;
  }
  .mt-xl-n21 {
    margin-top: -5.25rem !important;
  }
  .mt-xl-n22 {
    margin-top: -5.5rem !important;
  }
  .mt-xl-n23 {
    margin-top: -5.75rem !important;
  }
  .mt-xl-n24 {
    margin-top: -6rem !important;
  }
  .mt-xl-n25 {
    margin-top: -6.25rem !important;
  }
  .mt-xl-n26 {
    margin-top: -6.5rem !important;
  }
  .mt-xl-n27 {
    margin-top: -6.75rem !important;
  }
  .mt-xl-n28 {
    margin-top: -7rem !important;
  }
  .mt-xl-n29 {
    margin-top: -7.25rem !important;
  }
  .mt-xl-n30 {
    margin-top: -7.5rem !important;
  }
  .mt-xl-n31 {
    margin-top: -7.75rem !important;
  }
  .mt-xl-n32 {
    margin-top: -8.25rem !important;
  }
  .mt-xl-n33 {
    margin-top: -8.5rem !important;
  }
  .mt-xl-n34 {
    margin-top: -8.75rem !important;
  }
  .mt-xl-n35 {
    margin-top: -9rem !important;
  }
  .mt-xl-n36 {
    margin-top: -9.25rem !important;
  }
  .mt-xl-n37 {
    margin-top: -9.5rem !important;
  }
  .mt-xl-n38 {
    margin-top: -9.75rem !important;
  }
  .mt-xl-n39 {
    margin-top: -10rem !important;
  }
  .mt-xl-n40 {
    margin-top: -10.25rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -0.75rem !important;
  }
  .me-xl-n4 {
    margin-right: -1rem !important;
  }
  .me-xl-n5 {
    margin-right: -1.25rem !important;
  }
  .me-xl-n6 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n7 {
    margin-right: -1.75rem !important;
  }
  .me-xl-n8 {
    margin-right: -2rem !important;
  }
  .me-xl-n9 {
    margin-right: -2.25rem !important;
  }
  .me-xl-n10 {
    margin-right: -2.5rem !important;
  }
  .me-xl-n11 {
    margin-right: -2.75rem !important;
  }
  .me-xl-n12 {
    margin-right: -3rem !important;
  }
  .me-xl-n13 {
    margin-right: -3.25rem !important;
  }
  .me-xl-n14 {
    margin-right: -3.5rem !important;
  }
  .me-xl-n15 {
    margin-right: -3.75rem !important;
  }
  .me-xl-n16 {
    margin-right: -4rem !important;
  }
  .me-xl-n17 {
    margin-right: -4.25rem !important;
  }
  .me-xl-n18 {
    margin-right: -4.5rem !important;
  }
  .me-xl-n19 {
    margin-right: -4.75rem !important;
  }
  .me-xl-n20 {
    margin-right: -5rem !important;
  }
  .me-xl-n21 {
    margin-right: -5.25rem !important;
  }
  .me-xl-n22 {
    margin-right: -5.5rem !important;
  }
  .me-xl-n23 {
    margin-right: -5.75rem !important;
  }
  .me-xl-n24 {
    margin-right: -6rem !important;
  }
  .me-xl-n25 {
    margin-right: -6.25rem !important;
  }
  .me-xl-n26 {
    margin-right: -6.5rem !important;
  }
  .me-xl-n27 {
    margin-right: -6.75rem !important;
  }
  .me-xl-n28 {
    margin-right: -7rem !important;
  }
  .me-xl-n29 {
    margin-right: -7.25rem !important;
  }
  .me-xl-n30 {
    margin-right: -7.5rem !important;
  }
  .me-xl-n31 {
    margin-right: -7.75rem !important;
  }
  .me-xl-n32 {
    margin-right: -8.25rem !important;
  }
  .me-xl-n33 {
    margin-right: -8.5rem !important;
  }
  .me-xl-n34 {
    margin-right: -8.75rem !important;
  }
  .me-xl-n35 {
    margin-right: -9rem !important;
  }
  .me-xl-n36 {
    margin-right: -9.25rem !important;
  }
  .me-xl-n37 {
    margin-right: -9.5rem !important;
  }
  .me-xl-n38 {
    margin-right: -9.75rem !important;
  }
  .me-xl-n39 {
    margin-right: -10rem !important;
  }
  .me-xl-n40 {
    margin-right: -10.25rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -0.75rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -1.25rem !important;
  }
  .mb-xl-n6 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n7 {
    margin-bottom: -1.75rem !important;
  }
  .mb-xl-n8 {
    margin-bottom: -2rem !important;
  }
  .mb-xl-n9 {
    margin-bottom: -2.25rem !important;
  }
  .mb-xl-n10 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xl-n11 {
    margin-bottom: -2.75rem !important;
  }
  .mb-xl-n12 {
    margin-bottom: -3rem !important;
  }
  .mb-xl-n13 {
    margin-bottom: -3.25rem !important;
  }
  .mb-xl-n14 {
    margin-bottom: -3.5rem !important;
  }
  .mb-xl-n15 {
    margin-bottom: -3.75rem !important;
  }
  .mb-xl-n16 {
    margin-bottom: -4rem !important;
  }
  .mb-xl-n17 {
    margin-bottom: -4.25rem !important;
  }
  .mb-xl-n18 {
    margin-bottom: -4.5rem !important;
  }
  .mb-xl-n19 {
    margin-bottom: -4.75rem !important;
  }
  .mb-xl-n20 {
    margin-bottom: -5rem !important;
  }
  .mb-xl-n21 {
    margin-bottom: -5.25rem !important;
  }
  .mb-xl-n22 {
    margin-bottom: -5.5rem !important;
  }
  .mb-xl-n23 {
    margin-bottom: -5.75rem !important;
  }
  .mb-xl-n24 {
    margin-bottom: -6rem !important;
  }
  .mb-xl-n25 {
    margin-bottom: -6.25rem !important;
  }
  .mb-xl-n26 {
    margin-bottom: -6.5rem !important;
  }
  .mb-xl-n27 {
    margin-bottom: -6.75rem !important;
  }
  .mb-xl-n28 {
    margin-bottom: -7rem !important;
  }
  .mb-xl-n29 {
    margin-bottom: -7.25rem !important;
  }
  .mb-xl-n30 {
    margin-bottom: -7.5rem !important;
  }
  .mb-xl-n31 {
    margin-bottom: -7.75rem !important;
  }
  .mb-xl-n32 {
    margin-bottom: -8.25rem !important;
  }
  .mb-xl-n33 {
    margin-bottom: -8.5rem !important;
  }
  .mb-xl-n34 {
    margin-bottom: -8.75rem !important;
  }
  .mb-xl-n35 {
    margin-bottom: -9rem !important;
  }
  .mb-xl-n36 {
    margin-bottom: -9.25rem !important;
  }
  .mb-xl-n37 {
    margin-bottom: -9.5rem !important;
  }
  .mb-xl-n38 {
    margin-bottom: -9.75rem !important;
  }
  .mb-xl-n39 {
    margin-bottom: -10rem !important;
  }
  .mb-xl-n40 {
    margin-bottom: -10.25rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -0.75rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1rem !important;
  }
  .ms-xl-n5 {
    margin-left: -1.25rem !important;
  }
  .ms-xl-n6 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n7 {
    margin-left: -1.75rem !important;
  }
  .ms-xl-n8 {
    margin-left: -2rem !important;
  }
  .ms-xl-n9 {
    margin-left: -2.25rem !important;
  }
  .ms-xl-n10 {
    margin-left: -2.5rem !important;
  }
  .ms-xl-n11 {
    margin-left: -2.75rem !important;
  }
  .ms-xl-n12 {
    margin-left: -3rem !important;
  }
  .ms-xl-n13 {
    margin-left: -3.25rem !important;
  }
  .ms-xl-n14 {
    margin-left: -3.5rem !important;
  }
  .ms-xl-n15 {
    margin-left: -3.75rem !important;
  }
  .ms-xl-n16 {
    margin-left: -4rem !important;
  }
  .ms-xl-n17 {
    margin-left: -4.25rem !important;
  }
  .ms-xl-n18 {
    margin-left: -4.5rem !important;
  }
  .ms-xl-n19 {
    margin-left: -4.75rem !important;
  }
  .ms-xl-n20 {
    margin-left: -5rem !important;
  }
  .ms-xl-n21 {
    margin-left: -5.25rem !important;
  }
  .ms-xl-n22 {
    margin-left: -5.5rem !important;
  }
  .ms-xl-n23 {
    margin-left: -5.75rem !important;
  }
  .ms-xl-n24 {
    margin-left: -6rem !important;
  }
  .ms-xl-n25 {
    margin-left: -6.25rem !important;
  }
  .ms-xl-n26 {
    margin-left: -6.5rem !important;
  }
  .ms-xl-n27 {
    margin-left: -6.75rem !important;
  }
  .ms-xl-n28 {
    margin-left: -7rem !important;
  }
  .ms-xl-n29 {
    margin-left: -7.25rem !important;
  }
  .ms-xl-n30 {
    margin-left: -7.5rem !important;
  }
  .ms-xl-n31 {
    margin-left: -7.75rem !important;
  }
  .ms-xl-n32 {
    margin-left: -8.25rem !important;
  }
  .ms-xl-n33 {
    margin-left: -8.5rem !important;
  }
  .ms-xl-n34 {
    margin-left: -8.75rem !important;
  }
  .ms-xl-n35 {
    margin-left: -9rem !important;
  }
  .ms-xl-n36 {
    margin-left: -9.25rem !important;
  }
  .ms-xl-n37 {
    margin-left: -9.5rem !important;
  }
  .ms-xl-n38 {
    margin-left: -9.75rem !important;
  }
  .ms-xl-n39 {
    margin-left: -10rem !important;
  }
  .ms-xl-n40 {
    margin-left: -10.25rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 0.75rem !important;
  }
  .p-xl-4 {
    padding: 1rem !important;
  }
  .p-xl-5 {
    padding: 1.25rem !important;
  }
  .p-xl-6 {
    padding: 1.5rem !important;
  }
  .p-xl-7 {
    padding: 1.75rem !important;
  }
  .p-xl-8 {
    padding: 2rem !important;
  }
  .p-xl-9 {
    padding: 2.25rem !important;
  }
  .p-xl-10 {
    padding: 2.5rem !important;
  }
  .p-xl-11 {
    padding: 2.75rem !important;
  }
  .p-xl-12 {
    padding: 3rem !important;
  }
  .p-xl-13 {
    padding: 3.25rem !important;
  }
  .p-xl-14 {
    padding: 3.5rem !important;
  }
  .p-xl-15 {
    padding: 3.75rem !important;
  }
  .p-xl-16 {
    padding: 4rem !important;
  }
  .p-xl-17 {
    padding: 4.25rem !important;
  }
  .p-xl-18 {
    padding: 4.5rem !important;
  }
  .p-xl-19 {
    padding: 4.75rem !important;
  }
  .p-xl-20 {
    padding: 5rem !important;
  }
  .p-xl-21 {
    padding: 5.25rem !important;
  }
  .p-xl-22 {
    padding: 5.5rem !important;
  }
  .p-xl-23 {
    padding: 5.75rem !important;
  }
  .p-xl-24 {
    padding: 6rem !important;
  }
  .p-xl-25 {
    padding: 6.25rem !important;
  }
  .p-xl-26 {
    padding: 6.5rem !important;
  }
  .p-xl-27 {
    padding: 6.75rem !important;
  }
  .p-xl-28 {
    padding: 7rem !important;
  }
  .p-xl-29 {
    padding: 7.25rem !important;
  }
  .p-xl-30 {
    padding: 7.5rem !important;
  }
  .p-xl-31 {
    padding: 7.75rem !important;
  }
  .p-xl-32 {
    padding: 8.25rem !important;
  }
  .p-xl-33 {
    padding: 8.5rem !important;
  }
  .p-xl-34 {
    padding: 8.75rem !important;
  }
  .p-xl-35 {
    padding: 9rem !important;
  }
  .p-xl-36 {
    padding: 9.25rem !important;
  }
  .p-xl-37 {
    padding: 9.5rem !important;
  }
  .p-xl-38 {
    padding: 9.75rem !important;
  }
  .p-xl-39 {
    padding: 10rem !important;
  }
  .p-xl-40 {
    padding: 10.25rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-xl-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-xl-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }
  .px-xl-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xl-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-xl-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xl-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }
  .px-xl-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xl-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }
  .px-xl-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-xl-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-xl-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xl-17 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }
  .px-xl-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-xl-19 {
    padding-right: 4.75rem !important;
    padding-left: 4.75rem !important;
  }
  .px-xl-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xl-21 {
    padding-right: 5.25rem !important;
    padding-left: 5.25rem !important;
  }
  .px-xl-22 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }
  .px-xl-23 {
    padding-right: 5.75rem !important;
    padding-left: 5.75rem !important;
  }
  .px-xl-24 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xl-25 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }
  .px-xl-26 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }
  .px-xl-27 {
    padding-right: 6.75rem !important;
    padding-left: 6.75rem !important;
  }
  .px-xl-28 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-xl-29 {
    padding-right: 7.25rem !important;
    padding-left: 7.25rem !important;
  }
  .px-xl-30 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-xl-31 {
    padding-right: 7.75rem !important;
    padding-left: 7.75rem !important;
  }
  .px-xl-32 {
    padding-right: 8.25rem !important;
    padding-left: 8.25rem !important;
  }
  .px-xl-33 {
    padding-right: 8.5rem !important;
    padding-left: 8.5rem !important;
  }
  .px-xl-34 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important;
  }
  .px-xl-35 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-xl-36 {
    padding-right: 9.25rem !important;
    padding-left: 9.25rem !important;
  }
  .px-xl-37 {
    padding-right: 9.5rem !important;
    padding-left: 9.5rem !important;
  }
  .px-xl-38 {
    padding-right: 9.75rem !important;
    padding-left: 9.75rem !important;
  }
  .px-xl-39 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-xl-40 {
    padding-right: 10.25rem !important;
    padding-left: 10.25rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-xl-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-xl-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }
  .py-xl-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xl-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-xl-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xl-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }
  .py-xl-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }
  .py-xl-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-xl-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-xl-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xl-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }
  .py-xl-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-xl-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }
  .py-xl-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xl-21 {
    padding-top: 5.25rem !important;
    padding-bottom: 5.25rem !important;
  }
  .py-xl-22 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }
  .py-xl-23 {
    padding-top: 5.75rem !important;
    padding-bottom: 5.75rem !important;
  }
  .py-xl-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xl-25 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .py-xl-26 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }
  .py-xl-27 {
    padding-top: 6.75rem !important;
    padding-bottom: 6.75rem !important;
  }
  .py-xl-28 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-xl-29 {
    padding-top: 7.25rem !important;
    padding-bottom: 7.25rem !important;
  }
  .py-xl-30 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-xl-31 {
    padding-top: 7.75rem !important;
    padding-bottom: 7.75rem !important;
  }
  .py-xl-32 {
    padding-top: 8.25rem !important;
    padding-bottom: 8.25rem !important;
  }
  .py-xl-33 {
    padding-top: 8.5rem !important;
    padding-bottom: 8.5rem !important;
  }
  .py-xl-34 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important;
  }
  .py-xl-35 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-xl-36 {
    padding-top: 9.25rem !important;
    padding-bottom: 9.25rem !important;
  }
  .py-xl-37 {
    padding-top: 9.5rem !important;
    padding-bottom: 9.5rem !important;
  }
  .py-xl-38 {
    padding-top: 9.75rem !important;
    padding-bottom: 9.75rem !important;
  }
  .py-xl-39 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-xl-40 {
    padding-top: 10.25rem !important;
    padding-bottom: 10.25rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 0.75rem !important;
  }
  .pt-xl-4 {
    padding-top: 1rem !important;
  }
  .pt-xl-5 {
    padding-top: 1.25rem !important;
  }
  .pt-xl-6 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-7 {
    padding-top: 1.75rem !important;
  }
  .pt-xl-8 {
    padding-top: 2rem !important;
  }
  .pt-xl-9 {
    padding-top: 2.25rem !important;
  }
  .pt-xl-10 {
    padding-top: 2.5rem !important;
  }
  .pt-xl-11 {
    padding-top: 2.75rem !important;
  }
  .pt-xl-12 {
    padding-top: 3rem !important;
  }
  .pt-xl-13 {
    padding-top: 3.25rem !important;
  }
  .pt-xl-14 {
    padding-top: 3.5rem !important;
  }
  .pt-xl-15 {
    padding-top: 3.75rem !important;
  }
  .pt-xl-16 {
    padding-top: 4rem !important;
  }
  .pt-xl-17 {
    padding-top: 4.25rem !important;
  }
  .pt-xl-18 {
    padding-top: 4.5rem !important;
  }
  .pt-xl-19 {
    padding-top: 4.75rem !important;
  }
  .pt-xl-20 {
    padding-top: 5rem !important;
  }
  .pt-xl-21 {
    padding-top: 5.25rem !important;
  }
  .pt-xl-22 {
    padding-top: 5.5rem !important;
  }
  .pt-xl-23 {
    padding-top: 5.75rem !important;
  }
  .pt-xl-24 {
    padding-top: 6rem !important;
  }
  .pt-xl-25 {
    padding-top: 6.25rem !important;
  }
  .pt-xl-26 {
    padding-top: 6.5rem !important;
  }
  .pt-xl-27 {
    padding-top: 6.75rem !important;
  }
  .pt-xl-28 {
    padding-top: 7rem !important;
  }
  .pt-xl-29 {
    padding-top: 7.25rem !important;
  }
  .pt-xl-30 {
    padding-top: 7.5rem !important;
  }
  .pt-xl-31 {
    padding-top: 7.75rem !important;
  }
  .pt-xl-32 {
    padding-top: 8.25rem !important;
  }
  .pt-xl-33 {
    padding-top: 8.5rem !important;
  }
  .pt-xl-34 {
    padding-top: 8.75rem !important;
  }
  .pt-xl-35 {
    padding-top: 9rem !important;
  }
  .pt-xl-36 {
    padding-top: 9.25rem !important;
  }
  .pt-xl-37 {
    padding-top: 9.5rem !important;
  }
  .pt-xl-38 {
    padding-top: 9.75rem !important;
  }
  .pt-xl-39 {
    padding-top: 10rem !important;
  }
  .pt-xl-40 {
    padding-top: 10.25rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 0.75rem !important;
  }
  .pe-xl-4 {
    padding-right: 1rem !important;
  }
  .pe-xl-5 {
    padding-right: 1.25rem !important;
  }
  .pe-xl-6 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-7 {
    padding-right: 1.75rem !important;
  }
  .pe-xl-8 {
    padding-right: 2rem !important;
  }
  .pe-xl-9 {
    padding-right: 2.25rem !important;
  }
  .pe-xl-10 {
    padding-right: 2.5rem !important;
  }
  .pe-xl-11 {
    padding-right: 2.75rem !important;
  }
  .pe-xl-12 {
    padding-right: 3rem !important;
  }
  .pe-xl-13 {
    padding-right: 3.25rem !important;
  }
  .pe-xl-14 {
    padding-right: 3.5rem !important;
  }
  .pe-xl-15 {
    padding-right: 3.75rem !important;
  }
  .pe-xl-16 {
    padding-right: 4rem !important;
  }
  .pe-xl-17 {
    padding-right: 4.25rem !important;
  }
  .pe-xl-18 {
    padding-right: 4.5rem !important;
  }
  .pe-xl-19 {
    padding-right: 4.75rem !important;
  }
  .pe-xl-20 {
    padding-right: 5rem !important;
  }
  .pe-xl-21 {
    padding-right: 5.25rem !important;
  }
  .pe-xl-22 {
    padding-right: 5.5rem !important;
  }
  .pe-xl-23 {
    padding-right: 5.75rem !important;
  }
  .pe-xl-24 {
    padding-right: 6rem !important;
  }
  .pe-xl-25 {
    padding-right: 6.25rem !important;
  }
  .pe-xl-26 {
    padding-right: 6.5rem !important;
  }
  .pe-xl-27 {
    padding-right: 6.75rem !important;
  }
  .pe-xl-28 {
    padding-right: 7rem !important;
  }
  .pe-xl-29 {
    padding-right: 7.25rem !important;
  }
  .pe-xl-30 {
    padding-right: 7.5rem !important;
  }
  .pe-xl-31 {
    padding-right: 7.75rem !important;
  }
  .pe-xl-32 {
    padding-right: 8.25rem !important;
  }
  .pe-xl-33 {
    padding-right: 8.5rem !important;
  }
  .pe-xl-34 {
    padding-right: 8.75rem !important;
  }
  .pe-xl-35 {
    padding-right: 9rem !important;
  }
  .pe-xl-36 {
    padding-right: 9.25rem !important;
  }
  .pe-xl-37 {
    padding-right: 9.5rem !important;
  }
  .pe-xl-38 {
    padding-right: 9.75rem !important;
  }
  .pe-xl-39 {
    padding-right: 10rem !important;
  }
  .pe-xl-40 {
    padding-right: 10.25rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 1.75rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 2rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 2.25rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xl-11 {
    padding-bottom: 2.75rem !important;
  }
  .pb-xl-12 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-13 {
    padding-bottom: 3.25rem !important;
  }
  .pb-xl-14 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xl-15 {
    padding-bottom: 3.75rem !important;
  }
  .pb-xl-16 {
    padding-bottom: 4rem !important;
  }
  .pb-xl-17 {
    padding-bottom: 4.25rem !important;
  }
  .pb-xl-18 {
    padding-bottom: 4.5rem !important;
  }
  .pb-xl-19 {
    padding-bottom: 4.75rem !important;
  }
  .pb-xl-20 {
    padding-bottom: 5rem !important;
  }
  .pb-xl-21 {
    padding-bottom: 5.25rem !important;
  }
  .pb-xl-22 {
    padding-bottom: 5.5rem !important;
  }
  .pb-xl-23 {
    padding-bottom: 5.75rem !important;
  }
  .pb-xl-24 {
    padding-bottom: 6rem !important;
  }
  .pb-xl-25 {
    padding-bottom: 6.25rem !important;
  }
  .pb-xl-26 {
    padding-bottom: 6.5rem !important;
  }
  .pb-xl-27 {
    padding-bottom: 6.75rem !important;
  }
  .pb-xl-28 {
    padding-bottom: 7rem !important;
  }
  .pb-xl-29 {
    padding-bottom: 7.25rem !important;
  }
  .pb-xl-30 {
    padding-bottom: 7.5rem !important;
  }
  .pb-xl-31 {
    padding-bottom: 7.75rem !important;
  }
  .pb-xl-32 {
    padding-bottom: 8.25rem !important;
  }
  .pb-xl-33 {
    padding-bottom: 8.5rem !important;
  }
  .pb-xl-34 {
    padding-bottom: 8.75rem !important;
  }
  .pb-xl-35 {
    padding-bottom: 9rem !important;
  }
  .pb-xl-36 {
    padding-bottom: 9.25rem !important;
  }
  .pb-xl-37 {
    padding-bottom: 9.5rem !important;
  }
  .pb-xl-38 {
    padding-bottom: 9.75rem !important;
  }
  .pb-xl-39 {
    padding-bottom: 10rem !important;
  }
  .pb-xl-40 {
    padding-bottom: 10.25rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 0.75rem !important;
  }
  .ps-xl-4 {
    padding-left: 1rem !important;
  }
  .ps-xl-5 {
    padding-left: 1.25rem !important;
  }
  .ps-xl-6 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-7 {
    padding-left: 1.75rem !important;
  }
  .ps-xl-8 {
    padding-left: 2rem !important;
  }
  .ps-xl-9 {
    padding-left: 2.25rem !important;
  }
  .ps-xl-10 {
    padding-left: 2.5rem !important;
  }
  .ps-xl-11 {
    padding-left: 2.75rem !important;
  }
  .ps-xl-12 {
    padding-left: 3rem !important;
  }
  .ps-xl-13 {
    padding-left: 3.25rem !important;
  }
  .ps-xl-14 {
    padding-left: 3.5rem !important;
  }
  .ps-xl-15 {
    padding-left: 3.75rem !important;
  }
  .ps-xl-16 {
    padding-left: 4rem !important;
  }
  .ps-xl-17 {
    padding-left: 4.25rem !important;
  }
  .ps-xl-18 {
    padding-left: 4.5rem !important;
  }
  .ps-xl-19 {
    padding-left: 4.75rem !important;
  }
  .ps-xl-20 {
    padding-left: 5rem !important;
  }
  .ps-xl-21 {
    padding-left: 5.25rem !important;
  }
  .ps-xl-22 {
    padding-left: 5.5rem !important;
  }
  .ps-xl-23 {
    padding-left: 5.75rem !important;
  }
  .ps-xl-24 {
    padding-left: 6rem !important;
  }
  .ps-xl-25 {
    padding-left: 6.25rem !important;
  }
  .ps-xl-26 {
    padding-left: 6.5rem !important;
  }
  .ps-xl-27 {
    padding-left: 6.75rem !important;
  }
  .ps-xl-28 {
    padding-left: 7rem !important;
  }
  .ps-xl-29 {
    padding-left: 7.25rem !important;
  }
  .ps-xl-30 {
    padding-left: 7.5rem !important;
  }
  .ps-xl-31 {
    padding-left: 7.75rem !important;
  }
  .ps-xl-32 {
    padding-left: 8.25rem !important;
  }
  .ps-xl-33 {
    padding-left: 8.5rem !important;
  }
  .ps-xl-34 {
    padding-left: 8.75rem !important;
  }
  .ps-xl-35 {
    padding-left: 9rem !important;
  }
  .ps-xl-36 {
    padding-left: 9.25rem !important;
  }
  .ps-xl-37 {
    padding-left: 9.5rem !important;
  }
  .ps-xl-38 {
    padding-left: 9.75rem !important;
  }
  .ps-xl-39 {
    padding-left: 10rem !important;
  }
  .ps-xl-40 {
    padding-left: 10.25rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 0.75rem !important;
  }
  .gap-xl-4 {
    gap: 1rem !important;
  }
  .gap-xl-5 {
    gap: 1.25rem !important;
  }
  .gap-xl-6 {
    gap: 1.5rem !important;
  }
  .gap-xl-7 {
    gap: 1.75rem !important;
  }
  .gap-xl-8 {
    gap: 2rem !important;
  }
  .gap-xl-9 {
    gap: 2.25rem !important;
  }
  .gap-xl-10 {
    gap: 2.5rem !important;
  }
  .gap-xl-11 {
    gap: 2.75rem !important;
  }
  .gap-xl-12 {
    gap: 3rem !important;
  }
  .gap-xl-13 {
    gap: 3.25rem !important;
  }
  .gap-xl-14 {
    gap: 3.5rem !important;
  }
  .gap-xl-15 {
    gap: 3.75rem !important;
  }
  .gap-xl-16 {
    gap: 4rem !important;
  }
  .gap-xl-17 {
    gap: 4.25rem !important;
  }
  .gap-xl-18 {
    gap: 4.5rem !important;
  }
  .gap-xl-19 {
    gap: 4.75rem !important;
  }
  .gap-xl-20 {
    gap: 5rem !important;
  }
  .gap-xl-21 {
    gap: 5.25rem !important;
  }
  .gap-xl-22 {
    gap: 5.5rem !important;
  }
  .gap-xl-23 {
    gap: 5.75rem !important;
  }
  .gap-xl-24 {
    gap: 6rem !important;
  }
  .gap-xl-25 {
    gap: 6.25rem !important;
  }
  .gap-xl-26 {
    gap: 6.5rem !important;
  }
  .gap-xl-27 {
    gap: 6.75rem !important;
  }
  .gap-xl-28 {
    gap: 7rem !important;
  }
  .gap-xl-29 {
    gap: 7.25rem !important;
  }
  .gap-xl-30 {
    gap: 7.5rem !important;
  }
  .gap-xl-31 {
    gap: 7.75rem !important;
  }
  .gap-xl-32 {
    gap: 8.25rem !important;
  }
  .gap-xl-33 {
    gap: 8.5rem !important;
  }
  .gap-xl-34 {
    gap: 8.75rem !important;
  }
  .gap-xl-35 {
    gap: 9rem !important;
  }
  .gap-xl-36 {
    gap: 9.25rem !important;
  }
  .gap-xl-37 {
    gap: 9.5rem !important;
  }
  .gap-xl-38 {
    gap: 9.75rem !important;
  }
  .gap-xl-39 {
    gap: 10rem !important;
  }
  .gap-xl-40 {
    gap: 10.25rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 0.75rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 1.25rem !important;
  }
  .row-gap-xl-6 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-7 {
    row-gap: 1.75rem !important;
  }
  .row-gap-xl-8 {
    row-gap: 2rem !important;
  }
  .row-gap-xl-9 {
    row-gap: 2.25rem !important;
  }
  .row-gap-xl-10 {
    row-gap: 2.5rem !important;
  }
  .row-gap-xl-11 {
    row-gap: 2.75rem !important;
  }
  .row-gap-xl-12 {
    row-gap: 3rem !important;
  }
  .row-gap-xl-13 {
    row-gap: 3.25rem !important;
  }
  .row-gap-xl-14 {
    row-gap: 3.5rem !important;
  }
  .row-gap-xl-15 {
    row-gap: 3.75rem !important;
  }
  .row-gap-xl-16 {
    row-gap: 4rem !important;
  }
  .row-gap-xl-17 {
    row-gap: 4.25rem !important;
  }
  .row-gap-xl-18 {
    row-gap: 4.5rem !important;
  }
  .row-gap-xl-19 {
    row-gap: 4.75rem !important;
  }
  .row-gap-xl-20 {
    row-gap: 5rem !important;
  }
  .row-gap-xl-21 {
    row-gap: 5.25rem !important;
  }
  .row-gap-xl-22 {
    row-gap: 5.5rem !important;
  }
  .row-gap-xl-23 {
    row-gap: 5.75rem !important;
  }
  .row-gap-xl-24 {
    row-gap: 6rem !important;
  }
  .row-gap-xl-25 {
    row-gap: 6.25rem !important;
  }
  .row-gap-xl-26 {
    row-gap: 6.5rem !important;
  }
  .row-gap-xl-27 {
    row-gap: 6.75rem !important;
  }
  .row-gap-xl-28 {
    row-gap: 7rem !important;
  }
  .row-gap-xl-29 {
    row-gap: 7.25rem !important;
  }
  .row-gap-xl-30 {
    row-gap: 7.5rem !important;
  }
  .row-gap-xl-31 {
    row-gap: 7.75rem !important;
  }
  .row-gap-xl-32 {
    row-gap: 8.25rem !important;
  }
  .row-gap-xl-33 {
    row-gap: 8.5rem !important;
  }
  .row-gap-xl-34 {
    row-gap: 8.75rem !important;
  }
  .row-gap-xl-35 {
    row-gap: 9rem !important;
  }
  .row-gap-xl-36 {
    row-gap: 9.25rem !important;
  }
  .row-gap-xl-37 {
    row-gap: 9.5rem !important;
  }
  .row-gap-xl-38 {
    row-gap: 9.75rem !important;
  }
  .row-gap-xl-39 {
    row-gap: 10rem !important;
  }
  .row-gap-xl-40 {
    row-gap: 10.25rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 0.75rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 1.25rem !important;
  }
  .column-gap-xl-6 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-7 {
    column-gap: 1.75rem !important;
  }
  .column-gap-xl-8 {
    column-gap: 2rem !important;
  }
  .column-gap-xl-9 {
    column-gap: 2.25rem !important;
  }
  .column-gap-xl-10 {
    column-gap: 2.5rem !important;
  }
  .column-gap-xl-11 {
    column-gap: 2.75rem !important;
  }
  .column-gap-xl-12 {
    column-gap: 3rem !important;
  }
  .column-gap-xl-13 {
    column-gap: 3.25rem !important;
  }
  .column-gap-xl-14 {
    column-gap: 3.5rem !important;
  }
  .column-gap-xl-15 {
    column-gap: 3.75rem !important;
  }
  .column-gap-xl-16 {
    column-gap: 4rem !important;
  }
  .column-gap-xl-17 {
    column-gap: 4.25rem !important;
  }
  .column-gap-xl-18 {
    column-gap: 4.5rem !important;
  }
  .column-gap-xl-19 {
    column-gap: 4.75rem !important;
  }
  .column-gap-xl-20 {
    column-gap: 5rem !important;
  }
  .column-gap-xl-21 {
    column-gap: 5.25rem !important;
  }
  .column-gap-xl-22 {
    column-gap: 5.5rem !important;
  }
  .column-gap-xl-23 {
    column-gap: 5.75rem !important;
  }
  .column-gap-xl-24 {
    column-gap: 6rem !important;
  }
  .column-gap-xl-25 {
    column-gap: 6.25rem !important;
  }
  .column-gap-xl-26 {
    column-gap: 6.5rem !important;
  }
  .column-gap-xl-27 {
    column-gap: 6.75rem !important;
  }
  .column-gap-xl-28 {
    column-gap: 7rem !important;
  }
  .column-gap-xl-29 {
    column-gap: 7.25rem !important;
  }
  .column-gap-xl-30 {
    column-gap: 7.5rem !important;
  }
  .column-gap-xl-31 {
    column-gap: 7.75rem !important;
  }
  .column-gap-xl-32 {
    column-gap: 8.25rem !important;
  }
  .column-gap-xl-33 {
    column-gap: 8.5rem !important;
  }
  .column-gap-xl-34 {
    column-gap: 8.75rem !important;
  }
  .column-gap-xl-35 {
    column-gap: 9rem !important;
  }
  .column-gap-xl-36 {
    column-gap: 9.25rem !important;
  }
  .column-gap-xl-37 {
    column-gap: 9.5rem !important;
  }
  .column-gap-xl-38 {
    column-gap: 9.75rem !important;
  }
  .column-gap-xl-39 {
    column-gap: 10rem !important;
  }
  .column-gap-xl-40 {
    column-gap: 10.25rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-xxl-contain {
    object-fit: contain !important;
  }
  .object-xxl-cover {
    object-fit: cover !important;
  }
  .object-xxl-fill {
    object-fit: fill !important;
  }
  .object-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .w-xxl-0 {
    width: 0 !important;
  }
  .w-xxl-px {
    width: 1px !important;
  }
  .w-xxl-2px {
    width: 2px !important;
  }
  .w-xxl-1 {
    width: 0.25rem !important;
  }
  .w-xxl-2 {
    width: 0.5rem !important;
  }
  .w-xxl-3 {
    width: 0.75rem !important;
  }
  .w-xxl-4 {
    width: 1rem !important;
  }
  .w-xxl-5 {
    width: 1.25rem !important;
  }
  .w-xxl-6 {
    width: 1.5rem !important;
  }
  .w-xxl-7 {
    width: 1.75rem !important;
  }
  .w-xxl-8 {
    width: 2rem !important;
  }
  .w-xxl-9 {
    width: 2.25rem !important;
  }
  .w-xxl-10 {
    width: 2.5rem !important;
  }
  .w-xxl-11 {
    width: 2.75rem !important;
  }
  .w-xxl-12 {
    width: 3rem !important;
  }
  .w-xxl-13 {
    width: 3.25rem !important;
  }
  .w-xxl-14 {
    width: 3.5rem !important;
  }
  .w-xxl-15 {
    width: 3.75rem !important;
  }
  .w-xxl-16 {
    width: 4rem !important;
  }
  .w-xxl-17 {
    width: 4.25rem !important;
  }
  .w-xxl-18 {
    width: 4.5rem !important;
  }
  .w-xxl-19 {
    width: 4.75rem !important;
  }
  .w-xxl-20 {
    width: 5rem !important;
  }
  .w-xxl-21 {
    width: 5.25rem !important;
  }
  .w-xxl-22 {
    width: 5.5rem !important;
  }
  .w-xxl-23 {
    width: 5.75rem !important;
  }
  .w-xxl-24 {
    width: 6rem !important;
  }
  .w-xxl-25 {
    width: 6.25rem !important;
  }
  .w-xxl-26 {
    width: 6.5rem !important;
  }
  .w-xxl-27 {
    width: 6.75rem !important;
  }
  .w-xxl-28 {
    width: 7rem !important;
  }
  .w-xxl-29 {
    width: 7.25rem !important;
  }
  .w-xxl-30 {
    width: 7.5rem !important;
  }
  .w-xxl-31 {
    width: 7.75rem !important;
  }
  .w-xxl-32 {
    width: 8rem !important;
  }
  .w-xxl-quarter {
    width: 25% !important;
  }
  .w-xxl-half {
    width: 50% !important;
  }
  .w-xxl-3quarter {
    width: 75% !important;
  }
  .w-xxl-full {
    width: 100% !important;
  }
  .w-xxl-auto {
    width: auto !important;
  }
  .h-xxl-0 {
    height: 0 !important;
  }
  .h-xxl-px {
    height: 1px !important;
  }
  .h-xxl-2px {
    height: 2px !important;
  }
  .h-xxl-1 {
    height: 0.25rem !important;
  }
  .h-xxl-2 {
    height: 0.5rem !important;
  }
  .h-xxl-3 {
    height: 0.75rem !important;
  }
  .h-xxl-4 {
    height: 1rem !important;
  }
  .h-xxl-5 {
    height: 1.25rem !important;
  }
  .h-xxl-6 {
    height: 1.5rem !important;
  }
  .h-xxl-7 {
    height: 1.75rem !important;
  }
  .h-xxl-8 {
    height: 2rem !important;
  }
  .h-xxl-9 {
    height: 2.25rem !important;
  }
  .h-xxl-10 {
    height: 2.5rem !important;
  }
  .h-xxl-11 {
    height: 2.75rem !important;
  }
  .h-xxl-12 {
    height: 3rem !important;
  }
  .h-xxl-13 {
    height: 3.25rem !important;
  }
  .h-xxl-14 {
    height: 3.5rem !important;
  }
  .h-xxl-15 {
    height: 3.75rem !important;
  }
  .h-xxl-16 {
    height: 4rem !important;
  }
  .h-xxl-17 {
    height: 4.25rem !important;
  }
  .h-xxl-18 {
    height: 4.5rem !important;
  }
  .h-xxl-19 {
    height: 4.75rem !important;
  }
  .h-xxl-20 {
    height: 5rem !important;
  }
  .h-xxl-21 {
    height: 5.25rem !important;
  }
  .h-xxl-22 {
    height: 5.5rem !important;
  }
  .h-xxl-23 {
    height: 5.75rem !important;
  }
  .h-xxl-24 {
    height: 6rem !important;
  }
  .h-xxl-25 {
    height: 6.25rem !important;
  }
  .h-xxl-26 {
    height: 6.5rem !important;
  }
  .h-xxl-27 {
    height: 6.75rem !important;
  }
  .h-xxl-28 {
    height: 7rem !important;
  }
  .h-xxl-29 {
    height: 7.25rem !important;
  }
  .h-xxl-30 {
    height: 7.5rem !important;
  }
  .h-xxl-31 {
    height: 7.75rem !important;
  }
  .h-xxl-32 {
    height: 8rem !important;
  }
  .h-xxl-quarter {
    height: 25% !important;
  }
  .h-xxl-half {
    height: 50% !important;
  }
  .h-xxl-3quarter {
    height: 75% !important;
  }
  .h-xxl-full {
    height: 100% !important;
  }
  .h-xxl-auto {
    height: auto !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-xxl-center {
    justify-content: center !important;
  }
  .justify-xxl-between {
    justify-content: space-between !important;
  }
  .justify-xxl-around {
    justify-content: space-around !important;
  }
  .justify-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-xxl-start {
    align-items: flex-start !important;
  }
  .align-xxl-end {
    align-items: flex-end !important;
  }
  .align-xxl-center {
    align-items: center !important;
  }
  .align-xxl-baseline {
    align-items: baseline !important;
  }
  .align-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 0.75rem !important;
  }
  .m-xxl-4 {
    margin: 1rem !important;
  }
  .m-xxl-5 {
    margin: 1.25rem !important;
  }
  .m-xxl-6 {
    margin: 1.5rem !important;
  }
  .m-xxl-7 {
    margin: 1.75rem !important;
  }
  .m-xxl-8 {
    margin: 2rem !important;
  }
  .m-xxl-9 {
    margin: 2.25rem !important;
  }
  .m-xxl-10 {
    margin: 2.5rem !important;
  }
  .m-xxl-11 {
    margin: 2.75rem !important;
  }
  .m-xxl-12 {
    margin: 3rem !important;
  }
  .m-xxl-13 {
    margin: 3.25rem !important;
  }
  .m-xxl-14 {
    margin: 3.5rem !important;
  }
  .m-xxl-15 {
    margin: 3.75rem !important;
  }
  .m-xxl-16 {
    margin: 4rem !important;
  }
  .m-xxl-17 {
    margin: 4.25rem !important;
  }
  .m-xxl-18 {
    margin: 4.5rem !important;
  }
  .m-xxl-19 {
    margin: 4.75rem !important;
  }
  .m-xxl-20 {
    margin: 5rem !important;
  }
  .m-xxl-21 {
    margin: 5.25rem !important;
  }
  .m-xxl-22 {
    margin: 5.5rem !important;
  }
  .m-xxl-23 {
    margin: 5.75rem !important;
  }
  .m-xxl-24 {
    margin: 6rem !important;
  }
  .m-xxl-25 {
    margin: 6.25rem !important;
  }
  .m-xxl-26 {
    margin: 6.5rem !important;
  }
  .m-xxl-27 {
    margin: 6.75rem !important;
  }
  .m-xxl-28 {
    margin: 7rem !important;
  }
  .m-xxl-29 {
    margin: 7.25rem !important;
  }
  .m-xxl-30 {
    margin: 7.5rem !important;
  }
  .m-xxl-31 {
    margin: 7.75rem !important;
  }
  .m-xxl-32 {
    margin: 8.25rem !important;
  }
  .m-xxl-33 {
    margin: 8.5rem !important;
  }
  .m-xxl-34 {
    margin: 8.75rem !important;
  }
  .m-xxl-35 {
    margin: 9rem !important;
  }
  .m-xxl-36 {
    margin: 9.25rem !important;
  }
  .m-xxl-37 {
    margin: 9.5rem !important;
  }
  .m-xxl-38 {
    margin: 9.75rem !important;
  }
  .m-xxl-39 {
    margin: 10rem !important;
  }
  .m-xxl-40 {
    margin: 10.25rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-xxl-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }
  .mx-xxl-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xxl-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-xxl-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xxl-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }
  .mx-xxl-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }
  .mx-xxl-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-xxl-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-xxl-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xxl-17 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }
  .mx-xxl-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-xxl-19 {
    margin-right: 4.75rem !important;
    margin-left: 4.75rem !important;
  }
  .mx-xxl-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xxl-21 {
    margin-right: 5.25rem !important;
    margin-left: 5.25rem !important;
  }
  .mx-xxl-22 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }
  .mx-xxl-23 {
    margin-right: 5.75rem !important;
    margin-left: 5.75rem !important;
  }
  .mx-xxl-24 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xxl-25 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }
  .mx-xxl-26 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }
  .mx-xxl-27 {
    margin-right: 6.75rem !important;
    margin-left: 6.75rem !important;
  }
  .mx-xxl-28 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-xxl-29 {
    margin-right: 7.25rem !important;
    margin-left: 7.25rem !important;
  }
  .mx-xxl-30 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-xxl-31 {
    margin-right: 7.75rem !important;
    margin-left: 7.75rem !important;
  }
  .mx-xxl-32 {
    margin-right: 8.25rem !important;
    margin-left: 8.25rem !important;
  }
  .mx-xxl-33 {
    margin-right: 8.5rem !important;
    margin-left: 8.5rem !important;
  }
  .mx-xxl-34 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important;
  }
  .mx-xxl-35 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-xxl-36 {
    margin-right: 9.25rem !important;
    margin-left: 9.25rem !important;
  }
  .mx-xxl-37 {
    margin-right: 9.5rem !important;
    margin-left: 9.5rem !important;
  }
  .mx-xxl-38 {
    margin-right: 9.75rem !important;
    margin-left: 9.75rem !important;
  }
  .mx-xxl-39 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-xxl-40 {
    margin-right: 10.25rem !important;
    margin-left: 10.25rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-xxl-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-xxl-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }
  .my-xxl-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xxl-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-xxl-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xxl-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }
  .my-xxl-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }
  .my-xxl-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-xxl-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-xxl-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xxl-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }
  .my-xxl-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-xxl-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }
  .my-xxl-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xxl-21 {
    margin-top: 5.25rem !important;
    margin-bottom: 5.25rem !important;
  }
  .my-xxl-22 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }
  .my-xxl-23 {
    margin-top: 5.75rem !important;
    margin-bottom: 5.75rem !important;
  }
  .my-xxl-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xxl-25 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }
  .my-xxl-26 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }
  .my-xxl-27 {
    margin-top: 6.75rem !important;
    margin-bottom: 6.75rem !important;
  }
  .my-xxl-28 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-xxl-29 {
    margin-top: 7.25rem !important;
    margin-bottom: 7.25rem !important;
  }
  .my-xxl-30 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-xxl-31 {
    margin-top: 7.75rem !important;
    margin-bottom: 7.75rem !important;
  }
  .my-xxl-32 {
    margin-top: 8.25rem !important;
    margin-bottom: 8.25rem !important;
  }
  .my-xxl-33 {
    margin-top: 8.5rem !important;
    margin-bottom: 8.5rem !important;
  }
  .my-xxl-34 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important;
  }
  .my-xxl-35 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-xxl-36 {
    margin-top: 9.25rem !important;
    margin-bottom: 9.25rem !important;
  }
  .my-xxl-37 {
    margin-top: 9.5rem !important;
    margin-bottom: 9.5rem !important;
  }
  .my-xxl-38 {
    margin-top: 9.75rem !important;
    margin-bottom: 9.75rem !important;
  }
  .my-xxl-39 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-xxl-40 {
    margin-top: 10.25rem !important;
    margin-bottom: 10.25rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 0.75rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1rem !important;
  }
  .mt-xxl-5 {
    margin-top: 1.25rem !important;
  }
  .mt-xxl-6 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-7 {
    margin-top: 1.75rem !important;
  }
  .mt-xxl-8 {
    margin-top: 2rem !important;
  }
  .mt-xxl-9 {
    margin-top: 2.25rem !important;
  }
  .mt-xxl-10 {
    margin-top: 2.5rem !important;
  }
  .mt-xxl-11 {
    margin-top: 2.75rem !important;
  }
  .mt-xxl-12 {
    margin-top: 3rem !important;
  }
  .mt-xxl-13 {
    margin-top: 3.25rem !important;
  }
  .mt-xxl-14 {
    margin-top: 3.5rem !important;
  }
  .mt-xxl-15 {
    margin-top: 3.75rem !important;
  }
  .mt-xxl-16 {
    margin-top: 4rem !important;
  }
  .mt-xxl-17 {
    margin-top: 4.25rem !important;
  }
  .mt-xxl-18 {
    margin-top: 4.5rem !important;
  }
  .mt-xxl-19 {
    margin-top: 4.75rem !important;
  }
  .mt-xxl-20 {
    margin-top: 5rem !important;
  }
  .mt-xxl-21 {
    margin-top: 5.25rem !important;
  }
  .mt-xxl-22 {
    margin-top: 5.5rem !important;
  }
  .mt-xxl-23 {
    margin-top: 5.75rem !important;
  }
  .mt-xxl-24 {
    margin-top: 6rem !important;
  }
  .mt-xxl-25 {
    margin-top: 6.25rem !important;
  }
  .mt-xxl-26 {
    margin-top: 6.5rem !important;
  }
  .mt-xxl-27 {
    margin-top: 6.75rem !important;
  }
  .mt-xxl-28 {
    margin-top: 7rem !important;
  }
  .mt-xxl-29 {
    margin-top: 7.25rem !important;
  }
  .mt-xxl-30 {
    margin-top: 7.5rem !important;
  }
  .mt-xxl-31 {
    margin-top: 7.75rem !important;
  }
  .mt-xxl-32 {
    margin-top: 8.25rem !important;
  }
  .mt-xxl-33 {
    margin-top: 8.5rem !important;
  }
  .mt-xxl-34 {
    margin-top: 8.75rem !important;
  }
  .mt-xxl-35 {
    margin-top: 9rem !important;
  }
  .mt-xxl-36 {
    margin-top: 9.25rem !important;
  }
  .mt-xxl-37 {
    margin-top: 9.5rem !important;
  }
  .mt-xxl-38 {
    margin-top: 9.75rem !important;
  }
  .mt-xxl-39 {
    margin-top: 10rem !important;
  }
  .mt-xxl-40 {
    margin-top: 10.25rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 0.75rem !important;
  }
  .me-xxl-4 {
    margin-right: 1rem !important;
  }
  .me-xxl-5 {
    margin-right: 1.25rem !important;
  }
  .me-xxl-6 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-7 {
    margin-right: 1.75rem !important;
  }
  .me-xxl-8 {
    margin-right: 2rem !important;
  }
  .me-xxl-9 {
    margin-right: 2.25rem !important;
  }
  .me-xxl-10 {
    margin-right: 2.5rem !important;
  }
  .me-xxl-11 {
    margin-right: 2.75rem !important;
  }
  .me-xxl-12 {
    margin-right: 3rem !important;
  }
  .me-xxl-13 {
    margin-right: 3.25rem !important;
  }
  .me-xxl-14 {
    margin-right: 3.5rem !important;
  }
  .me-xxl-15 {
    margin-right: 3.75rem !important;
  }
  .me-xxl-16 {
    margin-right: 4rem !important;
  }
  .me-xxl-17 {
    margin-right: 4.25rem !important;
  }
  .me-xxl-18 {
    margin-right: 4.5rem !important;
  }
  .me-xxl-19 {
    margin-right: 4.75rem !important;
  }
  .me-xxl-20 {
    margin-right: 5rem !important;
  }
  .me-xxl-21 {
    margin-right: 5.25rem !important;
  }
  .me-xxl-22 {
    margin-right: 5.5rem !important;
  }
  .me-xxl-23 {
    margin-right: 5.75rem !important;
  }
  .me-xxl-24 {
    margin-right: 6rem !important;
  }
  .me-xxl-25 {
    margin-right: 6.25rem !important;
  }
  .me-xxl-26 {
    margin-right: 6.5rem !important;
  }
  .me-xxl-27 {
    margin-right: 6.75rem !important;
  }
  .me-xxl-28 {
    margin-right: 7rem !important;
  }
  .me-xxl-29 {
    margin-right: 7.25rem !important;
  }
  .me-xxl-30 {
    margin-right: 7.5rem !important;
  }
  .me-xxl-31 {
    margin-right: 7.75rem !important;
  }
  .me-xxl-32 {
    margin-right: 8.25rem !important;
  }
  .me-xxl-33 {
    margin-right: 8.5rem !important;
  }
  .me-xxl-34 {
    margin-right: 8.75rem !important;
  }
  .me-xxl-35 {
    margin-right: 9rem !important;
  }
  .me-xxl-36 {
    margin-right: 9.25rem !important;
  }
  .me-xxl-37 {
    margin-right: 9.5rem !important;
  }
  .me-xxl-38 {
    margin-right: 9.75rem !important;
  }
  .me-xxl-39 {
    margin-right: 10rem !important;
  }
  .me-xxl-40 {
    margin-right: 10.25rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 1.75rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 2rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 2.25rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xxl-11 {
    margin-bottom: 2.75rem !important;
  }
  .mb-xxl-12 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-13 {
    margin-bottom: 3.25rem !important;
  }
  .mb-xxl-14 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xxl-15 {
    margin-bottom: 3.75rem !important;
  }
  .mb-xxl-16 {
    margin-bottom: 4rem !important;
  }
  .mb-xxl-17 {
    margin-bottom: 4.25rem !important;
  }
  .mb-xxl-18 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xxl-19 {
    margin-bottom: 4.75rem !important;
  }
  .mb-xxl-20 {
    margin-bottom: 5rem !important;
  }
  .mb-xxl-21 {
    margin-bottom: 5.25rem !important;
  }
  .mb-xxl-22 {
    margin-bottom: 5.5rem !important;
  }
  .mb-xxl-23 {
    margin-bottom: 5.75rem !important;
  }
  .mb-xxl-24 {
    margin-bottom: 6rem !important;
  }
  .mb-xxl-25 {
    margin-bottom: 6.25rem !important;
  }
  .mb-xxl-26 {
    margin-bottom: 6.5rem !important;
  }
  .mb-xxl-27 {
    margin-bottom: 6.75rem !important;
  }
  .mb-xxl-28 {
    margin-bottom: 7rem !important;
  }
  .mb-xxl-29 {
    margin-bottom: 7.25rem !important;
  }
  .mb-xxl-30 {
    margin-bottom: 7.5rem !important;
  }
  .mb-xxl-31 {
    margin-bottom: 7.75rem !important;
  }
  .mb-xxl-32 {
    margin-bottom: 8.25rem !important;
  }
  .mb-xxl-33 {
    margin-bottom: 8.5rem !important;
  }
  .mb-xxl-34 {
    margin-bottom: 8.75rem !important;
  }
  .mb-xxl-35 {
    margin-bottom: 9rem !important;
  }
  .mb-xxl-36 {
    margin-bottom: 9.25rem !important;
  }
  .mb-xxl-37 {
    margin-bottom: 9.5rem !important;
  }
  .mb-xxl-38 {
    margin-bottom: 9.75rem !important;
  }
  .mb-xxl-39 {
    margin-bottom: 10rem !important;
  }
  .mb-xxl-40 {
    margin-bottom: 10.25rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 0.75rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1rem !important;
  }
  .ms-xxl-5 {
    margin-left: 1.25rem !important;
  }
  .ms-xxl-6 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-7 {
    margin-left: 1.75rem !important;
  }
  .ms-xxl-8 {
    margin-left: 2rem !important;
  }
  .ms-xxl-9 {
    margin-left: 2.25rem !important;
  }
  .ms-xxl-10 {
    margin-left: 2.5rem !important;
  }
  .ms-xxl-11 {
    margin-left: 2.75rem !important;
  }
  .ms-xxl-12 {
    margin-left: 3rem !important;
  }
  .ms-xxl-13 {
    margin-left: 3.25rem !important;
  }
  .ms-xxl-14 {
    margin-left: 3.5rem !important;
  }
  .ms-xxl-15 {
    margin-left: 3.75rem !important;
  }
  .ms-xxl-16 {
    margin-left: 4rem !important;
  }
  .ms-xxl-17 {
    margin-left: 4.25rem !important;
  }
  .ms-xxl-18 {
    margin-left: 4.5rem !important;
  }
  .ms-xxl-19 {
    margin-left: 4.75rem !important;
  }
  .ms-xxl-20 {
    margin-left: 5rem !important;
  }
  .ms-xxl-21 {
    margin-left: 5.25rem !important;
  }
  .ms-xxl-22 {
    margin-left: 5.5rem !important;
  }
  .ms-xxl-23 {
    margin-left: 5.75rem !important;
  }
  .ms-xxl-24 {
    margin-left: 6rem !important;
  }
  .ms-xxl-25 {
    margin-left: 6.25rem !important;
  }
  .ms-xxl-26 {
    margin-left: 6.5rem !important;
  }
  .ms-xxl-27 {
    margin-left: 6.75rem !important;
  }
  .ms-xxl-28 {
    margin-left: 7rem !important;
  }
  .ms-xxl-29 {
    margin-left: 7.25rem !important;
  }
  .ms-xxl-30 {
    margin-left: 7.5rem !important;
  }
  .ms-xxl-31 {
    margin-left: 7.75rem !important;
  }
  .ms-xxl-32 {
    margin-left: 8.25rem !important;
  }
  .ms-xxl-33 {
    margin-left: 8.5rem !important;
  }
  .ms-xxl-34 {
    margin-left: 8.75rem !important;
  }
  .ms-xxl-35 {
    margin-left: 9rem !important;
  }
  .ms-xxl-36 {
    margin-left: 9.25rem !important;
  }
  .ms-xxl-37 {
    margin-left: 9.5rem !important;
  }
  .ms-xxl-38 {
    margin-left: 9.75rem !important;
  }
  .ms-xxl-39 {
    margin-left: 10rem !important;
  }
  .ms-xxl-40 {
    margin-left: 10.25rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -0.75rem !important;
  }
  .m-xxl-n4 {
    margin: -1rem !important;
  }
  .m-xxl-n5 {
    margin: -1.25rem !important;
  }
  .m-xxl-n6 {
    margin: -1.5rem !important;
  }
  .m-xxl-n7 {
    margin: -1.75rem !important;
  }
  .m-xxl-n8 {
    margin: -2rem !important;
  }
  .m-xxl-n9 {
    margin: -2.25rem !important;
  }
  .m-xxl-n10 {
    margin: -2.5rem !important;
  }
  .m-xxl-n11 {
    margin: -2.75rem !important;
  }
  .m-xxl-n12 {
    margin: -3rem !important;
  }
  .m-xxl-n13 {
    margin: -3.25rem !important;
  }
  .m-xxl-n14 {
    margin: -3.5rem !important;
  }
  .m-xxl-n15 {
    margin: -3.75rem !important;
  }
  .m-xxl-n16 {
    margin: -4rem !important;
  }
  .m-xxl-n17 {
    margin: -4.25rem !important;
  }
  .m-xxl-n18 {
    margin: -4.5rem !important;
  }
  .m-xxl-n19 {
    margin: -4.75rem !important;
  }
  .m-xxl-n20 {
    margin: -5rem !important;
  }
  .m-xxl-n21 {
    margin: -5.25rem !important;
  }
  .m-xxl-n22 {
    margin: -5.5rem !important;
  }
  .m-xxl-n23 {
    margin: -5.75rem !important;
  }
  .m-xxl-n24 {
    margin: -6rem !important;
  }
  .m-xxl-n25 {
    margin: -6.25rem !important;
  }
  .m-xxl-n26 {
    margin: -6.5rem !important;
  }
  .m-xxl-n27 {
    margin: -6.75rem !important;
  }
  .m-xxl-n28 {
    margin: -7rem !important;
  }
  .m-xxl-n29 {
    margin: -7.25rem !important;
  }
  .m-xxl-n30 {
    margin: -7.5rem !important;
  }
  .m-xxl-n31 {
    margin: -7.75rem !important;
  }
  .m-xxl-n32 {
    margin: -8.25rem !important;
  }
  .m-xxl-n33 {
    margin: -8.5rem !important;
  }
  .m-xxl-n34 {
    margin: -8.75rem !important;
  }
  .m-xxl-n35 {
    margin: -9rem !important;
  }
  .m-xxl-n36 {
    margin: -9.25rem !important;
  }
  .m-xxl-n37 {
    margin: -9.5rem !important;
  }
  .m-xxl-n38 {
    margin: -9.75rem !important;
  }
  .m-xxl-n39 {
    margin: -10rem !important;
  }
  .m-xxl-n40 {
    margin: -10.25rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-xxl-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }
  .mx-xxl-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-xxl-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }
  .mx-xxl-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-xxl-n11 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }
  .mx-xxl-n12 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xxl-n13 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }
  .mx-xxl-n14 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-xxl-n15 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }
  .mx-xxl-n16 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-xxl-n17 {
    margin-right: -4.25rem !important;
    margin-left: -4.25rem !important;
  }
  .mx-xxl-n18 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .mx-xxl-n19 {
    margin-right: -4.75rem !important;
    margin-left: -4.75rem !important;
  }
  .mx-xxl-n20 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-xxl-n21 {
    margin-right: -5.25rem !important;
    margin-left: -5.25rem !important;
  }
  .mx-xxl-n22 {
    margin-right: -5.5rem !important;
    margin-left: -5.5rem !important;
  }
  .mx-xxl-n23 {
    margin-right: -5.75rem !important;
    margin-left: -5.75rem !important;
  }
  .mx-xxl-n24 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-xxl-n25 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important;
  }
  .mx-xxl-n26 {
    margin-right: -6.5rem !important;
    margin-left: -6.5rem !important;
  }
  .mx-xxl-n27 {
    margin-right: -6.75rem !important;
    margin-left: -6.75rem !important;
  }
  .mx-xxl-n28 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }
  .mx-xxl-n29 {
    margin-right: -7.25rem !important;
    margin-left: -7.25rem !important;
  }
  .mx-xxl-n30 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  .mx-xxl-n31 {
    margin-right: -7.75rem !important;
    margin-left: -7.75rem !important;
  }
  .mx-xxl-n32 {
    margin-right: -8.25rem !important;
    margin-left: -8.25rem !important;
  }
  .mx-xxl-n33 {
    margin-right: -8.5rem !important;
    margin-left: -8.5rem !important;
  }
  .mx-xxl-n34 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important;
  }
  .mx-xxl-n35 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  .mx-xxl-n36 {
    margin-right: -9.25rem !important;
    margin-left: -9.25rem !important;
  }
  .mx-xxl-n37 {
    margin-right: -9.5rem !important;
    margin-left: -9.5rem !important;
  }
  .mx-xxl-n38 {
    margin-right: -9.75rem !important;
    margin-left: -9.75rem !important;
  }
  .mx-xxl-n39 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .mx-xxl-n40 {
    margin-right: -10.25rem !important;
    margin-left: -10.25rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxl-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-xxl-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }
  .my-xxl-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-xxl-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }
  .my-xxl-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-xxl-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }
  .my-xxl-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xxl-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }
  .my-xxl-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-xxl-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }
  .my-xxl-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-xxl-n17 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }
  .my-xxl-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .my-xxl-n19 {
    margin-top: -4.75rem !important;
    margin-bottom: -4.75rem !important;
  }
  .my-xxl-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-xxl-n21 {
    margin-top: -5.25rem !important;
    margin-bottom: -5.25rem !important;
  }
  .my-xxl-n22 {
    margin-top: -5.5rem !important;
    margin-bottom: -5.5rem !important;
  }
  .my-xxl-n23 {
    margin-top: -5.75rem !important;
    margin-bottom: -5.75rem !important;
  }
  .my-xxl-n24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-xxl-n25 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important;
  }
  .my-xxl-n26 {
    margin-top: -6.5rem !important;
    margin-bottom: -6.5rem !important;
  }
  .my-xxl-n27 {
    margin-top: -6.75rem !important;
    margin-bottom: -6.75rem !important;
  }
  .my-xxl-n28 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }
  .my-xxl-n29 {
    margin-top: -7.25rem !important;
    margin-bottom: -7.25rem !important;
  }
  .my-xxl-n30 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .my-xxl-n31 {
    margin-top: -7.75rem !important;
    margin-bottom: -7.75rem !important;
  }
  .my-xxl-n32 {
    margin-top: -8.25rem !important;
    margin-bottom: -8.25rem !important;
  }
  .my-xxl-n33 {
    margin-top: -8.5rem !important;
    margin-bottom: -8.5rem !important;
  }
  .my-xxl-n34 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important;
  }
  .my-xxl-n35 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  .my-xxl-n36 {
    margin-top: -9.25rem !important;
    margin-bottom: -9.25rem !important;
  }
  .my-xxl-n37 {
    margin-top: -9.5rem !important;
    margin-bottom: -9.5rem !important;
  }
  .my-xxl-n38 {
    margin-top: -9.75rem !important;
    margin-bottom: -9.75rem !important;
  }
  .my-xxl-n39 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .my-xxl-n40 {
    margin-top: -10.25rem !important;
    margin-bottom: -10.25rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -0.75rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -1.25rem !important;
  }
  .mt-xxl-n6 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n7 {
    margin-top: -1.75rem !important;
  }
  .mt-xxl-n8 {
    margin-top: -2rem !important;
  }
  .mt-xxl-n9 {
    margin-top: -2.25rem !important;
  }
  .mt-xxl-n10 {
    margin-top: -2.5rem !important;
  }
  .mt-xxl-n11 {
    margin-top: -2.75rem !important;
  }
  .mt-xxl-n12 {
    margin-top: -3rem !important;
  }
  .mt-xxl-n13 {
    margin-top: -3.25rem !important;
  }
  .mt-xxl-n14 {
    margin-top: -3.5rem !important;
  }
  .mt-xxl-n15 {
    margin-top: -3.75rem !important;
  }
  .mt-xxl-n16 {
    margin-top: -4rem !important;
  }
  .mt-xxl-n17 {
    margin-top: -4.25rem !important;
  }
  .mt-xxl-n18 {
    margin-top: -4.5rem !important;
  }
  .mt-xxl-n19 {
    margin-top: -4.75rem !important;
  }
  .mt-xxl-n20 {
    margin-top: -5rem !important;
  }
  .mt-xxl-n21 {
    margin-top: -5.25rem !important;
  }
  .mt-xxl-n22 {
    margin-top: -5.5rem !important;
  }
  .mt-xxl-n23 {
    margin-top: -5.75rem !important;
  }
  .mt-xxl-n24 {
    margin-top: -6rem !important;
  }
  .mt-xxl-n25 {
    margin-top: -6.25rem !important;
  }
  .mt-xxl-n26 {
    margin-top: -6.5rem !important;
  }
  .mt-xxl-n27 {
    margin-top: -6.75rem !important;
  }
  .mt-xxl-n28 {
    margin-top: -7rem !important;
  }
  .mt-xxl-n29 {
    margin-top: -7.25rem !important;
  }
  .mt-xxl-n30 {
    margin-top: -7.5rem !important;
  }
  .mt-xxl-n31 {
    margin-top: -7.75rem !important;
  }
  .mt-xxl-n32 {
    margin-top: -8.25rem !important;
  }
  .mt-xxl-n33 {
    margin-top: -8.5rem !important;
  }
  .mt-xxl-n34 {
    margin-top: -8.75rem !important;
  }
  .mt-xxl-n35 {
    margin-top: -9rem !important;
  }
  .mt-xxl-n36 {
    margin-top: -9.25rem !important;
  }
  .mt-xxl-n37 {
    margin-top: -9.5rem !important;
  }
  .mt-xxl-n38 {
    margin-top: -9.75rem !important;
  }
  .mt-xxl-n39 {
    margin-top: -10rem !important;
  }
  .mt-xxl-n40 {
    margin-top: -10.25rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n3 {
    margin-right: -0.75rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1rem !important;
  }
  .me-xxl-n5 {
    margin-right: -1.25rem !important;
  }
  .me-xxl-n6 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n7 {
    margin-right: -1.75rem !important;
  }
  .me-xxl-n8 {
    margin-right: -2rem !important;
  }
  .me-xxl-n9 {
    margin-right: -2.25rem !important;
  }
  .me-xxl-n10 {
    margin-right: -2.5rem !important;
  }
  .me-xxl-n11 {
    margin-right: -2.75rem !important;
  }
  .me-xxl-n12 {
    margin-right: -3rem !important;
  }
  .me-xxl-n13 {
    margin-right: -3.25rem !important;
  }
  .me-xxl-n14 {
    margin-right: -3.5rem !important;
  }
  .me-xxl-n15 {
    margin-right: -3.75rem !important;
  }
  .me-xxl-n16 {
    margin-right: -4rem !important;
  }
  .me-xxl-n17 {
    margin-right: -4.25rem !important;
  }
  .me-xxl-n18 {
    margin-right: -4.5rem !important;
  }
  .me-xxl-n19 {
    margin-right: -4.75rem !important;
  }
  .me-xxl-n20 {
    margin-right: -5rem !important;
  }
  .me-xxl-n21 {
    margin-right: -5.25rem !important;
  }
  .me-xxl-n22 {
    margin-right: -5.5rem !important;
  }
  .me-xxl-n23 {
    margin-right: -5.75rem !important;
  }
  .me-xxl-n24 {
    margin-right: -6rem !important;
  }
  .me-xxl-n25 {
    margin-right: -6.25rem !important;
  }
  .me-xxl-n26 {
    margin-right: -6.5rem !important;
  }
  .me-xxl-n27 {
    margin-right: -6.75rem !important;
  }
  .me-xxl-n28 {
    margin-right: -7rem !important;
  }
  .me-xxl-n29 {
    margin-right: -7.25rem !important;
  }
  .me-xxl-n30 {
    margin-right: -7.5rem !important;
  }
  .me-xxl-n31 {
    margin-right: -7.75rem !important;
  }
  .me-xxl-n32 {
    margin-right: -8.25rem !important;
  }
  .me-xxl-n33 {
    margin-right: -8.5rem !important;
  }
  .me-xxl-n34 {
    margin-right: -8.75rem !important;
  }
  .me-xxl-n35 {
    margin-right: -9rem !important;
  }
  .me-xxl-n36 {
    margin-right: -9.25rem !important;
  }
  .me-xxl-n37 {
    margin-right: -9.5rem !important;
  }
  .me-xxl-n38 {
    margin-right: -9.75rem !important;
  }
  .me-xxl-n39 {
    margin-right: -10rem !important;
  }
  .me-xxl-n40 {
    margin-right: -10.25rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -0.75rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -1.25rem !important;
  }
  .mb-xxl-n6 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n7 {
    margin-bottom: -1.75rem !important;
  }
  .mb-xxl-n8 {
    margin-bottom: -2rem !important;
  }
  .mb-xxl-n9 {
    margin-bottom: -2.25rem !important;
  }
  .mb-xxl-n10 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xxl-n11 {
    margin-bottom: -2.75rem !important;
  }
  .mb-xxl-n12 {
    margin-bottom: -3rem !important;
  }
  .mb-xxl-n13 {
    margin-bottom: -3.25rem !important;
  }
  .mb-xxl-n14 {
    margin-bottom: -3.5rem !important;
  }
  .mb-xxl-n15 {
    margin-bottom: -3.75rem !important;
  }
  .mb-xxl-n16 {
    margin-bottom: -4rem !important;
  }
  .mb-xxl-n17 {
    margin-bottom: -4.25rem !important;
  }
  .mb-xxl-n18 {
    margin-bottom: -4.5rem !important;
  }
  .mb-xxl-n19 {
    margin-bottom: -4.75rem !important;
  }
  .mb-xxl-n20 {
    margin-bottom: -5rem !important;
  }
  .mb-xxl-n21 {
    margin-bottom: -5.25rem !important;
  }
  .mb-xxl-n22 {
    margin-bottom: -5.5rem !important;
  }
  .mb-xxl-n23 {
    margin-bottom: -5.75rem !important;
  }
  .mb-xxl-n24 {
    margin-bottom: -6rem !important;
  }
  .mb-xxl-n25 {
    margin-bottom: -6.25rem !important;
  }
  .mb-xxl-n26 {
    margin-bottom: -6.5rem !important;
  }
  .mb-xxl-n27 {
    margin-bottom: -6.75rem !important;
  }
  .mb-xxl-n28 {
    margin-bottom: -7rem !important;
  }
  .mb-xxl-n29 {
    margin-bottom: -7.25rem !important;
  }
  .mb-xxl-n30 {
    margin-bottom: -7.5rem !important;
  }
  .mb-xxl-n31 {
    margin-bottom: -7.75rem !important;
  }
  .mb-xxl-n32 {
    margin-bottom: -8.25rem !important;
  }
  .mb-xxl-n33 {
    margin-bottom: -8.5rem !important;
  }
  .mb-xxl-n34 {
    margin-bottom: -8.75rem !important;
  }
  .mb-xxl-n35 {
    margin-bottom: -9rem !important;
  }
  .mb-xxl-n36 {
    margin-bottom: -9.25rem !important;
  }
  .mb-xxl-n37 {
    margin-bottom: -9.5rem !important;
  }
  .mb-xxl-n38 {
    margin-bottom: -9.75rem !important;
  }
  .mb-xxl-n39 {
    margin-bottom: -10rem !important;
  }
  .mb-xxl-n40 {
    margin-bottom: -10.25rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -0.75rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -1.25rem !important;
  }
  .ms-xxl-n6 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n7 {
    margin-left: -1.75rem !important;
  }
  .ms-xxl-n8 {
    margin-left: -2rem !important;
  }
  .ms-xxl-n9 {
    margin-left: -2.25rem !important;
  }
  .ms-xxl-n10 {
    margin-left: -2.5rem !important;
  }
  .ms-xxl-n11 {
    margin-left: -2.75rem !important;
  }
  .ms-xxl-n12 {
    margin-left: -3rem !important;
  }
  .ms-xxl-n13 {
    margin-left: -3.25rem !important;
  }
  .ms-xxl-n14 {
    margin-left: -3.5rem !important;
  }
  .ms-xxl-n15 {
    margin-left: -3.75rem !important;
  }
  .ms-xxl-n16 {
    margin-left: -4rem !important;
  }
  .ms-xxl-n17 {
    margin-left: -4.25rem !important;
  }
  .ms-xxl-n18 {
    margin-left: -4.5rem !important;
  }
  .ms-xxl-n19 {
    margin-left: -4.75rem !important;
  }
  .ms-xxl-n20 {
    margin-left: -5rem !important;
  }
  .ms-xxl-n21 {
    margin-left: -5.25rem !important;
  }
  .ms-xxl-n22 {
    margin-left: -5.5rem !important;
  }
  .ms-xxl-n23 {
    margin-left: -5.75rem !important;
  }
  .ms-xxl-n24 {
    margin-left: -6rem !important;
  }
  .ms-xxl-n25 {
    margin-left: -6.25rem !important;
  }
  .ms-xxl-n26 {
    margin-left: -6.5rem !important;
  }
  .ms-xxl-n27 {
    margin-left: -6.75rem !important;
  }
  .ms-xxl-n28 {
    margin-left: -7rem !important;
  }
  .ms-xxl-n29 {
    margin-left: -7.25rem !important;
  }
  .ms-xxl-n30 {
    margin-left: -7.5rem !important;
  }
  .ms-xxl-n31 {
    margin-left: -7.75rem !important;
  }
  .ms-xxl-n32 {
    margin-left: -8.25rem !important;
  }
  .ms-xxl-n33 {
    margin-left: -8.5rem !important;
  }
  .ms-xxl-n34 {
    margin-left: -8.75rem !important;
  }
  .ms-xxl-n35 {
    margin-left: -9rem !important;
  }
  .ms-xxl-n36 {
    margin-left: -9.25rem !important;
  }
  .ms-xxl-n37 {
    margin-left: -9.5rem !important;
  }
  .ms-xxl-n38 {
    margin-left: -9.75rem !important;
  }
  .ms-xxl-n39 {
    margin-left: -10rem !important;
  }
  .ms-xxl-n40 {
    margin-left: -10.25rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 0.75rem !important;
  }
  .p-xxl-4 {
    padding: 1rem !important;
  }
  .p-xxl-5 {
    padding: 1.25rem !important;
  }
  .p-xxl-6 {
    padding: 1.5rem !important;
  }
  .p-xxl-7 {
    padding: 1.75rem !important;
  }
  .p-xxl-8 {
    padding: 2rem !important;
  }
  .p-xxl-9 {
    padding: 2.25rem !important;
  }
  .p-xxl-10 {
    padding: 2.5rem !important;
  }
  .p-xxl-11 {
    padding: 2.75rem !important;
  }
  .p-xxl-12 {
    padding: 3rem !important;
  }
  .p-xxl-13 {
    padding: 3.25rem !important;
  }
  .p-xxl-14 {
    padding: 3.5rem !important;
  }
  .p-xxl-15 {
    padding: 3.75rem !important;
  }
  .p-xxl-16 {
    padding: 4rem !important;
  }
  .p-xxl-17 {
    padding: 4.25rem !important;
  }
  .p-xxl-18 {
    padding: 4.5rem !important;
  }
  .p-xxl-19 {
    padding: 4.75rem !important;
  }
  .p-xxl-20 {
    padding: 5rem !important;
  }
  .p-xxl-21 {
    padding: 5.25rem !important;
  }
  .p-xxl-22 {
    padding: 5.5rem !important;
  }
  .p-xxl-23 {
    padding: 5.75rem !important;
  }
  .p-xxl-24 {
    padding: 6rem !important;
  }
  .p-xxl-25 {
    padding: 6.25rem !important;
  }
  .p-xxl-26 {
    padding: 6.5rem !important;
  }
  .p-xxl-27 {
    padding: 6.75rem !important;
  }
  .p-xxl-28 {
    padding: 7rem !important;
  }
  .p-xxl-29 {
    padding: 7.25rem !important;
  }
  .p-xxl-30 {
    padding: 7.5rem !important;
  }
  .p-xxl-31 {
    padding: 7.75rem !important;
  }
  .p-xxl-32 {
    padding: 8.25rem !important;
  }
  .p-xxl-33 {
    padding: 8.5rem !important;
  }
  .p-xxl-34 {
    padding: 8.75rem !important;
  }
  .p-xxl-35 {
    padding: 9rem !important;
  }
  .p-xxl-36 {
    padding: 9.25rem !important;
  }
  .p-xxl-37 {
    padding: 9.5rem !important;
  }
  .p-xxl-38 {
    padding: 9.75rem !important;
  }
  .p-xxl-39 {
    padding: 10rem !important;
  }
  .p-xxl-40 {
    padding: 10.25rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-xxl-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-xxl-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }
  .px-xxl-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xxl-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-xxl-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xxl-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }
  .px-xxl-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xxl-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }
  .px-xxl-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-xxl-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-xxl-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xxl-17 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }
  .px-xxl-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-xxl-19 {
    padding-right: 4.75rem !important;
    padding-left: 4.75rem !important;
  }
  .px-xxl-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xxl-21 {
    padding-right: 5.25rem !important;
    padding-left: 5.25rem !important;
  }
  .px-xxl-22 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }
  .px-xxl-23 {
    padding-right: 5.75rem !important;
    padding-left: 5.75rem !important;
  }
  .px-xxl-24 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xxl-25 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }
  .px-xxl-26 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }
  .px-xxl-27 {
    padding-right: 6.75rem !important;
    padding-left: 6.75rem !important;
  }
  .px-xxl-28 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-xxl-29 {
    padding-right: 7.25rem !important;
    padding-left: 7.25rem !important;
  }
  .px-xxl-30 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-xxl-31 {
    padding-right: 7.75rem !important;
    padding-left: 7.75rem !important;
  }
  .px-xxl-32 {
    padding-right: 8.25rem !important;
    padding-left: 8.25rem !important;
  }
  .px-xxl-33 {
    padding-right: 8.5rem !important;
    padding-left: 8.5rem !important;
  }
  .px-xxl-34 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important;
  }
  .px-xxl-35 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-xxl-36 {
    padding-right: 9.25rem !important;
    padding-left: 9.25rem !important;
  }
  .px-xxl-37 {
    padding-right: 9.5rem !important;
    padding-left: 9.5rem !important;
  }
  .px-xxl-38 {
    padding-right: 9.75rem !important;
    padding-left: 9.75rem !important;
  }
  .px-xxl-39 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-xxl-40 {
    padding-right: 10.25rem !important;
    padding-left: 10.25rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-xxl-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-xxl-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }
  .py-xxl-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xxl-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-xxl-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xxl-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }
  .py-xxl-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xxl-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }
  .py-xxl-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-xxl-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-xxl-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xxl-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }
  .py-xxl-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-xxl-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }
  .py-xxl-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xxl-21 {
    padding-top: 5.25rem !important;
    padding-bottom: 5.25rem !important;
  }
  .py-xxl-22 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }
  .py-xxl-23 {
    padding-top: 5.75rem !important;
    padding-bottom: 5.75rem !important;
  }
  .py-xxl-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xxl-25 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .py-xxl-26 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }
  .py-xxl-27 {
    padding-top: 6.75rem !important;
    padding-bottom: 6.75rem !important;
  }
  .py-xxl-28 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-xxl-29 {
    padding-top: 7.25rem !important;
    padding-bottom: 7.25rem !important;
  }
  .py-xxl-30 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-xxl-31 {
    padding-top: 7.75rem !important;
    padding-bottom: 7.75rem !important;
  }
  .py-xxl-32 {
    padding-top: 8.25rem !important;
    padding-bottom: 8.25rem !important;
  }
  .py-xxl-33 {
    padding-top: 8.5rem !important;
    padding-bottom: 8.5rem !important;
  }
  .py-xxl-34 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important;
  }
  .py-xxl-35 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-xxl-36 {
    padding-top: 9.25rem !important;
    padding-bottom: 9.25rem !important;
  }
  .py-xxl-37 {
    padding-top: 9.5rem !important;
    padding-bottom: 9.5rem !important;
  }
  .py-xxl-38 {
    padding-top: 9.75rem !important;
    padding-bottom: 9.75rem !important;
  }
  .py-xxl-39 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-xxl-40 {
    padding-top: 10.25rem !important;
    padding-bottom: 10.25rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 0.75rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1rem !important;
  }
  .pt-xxl-5 {
    padding-top: 1.25rem !important;
  }
  .pt-xxl-6 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-7 {
    padding-top: 1.75rem !important;
  }
  .pt-xxl-8 {
    padding-top: 2rem !important;
  }
  .pt-xxl-9 {
    padding-top: 2.25rem !important;
  }
  .pt-xxl-10 {
    padding-top: 2.5rem !important;
  }
  .pt-xxl-11 {
    padding-top: 2.75rem !important;
  }
  .pt-xxl-12 {
    padding-top: 3rem !important;
  }
  .pt-xxl-13 {
    padding-top: 3.25rem !important;
  }
  .pt-xxl-14 {
    padding-top: 3.5rem !important;
  }
  .pt-xxl-15 {
    padding-top: 3.75rem !important;
  }
  .pt-xxl-16 {
    padding-top: 4rem !important;
  }
  .pt-xxl-17 {
    padding-top: 4.25rem !important;
  }
  .pt-xxl-18 {
    padding-top: 4.5rem !important;
  }
  .pt-xxl-19 {
    padding-top: 4.75rem !important;
  }
  .pt-xxl-20 {
    padding-top: 5rem !important;
  }
  .pt-xxl-21 {
    padding-top: 5.25rem !important;
  }
  .pt-xxl-22 {
    padding-top: 5.5rem !important;
  }
  .pt-xxl-23 {
    padding-top: 5.75rem !important;
  }
  .pt-xxl-24 {
    padding-top: 6rem !important;
  }
  .pt-xxl-25 {
    padding-top: 6.25rem !important;
  }
  .pt-xxl-26 {
    padding-top: 6.5rem !important;
  }
  .pt-xxl-27 {
    padding-top: 6.75rem !important;
  }
  .pt-xxl-28 {
    padding-top: 7rem !important;
  }
  .pt-xxl-29 {
    padding-top: 7.25rem !important;
  }
  .pt-xxl-30 {
    padding-top: 7.5rem !important;
  }
  .pt-xxl-31 {
    padding-top: 7.75rem !important;
  }
  .pt-xxl-32 {
    padding-top: 8.25rem !important;
  }
  .pt-xxl-33 {
    padding-top: 8.5rem !important;
  }
  .pt-xxl-34 {
    padding-top: 8.75rem !important;
  }
  .pt-xxl-35 {
    padding-top: 9rem !important;
  }
  .pt-xxl-36 {
    padding-top: 9.25rem !important;
  }
  .pt-xxl-37 {
    padding-top: 9.5rem !important;
  }
  .pt-xxl-38 {
    padding-top: 9.75rem !important;
  }
  .pt-xxl-39 {
    padding-top: 10rem !important;
  }
  .pt-xxl-40 {
    padding-top: 10.25rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 0.75rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1rem !important;
  }
  .pe-xxl-5 {
    padding-right: 1.25rem !important;
  }
  .pe-xxl-6 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-7 {
    padding-right: 1.75rem !important;
  }
  .pe-xxl-8 {
    padding-right: 2rem !important;
  }
  .pe-xxl-9 {
    padding-right: 2.25rem !important;
  }
  .pe-xxl-10 {
    padding-right: 2.5rem !important;
  }
  .pe-xxl-11 {
    padding-right: 2.75rem !important;
  }
  .pe-xxl-12 {
    padding-right: 3rem !important;
  }
  .pe-xxl-13 {
    padding-right: 3.25rem !important;
  }
  .pe-xxl-14 {
    padding-right: 3.5rem !important;
  }
  .pe-xxl-15 {
    padding-right: 3.75rem !important;
  }
  .pe-xxl-16 {
    padding-right: 4rem !important;
  }
  .pe-xxl-17 {
    padding-right: 4.25rem !important;
  }
  .pe-xxl-18 {
    padding-right: 4.5rem !important;
  }
  .pe-xxl-19 {
    padding-right: 4.75rem !important;
  }
  .pe-xxl-20 {
    padding-right: 5rem !important;
  }
  .pe-xxl-21 {
    padding-right: 5.25rem !important;
  }
  .pe-xxl-22 {
    padding-right: 5.5rem !important;
  }
  .pe-xxl-23 {
    padding-right: 5.75rem !important;
  }
  .pe-xxl-24 {
    padding-right: 6rem !important;
  }
  .pe-xxl-25 {
    padding-right: 6.25rem !important;
  }
  .pe-xxl-26 {
    padding-right: 6.5rem !important;
  }
  .pe-xxl-27 {
    padding-right: 6.75rem !important;
  }
  .pe-xxl-28 {
    padding-right: 7rem !important;
  }
  .pe-xxl-29 {
    padding-right: 7.25rem !important;
  }
  .pe-xxl-30 {
    padding-right: 7.5rem !important;
  }
  .pe-xxl-31 {
    padding-right: 7.75rem !important;
  }
  .pe-xxl-32 {
    padding-right: 8.25rem !important;
  }
  .pe-xxl-33 {
    padding-right: 8.5rem !important;
  }
  .pe-xxl-34 {
    padding-right: 8.75rem !important;
  }
  .pe-xxl-35 {
    padding-right: 9rem !important;
  }
  .pe-xxl-36 {
    padding-right: 9.25rem !important;
  }
  .pe-xxl-37 {
    padding-right: 9.5rem !important;
  }
  .pe-xxl-38 {
    padding-right: 9.75rem !important;
  }
  .pe-xxl-39 {
    padding-right: 10rem !important;
  }
  .pe-xxl-40 {
    padding-right: 10.25rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 1.75rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 2rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 2.25rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xxl-11 {
    padding-bottom: 2.75rem !important;
  }
  .pb-xxl-12 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-13 {
    padding-bottom: 3.25rem !important;
  }
  .pb-xxl-14 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xxl-15 {
    padding-bottom: 3.75rem !important;
  }
  .pb-xxl-16 {
    padding-bottom: 4rem !important;
  }
  .pb-xxl-17 {
    padding-bottom: 4.25rem !important;
  }
  .pb-xxl-18 {
    padding-bottom: 4.5rem !important;
  }
  .pb-xxl-19 {
    padding-bottom: 4.75rem !important;
  }
  .pb-xxl-20 {
    padding-bottom: 5rem !important;
  }
  .pb-xxl-21 {
    padding-bottom: 5.25rem !important;
  }
  .pb-xxl-22 {
    padding-bottom: 5.5rem !important;
  }
  .pb-xxl-23 {
    padding-bottom: 5.75rem !important;
  }
  .pb-xxl-24 {
    padding-bottom: 6rem !important;
  }
  .pb-xxl-25 {
    padding-bottom: 6.25rem !important;
  }
  .pb-xxl-26 {
    padding-bottom: 6.5rem !important;
  }
  .pb-xxl-27 {
    padding-bottom: 6.75rem !important;
  }
  .pb-xxl-28 {
    padding-bottom: 7rem !important;
  }
  .pb-xxl-29 {
    padding-bottom: 7.25rem !important;
  }
  .pb-xxl-30 {
    padding-bottom: 7.5rem !important;
  }
  .pb-xxl-31 {
    padding-bottom: 7.75rem !important;
  }
  .pb-xxl-32 {
    padding-bottom: 8.25rem !important;
  }
  .pb-xxl-33 {
    padding-bottom: 8.5rem !important;
  }
  .pb-xxl-34 {
    padding-bottom: 8.75rem !important;
  }
  .pb-xxl-35 {
    padding-bottom: 9rem !important;
  }
  .pb-xxl-36 {
    padding-bottom: 9.25rem !important;
  }
  .pb-xxl-37 {
    padding-bottom: 9.5rem !important;
  }
  .pb-xxl-38 {
    padding-bottom: 9.75rem !important;
  }
  .pb-xxl-39 {
    padding-bottom: 10rem !important;
  }
  .pb-xxl-40 {
    padding-bottom: 10.25rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 0.75rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1rem !important;
  }
  .ps-xxl-5 {
    padding-left: 1.25rem !important;
  }
  .ps-xxl-6 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-7 {
    padding-left: 1.75rem !important;
  }
  .ps-xxl-8 {
    padding-left: 2rem !important;
  }
  .ps-xxl-9 {
    padding-left: 2.25rem !important;
  }
  .ps-xxl-10 {
    padding-left: 2.5rem !important;
  }
  .ps-xxl-11 {
    padding-left: 2.75rem !important;
  }
  .ps-xxl-12 {
    padding-left: 3rem !important;
  }
  .ps-xxl-13 {
    padding-left: 3.25rem !important;
  }
  .ps-xxl-14 {
    padding-left: 3.5rem !important;
  }
  .ps-xxl-15 {
    padding-left: 3.75rem !important;
  }
  .ps-xxl-16 {
    padding-left: 4rem !important;
  }
  .ps-xxl-17 {
    padding-left: 4.25rem !important;
  }
  .ps-xxl-18 {
    padding-left: 4.5rem !important;
  }
  .ps-xxl-19 {
    padding-left: 4.75rem !important;
  }
  .ps-xxl-20 {
    padding-left: 5rem !important;
  }
  .ps-xxl-21 {
    padding-left: 5.25rem !important;
  }
  .ps-xxl-22 {
    padding-left: 5.5rem !important;
  }
  .ps-xxl-23 {
    padding-left: 5.75rem !important;
  }
  .ps-xxl-24 {
    padding-left: 6rem !important;
  }
  .ps-xxl-25 {
    padding-left: 6.25rem !important;
  }
  .ps-xxl-26 {
    padding-left: 6.5rem !important;
  }
  .ps-xxl-27 {
    padding-left: 6.75rem !important;
  }
  .ps-xxl-28 {
    padding-left: 7rem !important;
  }
  .ps-xxl-29 {
    padding-left: 7.25rem !important;
  }
  .ps-xxl-30 {
    padding-left: 7.5rem !important;
  }
  .ps-xxl-31 {
    padding-left: 7.75rem !important;
  }
  .ps-xxl-32 {
    padding-left: 8.25rem !important;
  }
  .ps-xxl-33 {
    padding-left: 8.5rem !important;
  }
  .ps-xxl-34 {
    padding-left: 8.75rem !important;
  }
  .ps-xxl-35 {
    padding-left: 9rem !important;
  }
  .ps-xxl-36 {
    padding-left: 9.25rem !important;
  }
  .ps-xxl-37 {
    padding-left: 9.5rem !important;
  }
  .ps-xxl-38 {
    padding-left: 9.75rem !important;
  }
  .ps-xxl-39 {
    padding-left: 10rem !important;
  }
  .ps-xxl-40 {
    padding-left: 10.25rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 0.75rem !important;
  }
  .gap-xxl-4 {
    gap: 1rem !important;
  }
  .gap-xxl-5 {
    gap: 1.25rem !important;
  }
  .gap-xxl-6 {
    gap: 1.5rem !important;
  }
  .gap-xxl-7 {
    gap: 1.75rem !important;
  }
  .gap-xxl-8 {
    gap: 2rem !important;
  }
  .gap-xxl-9 {
    gap: 2.25rem !important;
  }
  .gap-xxl-10 {
    gap: 2.5rem !important;
  }
  .gap-xxl-11 {
    gap: 2.75rem !important;
  }
  .gap-xxl-12 {
    gap: 3rem !important;
  }
  .gap-xxl-13 {
    gap: 3.25rem !important;
  }
  .gap-xxl-14 {
    gap: 3.5rem !important;
  }
  .gap-xxl-15 {
    gap: 3.75rem !important;
  }
  .gap-xxl-16 {
    gap: 4rem !important;
  }
  .gap-xxl-17 {
    gap: 4.25rem !important;
  }
  .gap-xxl-18 {
    gap: 4.5rem !important;
  }
  .gap-xxl-19 {
    gap: 4.75rem !important;
  }
  .gap-xxl-20 {
    gap: 5rem !important;
  }
  .gap-xxl-21 {
    gap: 5.25rem !important;
  }
  .gap-xxl-22 {
    gap: 5.5rem !important;
  }
  .gap-xxl-23 {
    gap: 5.75rem !important;
  }
  .gap-xxl-24 {
    gap: 6rem !important;
  }
  .gap-xxl-25 {
    gap: 6.25rem !important;
  }
  .gap-xxl-26 {
    gap: 6.5rem !important;
  }
  .gap-xxl-27 {
    gap: 6.75rem !important;
  }
  .gap-xxl-28 {
    gap: 7rem !important;
  }
  .gap-xxl-29 {
    gap: 7.25rem !important;
  }
  .gap-xxl-30 {
    gap: 7.5rem !important;
  }
  .gap-xxl-31 {
    gap: 7.75rem !important;
  }
  .gap-xxl-32 {
    gap: 8.25rem !important;
  }
  .gap-xxl-33 {
    gap: 8.5rem !important;
  }
  .gap-xxl-34 {
    gap: 8.75rem !important;
  }
  .gap-xxl-35 {
    gap: 9rem !important;
  }
  .gap-xxl-36 {
    gap: 9.25rem !important;
  }
  .gap-xxl-37 {
    gap: 9.5rem !important;
  }
  .gap-xxl-38 {
    gap: 9.75rem !important;
  }
  .gap-xxl-39 {
    gap: 10rem !important;
  }
  .gap-xxl-40 {
    gap: 10.25rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 0.75rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 1.25rem !important;
  }
  .row-gap-xxl-6 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-7 {
    row-gap: 1.75rem !important;
  }
  .row-gap-xxl-8 {
    row-gap: 2rem !important;
  }
  .row-gap-xxl-9 {
    row-gap: 2.25rem !important;
  }
  .row-gap-xxl-10 {
    row-gap: 2.5rem !important;
  }
  .row-gap-xxl-11 {
    row-gap: 2.75rem !important;
  }
  .row-gap-xxl-12 {
    row-gap: 3rem !important;
  }
  .row-gap-xxl-13 {
    row-gap: 3.25rem !important;
  }
  .row-gap-xxl-14 {
    row-gap: 3.5rem !important;
  }
  .row-gap-xxl-15 {
    row-gap: 3.75rem !important;
  }
  .row-gap-xxl-16 {
    row-gap: 4rem !important;
  }
  .row-gap-xxl-17 {
    row-gap: 4.25rem !important;
  }
  .row-gap-xxl-18 {
    row-gap: 4.5rem !important;
  }
  .row-gap-xxl-19 {
    row-gap: 4.75rem !important;
  }
  .row-gap-xxl-20 {
    row-gap: 5rem !important;
  }
  .row-gap-xxl-21 {
    row-gap: 5.25rem !important;
  }
  .row-gap-xxl-22 {
    row-gap: 5.5rem !important;
  }
  .row-gap-xxl-23 {
    row-gap: 5.75rem !important;
  }
  .row-gap-xxl-24 {
    row-gap: 6rem !important;
  }
  .row-gap-xxl-25 {
    row-gap: 6.25rem !important;
  }
  .row-gap-xxl-26 {
    row-gap: 6.5rem !important;
  }
  .row-gap-xxl-27 {
    row-gap: 6.75rem !important;
  }
  .row-gap-xxl-28 {
    row-gap: 7rem !important;
  }
  .row-gap-xxl-29 {
    row-gap: 7.25rem !important;
  }
  .row-gap-xxl-30 {
    row-gap: 7.5rem !important;
  }
  .row-gap-xxl-31 {
    row-gap: 7.75rem !important;
  }
  .row-gap-xxl-32 {
    row-gap: 8.25rem !important;
  }
  .row-gap-xxl-33 {
    row-gap: 8.5rem !important;
  }
  .row-gap-xxl-34 {
    row-gap: 8.75rem !important;
  }
  .row-gap-xxl-35 {
    row-gap: 9rem !important;
  }
  .row-gap-xxl-36 {
    row-gap: 9.25rem !important;
  }
  .row-gap-xxl-37 {
    row-gap: 9.5rem !important;
  }
  .row-gap-xxl-38 {
    row-gap: 9.75rem !important;
  }
  .row-gap-xxl-39 {
    row-gap: 10rem !important;
  }
  .row-gap-xxl-40 {
    row-gap: 10.25rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 0.75rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 1.25rem !important;
  }
  .column-gap-xxl-6 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-7 {
    column-gap: 1.75rem !important;
  }
  .column-gap-xxl-8 {
    column-gap: 2rem !important;
  }
  .column-gap-xxl-9 {
    column-gap: 2.25rem !important;
  }
  .column-gap-xxl-10 {
    column-gap: 2.5rem !important;
  }
  .column-gap-xxl-11 {
    column-gap: 2.75rem !important;
  }
  .column-gap-xxl-12 {
    column-gap: 3rem !important;
  }
  .column-gap-xxl-13 {
    column-gap: 3.25rem !important;
  }
  .column-gap-xxl-14 {
    column-gap: 3.5rem !important;
  }
  .column-gap-xxl-15 {
    column-gap: 3.75rem !important;
  }
  .column-gap-xxl-16 {
    column-gap: 4rem !important;
  }
  .column-gap-xxl-17 {
    column-gap: 4.25rem !important;
  }
  .column-gap-xxl-18 {
    column-gap: 4.5rem !important;
  }
  .column-gap-xxl-19 {
    column-gap: 4.75rem !important;
  }
  .column-gap-xxl-20 {
    column-gap: 5rem !important;
  }
  .column-gap-xxl-21 {
    column-gap: 5.25rem !important;
  }
  .column-gap-xxl-22 {
    column-gap: 5.5rem !important;
  }
  .column-gap-xxl-23 {
    column-gap: 5.75rem !important;
  }
  .column-gap-xxl-24 {
    column-gap: 6rem !important;
  }
  .column-gap-xxl-25 {
    column-gap: 6.25rem !important;
  }
  .column-gap-xxl-26 {
    column-gap: 6.5rem !important;
  }
  .column-gap-xxl-27 {
    column-gap: 6.75rem !important;
  }
  .column-gap-xxl-28 {
    column-gap: 7rem !important;
  }
  .column-gap-xxl-29 {
    column-gap: 7.25rem !important;
  }
  .column-gap-xxl-30 {
    column-gap: 7.5rem !important;
  }
  .column-gap-xxl-31 {
    column-gap: 7.75rem !important;
  }
  .column-gap-xxl-32 {
    column-gap: 8.25rem !important;
  }
  .column-gap-xxl-33 {
    column-gap: 8.5rem !important;
  }
  .column-gap-xxl-34 {
    column-gap: 8.75rem !important;
  }
  .column-gap-xxl-35 {
    column-gap: 9rem !important;
  }
  .column-gap-xxl-36 {
    column-gap: 9.25rem !important;
  }
  .column-gap-xxl-37 {
    column-gap: 9.5rem !important;
  }
  .column-gap-xxl-38 {
    column-gap: 9.75rem !important;
  }
  .column-gap-xxl-39 {
    column-gap: 10rem !important;
  }
  .column-gap-xxl-40 {
    column-gap: 10.25rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
html,
body,
.wrapper {
  height: 100%;
}

a {
  transition: all 0.15s ease-in-out;
}

.bg-gradient-1 {
  background: linear-gradient(92.73deg, #b324d7 7.07%, #fe577f 97.81%);
}

.text-gradient-1 {
  background: linear-gradient(92.73deg, #b324d7 7.07%, #fe577f 97.81%);
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}

.btn-gradient-1 {
  background: linear-gradient(92.73deg, #b324d7 7.07%, #fe577f 97.81%);
  color: #fff;
  border: 0;
}
.btn-gradient-1:hover, .btn-gradient-1:focus, .btn-check:checked + .btn-gradient-1, :not(.btn-check) + .btn-gradient-1:active, .btn-gradient-1:first-child:active, .btn-gradient-1.active, .btn-gradient-1.show {
  color: #fff;
}

.bg-gradient-2 {
  background: linear-gradient(90.22deg, #58ebff 0.19%, #e6f85e 101.84%);
}

.text-gradient-2 {
  background: linear-gradient(90.22deg, #58ebff 0.19%, #e6f85e 101.84%);
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}

.btn-gradient-2 {
  background: linear-gradient(90.22deg, #58ebff 0.19%, #e6f85e 101.84%);
  color: #fff;
  border: 0;
}
.btn-gradient-2:hover, .btn-gradient-2:focus, .btn-check:checked + .btn-gradient-2, :not(.btn-check) + .btn-gradient-2:active, .btn-gradient-2:first-child:active, .btn-gradient-2.active, .btn-gradient-2.show {
  color: #fff;
}

.bg-gradient-3 {
  background: linear-gradient(270deg, rgba(50, 51, 55, 0.4) 50%, rgba(70, 79, 111, 0.2) 100%);
}

.text-gradient-3 {
  background: linear-gradient(270deg, rgba(50, 51, 55, 0.4) 50%, rgba(70, 79, 111, 0.2) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}

.btn-gradient-3 {
  background: linear-gradient(270deg, rgba(50, 51, 55, 0.4) 50%, rgba(70, 79, 111, 0.2) 100%);
  color: #fff;
  border: 0;
}
.btn-gradient-3:hover, .btn-gradient-3:focus, .btn-check:checked + .btn-gradient-3, :not(.btn-check) + .btn-gradient-3:active, .btn-gradient-3:first-child:active, .btn-gradient-3.active, .btn-gradient-3.show {
  color: #fff;
}

.animate-scale {
  animation-name: scale;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes scale {
  0%, 100% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.1);
  }
  75% {
    transform: scale(0.9);
  }
}
.typed-animation {
  white-space: nowrap;
}

[data-aos=fade-up-sm] {
  opacity: 0;
  transition-property: transform, opacity;
}
[data-aos=fade-up-sm].aos-animate {
  opacity: 1;
}
@media screen and (min-width: 768px) {
  [data-aos=fade-up-sm] {
    transform: translate3d(0, 20px, 0) rotateY(10deg) !important;
  }
  [data-aos=fade-up-sm].aos-animate {
    transform: translate3d(0, 0, 0) rotateY(0deg) !important;
  }
}

[data-aos=fade-down-sm] {
  opacity: 0;
  transition-property: transform, opacity;
}
[data-aos=fade-down-sm].aos-animate {
  opacity: 1;
}
@media screen and (min-width: 768px) {
  [data-aos=fade-down-sm] {
    transform: translate3d(0, -20px, 0) !important;
  }
  [data-aos=fade-down-sm].aos-animate {
    transform: translate3d(0, 0, 0) !important;
  }
}

[data-aos=fade-left-sm] {
  opacity: 0;
  transition-property: transform, opacity;
}
[data-aos=fade-left-sm].aos-animate {
  opacity: 1;
}
@media screen and (min-width: 768px) {
  [data-aos=fade-left-sm] {
    transform: translate3d(20px, 0, 0) !important;
  }
  [data-aos=fade-left-sm].aos-animate {
    transform: translate3d(0, 0, 0) !important;
  }
}

[data-aos=fade-right-sm] {
  opacity: 0;
  transition-property: transform, opacity;
}
[data-aos=fade-right-sm].aos-animate {
  opacity: 1;
}
@media screen and (min-width: 768px) {
  [data-aos=fade-right-sm] {
    transform: translate3d(-20px, 0, 0) !important;
  }
  [data-aos=fade-right-sm].aos-animate {
    transform: translate3d(0, 0, 0) !important;
  }
}

.rolling-carousel-wrapper {
  transition-timing-function: linear;
}

.swiper-pagination {
  position: static;
  display: flex;
  align-items: center;
  gap: 10px;
}

.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  display: block;
  border-radius: 1000px;
  background: #eae6ff;
  opacity: 1;
  transition: all 0.2s ease-in-out;
}
.swiper-pagination-bullet-active {
  background: #6a4dff;
  opacity: 1;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0;
}

.account-btn {
  color: #11121c;
  border: 1px solid #bdbfd4;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.account-btn .icon {
  width: 1.5rem;
  height: 1.5rem;
}
.account-btn:hover, .account-btn:focus, .btn-check:checked + .account-btn, :not(.btn-check) + .account-btn:active, .account-btn:first-child:active, .account-btn.active, .account-btn.show {
  color: #11121c;
  border: 1px solid #6a4dff;
  background-color: transparent;
}

.arrow-link {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  position: relative;
  transition: all 0.2s ease-in-out;
}
.arrow-link .icon {
  width: 1rem;
  height: 1rem;
}
.arrow-link::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 1px;
  background: #6a4dff;
  transition: all 0.2s ease-in-out;
}
.arrow-link:hover {
  color: #6a4dff;
}
.arrow-link:hover::after {
  width: 100%;
}
.arrow-link-primary {
  color: #262838;
}
.arrow-link-primary::after {
  background: #6a4dff;
}
.arrow-link-primary:hover {
  color: #6a4dff;
}
.arrow-link-primary-dark {
  color: #747694;
}
.arrow-link-primary-dark::after {
  background: #e6f85e;
}
.arrow-link-primary-dark:hover {
  color: #e6f85e;
}

.bg-striped {
  background-image: url("../images/shapes/stripe-light.svg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}
.bg-striped[data-bs-theme=dark] {
  background-image: url("../images/shapes/stripe-dark.svg");
}
.bg-striped-sm {
  background-image: url("../images/shapes/stripe-sm-light.svg");
}
.bg-striped-sm[data-bs-theme=dark] {
  background-image: url("../images/shapes/stripe-sm-dark.svg");
}
.bg-striped-bottom {
  background-position: center bottom;
  background-size: contain;
}

@media (min-width: 992px) {
  .divider-x > *:not(:first-child) {
    border-left: 1px solid #bdbfd4;
  }
}
.breadcrumb-item a {
  color: inherit;
  text-decoration: none;
}
.breadcrumb-item a:not(.active):hover {
  color: #6a4dff;
}

.video-play-btn {
  width: 4rem;
  height: 4rem;
  border-radius: 1000px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video-play-btn .icon {
  width: 2.5rem;
  height: 2.5rem;
}
@media (min-width: 992px) {
  .video-play-btn {
    width: 7rem;
    height: 7rem;
  }
}

.link-list a {
  color: #11121c;
  display: inline-block;
  font-size: 0.875rem;
  padding: 5px 0;
  text-decoration: none;
}
.link-list a:hover {
  color: #6a4dff;
  padding-left: 5px;
}

.social-list li {
  line-height: 1;
}
.social-list a {
  display: inline-block;
  padding: 5px;
  color: #11121c;
}
.social-list a:hover {
  color: #6a4dff;
}
.social-list svg {
  width: 1.25rem;
  height: 1.25rem;
  display: block;
}

.divider-with-text {
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-align: center;
  border-block-start: 0 #bdbfd4;
}
.divider-with-text::before, .divider-with-text::after {
  position: relative;
  width: 50%;
  border-block-start: 1px solid transparent;
  border-block-start-color: inherit;
  border-block-end: 0;
  transform: translateY(50%);
  content: "";
}
.divider-with-text span {
  display: inline-block;
  padding: 0 1em;
}

.brand-img {
  opacity: 0.7;
  transition: all 0.2s ease-in-out;
}
.brand-img:hover {
  opacity: 1;
}

.navbar-brand img {
  max-width: 165px;
}

.navbar-toggler {
  padding: 5px;
  border: 0;
}
.navbar-toggler:focus {
  box-shadow: 0 0 0 0.25rem rgba(106, 77, 255, 0.2);
}
.navbar-toggler .navbar-toggler-icon {
  position: relative;
  width: 2rem;
  height: 1.5rem;
  background: none;
}
.navbar-toggler .navbar-toggler-icon span {
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  border-radius: 100px;
  background: #6a4dff;
  transition: all 0.2s ease-in-out;
  transform: rotate(0);
  transform-origin: center;
  will-change: transform;
}
.navbar-toggler .navbar-toggler-icon span:first-child {
  top: 0;
}
.navbar-toggler .navbar-toggler-icon span:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}
.navbar-toggler .navbar-toggler-icon span:last-child {
  top: calc(100% - 3px);
}
.navbar-toggler[aria-expanded=true] .navbar-toggler-icon span:first-child {
  top: 50%;
  transform: rotate(-45deg);
}
.navbar-toggler[aria-expanded=true] .navbar-toggler-icon span:nth-child(2) {
  opacity: 0;
}
.navbar-toggler[aria-expanded=true] .navbar-toggler-icon span:last-child {
  top: 50%;
  transform: rotate(45deg);
}

.dropdown-toggle::after {
  border-style: solid;
  border-width: 0.1em 0.1em 0 0;
  border-color: inherit;
  content: "";
  display: inline-block;
  height: 0.45em;
  transform: rotate(135deg);
  vertical-align: 0.15em;
  width: 0.45em;
}

.dropdown-item {
  position: relative;
}
.dropdown-item:hover, .dropdown-item:focus {
  transform: translateX(5px);
}

@media (min-width: 992px) {
  .navbar-nav .dropdown-menu {
    display: block;
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
    transition: all 0.2s ease-in-out;
  }
  .navbar-nav .dropdown-menu.show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
@media (min-width: 992px) {
  .navbar-nav .dropdown:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
@media (min-width: 992px) {
  .navbar-nav .megamenu-cols-2 {
    columns: 2;
  }
}
@media (min-width: 992px) {
  .navbar-nav .megamenu-cols-3 {
    columns: 3;
  }
}

@media (max-width: 991.98px) {
  .navbar-content-inner {
    max-height: 70vh;
    overflow-y: auto;
  }
}
.headroom {
  will-change: transform;
  transition: all 0.2s ease-in-out;
}

.headroom--pinned {
  transform: translateY(0%);
  opacity: 1;
  box-shadow: 0 5px 15px rgba(106, 77, 255, 0.2);
}

.headroom--unpinned {
  transform: translateY(-100%);
  opacity: 0;
}

.headroom--top {
  box-shadow: none;
}

.navbar.bg-hover-scroll:hover, .navbar.bg-hover-scroll.headroom--not-top {
  background: #fff;
}

.hero-section {
  position: relative;
  z-index: 1;
}
.hero-section.style-1 .image-with-shape {
  position: relative;
}
.hero-section.style-1 .image-with-shape .shape {
  position: absolute;
  top: -12rem;
  left: -9rem;
  width: 700px;
  height: auto;
  z-index: -1;
}
@media (min-width: 768px) {
  .hero-section.style-1 .image-with-shape .shape {
    top: -15rem;
    left: -10rem;
    width: 1000px;
  }
}
@media (min-width: 1400px) {
  .hero-section.style-1 .image-with-shape .shape {
    top: -25rem;
    left: -18rem;
    width: 1500px;
  }
}
.hero-section.style-2 {
  overflow: hidden;
}
.hero-section.style-2 .striped-shape {
  position: absolute;
  bottom: 10rem;
  left: 0;
  right: 0;
  opacity: 0.7;
  z-index: -2;
}
.hero-section.style-2 .image-with-shape {
  position: relative;
}
.hero-section.style-2 .image-with-shape .shape {
  position: absolute;
  top: -5rem;
  left: -7rem;
  width: 500px;
  height: auto;
  z-index: -1;
}
@media (min-width: 768px) {
  .hero-section.style-2 .image-with-shape .shape {
    top: -8rem;
    left: -7rem;
    width: 1000px;
  }
}
@media (min-width: 992px) {
  .hero-section.style-2 .image-with-shape .shape {
    top: -10rem;
    left: -7rem;
    width: 800px;
  }
}
@media (min-width: 1400px) {
  .hero-section.style-2 .image-with-shape .shape {
    top: -6rem;
    left: -9rem;
    width: 1000px;
  }
}
.hero-section.gradient-bg::before {
  content: "";
  position: absolute;
  inset: 0;
  width: 100%;
  height: 60%;
  background: url(../images/bg/1.jpg) no-repeat center center/cover;
  z-index: -1;
}
@media (min-width: 992px) {
  .hero-section.gradient-bg::before {
    height: 70%;
  }
}

.review-badges img {
  max-width: 150px;
}
@media (min-width: 768px) {
  .review-badges img {
    max-width: 185px;
  }
}

.brand-images img {
  display: block;
  max-width: 155px;
  max-height: 55px;
}

.input-group.with-icon {
  position: relative;
}
.input-group.with-icon .form-control {
  padding-left: 3rem;
}
.input-group.with-icon .icon {
  position: absolute;
  width: 3rem;
  height: 3rem;
  z-index: 1000;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-group.with-icon .icon svg {
  width: 18px;
}

.features-section .blurry-shape {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1000px;
  z-index: -1;
}

.list-check li {
  display: flex;
  gap: 1rem;
}
.list-check li .icon {
  width: 1.25rem;
  height: 1.25rem;
  margin-top: 2px;
}
.list-check li + li {
  margin-top: 0.5rem;
}

.hover-border {
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
}
.hover-border:hover {
  border-color: #6a4dff;
}

.pricing-card {
  border: 1px solid #c3d1e3;
  transition: all 0.2s ease-in-out;
  position: relative;
}
.pricing-card:hover, .pricing-card.active {
  border-color: #6a4dff;
}
.pricing-card:hover .pricing-btn, .pricing-card.active .pricing-btn {
  color: #fff;
  background-color: #6a4dff;
  border-color: #6a4dff;
}
.pricing-card:hover {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
}
.pricing-card .badge {
  position: absolute;
  top: 2rem;
  right: 0;
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper [type=radio] {
  position: absolute;
  left: -9999px;
}

.switch-wrapper [type=radio]:checked#monthly ~ label[for=monthly],
.switch-wrapper [type=radio]:checked#yearly ~ label[for=yearly] {
  color: #fff;
}

.switch-wrapper [type=radio]:checked#monthly ~ label[for=monthly]:hover,
.switch-wrapper [type=radio]:checked#yearly ~ label[for=yearly]:hover {
  background: transparent;
}

.switch-wrapper [type=radio]:checked#monthly + label[for=yearly] ~ .highlighter {
  transform: none;
}

.switch-wrapper [type=radio]:checked#yearly + label[for=monthly] ~ .highlighter {
  transform: translateX(100%);
}

.switch-wrapper label {
  z-index: 1;
  min-width: 100px;
  cursor: pointer;
  border-radius: 8px;
  text-align: center;
  display: inline-block;
  padding: 0.5rem 1rem;
  transition: all 0.2s ease-in-out;
  font-weight: 500;
}

.switch-wrapper label:hover {
  color: #6a4dff;
}

.switch-wrapper .highlighter {
  position: absolute;
  top: 8px;
  left: 8px;
  width: calc(50% - 8px);
  height: calc(100% - 16px);
  border-radius: 8px;
  background: #6a4dff;
  transition: all 0.2s ease-in-out;
}

.accordion-item {
  border: 0;
}

.accordion-button {
  font-size: clamp(1.125rem, 0.8571rem + 0.8571vw, 1.5rem);
  gap: 1.5rem;
  border-bottom: 1px solid #c3d1e3;
}
.accordion-button:hover {
  color: #6a4dff;
}
.accordion-button:focus {
  box-shadow: none;
  color: #6a4dff;
  border-color: #6a4dff;
}
.accordion-button::after {
  display: none;
}
.accordion-button .icon,
.accordion-button .icon::after {
  display: block;
  box-sizing: border-box;
  background: currentColor;
  border-radius: 10px;
  transition: opacity 0.15s linear;
  flex-shrink: 0;
}
.accordion-button .icon {
  margin-top: -2px;
  position: relative;
  transform: scale(1);
  width: 16px;
  height: 2px;
}
.accordion-button .icon::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 16px;
  top: -7px;
  left: 7px;
}
.accordion-button:not(.collapsed) {
  color: #6a4dff;
  border-color: #6a4dff;
  box-shadow: none;
}
.accordion-button:not(.collapsed) .icon::after {
  opacity: 0;
}

.cta-image-container {
  position: relative;
}
.cta-image-container .arrow-shape {
  position: absolute;
  top: -3rem;
  left: 65%;
  transform: translateX(-50%);
  width: 33px;
  height: 90px;
}

.cta-img {
  max-height: 450px;
  overflow: hidden;
  background: linear-gradient(white, white) padding-box, linear-gradient(to bottom, #6a4dff, transparent) border-box;
  border: 1px solid transparent;
  border-bottom: 0;
}

.cta-section {
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.cta-section .bg-shape {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
  opacity: 0.5;
  z-index: -1;
}
@media (min-width: 992px) {
  .cta-section .bg-shape {
    width: 85%;
    opacity: 1;
  }
}
.cta-section.style-2 .cta-image-container .arrow-shape {
  position: absolute;
  top: -3rem;
  left: auto;
  right: 2rem;
  width: 50px;
  transform: none;
}
@media (min-width: 992px) {
  .cta-section.style-2 .cta-image-container .arrow-shape {
    top: -3rem;
    left: -3rem;
    width: 82px;
    height: 40px;
  }
}
@media (min-width: 1200px) {
  .cta-section.style-2 .cta-image-container .arrow-shape {
    top: 1rem;
    left: -6rem;
  }
}
.cta-section.style-2 .cta-img {
  background: none;
  border: 1px solid #bdbfd4;
}
.cta-section .gradient-bg {
  position: relative;
  overflow: hidden;
}
.cta-section .gradient-bg::before {
  content: "";
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background: url(../images/bg/1.jpg) no-repeat center center/cover;
  z-index: -1;
  border-radius: inherit;
}

.blog-card .post-title a {
  color: #11121c;
  text-decoration: none;
}
.blog-card .post-title a:hover {
  color: #6a4dff;
}

.meta-list li + li {
  padding-left: 10px;
}
.meta-list li + li::before {
  content: "-";
  float: left;
  padding-right: 10px;
}

.post-author a {
  color: inherit;
  text-decoration: none;
}
.post-author a:hover {
  color: #6a4dff;
}

article h1, article .h1,
article h2,
article .h2,
article h3,
article .h3,
article h4,
article .h4,
article h5,
article .h5,
article h6,
article .h6 {
  margin-bottom: 1.5rem;
}
article p,
article ul,
article ol {
  margin-bottom: 1.5rem;
  line-height: 1.625;
}
article img {
  margin-bottom: 2rem;
}

.pagination .page-link {
  width: 40px;
  height: 40px;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination .page-link.disabled {
  opacity: 0.8;
}

.team-card .team-member-img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 1000px;
  display: inline-block;
}

.account-wrapper {
  max-width: 460px;
  margin: auto;
}

@media (min-width: 768px) {
  .close-btn {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
  }
}

.footer {
  position: relative;
  z-index: 1;
}
.footer.bg-color-blur::before {
  content: "";
  position: absolute;
  inset: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: -1;
  filter: blur(100px);
  background: url(../images/shapes/stripe-colored.svg) no-repeat center center/cover;
}

.navbar[data-bs-theme=dark] {
  --gen-navbar-color: #bdbfd4;
  --gen-navbar-hover-color: #e6f85e;
  --gen-navbar-disabled-color: rgba(#bdbfd4, 0.5);
  --gen-navbar-active-color: #e6f85e;
}
.navbar[data-bs-theme=dark].bg-hover-scroll:hover, .navbar[data-bs-theme=dark].bg-hover-scroll.headroom--not-top {
  background: #11121c;
}
.navbar[data-bs-theme=dark] .navbar-toggler:focus {
  box-shadow: 0 0 0 0.25rem rgba(230, 248, 94, 0.2);
}
.navbar[data-bs-theme=dark] .navbar-toggler .navbar-toggler-icon span {
  background: #e6f85e;
}

[data-bs-theme=dark] .navbar.bg-hover-scroll:hover, [data-bs-theme=dark] .navbar.bg-hover-scroll.headroom--not-top {
  background: #11121c;
}
[data-bs-theme=dark] .dropdown-menu {
  --gen-dropdown-link-hover-color: #e6f85e;
  --gen-dropdown-link-active-color: #e6f85e;
}
[data-bs-theme=dark] .headroom--pinned {
  box-shadow: 0 5px 15px rgba(46, 47, 69, 0.2);
}
[data-bs-theme=dark] .headroom--top {
  box-shadow: none;
}
[data-bs-theme=dark] .bg-striped {
  background-image: url("../images/shapes/stripe-dark.svg");
}
[data-bs-theme=dark] .bg-striped-sm {
  background-image: url("../images/shapes/stripe-sm-dark.svg");
}
[data-bs-theme=dark] article h1, [data-bs-theme=dark] article .h1,
[data-bs-theme=dark] article h2,
[data-bs-theme=dark] article .h2,
[data-bs-theme=dark] article h3,
[data-bs-theme=dark] article .h3,
[data-bs-theme=dark] article h4,
[data-bs-theme=dark] article .h4,
[data-bs-theme=dark] article h5,
[data-bs-theme=dark] article .h5,
[data-bs-theme=dark] article h6,
[data-bs-theme=dark] article .h6 {
  color: #fff;
}
[data-bs-theme=dark] .pricing-card {
  border-color: rgba(255, 255, 255, 0.1);
}
[data-bs-theme=dark] .pricing-card:hover, [data-bs-theme=dark] .pricing-card.active {
  border-color: #e6f85e;
}
[data-bs-theme=dark] .pricing-card:hover .pricing-btn, [data-bs-theme=dark] .pricing-card.active .pricing-btn {
  color: #11121c;
  background-color: #e6f85e;
  border-color: #e6f85e;
}
[data-bs-theme=dark] .switch-wrapper [type=radio]:checked#monthly ~ label[for=monthly],
[data-bs-theme=dark] .switch-wrapper [type=radio]:checked#yearly ~ label[for=yearly] {
  color: #11121c;
}
[data-bs-theme=dark] .switch-wrapper label:hover {
  color: #e6f85e;
}
[data-bs-theme=dark] .switch-wrapper .highlighter {
  background: #e6f85e;
}
[data-bs-theme=dark] .accordion-button {
  border-color: #2e2f45;
}
[data-bs-theme=dark] .accordion-button:hover {
  color: #e6f85e;
}
[data-bs-theme=dark] .accordion-button:focus {
  color: #e6f85e;
  border-color: #e6f85e;
}
[data-bs-theme=dark] .accordion-button:not(.collapsed) {
  color: #e6f85e;
  border-color: #e6f85e;
}
[data-bs-theme=dark] .cta-img {
  background: linear-gradient(white, white) padding-box, linear-gradient(to bottom, #e6f85e, transparent) border-box;
}
[data-bs-theme=dark] .cta-section.style-2 .cta-img {
  border: 1px solid #2e2f45;
}
[data-bs-theme=dark] .form-control:focus {
  border-color: #e6f85e;
}
[data-bs-theme=dark] .link-list a {
  color: #bdbfd4;
}
[data-bs-theme=dark] .link-list a:hover {
  color: #e6f85e;
}
[data-bs-theme=dark] .social-list a {
  color: #bdbfd4;
}
[data-bs-theme=dark] .social-list a:hover {
  color: #e6f85e;
}
[data-bs-theme=dark] .hover-border:hover {
  border-color: #e6f85e;
}
@media (min-width: 992px) {
  [data-bs-theme=dark] .divider-x > *:not(:first-child) {
    border-color: #2e2f45;
  }
}
[data-bs-theme=dark] .swiper-pagination-bullet {
  background: #2e2f45;
}
[data-bs-theme=dark] .swiper-pagination-bullet-active {
  background: #e6f85e;
}
[data-bs-theme=dark] .blog-card .post-title a {
  color: #fff;
}
[data-bs-theme=dark] .blog-card .post-title a:hover {
  color: #e6f85e;
}
[data-bs-theme=dark] .post-author a:hover {
  color: #e6f85e;
}
[data-bs-theme=dark] .pagination {
  --gen-pagination-active-color: #11121c;
  --gen-pagination-active-bg: #e6f85e;
  --gen-pagination-active-border-color: #e6f85e;
  --gen-pagination-disabled-color: #bdbfd4;
  --gen-pagination-disabled-bg: #2e2f45;
  --gen-pagination-disabled-border-color: #2e2f45;
}
[data-bs-theme=dark] .account-btn {
  color: #fff;
  border-color: #2e2f45;
}
[data-bs-theme=dark] .account-btn:hover, [data-bs-theme=dark] .account-btn:focus, .btn-check:checked + [data-bs-theme=dark] .account-btn, :not(.btn-check) + [data-bs-theme=dark] .account-btn:active, [data-bs-theme=dark] .account-btn:first-child:active, [data-bs-theme=dark] .account-btn.active, [data-bs-theme=dark] .account-btn.show {
  color: #fff;
  border-color: #e6f85e;
}
[data-bs-theme=dark] .divider-with-text {
  border-block-start: 0 #2e2f45;
}